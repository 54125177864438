import { put, takeLatest, call, all, select } from "redux-saga/effects";
import getConfig from "next/config";
import AddressBookConstants from "../../src/action-constants/address-book-constants";
import errorLogger from "../../app_configs/logger-service";
import {
  getSavedAddresses,
  updateAddress,
  getEditSavedAddress,
  deleteAddressCard,
} from "../../src/services/api/myAccount/addressBookApi";
import {
  getSavedAddressForPinCode,
  updateAddressForItem,
  getEditAddressForItem,
} from "../../src/services/api/checkout/addressBookApi";
import checkoutActions from "../../src/actions/checkout-actions";
import CHECKOUT_CONSTANTS from "../../src/action-constants/checkout-constants";
import { verifySelectedContactMechIdsWithSavedAddress } from "../../src/utils/checkout";

const { publicRuntimeConfig } = getConfig();
const SHOW_REQUESTS_LOGS = publicRuntimeConfig.SHOW_REQUESTS_LOGS || "Y";

/**
 * fetches list of addresses for account address book
 */
function* getAccountAddressListData() {
  try {
    const { data } = yield call(getSavedAddresses);
    if (data.isSuccess) {
      yield put({
        type: AddressBookConstants.GET_ACCOUNT_ADDRESS_LIST,
        payload: { addressList: data.data.addressList },
      });
    }
  } catch (error) {
    yield put({
      type: AddressBookConstants.GET_ACCOUNT_ADDRESS_LIST_FAILURE,
    });
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}

/**
 * fetches list of addresses for checkout address book
 *
 * @param {object} action - action object for action type and payload
 */
function* getCheckoutAddressListData(action) {
  try {
    const { data } = yield call(getSavedAddressForPinCode, action.payload);
    if (data.isSuccess) {
      yield put({
        type: AddressBookConstants.GET_CHECKOUT_ADDRESS_LIST,
        payload: { addressList: data.addressList },
      });
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}

/**
 * save edit address details in case of account address
 *
 * @param {object} action - action object for action type and payload *
 */
function* setEditAccountAddressBookCard(action) {
  try {
    const { addressDetail } = action.payload;
    const params = {
      postalAddress: addressDetail.id,
      toName: addressDetail.name,
      address: addressDetail.address,
      city: addressDetail.city,
      countryGeoId: addressDetail.countryGeoId,
      contactMechPurpse: "SHIPPING_LOCATION",
      postalcode: addressDetail.postalCode,
      countryCode: addressDetail.mobileCode,
      phone: addressDetail.mobileNumber,
      telecomNumber: addressDetail.telecomNumber,
      telecomPurposeId: "PHONE_SHIPPING",
      altPhone: addressDetail.altMobileNumber,
      altTelecomPurposeId: "PHONE_SHIPPING",
      addressType: addressDetail.addressType,
      attnName: addressDetail.email,
      address2: addressDetail.localityArea || (addressDetail.address2 && addressDetail.address2.trim()),
      latitude: addressDetail.latitude,
      longitude: addressDetail.longitude,
      doorBellName: addressDetail.doorBell,
      intlpostalcode: addressDetail.intlpostalcode,
      stateProvinceGeoId: addressDetail.stateProvinceGeoId,
      addrSalutation: addressDetail.salutation,
      flatHouseNo: addressDetail.flatHouseNo,
      directions: addressDetail.directions,
      landmark: addressDetail.landmark,
    };
    if (Object.keys(addressDetail).indexOf("addressId") !== -1) {
      params.address2 = "";
      params.addressId = addressDetail.addressId;
      params.houseNo = addressDetail.houseNo;
      params.landmark = addressDetail.landmark;
    }
    const { data } = yield call(updateAddress, params);
    if (data.isSuccess) {
      yield put({
        type: AddressBookConstants.EDIT_ADDRESS_CARD,
        payload: { addressDetail },
      });
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}

/**
 * save edit address details in case of checkout address
 *
 * @param {object} action - action object for action type and payload
 */
function* setEditCheckoutAddressBookCard(action) {
  try {
    const { giftIndex, cartIndex, addressDetail, isEdit } = action.payload;
    let params;
    if (typeof addressDetail === "object") {
      params = {
        [`address_line_1_${cartIndex}`]: (addressDetail.address && addressDetail.address) || "_NA_",
        [`addressId_${cartIndex}`]: (addressDetail.addressId && addressDetail.addressId) || null,
        [`address_line_2_${cartIndex}`]: addressDetail.localityArea,
        [`latitude_${cartIndex}`]: addressDetail.latitude,
        [`longitude_${cartIndex}`]: addressDetail.longitude,
        [`rCountryCode_${cartIndex}`]: addressDetail.mobileCode,
        [`landmark_${cartIndex}`]: addressDetail.landmark,
        [`aRCountryCode_${cartIndex}`]: addressDetail.mobileCode,
        [`countryGeoId_${cartIndex}`]: addressDetail.countryGeoId,
        [`postalcode_${cartIndex}`]: addressDetail.postalCode,
        [`salutation_${cartIndex}`]: addressDetail.salutation,
        [`rname_${cartIndex}`]: addressDetail.name,
        [`selectedContactMechId_${cartIndex}`]: isEdit ? "editaddress" : "newaddress",
        [`recipientEmail_${cartIndex}`]: addressDetail.email,
        [`rTel_${cartIndex}`]: addressDetail.mobileNumber,
        [`aRTel_${cartIndex}`]: addressDetail.altMobileNumber,
        [`city_${cartIndex}`]: (addressDetail.city && addressDetail.city) || "_NA_",
        [`editContactMechId_${cartIndex}`]: (addressDetail?.id && addressDetail.id) || "",
        [`address2_${cartIndex}`]: addressDetail.intlpostalcode,
        [`address_line_3_${cartIndex}`]: addressDetail.houseNo,
        [`doorBellName_${cartIndex}`]: addressDetail.doorBell,
        [`addressType_${cartIndex}`]: addressDetail.addressType,
        [`stateGeoId_${cartIndex}`]: "_NA_",
        [`flatHouseNo_${cartIndex}`]: addressDetail.flatHouseNo,
        [`directions_${cartIndex}`]: addressDetail.directions,
        cartItemIndex: cartIndex,
      };
    } else {
      params = {
        cartItemIndex: cartIndex,
        [`selectedContactMechId_${cartIndex}`]: addressDetail,
      };
    }
    if (action.requestapi) {
      const { data } = yield call(updateAddressForItem, params);
      if (data.isSuccess) {
        yield put({
          type: AddressBookConstants.EDIT_ADDRESS_CARD,
        });
        if (!action.isMobile) {
          const cartResponse = yield all([
            call(checkoutActions.getCartExpiry),
            call(checkoutActions.getCompleteCartItemDetails),
            call(checkoutActions.getCartSummaryAmountDetail),
          ]);

          if (
            cartResponse[0]?.data?.isSuccess &&
            cartResponse[1]?.data?.isSuccess &&
            cartResponse[2]?.data?.isSuccess
          ) {
            yield put({ type: CHECKOUT_CONSTANTS.FETCH_CART_EXPIRY_SUCCESS, payload: { data: cartResponse[0]?.data } });
            yield put({
              type: CHECKOUT_CONSTANTS.FETCH_COMPLETE_CART_SUCCESS,
              payload: { data: cartResponse[1]?.data },
            });
            yield put({
              type: CHECKOUT_CONSTANTS.FETCH_CART_SUMMARY_AMOUNT_SUCCESS,
              payload: { data: cartResponse[2]?.data },
            });
          } else {
            // To be updated once ErrorLogger is ready
            // Console.error(cartResponse[0]?.data?.errorMsg || cartResponse[1]?.data?.errorMsg || cartResponse[2]?.data?.errorMsg);
          }
          if (cartResponse[1]?.data?.isSuccess) {
            let selectedContactMechIdList = yield select((store) => store.checkout.selectedContactMechIdList);
            const selectedContactMechId = cartResponse[1]?.data?.orderList?.find(
              (gift) => gift?.giftItem?.giftIndex === giftIndex,
            )?.giftItem?.selectedAddressDetails?.selectedContactMechId;
            if (selectedContactMechId) {
              selectedContactMechIdList[giftIndex] = selectedContactMechId;
            }
            // again verifying the address ids in the saved address in deleted or edited case
            selectedContactMechIdList = verifySelectedContactMechIdsWithSavedAddress(
              selectedContactMechIdList,
              cartResponse[1]?.data?.orderList,
            );
            yield put({
              type: CHECKOUT_CONSTANTS.SET_GIFT_ITEM_DEFAULT_ADDRESS,
              payload: selectedContactMechIdList,
            });
          }
        }
      }
    } else {
      yield put({
        type: AddressBookConstants.GET_EDIT_ADDRESS_DATA_SUCCESS,
        payload: { addressDetail },
      });
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}

/**
 * fetches pre-filled address details for account edit address form
 *
 * @param {object} action - action object for action type and payload
 */
function* getAccountEditAddressData(action) {
  try {
    const { data } = yield call(getEditSavedAddress, action.payload);
    if (data.isSuccess) {
      const detail = data.data;
      const addressDetails = {
        salutation: detail.salutation,
        name: detail.toName,
        address: detail.address1,
        city: detail.city,
        postalCode: detail.postalCode,
        address2: detail.address2,
        mobileCode: detail.teleCode,
        mobileNumber: detail.mobileNumber,
        altMobileNumber: detail.altRecpNumber,
        email: detail.email,
        addressType: detail.addressType,
        countryGeoId: detail.countryGeoId,
        id: detail.contactMechId,
        doorBell: detail.doorBell,
        landmark: detail.landmark,
        houseNo: detail.houseNo,
        flatHouseNo: detail.flatHouseNo,
        directions: detail.areaLocality,
        localityArea: detail.address2,
      };
      if (Object.keys(detail).indexOf("addressId") !== -1) {
        addressDetails.addressId = detail.addressId;
        addressDetails.houseNo = detail.houseNo;
        addressDetails.landmark = detail.landmark;
      }
      yield put({
        type: AddressBookConstants.GET_EDIT_ADDRESS_DATA_SUCCESS,
        payload: { addressDetails },
      });
    }
  } catch (error) {
    yield put({
      type: AddressBookConstants.GET_EDIT_ADDRESS_DATA_FAILURE,
    });
  }
}

/**
 * fetches pre-filled address details for checkout edit address form
 *
 * @param {object} action - action object for action type and payload
 */
function* getCheckoutEditAddressData(action) {
  try {
    const { data } = yield call(getEditAddressForItem, action.payload);

    if (data.isSuccess) {
      const detail = data.addressdetails;
      const addressDetails = {
        salutation: detail.salutationId,
        name: detail.recNameVal,
        address: detail.address1,
        city: detail.city,
        postalCode: detail.postalCode,
        address2: detail.address2Value,
        mobileCode: detail.telCountryVal,
        mobileNumber: detail.mobileNumber,
        altMobileNumber: detail.altRecpNumber,
        email: detail.emailVal,
        addressType: detail.addrsTypeVal,
        countryGeoId: detail.countryGeoId,
        id: detail.contactMechId,
        doorBell: detail.doorBell || "",
        landmark: detail.landmark,
        houseNo: detail.houseNo,
        addressId: detail.addressId,
        flatHouseNo: detail.flatHouseNo,
        directions: detail.areaLocality,
      };
      yield put({
        type: AddressBookConstants.GET_EDIT_ADDRESS_DATA_SUCCESS,
        payload: { addressDetails },
      });
    }
  } catch (error) {
    yield put({
      type: AddressBookConstants.GET_EDIT_ADDRESS_DATA_FAILURE,
    });
  }
}
/**
 * delete address details
 *
 * @param {object} action - action object for action type and payload
 */
function* deleteAddressBookCard(action) {
  try {
    const { data } = yield call(deleteAddressCard, action.payload);
    if (data.isSuccess) {
      yield put({
        type: AddressBookConstants.DELETE_ADDRESS_CARD,
        payload: { addressId: data.contactMechId },
      });
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}

/**
 * watcher saga for address book
 */
function* watchAddressBookPageRequest() {
  yield takeLatest(AddressBookConstants.GET_ACCOUNT_ADDRESS_LIST_REQUESTED, getAccountAddressListData);
  yield takeLatest(AddressBookConstants.GET_CHECKOUT_ADDRESS_LIST_REQUESTED, getCheckoutAddressListData);
  yield takeLatest(AddressBookConstants.DELETE_ADDRESS_CARD_REQUESTED, deleteAddressBookCard);
  yield takeLatest(AddressBookConstants.SET_EDIT_ACCOUNT_ADDRESS_REQUESTED, setEditAccountAddressBookCard);
  yield takeLatest(AddressBookConstants.SET_EDIT_CHECKOUT_ADDRESS_REQUESTED, setEditCheckoutAddressBookCard);
  yield takeLatest(AddressBookConstants.GET_ACCOUNT_EDIT_ADDRESS_DATA, getAccountEditAddressData);
  yield takeLatest(AddressBookConstants.GET_CHECKOUT_EDIT_ADDRESS_DATA, getCheckoutEditAddressData);
}

export default watchAddressBookPageRequest;
