import { getEarliestDeliveryDateString } from "./common";
import ListingPageConstants from "../action-constants/listing-contants";
/**
 * Populating Products List data to store
 *
 * @param {object} response - response object consisting of new products fetched from API
 * @param {object} options - sort options, etc.
 * @returns {object} returns data object
 */
export const getProductListingData = (response, options) => {
  const results = response?.data?.productResults || [];

  const {
    startRows,
    viewSize,
    CategoryRatingValue,
    CategoryTotalReview,
    productCategoryName,
    listSize,
    sortOptions,
    facetRangeOptions,
  } = response?.data || {};

  return {
    results,
    startRows,
    viewSize,
    categoryRating: CategoryRatingValue,
    categoryReviews: CategoryTotalReview,
    productCategoryName,
    listSize,
    options,
    sortOptions,
    facetRangeOptions,
  };
};

/**
 * Updating Category Products Data in case on Infinite Scroll
 *
 * @param {object} response - response object consisting of new products fetched after a scroll
 * @param {object} options - sort options, etc.
 * @param {object} productList - productList from store
 * @returns {object} returns data object
 */
export const getUpdatedProductListingData = (response, options, productList) => {
  const productListingData = response.data?.data?.productResults || [];
  const alreadyPopulated = productList?.productsList || [];
  const { params } = options;

  return {
    results: [...alreadyPopulated, ...productListingData],
    startRows: params.viewIndex,
    viewSize: params.viewSize,
    productCategoryName: productList.productCategoryName,
    listSize: productList.listSize,
    categoryRating: productList.categoryRating,
    categoryReviews: productList.categoryReviews,
    options,
    sortOptions: productList.sortOptions,
    facetRangeOptions: productList.facetRangeOptions,
  };
};

/**
 * Populating Products Earliest Delivery Data To The Store
 *
 * @param {object} deliveryDates This is dictionary which contains the delivery date
 * with respect to product id.
 * @returns {Array} returns an array of products with updated earliest delivery dates.
 */
export const populateEarliestDeliveries = (deliveryDates) => {
  const modifiedDates = Object.keys(deliveryDates).reduce((acc, productId) => {
    acc[productId] =
      deliveryDates[productId] === ListingPageConstants.NOT_APPLICABLE
        ? ListingPageConstants.NOT_APPLICABLE
        : getEarliestDeliveryDateString(deliveryDates[productId]);
    return acc;
  }, {});
  return modifiedDates;
};

/**
 * Receiving the list of products and returning their ids.
 *
 * @param {Array} allProducts list of products in store.
 * @param {number} viewSize size of list on scroll.
 * @returns {Array} returns an array of product ids.
 */
export const getProductIds = (allProducts, viewSize) => {
  const allProductsIds = [];
  allProducts.slice(allProducts.length - viewSize).forEach((product) => {
    if (!product.earliestDeliveryDate) {
      allProductsIds.push(product.productId);
    }
  });
  return allProductsIds;
};
