import { HYDRATE } from "next-redux-wrapper";
import ListingPageConstants from "../../../src/action-constants/listing-contants";

const initialState = {
  productsList: [],
  startRows: 0,
  viewSize: 40,
  categoryRating: 0,
  categoryReviews: 0,
  productCategoryName: "",
  listSize: 0,
  searchOptions: {},
  breadCrumbs: [],
  categoryDesc: {},
  longDescription: "",
  shortDescription: "",
  categoryReviewsCollection: [],
  bannerDetails: "",
  isFetching: false,
  errMsg: "",
  sortOptions: [],
  svnData: "",
  aggregateRating: {},
  dataType: "",
  svnCategoryDesc: {},
  svnBreadCrumbs: {},
  isEnableToShowAddToCartPlp: false,
  isLoading: false,
};

/**
 * This function is a reducer for all the product lists.
 * It includes data like productList, listsize etc.
 *
 * @param {object} state it represents the current state object
 * @param {object} action it represents the new action object which consists of the new payload.
 * @returns {object} Updated state object with the action object.
 */
function productsListsReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.productsLists,
      };
    case ListingPageConstants.SET_PRODUCTS_LISTING:
      return {
        ...state,
        productsList: action.payload.results,
        startRows: action.payload.startRows,
        viewSize: action.payload.viewSize,
        categoryRating: action.payload.categoryRating,
        categoryReviews: action.payload.categoryReviews,
        productCategoryName: action.payload.productCategoryName,
        listSize: action.payload.listSize,
        searchOptions: action.payload.options,
        isFetching: false,
        dataType: "",
        sortOptions: action.payload.sortOptions,
        facetRangeOptions: action.payload.facetRangeOptions,
        svnData: "",
        errMsg: "",
      };
    case ListingPageConstants.SET_BREAD_CRUMBS_DATA:
      return {
        ...state,
        breadCrumbs: action.payload.breadCrumbs,
        categoryDesc: action.payload.categoryDesc,
        longDescription: action.payload.categoryDesc.categoryLongDescription
          ? action.payload.categoryDesc.categoryLongDescription
          : "",
        shortDescription: action.payload.categoryDesc.categoryDescription
          ? action.payload.categoryDesc.categoryDescription
          : "",
      };
    case ListingPageConstants.SET_CATEGORY_REVIEWS:
      return {
        ...state,
        categoryReviewsCollection: [...action.payload],
      };
    case ListingPageConstants.SET_BANNER_DETAILS:
      return {
        ...state,
        bannerDetails: action.payload,
      };
    case ListingPageConstants.SORTING_PRODUCTS_LIST:
      return {
        ...state,
        productsList: [],
        isFetching: true,
      };
    case ListingPageConstants.SORTING_PRODUCTS_FAILED:
      return {
        ...state,
        isFetching: false,
      };
    case ListingPageConstants.CURRENCY_CHANGES_DONE:
      return {
        ...state,
        productsList: action.payload.productList,
      };
    case ListingPageConstants.CLEAR_PRODUCTS_LISTING:
      return initialState;
    case ListingPageConstants.SET_SVN_MICROSITE_DATA:
      return {
        ...state,
        productsList: [],
        searchOptions: {},
        svnData: action.payload.data.htmlDOM,
        aggregateRating: action.payload.data.aggregateRating,
        dataType: action.payload.dataType,
        productCategoryName: action.payload.data.aggregateRating.categoryName,
      };
    case ListingPageConstants.SET_PLP_SVN_BREAD_CRUMBS:
      return {
        ...state,
        svnBreadCrumbs: action.payload.breadcrumbs,
        svnCategoryDesc: action.payload.svnCategoryDesc,
      };
    case ListingPageConstants.PRODUCT_LISTING_ERROR_MSG:
      return {
        ...state,
        errMsg: action.payload,
      };
    case ListingPageConstants.SET_OMNITURE_DETAILS:
      return {
        ...state,
        omnitureTagDetails: action.payload,
      };
    case ListingPageConstants.SET_ENABLE_ADD_TO_CART:
      return {
        ...state,
        isEnableToShowAddToCartPlp: action.payload,
      };
    case ListingPageConstants.SET_PRODUCT_LISTING_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
}

export default productsListsReducer;
