import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";

/**
 * This function handle the chat widget open
 */
const handleChatWithUs = () => {
  if (window.fcWidget) {
    window.fcWidget?.open();
    window.fcWidget?.show();
  }
};

/**
 * *This method returns desktop info footer jsx.
 *
 * @param {object}root0 The main parameter.
 * @param {Array}root0.infoFooterItems The info footer items
 * @param {Function}root0.icon function to convert svg
 * @param {object}root0.isShowCallUs object configration to show chat or call center number
 * @returns {React.ReactElement} the main jsx for the info footer.
 */
function infoFooter({ infoFooterItems, icon, isShowCallUs }) {
  const infoFooterPanel = infoFooterItems.map((infoFooterItem) => (
    <li key={infoFooterItem.heading}>
      {icon(infoFooterItem.id)}
      <h6>{infoFooterItem.heading}</h6>

      {isShowCallUs?.switch === "N" && infoFooterItem.id === "support" ? (
        <button type="button" className={styles.footerchat} onClick={handleChatWithUs}>
          Chat with us
        </button>
      ) : (
        <p>{infoFooterItem.subheading}</p>
      )}
    </li>
  ));

  return (
    <div className={styles.infoFooterContainer}>
      <ul className={styles.infoFooter}>{infoFooterPanel}</ul>
    </div>
  );
}
infoFooter.propTypes = {
  infoFooterItems: PropTypes.instanceOf(Array).isRequired,
  icon: PropTypes.func.isRequired,
};
export default infoFooter;
