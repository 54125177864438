import { HYDRATE } from "next-redux-wrapper";
import APP_CONSTANTS from "../../../src/action-constants/app-actions";

const defaultState = {
  rootGeoId: "",
  configData: {},
  isMobile: false,
  baseApiUrl: "",
  apiUrl: "",
  host: "",
  show_logs: false,
  isInfo: false,
  isVerbose: false,
  catalogId: "india",
  category: "",
  loaderShow: false,
  fnpPageName: "",
  assetsUrl: "",
};
/**
 * This function is a reducer for all the configurations.
 * The configuration includes data like isMobile, catalogId etc
 *
 * @param {object} state it represents the current state object
 * @param {object} action it represents the new action object which consists of the new payload.
 * @returns {object} Updated state object with the action object.
 */
function configurationsReducer(state = defaultState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.appConfigs,
      };
    case APP_CONSTANTS.SET_STATIC_APP_CONFIGS:
      return {
        ...state,
        rootGeoId: action.payload.rootGeoId,
        baseApiUrl: action.payload.baseApiUrl,
        apiUrl: action.payload.apiUrl,
        host: action.payload.host,
        show_logs: action.payload.show_logs,
        isInfo: action.payload.isInfo,
        isVerbose: action.payload.isVerbose,
        rootCatalogId: action.payload.rootCatalogId,
        assetsUrl: action.payload.assetsUrl,
      };
    case APP_CONSTANTS.SET_DYNAMIC_APP_CONFIGS:
      return {
        ...state,
        catalogId: action.payload.catalogId,
        category: action.payload.category,
        isMobile: action.payload.isMobile,
        configData: {
          ...state.configData,
          ...action.payload.configData,
        },
      };
    case APP_CONSTANTS.SET_ORG_POSTAL_DATA:
      return {
        ...state,
        orgPostalSchema: action.payload.orgPostalData,
      };
    case APP_CONSTANTS.PAGE_TRANSITION_LOADER_SHOW:
      return {
        ...state,
        loaderShow: true,
      };
    case APP_CONSTANTS.PAGE_TRANSITION_LOADER_HIDE:
      return {
        ...state,
        loaderShow: false,
      };
    case APP_CONSTANTS.SET_PAGE_NAME:
      return {
        ...state,
        fnpPageName: action.payload,
      };
    case APP_CONSTANTS.SET_CATALOG_ID:
      return {
        ...state,
        catalogId: action.payload,
      };

    default:
      return state;
  }
}

export default configurationsReducer;
