import queryString from "query-string";
import axios from "../../app_configs/axios/base";
import { execDataLayer } from "../data-layer/dataLayer";
import { endpointConst } from "../data-layer/dataLayerConstants";

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
};

/**
 * This method is for password
 *
 *
 * @param {string} data - return event
 * @returns {void} - returns nothing
 */
export async function loginApi(data) {
  try {
    /**
     * @function apiFn wrapper function to execute login call from datalayer.
     * @returns response from datalayer
     */
    const apiFn = async () => {
      return axios.post(`${endpointConst.login}`, data, { headers });
    };
    const res = await execDataLayer(apiFn, endpointConst.login);
    return res;
  } catch (ex) {
    return ex;
  }
}

/**
 * This method is for password
 *
 *
 * @param {string} data - return event
 * @returns {void} - returns nothing
 */
export async function validIdApi(data) {
  try {
    const resp = await axios.post("control/qcle-rj", data, { headers });
    return resp;
  } catch (ex) {
    return ex;
  }
}

/**
 * This method is for password
 *
 *
 * @param {string} data - return event
 * @returns {void} - returns nothing
 */
export async function checkLoginApi(data) {
  try {
    const resp = await axios.post("control/qcl-rj", data, { headers });
    return resp;
  } catch (ex) {
    return ex;
  }
}

/**
 * This method is for password
 *
 *
 * @param {string} data - return event
 * @returns {void} - returns nothing
 */
export async function createUserApi(data) {
  try {
    /**
     * @function apiFn wrapper function to execute sign up call from datalayer.
     * @returns response from datalayer
     */
    const apiFn = async () => {
      return axios.post(`${endpointConst.signUp}`, data, { headers });
    };
    const res = await execDataLayer(apiFn, endpointConst.signUp);
    return res;
  } catch (ex) {
    return ex;
  }
}

/**
 * This method is for password
 *
 *
 * @param {string} data - return event
 * @returns {void} - returns nothing
 */
export async function sendOtpApi(data) {
  try {
    const resp = await axios.post("control/uotp-rj", data, { headers });
    return resp;
  } catch (ex) {
    return ex;
  }
}

/**
 * This method is for password
 *
 *
 * @param {string} data - return event
 * @returns {void} - returns nothing
 */
export async function validateOtpApi(data) {
  try {
    /**
     * @function apiFn wrapper function to execute otp login call from datalayer.
     * @returns response from datalayer
     */
    const apiFn = async () => {
      return axios.post(`${endpointConst.otpLogin}`, data, { headers });
    };
    const res = await execDataLayer(apiFn, endpointConst.otpLogin);
    return res;
  } catch (ex) {
    return ex;
  }
}

/**
 * This method is for password
 *
 *
 * @param {string} data - return event
 * @returns {void} - returns nothing
 */
export async function setPasswordApi(data) {
  try {
    /**
     * @function apiFn wrapper function to execute new password call from datalayer.
     * @returns response from datalayer
     */
    const apiFn = async () => {
      return axios.post(`${endpointConst.newPasswordLogin}`, data, { headers });
    };
    const res = await execDataLayer(apiFn, endpointConst.newPasswordLogin);
    return res;
  } catch (ex) {
    return ex;
  }
}

/**
 * This method is for fetching mobile validation present or not
 *
 * @returns {object} - returns json
 */
export async function mobileValidation() {
  try {
    const resp = await axios.get("control/getContentIdDetails-rj?contentId=APP_FEATURE_CONTROL", { headers });
    return resp;
  } catch (ex) {
    return ex;
  }
}
/**
 * This method is for calling google response call for one tap login
 *
 * @param jwtToken jwtToken
 *
 * @returns {object} - returns json
 */
export async function getGoogleResponseCall(jwtToken) {
  try {
    return await axios.get(`control/googleResponse?DONE_PAGE=${encodeURIComponent(window.location.href)}`, {
      headers: {
        jwtToken,
      },
    });
  } catch (ex) {
    return ex;
  }
}

/**
 *  @function setLoggedInPincode This method is for posting loggedIn user pincode details to database
 *
 * @param {object} data contains pincode and email of loggedIn user
 * @returns {object|null} addUserPincodeDetails data or null .
 */
export async function setLoggedInPincode(data) {
  try {
    return await axios.post(
      `control/addUserPincodeDetails?pincode=${data?.pincode}&userLoginId=${data?.email}&customerAddress=${data?.customerAddress}`,
    );
  } catch (ex) {
    return ex;
  }
}
/**
 *  @function initiateSocialLogin will initiate social login call
 *
 * @param {object} query
 */
export async function initiateSocialLogin(query) {
  /**
   * @function apiFn wrapper function to execute social login redirection from datalayer.
   */
  function apiFn() {
    window.location.href = `${window.location.origin}${endpointConst.socialLogin}?${queryString.stringify(query)}`;
  }
  execDataLayer(apiFn, endpointConst.socialLogin);
}
