import find from "lodash/find";
import { MONTH_NAMES, DAY, isMiniAppUserAgent, isMobileDevice } from "./common";

const isMobile = isMobileDevice();

export const paymentIds = {
  cod: "COD",
  card: "CDC",
  creditCard: "CC",
  debitCard: "DC",
  motoPayment: "motoPayment",
  netBanking: "NB",
  payLater: "PAYLATER",
  paytm: "PAYTM",
  paymentLink: "paymentLink",
  payOffline: "payOffline",
  paypal: "PAYPAL",
  phonePe: "PHONEPE",
  savedCards: "SC",
  upi: "UPI",
  wallets: "WALLETS",
  googlePay: "GPAY",
  billingAccount: "billingAccount",
  buyNowPayLater: "BNPL",
};

export const allPaymentOptions = [
  {
    title: "Pay Later",
    route: "emi",
    id: paymentIds.payLater,
  },
  {
    title: "Cash On Delivery",
    route: "COD",
    id: paymentIds.cod,
  },
  {
    title: "Credit/Debit Card",
    route: "card",
    id: paymentIds.card,
  },
  {
    title: "Net Banking",
    route: "net-banking",
    id: paymentIds.netBanking,
  },
  {
    title: "UPI",
    route: "upi",
    id: paymentIds.upi,
  },
  {
    title: "Wallets",
    route: "wallet",
    id: paymentIds.wallets,
  },
  {
    title: "PayPal",
    route: "paypal",
    id: paymentIds.paypal,
  },
  {
    title: "GooglePay",
    route: "googlepay",
    id: paymentIds.googlePay,
  },
  {
    title: "Recommended for you",
    route: "sc",
    id: paymentIds.savedCards,
  },
  {
    title: "Pay Later",
    route: "bnpl",
    id: paymentIds.buyNowPayLater,
  },
];
export const paymentOptionsAccordian = [
  {
    title: "Credit/Debit Card",
    route: "card",
    id: paymentIds.card,
  },
  {
    title: "Wallets",
    route: "wallet",
    id: paymentIds.wallets,
  },
  {
    title: "UPI",
    route: "upi",
    id: paymentIds.upi,
  },

  {
    title: "Net Banking",
    route: "net-banking",
    id: paymentIds.netBanking,
  },
  {
    title: "PayPal",
    route: "paypal",
    id: paymentIds.paypal,
  },
  {
    title: "GooglePay",
    route: "googlepay",
    id: paymentIds.googlePay,
  },
  {
    title: "Recommended for you",
    route: "sc",
    id: paymentIds.savedCards,
  },
  {
    title: "Pay Later",
    route: "bnpl",
    id: paymentIds.buyNowPayLater,
  },
];

export const agentPaymentOptions = [
  {
    title: "Payment Link",
    route: "paymentLink",
    id: paymentIds.paymentLink,
  },
  {
    title: "Moto Payment",
    route: "motoPayment",
    id: paymentIds.motoPayment,
  },
  {
    title: "Pay Offline",
    route: "payOffline",
    id: paymentIds.payOffline,
  },
];

export const redirectedPaymentOptions = ["Paytm Wallet, Postpaid", "PhonePe", "PayPal"];

export const paymentGateways = {
  RPAY: "EXT_RPAY",
  PAYU: "EXT_PAYU",
  ICICI: "EXT_ICICI",
  MOTO: "EXT_MOTO",
  OFFLINE: "EXT_OFFLINE",
};

export const paymentHashRoutes = {
  SIMPL_ERROR_REDIRECTED: "#simpl-error",
};

/**
 * This method determines all the available payment options
 *
 * @param {object} paymentDetails - all payment related info
 * @param {Array} savedCards - list of user saved cards
 * @param {boolean} walletShow - variable to decide whether to show EMI option
 * @returns {Array} array of all available payment options.
 */
export const constructPaymentOptions = (paymentDetails, savedCards = [], walletShow = false) => {
  let allOptions = [];
  const isMiniApp = isMiniAppUserAgent();
  const conditionBased = isMobile ? paymentOptionsAccordian : allPaymentOptions;
  paymentDetails?.paymentOptionsData?.paymentOptionsSeq?.split(",").forEach((item) => {
    const routeItem = find(conditionBased, { id: item });
    if (routeItem) {
      if (item === "WALLETS") {
        return walletShow ? allOptions.push(routeItem) : null;
      }
      if (item === "SC") {
        return savedCards.length ? allOptions.push(routeItem) : null;
      }
      return allOptions.push(routeItem);
    }
    return null;
  });
  if (isMiniApp) {
    allOptions = allOptions.filter((item) => item.id.toLowerCase() === "paytm");
  }
  return allOptions;
};

/**
 * This method returns a full date string
 *
 * @param {string} timestamp - Date timestamp
 * @param {string} format - Date timestamp
 * @returns {string} a string with details of date
 */
export const getFullDateString = (timestamp, format = "WW, DD MM YY") => {
  const fullDate = new Date(timestamp);
  const weekday = DAY[fullDate.getDay()];
  const date = fullDate.getDate();
  const month = MONTH_NAMES[fullDate.getMonth()];
  const year = fullDate.getFullYear().toString().slice(-2);
  const hours = fullDate.getHours();
  const minutes = fullDate.getMinutes();

  switch (format) {
    case "WW, DD MM YY":
      return `${weekday}, ${date} ${month} ${year}`;
    case "DD MM YY":
      return `${date} ${month} ${year}`;
    case "DD, MM":
      return `${date} ${month}`;
    case "DD MM HH:MMM":
      return `${date} ${month} ${hours}:${minutes}`;
    default:
      return "";
  }
};

export const occasionList = [
  "Diwali",
  "Birthday",
  "Bhai Dooj",
  "Congratulations",
  "Love and romance",
  "Anniversary",
  "Miss you",
  "Thank you",
  "I'm sorry",
  "Thinking of you",
  "Get well soon",
  "Best of luck",
  "House warming",
  "New baby",
  "Sympathy and funerals",
];

export const allRelations = [
  "Her",
  "Him",
  "Kids",
  "Parents",
  "Husband",
  "Wife",
  "Boyfriend",
  "Girlfriend",
  "Fiance",
  "For family",
  "Brother",
  "Sister",
  "Father",
  "Mother",
  "Boss",
  "Boys",
  "Girls",
  "Colleague",
  "Son",
  "Daughter",
  "Myself",
  "Other",
];

export const orderData = {
  deliveredDate: null,
  isGSTGeneratedForSubOrder: false,
  lastUpdatedStamp: null,
  orderDate: 1613593127000,
  orderId: "19991401",
  productList: [
    {
      productId: "EXP41907",
      primaryProductCategoryId: "EXPRESS",
      productImageURL: "https://uat-i7.fnp.com/images/pr/s/jade-plant-terrarium-in-glass-vase_1.jpg",
      productName: "Jade Plant Terrarium In Glass Vase",
    },
    {
      productId: "EXP3501",
      primaryProductCategoryId: "EXPRESS",
      productImageURL:
        "https://uat-i7.fnp.com/images/pr/s/v19700101053000/5-dairy-milk-chocolates-12gms-each-with-gift-wraping_1.jpg",
      productName: "5 Dairy Milk Chocolates 12gms each with gift wraping",
    },
  ],
  reason: null,
  recipientName: "Bhavish Hak",
  subOrderId: "1999103601",
  subOrderStatus: "Confirmed",
  // subOrderStatus: "Waiting",
  paymentGateway: "RazorPay Gateway",
  amount: "608",
  shippingMethodName: "Free Delivery",
  shippingPrice: 0,
  deliveryTimeSlot: "08:00 - 14:00 Hrs",
  address: "40, Vsant Vihar, Delhi",
  pinCode: 110010,
  recipientSignatureUrl: null,
  occasion: "Birthday",
  dateString: "18 Feb",
};

/**
 * get payment gateway
 *
 * @param {object} pgList - list
 * @param {string} cardCategory - card category
 * @param {string} isDomestic - is domestic
 * @param {string} mode - mode
 * @returns {string} paymentGateway - payment gateway
 */
export const getPaymentGateway = (pgList, cardCategory, isDomestic, mode) => {
  let paymentGateway = paymentGateways.PAYU;
  if (pgList?.defaultGateway) {
    paymentGateway = pgList.defaultGateway;
  }
  try {
    if (mode === "DC" || mode === "CC") {
      if (pgList?.IC && isDomestic === "N") {
        paymentGateway = pgList.IC;
      } else if (pgList?.DC && cardCategory === "DC" && isDomestic === "Y") {
        paymentGateway = pgList.DC?.domestic;
      } else if (pgList?.CC && cardCategory === "CC" && isDomestic === "Y") {
        paymentGateway = pgList.CC;
      }
    } else if (pgList?.NB && mode === "NB") {
      paymentGateway = pgList.NB;
    } else if (pgList?.UPI && mode === "UPI") {
      paymentGateway = pgList.UPI;
    } else if (pgList?.GPAY && mode === "GPAY") {
      paymentGateway = pgList.GPAY;
    }
  } catch (error) {
    return error;
  }
  return paymentGateway;
};
