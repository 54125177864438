import React from "react";
import { useSelector } from "react-redux";
import { parseCookies } from "nookies";
import PropTypes from "prop-types";
import style from "./redirectToCatalog.module.scss";
import { calDiscountPercentage, getProductDeliveryType } from "../../../src/utils/common";
import { CAKE_TYPE, productTypes } from "../../../src/utils/pdp-constants";
import callClevertapEvent from "../../../src/analytics/clevertapUtility";
import { cleverTapEventsConstants, cleverTapCommonWords } from "../../../src/analytics/clevertapConstants";

/**
 * This functional component represents the catalog redirection mesg and link.
 *
 * @param {object} props - The object containing message info.
 * @param {string} props.message - message to show.
 * @param {string} props.href - anchor link.
 * @param {string} props.linkText - text on which link apply.
 * @param {boolean} props.isCalender - check is calender component.
 * @param {boolean} props.isMobile - check is mobile device
 * @returns {React.Fragment} The main JSX for the desktop delivery input.
 */
const RedirectToCatalog = ({ message, href, linkText, isCalender, isMobile }) => {
  const { lastSelectedPincode, lastSelectedAddress } = parseCookies();
  const { selectedType } = useSelector((state) => state.pdpContext);
  const { catalogId } = useSelector((state) => state.appConfigs);
  const { product, shippingDatesAndMethods } = useSelector((state) => state.productDetail);

  const {
    productId,
    primaryCategoryId,
    productName,
    productPrice,
    productReviewsAndRatings,
    urlIdentifier,
    isVariant,
  } = product;

  /**
   * click on same day delivery
   */
  const handleCatalogRedirect = () => {
    const discountPercentage = calDiscountPercentage(productPrice.listPrice, productPrice.price);
    const productDeliveryType = getProductDeliveryType(primaryCategoryId);

    const cleverTapData = {
      page_type: cleverTapCommonWords.PDP,
      page_name: urlIdentifier,
      pincode: lastSelectedPincode,
      area: lastSelectedAddress,
      system_pincode: lastSelectedPincode,
      country: catalogId,
      city: lastSelectedAddress,
      product_price: productPrice.price,
      strike_price: productPrice.listPrice,
      discount_percentage: discountPercentage,
      product_rating: productReviewsAndRatings?.ratingValue,
      product_review_count: productReviewsAndRatings?.totalReview,
      earliest_delivery_date: shippingDatesAndMethods?.deliveryDates?.[0] ?? "",
      product_name: productName,
      pid: productId,
      product_category: primaryCategoryId,
      is_variant_available: isVariant === "Y",
      is_egg: selectedType?.toUpperCase() === CAKE_TYPE.WITH_EGG ?? false,
      delivery_type: productDeliveryType,
      is_limited_catalogue: false,
      is_on_calendar: isCalender,
      is_international: primaryCategoryId === productTypes.INTERNATIONAL,
    };

    callClevertapEvent(cleverTapEventsConstants.wantSameDayClicked, cleverTapData);
  };
  return (
    <div
      className={`${style.redirectToCatalog} ${isMobile && style.redirectToCatalogMobile} ${
        isCalender && style.redirectToCatalogCalender
      } `}
    >
      <span>{message}</span>
      <a href={href} onClick={handleCatalogRedirect}>
        {linkText}
      </a>
    </div>
  );
};
RedirectToCatalog.propTypes = {
  message: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  isCalender: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default RedirectToCatalog;
