/**
 * Function used to set cookie with name and its value for a specific time frame.
 *
 *  @param {string} cname cookie name.
 * @param {string} cvalue cookie value.
 * @param {number} minutes cookie alive time.
 */
export async function setCookieForMinutes(cname, cvalue, minutes) {
  const d = new Date();
  d.setTime(d.getTime() + minutes * 60 * 1000);
  const expires = cname === "fnpmobilesearch" ? "expires=" : `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

/**
 * Function used to retrieve cookie.
 *
 * @param {string} cname cookie name.
 * @returns {string} this returns a cookie
 */
export function getCookie(cname) {
  const name = `${cname}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

/**
 * Function used for set particular key in a local storage
 *
 * @param {string} key key to be stored in local storage
 * @param {string} value value corresponsing to the key
 */
export function setValueInLocalstorage(key, value) {
  if (typeof window.localStorage === "object")
    try {
      window.localStorage.setItem(key, value);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(
        'Your web browser does not support storing settings locally. In Safari, the most common cause of this is using "Private Browsing Mode". Some settings may not save or some features may not work properly for you.',
      );
    }
}

/**
 *
 * @param {string} key for which the corresponding value needs to be found
 * @returns {string} this is the value coming from local storage
 */
export function getValueFromLocalstorage(key) {
  if (typeof window.localStorage === "object") {
    try {
      const localstorageKey = window.localStorage.getItem(key);
      return localstorageKey !== null ? localstorageKey : "";
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(
        'Your web browser does not support storing settings locally. In Safari, the most common cause of this is using "Private Browsing Mode". Some settings may not save or some features may not work properly for you.',
      );
      return "";
    }
  }
  return "";
}
/**
 * @function removeItemFromLocalStorage this will remove key from local storage
 * @param {string} key for which the corresponding value needs to be found
 */
export function removeItemFromLocalStorage(key) {
  if (typeof window !== "undefined") {
    window.localStorage.removeItem(key);
  }
}

/**
 * This function gets the value from session storage
 *
 * @param {string} key for which the corresponding value needs to be found
 * @returns {string} returns the custom value created from key
 */
export function getValueFromKey(key) {
  if (typeof window.sessionStorage === "object") {
    try {
      return window.sessionStorage.getItem(key);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(
        'Your web browser does not support storing settings locally. In Safari, the most common cause of this is using "Private Browsing Mode". Some settings may not save or some features may not work properly for you.',
      );
      return "";
    }
  }
  return "";
}

/**
 * Function used for set particular key in a session storage, this is not supported in private browsing of safari.
 *
 * @param {string} key key to be stored in session storage
 * @param {string} value value corresponsing to the key
 */
export function setValueIn(key, value) {
  if (typeof window.sessionStorage === "object") {
    try {
      window.sessionStorage.setItem(key, value);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(
        'Your web browser does not support storing settings locally. In Safari, the most common cause of this is using "Private Browsing Mode". Some settings may not save or some features may not work properly for you.',
      );
    }
  }
}
/**
 * this function takes the response from api and create the element for the popular search
 *
 * @param {string} response response coming from api
 * @returns {string} returns the custom element created
 */
export function customCreatePopularSearch(response) {
  const respData = [];
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = response;
  const popularList = tempDiv.getElementsByTagName("li");
  Array.from(popularList).forEach((ele) => {
    const hintItem = {};
    if (ele.getElementsByClassName("ps-item")[0]) {
      const item = ele.getElementsByClassName("ps-item")[0];
      const label = item.innerHTML;
      const value = item.getAttribute("href");
      hintItem.label = label;
      hintItem.category = value;
      hintItem.addClass = "popular-search-item";
      respData.push(hintItem);
    }
  });
  return respData;
}
/**
 * This function gets the value from session storage
 *
 * @param {string} key for which the corresponding value needs to be found
 * @returns {string} returns the custom value created from key
 */
export function removeItemBasedOnKey(key) {
  if (typeof window.sessionStorage === "object") {
    try {
      return window.sessionStorage.removeItem(key);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(
        'Your web browser does not support storing settings locally. In Safari, the most common cause of this is using "Private Browsing Mode". Some settings may not save or some features may not work properly for you.',
      );
      return "";
    }
  }
  return "";
}

/**
 *  This function updates keywords by suffixing index
 *
 * @param {Array} searchSuggestionArray .
 * @returns {Array} updatedSearchTermSuggestionArray
 */
export function updateManualBoostKeyword(searchSuggestionArray) {
  const updatedSearchTermSuggestionArray = searchSuggestionArray.map((item, index) => {
    const searchSuggestionItems = item;
    if (searchSuggestionItems.suggestionResponseType === "MB") {
      searchSuggestionItems.keyword = `${searchSuggestionItems.keyword}_${index}`;
    }
    return searchSuggestionItems;
  });
  return updatedSearchTermSuggestionArray;
}
