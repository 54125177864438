import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import getConfig from "next/config";
import VerifiedUserSharpIcon from "@material-ui/icons/VerifiedUserSharp";
import styles from "./styles/mobile-footer.module.scss";
import BottomSticky from "./mobile-sticky-menu";
import { isMiniAppUserAgent } from "../../../src/utils/common";

const { publicRuntimeConfig } = getConfig();

/**
 * Footer across all pages
 *
 * @param {boolean} showBottomSticky - whether to show bottom sticky menu or not
 *
 * @returns {React.ReactElement} - React component
 */
const MobileFooter = ({ showBottomSticky }) => {
  const appConfigs = useSelector((state) => state.appConfigs);
  const { productsList, searchOptions } = useSelector((state) => state.productsLists);

  const country = publicRuntimeConfig.COUNTRY;
  const {
    mobile: { footerExtLinks, footerSocialURL },
  } = appConfigs?.configData;
  const isMiniApp = isMiniAppUserAgent();

  const isNoProductFound = !productsList.length && Object.keys(searchOptions).length !== 0;

  if (isNoProductFound) {
    return <></>;
  }

  return (
    <footer className={styles.footer}>
      {showBottomSticky && <BottomSticky />}
      {country === "IN" && (
        <GridList className={`${styles.footerLinks} ${styles.row}`} cols={footerExtLinks?.length}>
          {footerExtLinks?.map((item) => (
            <GridListTile className={styles.footerLi} key={item.id}>
              <a className={styles.footerLinksA} href={item.href}>
                {item.name}
              </a>
            </GridListTile>
          ))}
        </GridList>
      )}
      <div className={!isMiniApp ? styles.miniAppSafeMsg : ""}>
        {!isMiniApp && (
          <>
            <div className={styles.safeMsg}>
              <VerifiedUserSharpIcon />
              <span className={styles["safe-secure"]}>100% Safe and Secure Payments</span>
            </div>
            <div className={styles.socialLinks}>
              <span className={styles.socialSpan}>Follow us on</span>
              <GridList className={styles.socialLinksUL} cols={footerSocialURL?.length}>
                {footerSocialURL?.map((item) => (
                  <GridListTile className={styles.socialLinksLI} key={item.id}>
                    <a
                      className={`${styles.socialLinksAnch} ${styles[`${item.name}`]}`}
                      href={item.href}
                      aria-label={item.ariaLabel}
                    >
                      &nbsp;
                    </a>
                  </GridListTile>
                ))}
              </GridList>
            </div>
          </>
        )}
      </div>
    </footer>
  );
};

MobileFooter.propTypes = {
  showBottomSticky: PropTypes.bool.isRequired,
};

export default MobileFooter;
