import { put, takeLatest } from "redux-saga/effects";
import ActionConstants from "../../src/action-constants/app-actions";

/**
 * @function getFooterAccordianData
 *
 * @param {object} action pass action for setting accordian data
 */
export function* getFooterAccordianData(action) {
  yield put({
    type: ActionConstants.SET_ACCORDIAN_DATA,
    payload: { accordianData: action.payload.accordianData },
  });
}
/**
 @function watchFooterAccordianRequest
 */
function* watchFooterAccordianRequest() {
  yield takeLatest(ActionConstants.FOOTER_ACCORDIAN_FETCHED, getFooterAccordianData);
}
export default watchFooterAccordianRequest;
