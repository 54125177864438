import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ReactHtmlParser from "html-react-parser";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import style from "../style/shipping-method.module.scss";
import calenderStyles from "../style/delivery-calender.module.scss";
import { setGaShippingMethodName } from "../../../../src/ga/gaEvents";

/**
 * This functional component represents the desktop pdp shipping method selection.
 *
 * @param {object} root0 it includes object from parent component
 * @param {Function} root0.gotoPrev on date select handler.
 * @param {Function} root0.onSelect on date select handler.
 * @param {object} root0.shippingDetails shipping details object
 * @param {boolean} root0.isShippingDetailsExists is shipping exists.
 * @param {Function} root0.getPrice calculate price
 * @param {string} root0.currencyCode show currency code .
 * @param {boolean} root0.checkIsInternationalProduct check It's International product or not .
 * @param {string} root0.selectedShippingMethodId user selected shipping method
 * @returns {React.Fragment} main jsx for the desktop pdp shipping method selection.
 */
function ShippingMethod({
  gotoPrev,
  onSelect,
  shippingDetails,
  isShippingDetailsExists,
  getPrice,
  currencyCode,
  checkIsInternationalProduct,
  selectedShippingMethodId,
}) {
  const [value, setValue] = useState(selectedShippingMethodId);

  const { isInternationalEnable } = useSelector((state) => state.productDetail);

  useEffect(() => {
    setValue(selectedShippingMethodId);
  }, [selectedShippingMethodId]);

  /**
   * This method is used to handle panel change
   *
   * @param {string} shipping shipping key.
   * @param {object} slot slot info.
   * @param {event} event slotId .
   * @returns {void} update state.
   */
  const handleShippingChange = (shipping, slot, event) => {
    setValue(event.target.value);
    onSelect({
      shippingMethodName: slot.shippingMethodName,
      shipmentMethodId: shipping,
    });
    // Start Ga event
    setGaShippingMethodName(slot.shippingMethodName);
  };

  /**
   * This method is used to go back and update the select value
   *
   * @returns {void} update state.
   */
  const gotoBack = () => {
    gotoPrev();
    setValue(null);
  };

  /**
   * This functional component represents back arrow icons .
   *
   * @returns {React.Fragment} return arrow icons
   */
  const arrowBackIcons = () => {
    let icon;
    if (checkIsInternationalProduct) {
      if (isInternationalEnable === "N") {
        icon = <></>;
      } else {
        icon = (
          <span className={calenderStyles["back-btn"]}>
            <ArrowBackIcon onClick={gotoBack} role="presentation" data-testid="back-arrow" />
          </span>
        );
      }
    } else {
      icon = (
        <span className={calenderStyles["back-btn"]}>
          <ArrowBackIcon onClick={gotoBack} role="presentation" data-testid="back-arrow" />
        </span>
      );
    }
    return icon;
  };

  return (
    <div className={calenderStyles["calender-wrapper"]}>
      <div className={calenderStyles["delivery-calender"]}>
        <div className={calenderStyles["date-picker-heading"]}>
          {arrowBackIcons()}
          <span className={calenderStyles["calender-title"]} data-testid="heading">
            Select Shipping Method
          </span>
        </div>
        <div className={style.selectShippingDesktop}>
          <div className={style.scrollPane}>
            <ul>
              {isShippingDetailsExists &&
                Object.keys(shippingDetails).map((shipping, index) => {
                  const keyIndex = index;
                  let shippingPrice = shippingDetails[shipping].priceDetails.price;
                  if (checkIsInternationalProduct) {
                    if (isInternationalEnable === "N") {
                      shippingPrice = shippingDetails[shipping].priceDetails;
                    } else {
                      shippingPrice = shippingDetails[shipping].priceDetails.price;
                    }
                  }
                  return (
                    <li key={keyIndex}>
                      <FormLabel className={style.timeRadioLabel}>
                        <span className={style.leftBox}>
                          <Radio
                            checked={value === shipping}
                            value={shipping}
                            onClick={(event) => handleShippingChange(shipping, shippingDetails[shipping], event)}
                            inputProps={{
                              id: "shipping-methods",
                              "data-testid": `shipping-method-${keyIndex}`,
                            }}
                          />
                          <span
                            className={
                              checkIsInternationalProduct
                                ? style.shipmentMethodContainerInt
                                : style.shipmentMethodContainer
                            }
                          >
                            <span className={style.leftBoxText}>{shippingDetails[shipping].shippingMethodName}</span>
                            <span className={style.longDescription}>{shippingDetails[shipping].longDescription}</span>
                          </span>
                          {shippingDetails[shipping]?.earliestDelivery ? (
                            <span className={style.earliestdate}>
                              {`Earliest Delivery `}
                              {ReactHtmlParser(shippingDetails[shipping].earliestDelivery)}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                        <div className={style.inputGroupPrice}>
                          <span className={style.deliveryCost}>
                            <span className={style.WebRupee}>
                              {shippingPrice ? `${currencyCode} ${getPrice(shippingPrice)}` : "Free"}
                            </span>
                          </span>
                        </div>
                      </FormLabel>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        {!checkIsInternationalProduct && (
          <div className="desktop-delivery-footer desktop-shipping-footer">
            <span className="b2c" onClick={gotoBack} role="presentation">
              Back to Calendar
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

ShippingMethod.propTypes = {
  gotoPrev: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  shippingDetails: PropTypes.instanceOf(Object),
  isShippingDetailsExists: PropTypes.bool,
  getPrice: PropTypes.func.isRequired,
  currencyCode: PropTypes.string.isRequired,
  checkIsInternationalProduct: PropTypes.bool.isRequired,
  selectedShippingMethodId: PropTypes.string,
};

ShippingMethod.defaultProps = {
  shippingDetails: {},
  isShippingDetailsExists: false,
  selectedShippingMethodId: "",
};

export default ShippingMethod;
