import { useEffect } from "react";

/**
 * Omniture setup hook
 *
 * @param {object} state redux state data for omniture
 * @param {object} dependency .
 */
export default function useOmnitureSetup(state, dependency) {
  const setupDependency = dependency || [state];
  useEffect(() => {
    /**
     * set omniture data
     */
    async function setOmniture() {
      const { default: initOmniSetup } = await import("../../../../src/omniture/omniture");
      if (typeof initOmniSetup === "function") initOmniSetup(state);
    }
    /**
     * This method is used to trigger omniture setup when
     * its enabled.
     */
    async function initializeOmniture() {
      if (!state?.unLoadCheckoutCall && typeof window !== "undefined") {
        setOmniture();
      }
    }
    initializeOmniture();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, setupDependency);
}
