import delay from "lodash/delay";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import { useRouter } from "next/router";
import getConfig from "next/config";
import style from "./mega-menu.module.scss";
import scrollStyle from "../../../styles/desktop-layout.module.scss";
import { generateQueryFromUrl } from "../../../../../src/utils/common";

const { publicRuntimeConfig } = getConfig();

/**
 * Mega menu component uses the html markup to render.
 *
 * @returns {React.ReactElement} - returns the mega menu component.
 */
function MegaMenuComponent() {
  const router = useRouter();
  const payload = useSelector((state) => state.menuData?.desktopMegaMenuData);

  /**
   * generates html markup for the menu
   *
   * @returns {React.ReactElement} - returns the html markup elements.
   */
  function createMarkup() {
    const clientRoutingEnabled = false;
    const parseOptionsNode = {
      replace: (node) => {
        if (clientRoutingEnabled && node.name === "a" && !node.attribs.href.startsWith(`javascript:`)) {
          if (node.attribs.class) {
            node.attribs.class.concat(" aNjs");
            return node;
          }
          const newNode = node;
          newNode.attribs.class = " aNjs";
          return newNode;
        }
        return node;
      },
    };
    return payload?.length > 0 ? parse(payload, parseOptionsNode) : <></>;
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      document.querySelectorAll("a.aNjs").forEach((a) => {
        const href = a.getAttribute("href");
        if (!href.startsWith(`javascript:`)) {
          a.addEventListener("click", (e) => {
            e.preventDefault();
            const url = new URL(href, publicRuntimeConfig.D_HOST);
            const query = generateQueryFromUrl(url.pathname.substring(1));
            router.push({ pathname: "/listing", query }, href);
          });
        }
      });
    }
  });
  useEffect(() => {
    const headerWrapper = Array.from(document.getElementsByClassName("header-wrapper")); // Navigation container (direct parent of menu items)
    const menuItems = Array.from(document.querySelectorAll(".header-wrapper > ul a")); // anchor tag of menu items needed to get the id of element
    const listItems = Array.from(document.querySelectorAll(".header-wrapper > ul li")); // Get the navmenu list
    const dropdownMenus = Array.from(document.querySelectorAll(".header-wrapper > div")); // whole mega menu dropdown corresponding to a particular menu item

    let timeout = null;

    /**
     * Handles the mouse leave for menu wrapper which close the menu.
     *
     */
    const onHeaderWrapperMouseleave = () => {
      dropdownMenus.forEach((menu, index) => {
        menu?.classList.remove("opened");
        listItems[index]?.classList.remove("opened");
      });
      clearTimeout(timeout);
      timeout = null;
    };

    /**
     * Handles the mouse hover for menu wrapper which opens the menu.
     *
     * @param {object} menuItemList menu item list
     * @param {Array} listItem open list items
     */
    const onMenuItemMouseEnter = (menuItemList, listItem) => {
      const openedItem = dropdownMenus.find((el) => el.classList.contains("opened"));
      const openedList = listItems.find((el) => el.classList.contains("opened"));
      // Currently opened item if there is none then the value of openedItem is undefined
      if (openedItem || openedList) {
        menuItemList?.classList?.add("opened");
        openedItem?.classList?.remove("opened");
        listItem?.classList?.add("opened");
        openedList?.classList?.remove("opened");
      } else {
        timeout = delay(() => {
          menuItemList?.classList?.add("opened");
          listItem?.classList?.add("opened");
        }, 10);
      }
    };

    if (headerWrapper && headerWrapper.length) {
      headerWrapper[0].addEventListener("mouseleave", onHeaderWrapperMouseleave);
    }

    menuItems.forEach((item, navid) => {
      const id = item.id.split("#")[1];

      const menuItemList = document.getElementById(id);

      item.addEventListener("mouseenter", () => onMenuItemMouseEnter(menuItemList, listItems[navid]));
    });

    return () => {
      if (headerWrapper && headerWrapper.length) {
        headerWrapper[0].removeEventListener("mouseleave", onHeaderWrapperMouseleave);
      }
      menuItems.forEach((item, navid) => {
        const id = item.id.split("#")[1];

        const menuItemList = document.getElementById(id);
        item.removeEventListener("mouseenter", () => onMenuItemMouseEnter(menuItemList, listItems[navid]));
      });
    };
  }, []);

  return (
    <div data-test="megaMenuComponent" className={`${style["megamenu-component"]} ${scrollStyle["scroll-bar"]}`}>
      {createMarkup()}
    </div>
  );
}

export default MegaMenuComponent;
