import get from "lodash/get";
import queryString from "query-string";
import getConfig from "next/config";
import axios from "../../app_configs/axios/base";
import errorLogger from "../../app_configs/logger-service";
import { execDataLayer } from "../data-layer/dataLayer";
import { endpointConst } from "../data-layer/dataLayerConstants";

const { publicRuntimeConfig } = getConfig();
const SHOW_REQUESTS_LOGS = publicRuntimeConfig.SHOW_REQUESTS_LOGS || "Y";
const error = {
  config: {},
  response: {},
};

/**
 * This method fetches the cart summary data
 *
 * @returns {object} cart summary value
 */
export async function viewCartPreview() {
  try {
    /**
     * @function apiFn wrapper function to execute viewcart from datalayer.
     * @returns response from datalayer
     */
    const apiFn = async () => axios.get(`${endpointConst.viewCart}?isExternal=Y`);
    const res = await execDataLayer(apiFn, endpointConst.viewCart);
    return get(res, "data", "");
  } catch (ex) {
    error.config.url = "/control/viewcart?isExternal=Y";
    error.response.status = `An exception occurred while fetching cart summary => ${ex.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return "";
  }
}

/**
 * This method uses the cart summary data ,performs operation on that and returns a cart price details
 *
 * @param {object} cartData -contains the cart data
 * @returns {object} price summary value
 */
export function getPriceDetails(cartData) {
  const giftItems = [];
  let priceData = {};
  let baseItemTotal = 0;
  let addonsTotal = 0;
  let noOfAddons = 0;
  if (cartData.items.length === 0) {
    return {};
  }
  cartData.items.forEach((item) => {
    if (item.addon === "N") {
      giftItems.push(item);
      baseItemTotal += item.price;
    } else if (item.addon === "Y") {
      addonsTotal += item.price;
      noOfAddons += item.quantity;
    }
  });
  priceData = {
    baseItemTotal,
    addonsTotal,
    noOfAddons,
    noOfBaseItems: giftItems.length,
    subTotal: cartData.subTotal,
    totalShipping: cartData.totalShipping,
    total: cartData.total,
  };
  return priceData;
}

/**
 * This method fetches the retry payment info
 *
 * @returns {object} retry payment value
 */
export async function getRetryPaymentDetails() {
  try {
    const res = await axios.get("/control/getUserSubOrderDetails?viewSize=20");
    const { expiredOrders, subOrdersList } = res.data?.OrderDetails?.data;
    let pncCartDataResult = [];
    if (subOrdersList.length > 0) {
      pncCartDataResult = subOrdersList.filter(
        (pncCart) =>
          expiredOrders.indexOf(pncCart.orderId) === -1 && pncCart.subOrderStatus.toUpperCase() === "WAITING",
      );
    }
    return pncCartDataResult;
  } catch (ex) {
    error.config.url = "/control/getUserSubOrderDetails?viewSize=20";
    error.response.status = `An exception occurred while fetching Pnc details => ${ex.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return [];
  }
}

/**
 * Method that hit network call to delete cart items
 *
 * @param {object} params Params we need to send in get request
 *
 * @returns {object} response from api
 *
 */
export async function deleteCartItem(params) {
  try {
    /**
     * @function apiFn wrapper function to execute deleteItem call from datalayer.
     * @returns response from datalayer
     */
    const apiFn = async () => {
      return axios.get(`${endpointConst.deleteCartItem}?${queryString.stringify(params)}`);
    };
    const res = await execDataLayer(apiFn, endpointConst.deleteCartItem);
    return res;
  } catch (ex) {
    error.config.url = "/control/deleteItem-rj?";
    error.response.status = `An exception occurred while deleting item in cart => ${ex.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return "";
  }
}

/**
 * Method that hit network call and update addon quantity
 *
 * @param {object} params Prams we need to send in body of post request
 *
 * @returns {object} response from api
 */
export async function updateAddonQuantityProduct(params) {
  try {
    /**
     * @function apiFn wrapper function to execute updateAddOn call from datalayer.
     * @returns response from datalayer
     */
    const apiFn = async () => {
      return axios.post(`${endpointConst.updateAddOn}`, queryString.stringify(params), {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      });
    };
    const res = await execDataLayer(apiFn, endpointConst.updateAddOn);
    return res;
  } catch (ex) {
    error.config.url = "/control/updateAddonQuantityProduct-rj";
    error.response.status = `An exception occurred while updating addon quantity => ${ex.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return "";
  }
}
