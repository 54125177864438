import { call, put, takeEvery, select } from "redux-saga/effects";

import getConfig from "next/config";
import { ACTION_TYPE } from "../../src/action-constants/my-account-constants";
import APP_CONSTANTS from "../../src/action-constants/app-actions";
import fetchCurrencies from "../../src/actions/common-actions";
import {
  getMyOrders,
  logout,
  getOrderPaymentPreferenceStatus,
  deleteExpiredProduct,
  getSenderMobileCountryCodeAndCity,
} from "../../src/services/api/myAccount";
import errorLogger from "../../app_configs/logger-service";

const { publicRuntimeConfig } = getConfig();
const SHOW_REQUESTS_LOGS = publicRuntimeConfig.SHOW_REQUESTS_LOGS || "Y";

/**
 * this function fetches data of my orders.
 *
 */
function* fetchMyOrders() {
  try {
    const res = yield call(getMyOrders);
    if (res.isSuccess) {
      const currencies = yield select((store) => store.currenciesList.currencies);
      if (currencies.length === 0) {
        const resp = yield call(fetchCurrencies);
        yield put({ type: APP_CONSTANTS.GET_CURRENCIES_LIST, payload: resp.data.currencies });
      }
      const isMobile = yield select((store) => store.appConfigs.isMobile);
      if (!isMobile) {
        yield put({
          type: APP_CONSTANTS.GET_DATE_RANGE_ENABLE,
        });
      }
      yield put({ type: ACTION_TYPE.GET_MY_ORDER_LIST_SUCCESS, payload: res });
    } else {
      yield put({ type: ACTION_TYPE.GET_MY_ORDER_LIST_FAIL });
    }
  } catch (error) {
    yield put({ type: ACTION_TYPE.GET_MY_ORDER_LIST_FAIL });
  }
}

/**
 * this function fetches data of payment status.
 *
 * @param {object} action action dispatched containing orderId of the order whose payment status you want to fetch
 */
function* checkPaymentStatus(action) {
  try {
    const res = yield call(getOrderPaymentPreferenceStatus, action.payload?.orderId);
    yield put({ type: ACTION_TYPE.CHECK_PAYMENT_STATUS_SUCCESS, payload: { ...res, ...action.payload } });
  } catch (error) {
    yield put({ type: ACTION_TYPE.CHECK_PAYMENT_STATUS_FAIL });
  }
}

/**
 * function call will logout the user
 *
 */
function* getLogout() {
  try {
    yield call(logout);
  } catch (error) {
    yield put({ type: ACTION_TYPE.LOGOUT_FAIL, payload: error });
  }
}

/**
 * function call for fetching Exp Products
 *
 * @param {object} action action dispatched containing orderId of the order we want to delete
 *
 */
function* delExpiredProduct(action) {
  try {
    const res = yield call(deleteExpiredProduct, action.payload);
    if (res.responseMessage === "success") {
      yield put({ type: ACTION_TYPE.DELETE_EXPIRED_PRODUCT_SUCCESS });
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}

/**
 * this function fetches data of country name and country code.
 *
 * @param {object} action action dispatched containing subOrderId of the order from which country name to be fetched
 */
function* fetchCountryName(action) {
  try {
    const res = yield call(getSenderMobileCountryCodeAndCity, action.payload?.subOrderId);
    yield put({ type: ACTION_TYPE.GET_COUNTRY_NAME_SUCCESS, payload: { ...res, ...action.payload } });
  } catch (error) {
    yield put({ type: ACTION_TYPE.GET_COUNTRY_NAME_FAIL });
  }
}

/**
 * saga for fetching my orders
 *
 */
function* watchCartSummary() {
  yield takeEvery(ACTION_TYPE.GET_MY_ORDER_LIST_REQUESTED, fetchMyOrders);
  yield takeEvery(ACTION_TYPE.CHECK_PAYMENT_STATUS_REQUESTED, checkPaymentStatus);
  yield takeEvery(ACTION_TYPE.LOGOUT_REQUESTED, getLogout);
  yield takeEvery(ACTION_TYPE.DELETE_EXPIRED_PRODUCT, delExpiredProduct);
  yield takeEvery(ACTION_TYPE.GET_COUNTRY_NAME_REQUESTED, fetchCountryName);
}
export default watchCartSummary;
