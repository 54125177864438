import React from "react";
import { Box, Link, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import styles from "./styles/fnp-logo.module.scss";
import callClevertapEvent from "../../../../src/analytics/clevertapUtility";
import { cleverTapEventsConstants } from "../../../../src/analytics/clevertapConstants";
import { checkCartAndLoginCookie, getPageNameForCleverTap } from "../../../../src/utils/common";

const useStyles = makeStyles(() => ({
  homePagelogo: {
    position: "relative",
    top: "8px",
    left: "-5px",
  },
  homePagelogoDisabed: {
    position: "relative",
    top: "8px",
    left: "-5px",
    pointerEvents: "none",
  },
  logoWrapper: {
    width: "190px",
  },
}));

/**
 * This component is used to show fnp logo in the m-site header
 *
 * @param {object} root0 props from parent
 * @param {string} root0.catalogId current catalog id
 *
 * @returns {React.ReactElement} - renders fnp-logo component
 */
const FNPLogo = ({ catalogId }) => {
  const disableFnplogoClick = useSelector((state) => state.payments?.disableFnplogoClick);
  const classes = useStyles();
  const selectedCurrency = useSelector((state) => state.appData.selectedCurrency);
  const userDetails = useSelector((state) => state.userDetails);
  const userType = userDetails?.checkLoginInfo?.userType;

  let homeURL;
  if (catalogId === "india") {
    homeURL = `/`;
  } else {
    homeURL = catalogId === "singapore" ? `/${catalogId}/rakhi` : `/${catalogId}/gifts`;
  }

  /**
   * on click event for fnp logo
   */
  const handleLogoOnClick = () => {
    const pageHeaderMenuData = {
      page_name: getPageNameForCleverTap(),
      page_type: getPageNameForCleverTap(),
      menu_name: "FNP",
    };
    if (selectedCurrency && (!checkCartAndLoginCookie() || userType)) {
      callClevertapEvent(cleverTapEventsConstants.headerMenuClicked, pageHeaderMenuData);
    }
  };
  return (
    <>
      <div className={classes.logoWrapper}>
        <Link
          href={homeURL}
          className={disableFnplogoClick ? classes.homePagelogoDisabed : classes.homePagelogo}
          onClick={handleLogoOnClick}
        >
          <Box component="span" className={styles.logoSvg} />
        </Link>
      </div>
    </>
  );
};

FNPLogo.propTypes = {
  catalogId: PropTypes.string,
};

FNPLogo.defaultProps = {
  catalogId: "india",
};

export default FNPLogo;
