export const POPULAR_SEARCH_LOWERCASE = "popular search";
export const SEARCH_SUGGESTIONS = "search suggestions";
export const SEARCH_LIST_HEADING_LABEL = "Popular Searches";
export const POPULATED_SEARCH_LIST_HEADING_LABEL = "Your Personalized Category Suggestions";
export const CALL_US_LABEL = "Call Us";
export const HI_GUEST_LABEL = "Hi Guest";
export const EMPTY_VIEW_LABEL = "Empty View";
export const SEARCH_BOX_PLACE_HOLDER = "Search flowers, cakes, gifts, etc.";
export const NEED_TODAY_LABEL = "Need Today?";
export const HELP_CENTER_LABEL = "Help Center";
export const EMAIL_REQUIRED_LABEL = "Email is Required";
export const SUBMIT_LABEL = "SUBMIT";
export const SUBSCRIBE_LABEL = "SUBSCRIBE";
export const KEEP_IN_TOUCH_LABEL = "Keep in touch";
export const COPYRIGHT_LABEL = "fnp.com.All rights reserved.";
export const OUT_OF_STOCK = "Out of Stock";
export const OUT_OF_STOCK_PDP = "OUT OF STOCK";
export const VIEW_GIFTS_AVAILABLE_HERE = "VIEW AVAILABLE GIFTS HERE";
export const FREE_SHIPPING = "free-shipping";
export const MY_CART = "My Cart";
export const MAKE_IT_EXTRA_SPECIAL = "Add on something to make it extra special!";
export const WHAT_CUSTOMERS_SAYING = "What Customers Are Saying";
export const SOMETHING_WENT_WRONG = "Something went wrong";
export const WHAT_USERS_SAY = "What our users say about us";
export const NO_REVIEWS = "No reviews available";
export const READ_MORE = "Read More...";
export const READ_LESS = "Read Less";
export const FOOTER_DESCRIPTION_LENGTH = 800;
export const TIMEOUT_PERIOD_500 = 500;
export const DELIVERY_DATE_LABEL = "Select Delivery Date";
export const TERMS_AND_CONDITION = "Terms & Conditions";
export const FAQ = "FAQ's";
export const CONTROL = "control";
export const VIEW_PAGE = 10;
export const MORE = "More";
export const ADDON_MAKE_IT_EXTRA_SPECIAL = "Make it extra special";
export const LocationLockStaticConstants = {
  SET_LOGGED_IN_PINCODE: "SET_LOGGED_IN_PINCODE",
  WITHIN_INDIA: "Within India",
  OUTSIDE_INDIA: "Outside India",
  DELIVER_TO: "Deliver To",
  SELECT_DELIVERY_LOCATION: "Select Delivery Location",
  CHOOSE_YOUR_DELIVERY_LOCATION: "Choose your Delivery Location",
  LL_HEADER_TEXT:
    "Please enter the correct delivery location or pincode to view available products and delivery options",
  CONTINUE: "Continue",
  BTN: "BTN",
  ICON: "ICON",
  DONT_KNOW_PINCODE: "Don't Know Pincode?",
  DONT_SEE_YOUR_PINCODE: "Don't see your Pincode?",
  ENTER_PINCODE: "*Enter Delivery Pincode",
  PINCODE: "Pincode",
  PINCODE_LOCALITY: "* Enter delivery location or pincode or area",
  PINCODE_ONLY: "* Enter Pincode",
  PINCODE_INFO_MSJ: "Enter correct Pincode for hassle free timely delivery.",
  CLICK_ON_REMOVE_BUTTON_TO_CHANGE_LOCATION: "Click on remove(X) button to change location",
  START_TYPING_AND_SELECT_FROM_SUGGESTIONS: "Start typing and select from suggestions",
  ENTER_AREA_OR_LOCALITY_TO_GET_THE_PINCODE: "Enter delivery area or locality to get the Pincode",
  PF: "pf",
  LAST_SELECTED_PINCODE: "lastSelectedPincode",
  LAST_SELECTED_ADDRESS: "lastSelectedAddress",
  ALL_SELECTED_COUNTRIES: "selectedCountries",
  LAST_SELECTED_DELIVERY_DATE: "lastSelectedDeliveryDate",
  CATALOG_ID_INDIA: "india",
  LLP: "llp",
  DELIVERY_TO: "Delivery to",
  LL_DRAWER_SUB_HEADING:
    "Please enter the correct delivery location or pincode to view available products and delivery options",
  LL_DRAWER_BTN_TEXT: "Enter delivery location or pincode or area",
  LL_SELECT_COUNTRY: "Select Country",
  LL_Location_Suggestion: "*Enter Delivery Area or Locality",
  LL_HEADER_DL: "Select Delivery Location",
  AUTO_TRIGGER_LOCK: "autoTriggerLock",
  LL_AREA_DOES_NOT_EXIST: "Area does not exist. Please enter correct area name.",
  LL_NOT_INDIA_DELIVERY: "Not India Delivery?",
  DELIVERY_DATE_MODIFIED_TXT: "The delivery date has been changed to the earliest possible, for the desired location.",
  NOT_INDIA_DELIVERY: "Not India Delivery?",
  ENTER_LOCALITY_AND_AREA: "* Enter Area or Locality",
  CHANGING_COUNTRY: "Changing Country ?",
};
export const OOPS_PAGE = {
  CONTACT_US_NUMBER: "+91 92124 22 000",
  CALL_US_ON: "Call us on",
  NEED_HELP: "Need Help ?",
  FOUR_MILLION: "4 Million",
  PEOPLE_TRUST_US: "People Trust Us",
};
export const PLP = {
  NO_PRODUCT_MATCH_FOUND: "No product match found.",
  CHANGE_DELIVERY_PINCODE_OR_AREA_AND_TRY_AGAIN: "Change delivery pincode or area and try again.",
  CHANGE_DELIVERY_PINCODE_AREA: "CHANGE DELIVERY PINCODE/AREA",
};
export const GA4_EVENTS = {
  VARIANT_CHANGED: "variant_changed",
  VIEW_ITEM: "view_item",
  ADD_TO_CART: "add_to_cart",
  REMOVE_FROM_CART: "remove_from_cart",
  BUY_NOW: "buy_now",
  PINCODE_ENTERED: "pincode_entered",
  PINCODE_AVAILABLE: "pincode_available",
  NSA: "NSA",
  OUT_OF_STOCK: "out_of_stock",
  ADDONS_REMOVE: "addons_remove",
  PAYMENT_FAILED: "payment_failed",
  PURCHASE: "ga4purchase",
  SEARCH: "search",
  AFTER_CITY_SELECTION: "after_city_selection",
  ADDON_SELECTED: "add_on_selected",
  ADDON_ADDED: "addons_added",
  CONTINUE_WITHOUT_ADDON: "continue_without_addon",
  BEGIN_CHECKOUT: "begin_checkout",
  SHIPPING_METHOD_SELECTED: "shipping_method_selected",
  TIME_SLOT_SELECTED: "time_slot_selected",
  WIDGET_VIEWED: "widget_viewed",
  ADD_SHIPPING_INFO: "add_shipping_info",
  ADD_PAYMENT_INFO: "add_payment_info",
};

export const WALLET_CONST = {
  FNP_CASH: "FNP Cash",
  ORDER_ID: "Order Id",
  EXPIRING_ON: "Expiring on",
};

export const STATUS_CODE = {
  SUCCESS: 200,
};
export const PRICE_BREAKUP = {
  PRICE_BREAKUP: "Price Breakup:",
  PRODUCT_PRICE_BREAKUP: "Product Price Breakup",
  PRICE_DETAILS: "PRICE DETAILS",
};
