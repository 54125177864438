import { call, put, takeLatest } from "redux-saga/effects";
import appConstants from "../../src/action-constants/app-actions";
import getUspDataOnClient from "../../src/actions/usp-actions";
import { setUspSuccess } from "../../src/actions/plp-actions";
/**
 * @function getUspData to trigger usp content
 */
export function* getUspData() {
  const res = yield call(getUspDataOnClient);
  yield put(setUspSuccess(res));
}

/**
 * sagas for fetching usp content
 *
 */
function* watchUspDataRequest() {
  yield takeLatest(appConstants.GET_USP_DATA_REQUEST, getUspData);
}

export default watchUspDataRequest;
