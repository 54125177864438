import { call, takeLatest, select, put, all } from "redux-saga/effects";
import ListingPageConstants from "../../src/action-constants/listing-contants";
import {
  setOmnitureDetails,
  setPlpSvnData,
  setBannerCategory,
  plpErrorMSG,
  setPLPReviews,
  setBreadCrumbsData,
  setPlpSvnBreadCrumbs,
  setProductList,
  setPageName,
  updateEarliestDeliveries,
  setContentIdDetails,
  setProductSummaryDetails,
  setIsEnableToShowAddToCartPlp,
  setPlpListingLoading,
} from "../../src/actions/plp-actions";
import {
  fetchPLPReviews,
  fetchProductListing,
  getOmnitureTagDetails,
  fetchProductListingOnClient,
  fetchBreadcrumbs,
  fetchEarliestDeliveries,
  fetchContentSearchedList,
  getProductSummaryDetails,
  getSystemPropertyValue,
} from "../../src/services/api/plp/product-listing-api";
import { fetchContentIdDetails } from "../../src/services/api/common/common-api";
import {
  getProductListingData,
  getUpdatedProductListingData,
  populateEarliestDeliveries,
  getProductIds,
} from "../../src/utils/plp-helper";

/**
 * action to trigger search instead list
 *
 * @param {string} catalogId - action object
 * @param {boolean} isMobile whether device is mobile
 */
function* requestContentSearchList(catalogId, isMobile) {
  try {
    // Query Params for search instead
    const searchInsteadParam = {
      params: {
        contentId: "search-instead",
        FNP_CURRENT_CATALOG_ID: catalogId,
      },
    };
    const res = yield call(fetchContentSearchedList, searchInsteadParam, isMobile);
    if (res) {
      yield put(setContentIdDetails(res));
    }
  } catch (error) {
    yield put(plpErrorMSG(error));
  }
}

/**
 * this function handles the plp svn data and sets the value in store
 *
 * @param {object} svnData object of svn plp data
 */
function* handlePlpSvnData(svnData) {
  try {
    if (svnData.responseStatus === "success") {
      yield put(setPlpSvnData(svnData));
      yield put(setPageName("plp-micro-site"));
    }
  } catch (error) {
    yield put(plpErrorMSG(error));
  }
}

/**
 * this function handles the plp svn data and sets the value in store
 *
 * @param {object} breadcrumbDetails object of svn plp data
 */
function* handlePlpSvnBreadCrumb(breadcrumbDetails) {
  const { params, isMobile } = breadcrumbDetails;
  const options = { params };
  try {
    const response = yield call(fetchBreadcrumbs, options, isMobile);
    if (response?.breadCrumbs && response.breadCrumbs.length > 0) {
      yield put(
        setPlpSvnBreadCrumbs({
          breadcrumbs: response.breadCrumbs,
          svnCategoryDesc: response.categoryDesc,
        }),
      );
    }
  } catch (error) {
    yield put(plpErrorMSG(error));
  }
}

/**
 * This function handles the api call for Populating Bread Crumbs data for Category details and set the value in store
 *
 * @param {object} action contains type and payload
 */
function* getBreadCrumbData(action) {
  const { params, isMobile } = action;
  const options = { params };
  try {
    const res = yield call(fetchBreadcrumbs, options, isMobile);
    if (res) {
      yield put(setBreadCrumbsData(res));
    }
  } catch (error) {
    yield put(plpErrorMSG(error));
  }
}
/**
 * Handles plp reviews
 *
 * @param {object} root its a object contains categoryId and domainId
 * @param {string} root.payload dispatched action payload
 */
function* getAndSetPLPReviews({ payload }) {
  const { categoryId, domainId } = payload;
  const VIEW_ALL = "view_all";
  const redirectionUrl = `/info/testimonials?pageType=category&domainId=${domainId}&categoryId=${categoryId}`;

  try {
    const response = yield call(fetchPLPReviews, categoryId, domainId);

    if (response?.data) {
      const categoryReviews = [...response.data];
      if (response.data.length >= 12) {
        categoryReviews.push({
          categoryId: VIEW_ALL,
          redirectionUrl,
        });
      }
      yield put(setPLPReviews(categoryReviews));
    }
  } catch (error) {
    yield put(plpErrorMSG(error));
  }
}
/**
 * This function handles the product list api and sets the data in reducer accordingly
 *
 * @param {object} action contains the data required for this generator function
 */
function* getCategoryProductsList(action) {
  try {
    const response = yield call(fetchProductListing, action.payload);
    if (response.status === 200) {
      // The code is commented to remove the dependency of category review api on product list api. if something went wrong we will uncomment this change

      // if (response?.data?.CategoryTotalReview > 0) {
      //   const {
      //     options: {
      //       params: { categoryId },
      //     },
      //     domainId,
      //   } = action.payload;
      //   yield call(getAndSetPLPReviews, { categoryId, domainId });
      // }
      if (response.data.productResults) {
        const payload = yield call(getProductListingData, response, action.payload.options);
        yield all([
          put(setProductList(payload)),
          put(setPageName("category")),
          call(getBreadCrumbData, action.payload.breadcrumbsOptions),
        ]);
        if (response.data?.noResultsFound) {
          yield call(
            requestContentSearchList,
            action.payload.options.params.catalogId,
            action.payload.options.params.isMobile,
          );
        } else {
          yield put(setContentIdDetails([]));
        }
      } else {
        yield all([call(handlePlpSvnData, response), call(handlePlpSvnBreadCrumb, action.payload.breadcrumbsOptions)]);
      }
    } else {
      const ctxRes = action.payload.ctx.res;
      ctxRes.statusCode = response.status || response.errorStatus || 500;
    }
  } catch (error) {
    yield put(plpErrorMSG(error));
  }
}

/**
 * This function handles the api call for omniture details and set the value in store
 *
 * @param {object} action contains type and payload
 */
function* handlePlpOmniture(action) {
  const { categoryId, isMobile } = action.payload;
  try {
    const res = yield call(getOmnitureTagDetails, categoryId, isMobile);
    if (res) {
      yield put(setOmnitureDetails(res));
    }
  } catch (error) {
    yield put(plpErrorMSG(error));
  }
}

/**
 * This function is to handle API for banner category
 *
 * @param {object} action contains payload
 */
function* showBannerCategory(action) {
  try {
    const {
      params: { contentId },
      isMobile,
    } = action.payload;

    const response = yield call(fetchContentIdDetails, contentId, isMobile);
    if (response) {
      yield put(setBannerCategory(response));
    }
  } catch (error) {
    yield put(plpErrorMSG(error));
  }
}

/**
 * Handles
 *
 * @param {object} action contains type and payload
 */
function* getProductsListOnClient(action) {
  const { options, appendProducts } = action.payload;

  try {
    yield put(setPlpListingLoading(true));
    const response = yield call(fetchProductListingOnClient, options);

    let payload = {};
    if (appendProducts) {
      const productList = yield select((store) => store.productsLists);
      payload = yield call(getUpdatedProductListingData, response, options, productList);
    } else {
      payload = yield call(getProductListingData, response.data, options);
    }
    yield put(setProductList(payload));
  } catch (err) {
    yield put(plpErrorMSG(ListingPageConstants.PRODUCT_LISTING_LOAD_MORE_FAILED));
  } finally {
    yield put(setPlpListingLoading(false));
  }
}

/**
 * This function handles the api call for Populating Bread Crumbs data for Category details and set the value in store
 *
 * @param {object} action contains type and payload
 */
function* getBedCrumbData(action) {
  try {
    const res = yield call(fetchBreadcrumbs, action.payload);
    if (res) {
      yield put(setBreadCrumbsData(res));
    }
  } catch (error) {
    yield put(plpErrorMSG(error));
  }
}

/**
 * Watcher saga for clientSideProductListing
 *
 * @param {object} action object contains payload
 */
function* handleEarliestDeliveryDates(action) {
  try {
    const { pincode } = action.payload;
    const {
      productsLists: { productsList, viewSize },
      appConfigs: { catalogId },
    } = yield select((state) => state);
    const productsIds = yield call(getProductIds, productsList, viewSize);
    if (Array.isArray(productsIds) && productsIds.length) {
      let query = `productIds=${encodeURI(productsIds.join(","))}&FNP_CURRENT_CATALOG_ID=${catalogId}`;
      if (pincode) {
        query = `${query}&pincode=${pincode}`;
      }
      const deliveryData = yield call(fetchEarliestDeliveries, query);
      const modifiedEarliestDeliveryDates = yield call(populateEarliestDeliveries, deliveryData.deliveryDates);
      yield put(updateEarliestDeliveries(modifiedEarliestDeliveryDates, deliveryData.deliveryDates));
    }
  } catch (ex) {
    yield put(plpErrorMSG(ex));
  }
}

/**
 * This function returns a product description as per the product ids
 *
 * @param {object} action object of product ids
 */
function* getProductDesc(action) {
  try {
    const data = yield call(getProductSummaryDetails, action.payload);
    yield put(setProductSummaryDetails(data));
  } catch (error) {
    yield put(plpErrorMSG(error));
  }
}

/**
 * This function returns a flag for isEnableToShowAddToCartPlp
 *
 * @param {object} action object
 */
function* getIsEnabledAddToCart(action) {
  try {
    const { payload: isMobile } = action;
    const params = { isMobile, resource: "fnp", name: "isEnableToShowAddToCartPlp" };
    const data = yield call(getSystemPropertyValue, params);
    yield put(setIsEnableToShowAddToCartPlp(data?.value === "true"));
  } catch (error) {
    yield put(plpErrorMSG(error));
  }
}

/**
 * Watcher saga for serverSideProductListing
 */
function* watchPlpSaga() {
  yield takeLatest(ListingPageConstants.GET_PRODUCT_LISTING_SERVER_REQUEST, getCategoryProductsList);
  yield takeLatest(ListingPageConstants.SERVER_REQUEST_PLP_REVIEWS, getAndSetPLPReviews);
  yield takeLatest(ListingPageConstants.GET_OMNITURE_DETAILS, handlePlpOmniture);
  yield takeLatest(ListingPageConstants.GET_BANNER_CATEGORY, showBannerCategory);
  yield takeLatest(ListingPageConstants.REQUEST_GET_PRODUCT_LISTING, getProductsListOnClient);
  yield takeLatest(ListingPageConstants.SERVER_REQUEST_CATEGORY_BREADCRUMBS, getBedCrumbData);
  yield takeLatest(ListingPageConstants.FETCH_EARLIEST_DELIVERIES, handleEarliestDeliveryDates);
  yield takeLatest(ListingPageConstants.PRODUCT_DESC_REQUEST, getProductDesc);
  yield takeLatest(ListingPageConstants.FETCH_ENABLE_ADD_TO_CART, getIsEnabledAddToCart);
}
export default watchPlpSaga;
