const geoDefault = {
  title: "Flower Delivery Online | India's No.1 Florist - Ferns N Petals",
  menuItems: [
    { href: "/", name: "Home Page" },
    { href: "/about", name: "About Page" },
  ],
  desktopHeader: "FnpDesktopHeader",
  mobileHeader: "FnpMobileHeader",
  desktopFooter: "FnpDesktopFooter",
  mobileFooter: "FnpMobileFooter",
  currency: "INR",
  cookieDomain: ".fnp.com",
  footerExtLinks: [
    { href: "/info/help-center?cid=india", name: "Help Center", id: "HC" },
    { href: "/info/franchise-programme", name: "Get Franchise", id: "FR" },
    { href: "/info/retail-stores", name: "Retail Outlets", id: "RO" },
  ],
  rootCatalogId: "india",
  viewSize: 40,
};
export default geoDefault;
