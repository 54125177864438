import React from "react";
import { Box, Link, makeStyles } from "@material-ui/core";
import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { cleverTapEventsConstants } from "../../../../src/analytics/clevertapConstants";
import callClevertapEvent from "../../../../src/analytics/clevertapUtility";
import { checkCartAndLoginCookie, getPageNameForCleverTap } from "../../../../src/utils/common";

const CountryFlag = dynamic(() => import("./country-flag"), {
  ssr: false,
});

const useStyles = makeStyles(() => ({
  homePagelogo: {
    position: "absolute",
    top: "8px",
    left: "12px",
  },
  homePagelogoDisabed: {
    position: "absolute",
    top: "8px",
    left: "12px",
    pointerEvents: "none",
  },
  otherPageLogo: {
    position: "absolute",
    top: "6px",
    left: "44px",
  },
  notfoundPageLogo: {
    position: "absolute",
    top: "6px",
    left: "12px",
  },
  logoSvg: {
    background: "url('/all-icons.svg')",
    backgroundSize: "850%",
    width: "60px",
    height: "30px",
    position: "absolute",
    backgroundPositionX: "-100px",
    backgroundPositionY: "-1px",
  },
}));

/**
 * This component is used to show fnp logo in the m-site header
 *
 * @param {object} root0 props from parent
 * @param {boolean} root0.isHomePage whether the page is home page or not
 * @param {boolean} root0.isNotFoundPage whether the page is a 404 page
 * @param {string} root0.catalogId current catalog id
 * @param {boolean} root0.showCountryFlag whether to show country flag or not
 *
 * @returns {React.ReactElement} - renders fnp-logo component
 */
const FNPLogo = ({ isHomePage, isNotFoundPage, catalogId, showCountryFlag }) => {
  const disableFnplogoClick = useSelector((state) => state.payments?.disableFnplogoClick);
  const classes = useStyles();
  let logoStyle = isHomePage ? classes.homePagelogo : classes.otherPageLogo;
  const selectedCurrency = useSelector((state) => state.appData.selectedCurrency);
  const userDetails = useSelector((state) => state.userDetails);
  const userType = userDetails?.checkLoginInfo?.userType;

  if (isNotFoundPage) {
    logoStyle = classes.notfoundPageLogo;
  }
  let homeURL;
  if (catalogId === "india") {
    homeURL = `/`;
  } else {
    homeURL = catalogId === "singapore" ? `/${catalogId}/rakhi` : `/${catalogId}/gifts`;
  }
  /**
   * on click event for fnp logo
   */
  const handleLogoOnClick = () => {
    const pageHeaderMenuData = {
      page_name: getPageNameForCleverTap(),
      page_type: getPageNameForCleverTap(),
      menu_name: "FNP",
    };
    if (selectedCurrency && (!checkCartAndLoginCookie() || userType)) {
      callClevertapEvent(cleverTapEventsConstants.headerMenuClicked, pageHeaderMenuData);
    }
  };

  return (
    <>
      <Link
        href={homeURL}
        className={disableFnplogoClick ? classes.homePagelogoDisabed : logoStyle}
        onClick={handleLogoOnClick}
      >
        <Box component="span" className={classes.logoSvg} />
      </Link>
      {showCountryFlag && <CountryFlag catalogId={catalogId} />}
    </>
  );
};

FNPLogo.propTypes = {
  isHomePage: PropTypes.bool,
  isNotFoundPage: PropTypes.bool,
  catalogId: PropTypes.string,
  showCountryFlag: PropTypes.bool,
};

FNPLogo.defaultProps = {
  isHomePage: false,
  isNotFoundPage: false,
  catalogId: "india",
  showCountryFlag: false,
};

export default FNPLogo;
