import MenuPageConstants from "../action-constants/menu-constants";
import APP_CONSTANTS from "../action-constants/app-actions";

/**
 * Request mobile left menu data based on the current catalog id
 *
 * @param {string} payload current catalog id
 * @returns {object} with action type
 */
export const requestMobileLeftMenuData = (payload) => ({
  type: MenuPageConstants.SERVER_REQUEST_MOBILE_LEFT_MENU_DATA,
  payload,
});

/**
 * Setting mobile left menu data
 *
 * @param {string} payload left menu data
 * @returns {object} with action type and mobile left menu data
 */
export const setMobileLeftMenuData = (payload) => ({
  type: MenuPageConstants.SERVER_SET_MOBILE_LEFT_MENU_DATA,
  payload,
});

/**
 * Request mobile left menu data failure
 *
 * @param {string} payload left menu error
 * @returns {object} with action type
 */
export const requestMobileLeftMenuDataFail = (payload) => ({
  type: MenuPageConstants.SERVER_REQUEST_MOBILE_LEFT_MENU_DATA_FAIL,
  payload,
});

/**
 * Request mobile left menu data based on the current catalog id
 *
 * @param {string} payload current catalog id
 * @returns {object} with action type
 */
export const requestDesktopMegaMenuData = (payload) => ({
  type: MenuPageConstants.SERVER_REQUEST_DESKTOP_MEGA_MENU_DATA,
  payload,
});

/**
 * Setting mobile left menu data
 *
 * @param {string} payload left menu data
 * @returns {object} with action type and mobile left menu data
 */
export const setDesktopMegaMenuData = (payload = "") => ({
  type: MenuPageConstants.SERVER_SET_DESKTOP_MEGA_MENU_DATA,
  payload,
});

/**
 * Request mobile left menu data failure
 *
 * @param {string} payload left menu error
 * @returns {object} with action type
 */
export const requestDesktopMegaMenuDataFail = (payload) => ({
  type: MenuPageConstants.SERVER_REQUEST_DESKTOP_MEGA_MENU_DATA_FAIL,
  payload,
});

/**
 * Action for fetching currency data
 *
 * @param {string} payload contains currency found in the url on PDP page
 * @returns {object} returns action
 */
export const fetchCurrencyData = (payload) => ({
  type: APP_CONSTANTS.FETCH_CURRENCIES_REQUESTED,
  payload,
});

/**
 * Action for showing media info
 *
 * @param {string} payload contains boolean
 * @returns {object} returns action
 */
export const showMediaInfo = (payload) => ({
  type: APP_CONSTANTS.SHOW_MEDIA_INFO,
  payload,
});
