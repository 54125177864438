import getConfig from "next/config";

/**
 * This method used for calling logger api of server and client logs
 *
 * @param {object}  error it contains the msg and type of level
 */
const sendErrorToNewrelic = (error) => {
  if (typeof newrelic !== "undefined") {
    // eslint-disable-next-line no-undef
    newrelic.addPageAction(JSON.stringify(error));
  }
};

const { publicRuntimeConfig } = getConfig();

/**
 * This method will only used for to display error traces
 *
 * @param {error}  error response from the each api will come from interceptor
 */
const errorLogger = (error) => {
  // eslint-disable-next-line no-console
  if (publicRuntimeConfig.NODE_ENV === "development") console.error(error);

  sendErrorToNewrelic(error);
};

export default errorLogger;
