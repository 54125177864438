import React, { useState, useEffect, useRef, useCallback } from "react";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import HomeIcon from "@material-ui/icons/Home";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import ForumIcon from "@material-ui/icons/Forum";
import Account from "@material-ui/icons/AccountCircle";
import FormatListBulletedSharpIcon from "@material-ui/icons/FormatListBulletedSharp";
import { Drawer } from "@material-ui/core";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect, useSelector } from "react-redux";
import { useRouter } from "next/router";
import SortIcon from "@material-ui/icons/Sort";
import dynamic from "next/dynamic";
import get from "lodash/get";
import { makeStyles } from "@material-ui/core/styles";

import { Router } from "../../../routes/routes";
import {
  getJsonFromQueryString,
  setHashToRoute,
  RoutePageConstants,
  getRoutePageName,
} from "../../../src/utils/common";
import { saveStickyIndex } from "../../../src/actions/user-actions";
import { COUNTRIES } from "../../../src/action-constants/app-actions";
import styles from "./styles/mobile-sticky-menu.module.scss";
import callClevertapEvent from "../../../src/analytics/clevertapUtility";
import { cleverTapCommonWords, cleverTapEventsConstants } from "../../../src/analytics/clevertapConstants";

const CategorySortForm = dynamic(() => import("../../mobile/mobile-categories-sort"), {
  ssr: false,
});

const URL_CONSTANTS = {
  HOME_INDEX: 0,
  NEED_TODAY: 1,
  GIFTING_EXPERTS: 2,
  OFFERS: 3,
  ACCOUNT: 4,
  CATEGORIES: 5,
};

const useStyles = makeStyles((theme) => ({
  selected: {
    color: theme.color?.red,
  },
  label: {
    width: "5px",
    height: "5px",
    background: theme.color.red,
    borderRadius: "50%",
  },
  selectedSortLabel: {
    fontSize: "12px",
  },
}));

/**
 * This is life cycle method of the bottom sticky page.
 *
 * @param {object} func .
 * @returns {React.Fragment} main jsx for the mobile-sticky-menu page.
 */
const useComponentDidMount = (func) =>
  useEffect(() => {
    func();
  });

/**
 * This is life cycle method of the bottom sticky page.
 *
 * @param {object} func .
 */
const useComponentWillMount = (func) => {
  const willMount = useRef(true);
  if (willMount.current) {
    func();
  }
  useComponentDidMount(() => {
    willMount.current = false;
  });
};

/**
 * This functional component represents the bottom sticky page.
 *
 * @param {object} props is the prop the component receives from the parent component.
 * @returns {React.Fragment} main jsx for the mobile-sticky-menu page.
 */
const BottomSticky = (props) => {
  const classes = useStyles();
  const { index, country, catalogId } = props;
  const router = useRouter();
  const page = getRoutePageName(router.pathname);
  const actionClasses = page === RoutePageConstants.PLP_PAGE ? {} : classes.selected;
  const [prevScrollposition, setPrevScrollposition] = useState(0);
  const [sortIcon, setSortIcon] = useState("");
  const [visible, setVisible] = useState(true);
  const [stickyIndex, setStickyIndex] = useState(index === undefined ? 0 : index);
  const url = ["/", "/same-day-delivery-gifts", "", "/gifts-offers-lp", "/account/my-account"];
  const slugList = ["", "same-day-delivery-gifts", "", "gifts-offers-lp", "my-account"];
  const promoCode = ["", "?promo=bottom_sb_need_today", "", "?promo=bottom_sb_offers", "?promo=bottom_sb_my_ac"];
  const [sortClicked, setSortClicked] = useState(false);
  const [sortValue, setSortValue] = useState("Recommended");
  const searchOptions = useSelector((store) => get(store, "productsLists.searchOptions.params", {}));
  const completedClass = visible ? styles.hidden : "";
  const [namePosition, setNamePosition] = useState({});

  const appConfigs = useSelector((state) => state.appConfigs);
  const { fnpPageName } = appConfigs;

  const sortValueMap = {
    Recommended: "Recommended",
    "PRICE_INR|ASC": "Price: Low to High",
    "PRICE_INR|DESC": "Price: High to Low",
    "CREATED_STAMP|DESC": "New",
  };

  const pageStickyMenuData = {
    page_name: `${router.asPath}`,
    page_type: fnpPageName === "category" ? cleverTapCommonWords.MICROSITE : cleverTapCommonWords.ACCOUNT,
    icon_name: namePosition?.value1,
    icon_position: namePosition?.value2,
  };
  useEffect(() => {
    if (namePosition?.value1) {
      callClevertapEvent(cleverTapEventsConstants.bottomNavigationNavIconClicked, pageStickyMenuData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [namePosition?.value1, namePosition?.value2]);

  useEffect(() => {
    /**
     * This method handle visible size of page
     */
    const handleScroll = () => {
      const currentScrollPosition = window.scrollY;
      setPrevScrollposition(currentScrollPosition);
      if (Math.abs(prevScrollposition - currentScrollPosition) > 5) {
        setVisible(prevScrollposition > currentScrollPosition);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollposition]);

  useEffect(() => {
    const pageIndex = slugList.findIndex((item) => item === router.query.slug1);
    setStickyIndex(pageIndex);
  }, [router.query.slug1, slugList]);

  /**
   * This method returns visibleClass on scrolling
   *
   * @param {isVisible} isVisible value.
   * @param {pageName} pageName value.
   * @returns {object} - returns class name.
   */
  const getVisibleClassName = (isVisible, pageName) => {
    let className = "";
    if (!isVisible) {
      if (pageName === RoutePageConstants.PLP_PAGE) {
        className = classnames(`${styles.bottomIconsPlp} ${styles.hidden}`);
      } else {
        className = classnames(`${styles.bottomIcons} ${styles.hidden}`);
      }
    } else if (pageName === RoutePageConstants.PLP_PAGE) {
      className = styles.bottomIconsPlp;
    } else {
      className = styles.bottomIcons;
    }
    return className;
  };

  /**
   * Returns an object with icon name and position based on the provided constant.
   *
   * This function maps specific URL constants to their corresponding icon names and positions.
   *
   * @param {string} constant - The URL constant used to determine the icon name and position.
   * @returns {object} An object containing:
   *  - {string} value1 - The icon name associated with the provided constant.
   *  - {number} value2 - The position of the icon associated with the provided constant.
   *
   * If the provided constant does not match any case, an empty object is returned.
   */
  const iconNamePosition = (constant) => {
    let iconNamPosition;
    switch (constant) {
      case URL_CONSTANTS.HOME_INDEX:
        iconNamPosition = { value1: "Home", value2: 0 };
        break;
      case URL_CONSTANTS.NEED_TODAY:
        iconNamPosition = { value1: "Need Today", value2: 1 };
        break;
      case URL_CONSTANTS.GIFTING_EXPERTS:
        iconNamPosition = { value1: "Chat with us", value2: 2 };
        break;
      case URL_CONSTANTS.OFFERS:
        iconNamPosition = { value1: "Offers", value2: 3 };
        break;
      case URL_CONSTANTS.ACCOUNT:
        iconNamPosition = { value1: "Account", value2: 4 };
        break;
      case URL_CONSTANTS.CATEGORIES:
        iconNamPosition = { value1: "Categories", value2: 5 };
        break;
      default:
        iconNamPosition = {};
        break;
    }
    return iconNamPosition;
  };

  /**
   * This method navigates to the desired pages in bottom sticky strip.
   *
   * @param {event} event object.
   * @param {stickyIndexVal} stickyIndexVal object.
   */
  const handleChange = (event, stickyIndexVal) => {
    setStickyIndex(stickyIndexVal);
    props.saveStickyIndex(stickyIndexVal);
    const iconData = iconNamePosition(stickyIndexVal);
    setNamePosition(iconData);
    if (stickyIndexVal === URL_CONSTANTS.GIFTING_EXPERTS) {
      if (window.fcWidget) {
        window.fcWidget?.open();
        window.fcWidget?.show();
      }
    } else if (stickyIndexVal === URL_CONSTANTS.NEED_TODAY && props.country !== COUNTRIES.INDIA) {
      document.getElementsByClassName("categorySelection")[0].classList.remove("displayNone");
      if (document.getElementsByClassName("header-secondary")[0])
        document.getElementsByClassName("header-secondary")[0].classList.add("displayNone");
      if (page === RoutePageConstants.PLP_PAGE) {
        document.getElementsByClassName("plp-sec")[0].classList.add("displayNone");
      } else document.getElementsByClassName("main")[0].classList.add("displayNone");
    } else {
      const newUrl = url.map((ele, i) => url[i] + promoCode[i]);
      router.push(newUrl[stickyIndexVal]);
    }
  };

  /**
   * This method handle sort.
   */
  const handleSort = () => {
    if (!sortClicked) {
      setHashToRoute("sortm");
      setSortClicked(true);
    }
  };

  /**
   * The method is a utility function to generate route with query param
   *
   * @param {string} sortFields all query params you need to pass .
   * @returns {string} url url with params.
   */
  const getRouteWithParams = (sortFields) => {
    const currentURL = new URL(window.location.href);
    const { pathname, search } = currentURL;
    return `${pathname + search}#category_id=${router.query.slug1}&FNP_CURRENT_CATALOG_ID=${
      searchOptions.catalogId
    }&viewIndex=${searchOptions.viewIndex}&viewSize=${searchOptions.viewSize}&sortFields=${sortFields}`;
  };

  /**
   * The method is a helper function to generate combination of all the slugs
   *
   *
   * @returns {string} returns combination of all the slugs.
   */
  const combineAllSlugs = useCallback(() => {
    let combinedSlugs = "";
    const allSlug = [Object.values(router.query)];
    allSlug.forEach(() => {
      combinedSlugs = allSlug[0].join();
    });
    return combinedSlugs;
  }, [router.query]);

  /**
   * Function takes url in argument and update state of sort value according to the query params in the url
   *
   * @param {string} urlPath url with query params.
   * @returns {void} nothing.
   */
  const setSortValueByUrl = useCallback(
    (urlPath) => {
      let combinedSlugs = combineAllSlugs();
      combinedSlugs = combinedSlugs.replace(",", "/");
      const query = getJsonFromQueryString(urlPath.split("#")[1]);

      if (query.sortFields) {
        setSortIcon("sortIconSelected");
        if (query.sortFields === `SEQUENCE_NUM_${combinedSlugs}|ASC`) {
          setSortValue("Recommended");
        } else {
          setSortValue(query.sortFields);
        }
      } else {
        setSortIcon("");
        setSortValue("Recommended");
      }
    },
    [combineAllSlugs],
  );

  /**
   * Calls setSortValueByUrl function with new url
   *
   * @param {string} newUrl URL
   */
  const handleRouteChange = useCallback(
    (newUrl) => {
      setSortValueByUrl(newUrl);
    },
    [setSortValueByUrl],
  );

  /**
   * This method handle sort function
   *
   * @param {event} event object.
   * @param {newValue} newValue object.
   */
  const handleSortChange = (event, newValue) => {
    let combinedSlugs = combineAllSlugs();
    combinedSlugs = combinedSlugs.replace(",", "%2F");
    setSortValue(newValue);
    setSortIcon("sortIconSelected");
    let sortFields = newValue;
    if (newValue === "Recommended") {
      sortFields = `SEQUENCE_NUM_${combinedSlugs}|ASC`;
    }

    Router.replaceRoute(getRouteWithParams(sortFields), { shallow: true });

    setSortClicked(false);

    window.scrollTo(0, 0);
  };

  useComponentWillMount(() => {
    if (props.stickyIndex === undefined) props.saveStickyIndex(stickyIndex);
  });

  useEffect(() => {
    setSortValueByUrl(router.asPath);
    router.events.on("hashChangeComplete", handleRouteChange);

    return () => {
      router.events.off("hashChangeComplete", handleRouteChange);
    };
  }, [combineAllSlugs, handleRouteChange, router.asPath, router.events, setSortValueByUrl]);

  /**
   * Function used to close sort modal
   */
  const closeSortModal = useCallback(() => {
    setSortClicked(false);
    router.back();
  }, [router]);

  useEffect(() => {
    router.beforePopState(() => {
      if (sortClicked) {
        setSortClicked(false);
      }
      return true;
    });
  }, [router, sortClicked]);

  const sortSelectedLabel = (
    <>
      <div>Sort</div>
      <div className={classes.selectedSortLabel}>{sortValueMap[sortValue]}</div>
    </>
  );

  const sortIconJSX = (
    <>
      <span className={`${sortIcon ? classes.label : ""}`} />
      <SortIcon />
    </>
  );

  /**
   * PLP Sticky Menu JSX
   *
   * @param {string} pageName based on page name menu will be shown
   *
   * @returns {React.ReactElement | null} returns either JSX or Null
   */
  const PLPStickyMenuJSX = (pageName) => {
    if (pageName === RoutePageConstants.PLP_PAGE && fnpPageName === "category") {
      return (
        <div className={styles.categoriesSort} id="plpFilter">
          <div className={`${styles.bottomIconsPlp} ${styles.catSort} ${completedClass}`}>
            <BottomNavigation showLabels onChange={handleSort}>
              <BottomNavigationAction
                disableRipple
                classes={actionClasses}
                label={sortSelectedLabel}
                icon={sortIconJSX}
              />
            </BottomNavigation>
            <Drawer anchor="bottom" transitionDuration={{ enter: 0 }} open={sortClicked} onClose={closeSortModal}>
              <div className={styles.sortPopUp}>
                <CategorySortForm
                  sortValue={sortValue}
                  handleSortChange={handleSortChange}
                  closeSortModal={closeSortModal}
                />
              </div>
            </Drawer>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {catalogId === "india" && (
        <div className={getVisibleClassName(visible, page)} id="stickyMenu">
          <BottomNavigation value={stickyIndex} onChange={handleChange} showLabels>
            <BottomNavigationAction label="Home" icon={<HomeIcon />} />
            {country === COUNTRIES.INDIA ? (
              <BottomNavigationAction className={styles.chatLabel} label="Same Day" icon={<QueryBuilderIcon />} />
            ) : (
              <BottomNavigationAction label="Categories" icon={<FormatListBulletedSharpIcon />} />
            )}
            <BottomNavigationAction className={styles.chatLabel} label="Chat with us" icon={<ForumIcon />} />
            <BottomNavigationAction label="Offers" icon={<LocalOfferIcon />} />
            <BottomNavigationAction label="Account" icon={<Account />} />
          </BottomNavigation>
        </div>
      )}
      {country === COUNTRIES.INDIA ? PLPStickyMenuJSX(page) : null}
    </>
  );
};

/**
 * mapping mobile sticky state to props
 *
 * @param {*} state - returns root geoId and user detail
 * @returns {void} - returns nothing
 */
const mapStateToProps = (state) => {
  return {
    country: state.appConfigs.rootGeoId,
    index: state.userDetails.index,
    catalogId: state.appConfigs.catalogId,
  };
};
/**
 * mapping mobile sticky mapDispatchToProps
 *
 * @param {dispatch} dispatch .
 * @returns {props} return prop.
 */
const mapDispatchToProps = (dispatch) => ({
  saveStickyIndex: (stickyIndex) => dispatch(saveStickyIndex(stickyIndex)),
});

BottomSticky.propTypes = {
  stickyIndex: PropTypes.number,
  index: PropTypes.number,
  saveStickyIndex: PropTypes.func.isRequired,
  country: PropTypes.string,
  catalogId: PropTypes.string,
};
BottomSticky.defaultProps = {
  stickyIndex: 0,
  index: 0,
  country: "",
  catalogId: "",
};
export default connect(mapStateToProps, mapDispatchToProps)(BottomSticky);
