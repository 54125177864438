import React, { useState } from "react";
import PropTypes from "prop-types";
import dynamic from "next/dynamic";
import { useSelector } from "react-redux";
import CustomButton from "../../common/customButton";
import styles from "./styles/cart-preview.module.scss";
import CurrencyDetails from "./components/currencyDetails";
import { CheckoutRoutes } from "../../../src/action-constants/checkout-constants";
import checkPaytmLogin from "../../../src/utils/paytmMiniAppLogin";
import { isMiniAppUserAgent } from "../../../src/utils/common";
import { cleverTapEventsConstants } from "../../../src/analytics/clevertapConstants";
import callClevertapEvent from "../../../src/analytics/clevertapUtility";
import useFNPWalletBalanceData from "../../hooks/useFNPWalletBalanceData";
import { textConst } from "../../../const/text-english";

const FNPCashText = dynamic(() => import("../../layouts/common/fnpCashText"), { ssr: false });

const jsStyles = {
  priceSymbol: { color: "#222222", marginRight: 4 },
};
/**
 * This function build the sticky price details & Checkout button for the cart preview page
 *
 * @param {object} root0 props passed to component.
 * @param {boolean} root0.loggedIn flag for user login.
 * @param {object} root0.priceInfo price details.
 * @param {Array} root0.currencies list of currencies.
 * @param {string} root0.localCurrency price details.
 * @param {boolean} root0.isMobile boolean for mobile or desktop device
 * @param {number} root0.availableAmountUsed amount to be used by wallet
 * @param {number} root0.checkBoxChecked check and unchecked state of wallet
 * @param {object} root0.cleverTapCartCommonData .
 * @returns {React.ReactElement} jsx for the empty cart.
 */
function StickyCartPriceDetails({
  loggedIn,
  priceInfo,
  currencies,
  localCurrency,
  isMobile,
  availableAmountUsed,
  checkBoxChecked,
  localCurrencyINR,
  cleverTapCartCommonData,
}) {
  const [disableCheckoutBtn, setDisableCheckoutBtn] = useState(false);
  const { addonsTotal, baseItemTotal, noOfAddons, noOfBaseItems, total, totalShipping, donationAmount } = priceInfo;
  const isMiniApp = isMiniAppUserAgent();
  const totalAmount = checkBoxChecked ? total : total - availableAmountUsed;
  const cartDetails = useSelector((state) => state?.cartDetails);
  const discount = cartDetails?.cartSummary?.discount;

  const totalCartPrice = Number(totalAmount);
  useFNPWalletBalanceData(totalCartPrice);
  /**
   * This function is used to handle the checkout button
   */
  const handleProceedToCheckout = () => {
    const checkoutCleverTapData = {
      ...cleverTapCartCommonData,
      fnp_cash_discount: checkBoxChecked ? 0 : availableAmountUsed,
      is_cart_empty: undefined,
    };
    callClevertapEvent(cleverTapEventsConstants.proceedToCheckoutClicked, checkoutCleverTapData);
    setDisableCheckoutBtn(true);
    if (loggedIn) {
      window.location.href = `/${CheckoutRoutes.CHECKOUT}/${CheckoutRoutes.DELIVERY_ADDRESS}`;
    } else {
      window.location.href = `/${CheckoutRoutes.CHECKOUT}/${CheckoutRoutes.LOGIN}`;
    }
  };
  /**
   * This function is used to handle the checkout button
   */
  const handleCheckoutForPaytmMiniApp = () => {
    setDisableCheckoutBtn(true);
    if (!loggedIn) {
      checkPaytmLogin(`/${CheckoutRoutes.CHECKOUT}/${CheckoutRoutes.DELIVERY_ADDRESS}`);
    } else {
      window.location.href = `/${CheckoutRoutes.CHECKOUT}/${CheckoutRoutes.DELIVERY_ADDRESS}`;
    }
  };

  /**
   * render condition based on cartPreview M-site and D-site
   *
   * @returns {React.ReactFragment} - checkbox clicked
   */
  const condRender = () => {
    if (availableAmountUsed) {
      return checkBoxChecked ? null : (
        <div className={isMobile ? styles["cart-total-price-container"] : styles["desktop-cart-total-price-container"]}>
          <span className={`${styles["price-info-title-donation"]} ${styles["addons-selected-text-total"]}`}>
            <FNPCashText />
          </span>
          <span className={`${styles["fNP-Cash-Total"]}`}>{`- ₹ ${availableAmountUsed}`}</span>
        </div>
      );
    }
    return null;
  };
  return (
    <div className={isMobile ? styles["sticky-cart-container"] : styles["desktop-sticky-cart-container"]}>
      <div className={styles["sticky-cart-price-info"]}>
        <div className={styles["base-addons-price-container"]}>
          <section className={styles["base-addon"]}>
            <div className={isMobile ? styles["cart-total-price-container"] : styles["desktop-left-price-container"]}>
              <span className={styles["addons-selected-text"]}>{`${noOfBaseItems} Base Item`}</span>
              <span className={`${styles["base-selected-price"]} ${styles["base-item"]}`}>
                <span className={styles["price-content"]}>
                  <CurrencyDetails
                    currencies={currencies}
                    selectedCurrency={localCurrency}
                    price={Number(baseItemTotal)}
                    priceSymbolStyles={jsStyles.priceSymbol}
                  />
                </span>
              </span>
            </div>
            <div className={isMobile ? styles["cart-total-price-container"] : styles["desktop-left-price-container"]}>
              <span className={styles["addons-selected-text"]}>{`${noOfAddons} Add ons`}</span>
              <span className={`${styles["addons-selected-price"]} ${styles["base-item"]}`}>
                <span className={styles["price-content"]}>
                  <CurrencyDetails
                    currencies={currencies}
                    selectedCurrency={localCurrency}
                    price={Number(addonsTotal)}
                    priceSymbolStyles={jsStyles.priceSymbol}
                  />
                </span>
              </span>
            </div>
            {localCurrencyINR && condRender()}
            <div className={isMobile ? styles["discount-text-amount-mobile"] : styles["discount-text-amount-desktop"]}>
              <span>{textConst.common.discount}</span>
              <span className={styles["price-content"]}>
                <span className={styles["inr-symbol"]}>{textConst.payment.inr}</span>
                &nbsp;
                <span>{discount && Math.abs(Number(discount))}</span>
              </span>
            </div>
          </section>
          <section className={isMobile ? styles["cart-total"] : styles["desktop-cart-total"]}>
            <div className={styles["cart-total-price-container"]}>
              <span className={styles["addons-selected-text"]}> Shipping</span>
              <span className={`${styles["addons-selected-price"]} ${styles["base-item"]}`}>
                <span className={`${styles["total-price-content"]} ${styles["fnp-bold"]}`}>
                  <CurrencyDetails
                    currencies={currencies}
                    selectedCurrency={localCurrency}
                    price={Number(totalShipping)}
                    priceSymbolStyles={jsStyles.priceSymbol}
                  />
                </span>
              </span>
            </div>
            <div className={styles["cart-total-price-container"]}>
              <span className={`${styles["price-info-title-donation"]} ${styles["addons-selected-text-total"]}`}>
                Donation
              </span>
              <span className={`${styles["addons-selected-price"]} ${styles["base-item"]}`}>
                <span className={`${styles["total-price-content"]}`}>
                  <CurrencyDetails
                    currencies={currencies}
                    selectedCurrency={localCurrency}
                    price={Number(donationAmount)}
                    priceSymbolStyles={jsStyles.priceSymbol}
                  />
                </span>
              </span>
            </div>
            <div className={isMobile ? styles["cart-total-price-container"] : styles["desktop-left-price-container"]}>
              <span className={`${styles["price-info-title"]} ${styles["addons-selected-text-total"]}`}> TOTAL</span>
              <span className={`${styles["addons-selected-price"]} ${styles["base-item"]}`}>
                <span className={`${styles["total-price-content"]} ${styles["total-price"]} ${styles["fnp-bold"]}`}>
                  <CurrencyDetails
                    currencies={currencies}
                    selectedCurrency={localCurrency}
                    price={Number(totalAmount)}
                    priceSymbolStyles={jsStyles.priceSymbol}
                  />
                </span>
              </span>
            </div>
          </section>
        </div>
      </div>
      <div className={styles["sticky-cart-checkout-button"]}>
        <CustomButton
          showTransition={!!disableCheckoutBtn}
          disableBtn={disableCheckoutBtn}
          onClick={isMiniApp ? handleCheckoutForPaytmMiniApp : handleProceedToCheckout}
          buttonText="proceed to checkout"
          buttonType="primary"
        />
      </div>
    </div>
  );
}

StickyCartPriceDetails.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  priceInfo: PropTypes.shape({
    addonsTotal: PropTypes.number.isRequired,
    baseItemTotal: PropTypes.number.isRequired,
    noOfAddons: PropTypes.number.isRequired,
    noOfBaseItems: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    totalShipping: PropTypes.number.isRequired,
    donationAmount: PropTypes.number.isRequired,
  }).isRequired,
  currencies: PropTypes.arrayOf(
    PropTypes.shape({
      currencyCode: PropTypes.string.isRequired,
      symbol: PropTypes.string.isRequired,
      conversionRate: PropTypes.number.isRequired,
    }),
  ).isRequired,
  localCurrency: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  availableAmountUsed: PropTypes.number.isRequired,
  checkBoxChecked: PropTypes.bool.isRequired,
  localCurrencyINR: PropTypes.bool,
  cleverTapCartCommonData: PropTypes.shape({
    cart_id: PropTypes.string,
    total_items: PropTypes.number,
    total_amount: PropTypes.number,
    currency: PropTypes.string,
  }),
};
StickyCartPriceDetails.defaultProps = {
  localCurrencyINR: false,
  cleverTapCartCommonData: {},
};
export default StickyCartPriceDetails;
