const PaymentsConstants = {
  FETCH_PAYMENT_METHODS: "FETCH_PAYMENT_METHODS",
  FETCH_PAYMENT_METHODS_SUCCESS: "FETCH_PAYMENT_METHODS_SUCCESS",
  FETCH_PAYMENT_METHODS_FAILED: "FETCH_PAYMENT_METHODS_FAILED",
  FETCH_PAYMENT_OPTIONS_METHODS: "FETCH_PAYMENT_OPTIONS_METHODS",
  FETCH_PAYMENT_OPTIONS_METHODS_SUCCESS: "FETCH_PAYMENT_OPTIONS_METHODS_SUCCESS",
  FETCH_PAYMENT_OPTIONS_METHODS_FAILED: "FETCH_PAYMENT_OPTIONS_METHODS_FAILED",
  FETCH_USER_SAVED_CARDS: "FETCH_USER_SAVED_CARDS",
  CHECK_CARD_TYPE: "CHECK_CARD_TYPE",
  CHECK_CARD_TYPE_SUCCESS: "CHECK_CARD_TYPE_SUCCESS",
  CHECK_CARD_TYPE_FAILED: "CHECK_CARD_TYPE_FAILED",
  FETCH_EMI_OPTIONS: "FETCH_EMI_OPTIONS",
  FETCH_EMI_OPTIONS_SUCCESS: "FETCH_EMI_OPTIONS_SUCCESS",
  FETCH_EMI_OPTIONS_FAILED: "FETCH_EMI_OPTIONS_FAILED",
  FETCH_PAYPAL_CURRENCIES: "FETCH_PAYPAL_CURRENCIES",
  FETCH_PAYPAL_CURRENCIES_SUCCESS: "FETCH_PAYPAL_CURRENCIES_SUCCESS",
  FETCH_PAYPAL_CURRENCIES_FAILED: "FETCH_PAYPAL_CURRENCIES_FAILED",
  FETCH_WALLET_DETAILS: "FETCH_WALLET_DETAILS",
  FETCH_WALLET_DETAILS_SUCCESS: "FETCH_WALLET_DETAILS_SUCCESS",
  FETCH_WALLET_DETAILS_FAILED: "FETCH_WALLET_DETAILS_FAILED",
  FETCH_NET_BANKING_LIST_SUCCESS: "FETCH_NET_BANKING_LIST_SUCCESS",
  FETCH_NET_BANKING_LIST_FAILED: "FETCH_NET_BANKING_LIST_FAILED",
  FETCH_PAYMENT_OPTION_DETAILS: "FETCH_PAYMENT_OPTION_DETAILS",
  FETCH_PAYMENT_OPTION_DETAILS_SUCCESS: "FETCH_PAYMENT_OPTION_DETAILS_SUCCESS",
  FETCH_PAYMENT_OPTION_DETAILS_FAILED: "FETCH_PAYMENT_OPTION_DETAILS_FAILED",
  FETCH_MIN_EMI_AMOUNT: "FETCH_MIN_EMI_AMOUNT",
  FETCH_MIN_EMI_AMOUNT_SUCCESS: "FETCH_MIN_EMI_AMOUNT_SUCCESS",
  FETCH_MIN_EMI_AMOUNT_FAILED: "FETCH_MIN_EMI_AMOUNT_FAILED",
  FETCH_THANK_YOU_PAGE_INFO: "FETCH_THANK_YOU_PAGE_INFO",
  FETCH_THANK_YOU_PAGE_INFO_SUCCESS: "FETCH_THANK_YOU_PAGE_INFO_SUCCESS",
  FETCH_THANK_YOU_PAGE_INFO_FAILED: "FETCH_THANK_YOU_PAGE_INFO_FAILED",
  FETCH_REMINDERS: "FETCH_REMINDERS",
  FETCH_REMINDERS_SUCCESS: "FETCH_REMINDERS_SUCCESS",
  FETCH_REMINDERS_FAILED: "FETCH_REMINDERS_FAILED",
  SAVE_REMINDERS: "SAVE_REMINDERS",
  SAVE_REMINDERS_SUCCESS: "SAVE_REMINDERS_SUCCESS",
  SAVE_REMINDERS_FAILED: "SAVE_REMINDERS_FAILED",
  DELETE_REMINDERS: "DELETE_REMINDERS",
  DELETE_REMINDERS_SUCCESS: "DELETE_REMINDERS_SUCCESS",
  DELETE_REMINDERS_FAILED: "DELETE_REMINDERS_FAILED",
  DELETE_SAVED_CARDS: "DELETE_SAVED_CARDS",
  DELETE_SAVED_CARDS_SUCCESS: "DELETE_SAVED_CARDS_SUCCESS",
  DELETE_SAVED_CARDS_FAILED: "DELETE_SAVED_CARDS_FAILED",
  FETCH_THANK_YOU_PAGE_DETAIL: "FETCH_THANK_YOU_PAGE_DETAIL",
  FETCH_THANK_YOU_PAGE_DETAIL_SUCCESS: "FETCH_THANK_YOU_PAGE_DETAIL_SUCCESS",
  FETCH_THANK_YOU_PAGE_DETAIL_FAILED: "FETCH_THANK_YOU_PAGE_DETAIL_FAILED",
  SAVE_USER_FEEDBACK: "SAVE_USER_FEEDBACK",
  CLEAR_CHECK_CARD_TYPE: "CLEAR_CHECK_CARD_TYPE",
  FETCH_NET_BANKING_OPTIONS: "FETCH_NET_BANKING_OPTIONS",
  FETCH_NET_BANKING_OPTIONS_FAILED: "FETCH_NET_BANKING_OPTIONS_FAILED",
  FETCH_NET_BANKING_OPTIONS_SUCCESS: "FETCH_NET_BANKING_OPTIONS_SUCCESS",
  UPDATE_REDIRECT_PAGE: "UPDATE_REDIRECT_PAGE",
  SAVE_USER_FEEDBACK_SUCCESS: "SAVE_USER_FEEDBACK_SUCCESS",
  GET_SYSTEM_PROPERTY_REQUESTED: "GET_SYSTEM_PROPERTY_REQUESTED",
  GET_SYSTEM_PROPERTY_SUCCESS: "GET_SYSTEM_PROPERTY_SUCCESS",
  GET_SAVED_CARD_SYSTEM_PROPERTY_REQUESTED: "GET_SAVED_CARD_SYSTEM_PROPERTY_REQUESTED",
  GET_SAVED_CARD_SYSTEM_PROPERTY_SUCCESS: "GET_SAVED_CARD_SYSTEM_PROPERTY_SUCCESS",
  FETCH_OCCASION_LIST_REQUESTED: "FETCH_OCCASION_LIST_REQUESTED",
  FETCH_OCCASION_LIST_SUCCESS: "FETCH_OCCASION_LIST_SUCCESS",
  FETCH_RELATION_LIST_REQUESTED: "FETCH_RELATION_LIST_REQUESTED",
  FETCH_RELATION_LIST_SUCCESS: "FETCH_RELATION_LIST_SUCCESS",
  SET_CURRENCY_SELECTED: "SET_CURRENCY_SELECTED",
  SWIPE_PAYMENT: "SWIPE_PAYMENT",
  SWIPE_PAYMENT_ERROR: "SWIPE_PAYMENT_ERROR",
  SWIPE_PAYMENT_REQUEST: "SWIPE_PAYMENT_REQUEST",
  SWIPE_PAYMENT_REDIRECT_URL: "SWIPE_PAYMENT_REDIRECT_URL",
  GET_PAYMENT_STATUS: "GET_PAYMENT_STATUS",
  GET_SYSTEM_PROPERTY_SUCCESS_FOR_SWIPE_PAYMENT: "GET_SYSTEM_PROPERTY_SUCCESS_FOR_SWIPE_PAYMENT",
  GET_SWIPE_PAYMENT_SYSTEM_PROPERTY: "GET_SWIPE_PAYMENT_SYSTEM_PROPERTY",
  SET_OPEN_CANCEL_TRANSACTION_POPUP: "SET_OPEN_CANCEL_TRANSACTION_POPUP",
  SET_CLOSE_CANCEL_TRANSACTION_POPUP: "SET_CLOSE_CANCEL_TRANSACTION_POPUP",
  SET_OPEN_FIND_UPI_ID_POPUP: "SET_OPEN_FIND_UPI_ID_POPUP",
  SET_CLOSE_FIND_UPI_ID_POPUP: "SET_CLOSE_FIND_UPI_ID_POPUP",
  GET_QR_IMAGE: "GET_QR_IMAGE",
  FETCH_UPI_HANDLES_LIST: "FETCH_UPI_HANDLES_LIST",
  FETCH_UPI_HANDLES_LIST_SUCCESS: "FETCH_UPI_HANDLES_LIST_SUCCESS",
  FETCH_UPI_HANDLES_LIST_FAILED: "FETCH_UPI_HANDLES_LIST_FAILED",
  UPI_COLLECT_PAYMENT_REQUESTED: "UPI_COLLECT_PAYMENT_REQUESTED",
  SAVE_UPI_ID_VALIDATION_REQUEST: "SAVE_UPI_ID_VALIDATION_REQUEST",
  SAVE_UPI_ID_VALIDATION_FAILED: "SAVE_UPI_ID_VALIDATION_FAILED",
  FETCH_PAYMENT_METHOD_FLAGS: "FETCH_PAYMENT_METHOD_FLAGS",
  FETCH_PAYMENT_METHOD_FLAGS_SUCCESS: "FETCH_PAYMENT_METHOD_FLAGS_SUCCESS",
  FETCH_PAYMENT_METHOD_FLAGS_FAILED: "FETCH_PAYMENT_METHOD_FLAGS_FAILED",
  SET_SWIPE_PAYMENT: "SET_SWIPE_PAYMENT",
  SET_UNDERWAY_PAYMENT: "SET_UNDERWAY_PAYMENT",
  FETCH_USER_SAVED_CARDS_PAYPLUS: "FETCH_USER_SAVED_CARDS_PAYPLUS",
  FETCH_ALL_USER_SAVED_CARDS_SUCCESS: "FETCH_ALL_USER_SAVED_CARDS_SUCCESS",
  FETCH_ALL_USER_SAVED_CARDS_FAILED: "FETCH_ALL_USER_SAVED_CARDS_FAILED",
  SWIPE_QR_PAYMENT_REQUEST: "SWIPE_QR_PAYMENT_REQUEST",
  SWIPE_SHOW_QR_PAYMENT: "SWIPE_SHOW_QR_PAYMENT",
  SWIPE_QR_PAYMENT_ERROR: "SWIPE_QR_PAYMENT_ERROR",
  SET_SWIPE_SHOW_QR_PAYMENT: "SET_SWIPE_SHOW_QR_PAYMENT",
  GET_AMOUNT_ON_GENERATE_QR_SCREEN: "GET_AMOUNT_ON_GENERATE_QR_SCREEN",
  FETCH_QR_PAYMENT_STATUS: "FETCH_QR_PAYMENT_STATUS",
  FETCH_UPI_QR_PAYMENT_POLLING_SUCCESS: "FETCH_UPI_QR_PAYMENT_POLLING_SUCCESS",
  FETCH_UPI_QR_PAYMENT_POLLING_FAILED: "FETCH_UPI_QR_PAYMENT_POLLING_FAILED",
  DISABLE_FNP_LOGO_REQUEST: "DISABLE_FNP_LOGO_REQUEST",
  SWIPE_UPI_INTENT_PAYMENT_RESPONSE: "SWIPE_UPI_INTENT_PAYMENT_RESPONSE",
  SWIPE_UPI_INTENT_PAYMENT: "SWIPE_UPI_INTENT_PAYMENT",
  SWITCH_VERIFY_PAYMENT_POLLING_SCREEN_SUCCESS: "SWITCH_VERIFY_PAYMENT_POLLING_SCREEN_SUCCESS",
  BLANK_PAYMENTS_PAGE_DATA: "BLANK_PAYMENTS_PAGE_DATA",
  SEND_PAYMENT_OTP_SUCCESS: "SEND_PAYMENT_OTP_SUCCESS",
  SEND_PAYMENT_OTP_ERROR: "SEND_PAYMENT_OTP_ERROR",
  SEND_PAYMENT_OTP: "SEND_PAYMENT_OTP",
  SEND_PAYMENT_OTP_RESET: "SEND_PAYMENT_OTP_RESET",
  SEND_PAYMENT_OTP_CLEAR: "SEND_PAYMENT_OTP_CLEAR",
  VALIDATE_PAYMENT_OTP_SUCCESS: "VALIDATE_PAYMENT_OTP_SUCCESS",
  VALIDATE_PAYMENT_OTP_ERROR: "VALIDATE_PAYMENT_OTP_ERROR",
  VALIDATE_PAYMENT_OTP_CLEAR: "VALIDATE_PAYMENT_OTP_CLEAR",
  VALIDATE_PAYMENT_OTP: "VALIDATE_PAYMENT_OTP",
  SIMPL_MOBILE_TOKEN: "SIMPL_MOBILE_TOKEN",
  CLEAR_PAYMENT_ERR_MSG: "CLEAR_PAYMENT_ERR_MSG",
  CLEAR_PAYMENT_OTP_ERROR: "CLEAR_PAYMENT_OTP_ERROR",
  CHECK_ELIGIBILITY_SUCCESS: "CHECK_ELIGIBILITY_SUCCESS",
  CHECK_ELIGIBILITY_ERROR: "CHECK_ELIGIBILITY_ERROR",
  FETCH_CHECK_PAYMENT_USER_ELIGIBILITY: "FETCH_CHECK_PAYMENT_USER_ELIGIBILITY",
  CHECK_ELIGIBILITY_RESPONSE_CLEAR: "CHECK_ELIGIBILITY_RESPONSE_CLEAR",
  SIMPL_INITIATE_PAYMENT_CALL_REQUEST: "SIMPL_INITIATE_PAYMENT_CALL_REQUEST",
  SIMPL_INITIATE_PAYMENT_CALL_SUCCESS: "SIMPL_INITIATE_PAYMENT_CALL_SUCCESS",
  SIMPL_INITIATE_PAYMENT_CALL_FAILED: "SIMPL_INITIATE_PAYMENT_CALL_FAILED",
};

export const AgentUrl = {
  CANCEL_URL: "/ordermgr/control/orderentry",
};

export const paymentGatewaysValues = {
  EXT_PAYU: "EXT_PAYU",
  EXT_PAYTM: "EXT_PAYTM",
  EXT_SWIPE: "EXT_SWIPE",
};
export const upiPaymentType = {
  UPI_QR: "UPI_QR",
  UPI_COLLECT: "UPI_COLLECT",
  UPI_INTENT: "UPI_INTENT",
};

export const upiPaymentTypeInSmallcase = {
  upi_qr: "upi_qr",
  upi_collect: "upi_collect",
  upi_intent: "upi_intent",
};
export const upiPaymentSwitch = {
  on: "on",
};

export const paymentOptPayloadCont = {
  PAYMENT_OPTIONS: "paymentoptions",
  EMI: "EMI",
  CC: "CC",
  DC: "DC",
  DEBIT_CARD_STATUS: "debitCardStatus",
  CREDIT_CARD_STATUS: "creditCardStatus",
  NB: "NB",
};

export const upiIntentAppsConst = {
  PHONEPE: "PHONEPE",
  GOOGLEPAY: "GOOGLEPAY",
  PAYTM: "PAYTM",
};

export const upiIntentAppsPrefixes = {
  GENERIC_PREFIX: "upi://pay?",
  PHONEPE_PREFIX: "phonepe://upi/pay?",
  GOOGLEPAY_PREFIX: "gpay://upi/pay?",
  PAYTM_PREFIX: "Paytm://upi/pay?",
};

export const userDeviceType = {
  MOBILE_ANDROID: "MOBILE_ANDROID",
  MOBILE_IOS: "MOBILE_IOS",
  MOBILE_OTHER: "MOBILE_OTHER",
};
export const PaymentsEndpoint = {
  verifyPayment: "/control/verifyPaymentStatus",
  initiatePayment: "/control/initiatePayment",
  retryInitiatePayment: "/control/retryInitiatePayment",
  orderConfirmation: "/checkout/order-confirmation",
  pnc: "/checkout/payment-not-confirmed",
  checkCardType: "/control/checkCardType",
  checkPaymentCardType: "/control/checkPaymentCardType",
  paymentBankList: "/control/getPaymentNetBankingList-rj",
  payUBankList: "/control/getNetBankingList-rj",
  ruPayBankList: "/control/getRpayNetBankingList-rj",
  fetchUserSavedCards: "/control/fetchUserSavedCards-rj",
  qrPaymentPollingStatus: "/control/pollingStatus",
  paymentMethodFlag: "global/static/web/paymentmethodflags",
};

export const usableBalanceEndpoint = {
  usableBalanceTnC: "global/static/web/usable_balance_tnc",
};

export const upiCollectHandlesEndpoint = {
  upiHandles: "/control/getUpiHandles",
};

export const upiIdValidationEndpoint = {
  upiValidation: "/control/validateUpi",
};

export default PaymentsConstants;
