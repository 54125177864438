import { HYDRATE } from "next-redux-wrapper";
import HomeActionsConstants from "../../../src/action-constants/home-actions-constants";

const initialState = {
  pageBodyData: "",
  accordionData: "",
  fnpBrandsData: "",
  errorMessages: {
    criticalError: "",
    pageBodyError: "",
    accordionDataError: "",
    fnpBrandsDataError: "",
  },
};

/**
 * Reducer for Home Page
 *
 * @param {object} state state object
 * @param {object} action action object
 * @returns {object} state object
 */
function homePageDataReducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.homePageData,
      };
    case HomeActionsConstants.SERVER_SET_MOBILE_HOME_CONTENT_DATA:
      return {
        ...state,
        pageBodyData: action.payload,
        errorMessages: {
          ...state.errorMessages,
          criticalError: "",
          pageBodyError: "",
        },
      };
    case HomeActionsConstants.SERVER_SET_MOBILE_HOME_ACCORDION_DATA:
      return {
        ...state,
        accordionData: action.payload,
        errorMessages: {
          ...state.errorMessages,
          accordionDataError: "",
        },
      };
    case HomeActionsConstants.SERVER_SET_DESKTOP_HOME_FOOTER_DATA:
      return {
        ...state,
        fnpBrandsData: action.payload,
        errorMessages: {
          ...state.errorMessages,
          fnpBrandsDataError: "",
        },
      };
    case HomeActionsConstants.SERVER_ERROR_MOBILE_HOME_CONTENT_DATA:
      return {
        ...state,
        pageBodyData: "",
        errorMessages: {
          ...state.errorMessages,
          pageBodyError: action.payload,
        },
      };
    case HomeActionsConstants.SERVER_ERROR_MOBILE_HOME_ACCORDION_DATA:
      return {
        ...state,
        accordionData: "",
        errorMessages: {
          ...state.errorMessages,
          accordionDataError: action.payload,
        },
      };
    case HomeActionsConstants.SERVER_ERROR_DESKTOP_HOME_FOOTER_DATA:
      return {
        ...state,
        fnpBrandsData: "",
        errorMessages: {
          ...state.errorMessages,
          fnpBrandsDataError: action.payload,
        },
      };
    case HomeActionsConstants.SERVER_SET_ERROR_MESSAGE:
      return {
        ...state,
        pageBodyData: "",
        errorMessages: {
          ...state.errorMessages,
          criticalError: action.payload,
        },
      };
    default:
      return state;
  }
}

export default homePageDataReducer;
