import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import DayPicker from "react-day-picker";
import { Swipeable } from "react-swipeable";

import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import InfoIcon from "@material-ui/icons/Info";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import {
  sanitizeDateStringToDate,
  dateToDMY,
  checkMonthIsPassed,
  getDatesAround,
  getDateRangeTimeWithoutHours,
  getDayCountInRange,
  isSameDayDeliveryAvailable,
  dayDifference,
} from "../../../../../src/utils/common";
import { getProductPriceByDeliveryDate } from "../../../../../src/actions/pdp-actions";
import "react-day-picker/lib/style.css";
import SurgePrice from "./surge-price";
import { callCdpDelivery } from "../../../../../src/helpers/CDPHelper";
import styles from "../../style/delivery-on-calender.module.scss";
import calenderStyles from "../../../../desktop/pdp/style/delivery-calender.module.scss";
import commonSpace from "../../../../../styles/commonSpace.module.scss";
import commonDisplay from "../../../../../styles/commonDisplay.module.scss";
import { WEEKDAYS_SHORT, MONTHS } from "../../../../../src/action-constants/pdp-constants";
import { getValueFromKey, setValueIn } from "../../../../../src/utils/search-utils";
import animateToast from "../../../../../src/utils/toastMsg";
import dateDiffInDays from "../../../../../src/utils/diffInDays";
import { setGaClickEventInteraction } from "../../../../../src/ga/gaEvents";
import { textConst } from "../../../../../const/text-english";
import RedirectionToPLPComponent from "../../../../common/redirectionToPLPComponent";
import { fetchContentFromCMSOnClient } from "../../../../../src/services/api/common/common-api";

dayjs.extend(customParseFormat);

/**
 * This method is used to render Month
 *
 * @param {object} root0 it includes object from parent component
 * @param {object} root0.date date object.
 * @returns {React.ReactElement} jsx for the month element.
 */
function YearMonthForm({ date }) {
  return (
    <div className="DayPicker-Caption">
      <div>{date.toLocaleString("default", { month: "long" })}</div>
    </div>
  );
}
YearMonthForm.propTypes = {
  date: PropTypes.instanceOf(Object).isRequired,
};

/**
 * This method is used to handle Navbar
 *
 * @param {object} root0 it includes object from parent component
 * @param {object} root0.previousMonth previousMonth.
 * @param {object} root0.onPreviousClick onPreviousClick.
 * @param {object} root0.onNextClick onNextClick.
 * @param {object} root0.setShowSurgeMsg setShowSurgeMsg.
 * @param {object} root0.showNextButton disable next button status the future dates.
 * @returns {React.ReactElement} jsx for the non deliverable pin code screen.
 */
function Navbar({ previousMonth, onPreviousClick, onNextClick, setShowSurgeMsg, showNextButton }) {
  const firstDate = new Date(previousMonth);
  const secondDate = new Date();
  const isPastMonth = checkMonthIsPassed(firstDate, secondDate);
  const { isMobile } = useSelector((state) => state.appConfigs);

  useEffect(() => {
    setShowSurgeMsg(document.querySelectorAll(".surge-icon").length > 0);
  });

  return (
    <div className={`${styles.calenderNavigationArrows} ${!isMobile ? styles.desktopCalenderNav : ""}`}>
      <button
        className={`${styles.calenderArrowButton} ${styles.contentLeft} ${isPastMonth ? styles.disableMonthArrow : ""}`}
        type="button"
        onClick={() => onPreviousClick()}
      >
        {isMobile ? <ChevronLeftIcon /> : <ArrowLeftIcon />}
      </button>
      <button
        className={`${styles.calenderArrowButton} ${styles.contentRight} ${
          !showNextButton ? styles.disableMonthArrow : ""
        }`}
        type="button"
        onClick={() => onNextClick()}
      >
        {isMobile ? <ChevronRightIcon /> : <ArrowRightIcon />}
      </button>
    </div>
  );
}
Navbar.propTypes = {
  onPreviousClick: PropTypes.func,
  onNextClick: PropTypes.func,
  previousMonth: PropTypes.instanceOf(Object),
  setShowSurgeMsg: PropTypes.func,
  showNextButton: PropTypes.bool,
};

Navbar.defaultProps = {
  onPreviousClick: () => {},
  onNextClick: () => {},
  previousMonth: () => {},
  setShowSurgeMsg: () => {},
  showNextButton: false,
};

const noDateToast = "no-delivery-toast";
const blockedDateToast = "blocked-date-toast";

/**
 * This method is used to render delivery calender
 *
 * @param {object} root0 it includes object from parent component
 * @param {Function} root0.onSelect on date select handler.
 * @param {Array} root0.deliveryDates on date select handler.
 * @param {Function} root0.onSelectRange on date range select handler
 * @param {string} root0.itemDesiredDeliveryDate item desired delivery date.
 * @param {string} root0.itemDesiredDeliveryDatesList list of item desired delivery dates.
 * @param {string} root0.pinCode pin code.
 * @param {boolean} root0.isCourierProduct whether its a courier product.
 * @param {boolean} root0.isPersonalizedProduct whether its a personalized product.
 * @param {string} root0.selectedVariant selected variant.
 * @param {boolean} root0.isCourierDelivery whether its a courier delivery.
 * @param {boolean} root0.checkIsInternationalProduct whether its an international product,
 * @param {object} root0.productDetail product detail.
 * @param {Function} root0.getPrice function that returns price after conversion.
 * @param {string} root0.currencyCode currency code.
 * @param {boolean} root0.showMultiOrderModal multi order modal
 * @param {Function} root0.setShowMultiOrderModal function to toggle the showMultiOrderModal flag
 * @param {boolean} root0.isCourierDateRangeEnable to check whether date range enable
 * @param {boolean} root0.isCheckout to check if it is in checkout-page or not
 * @returns {React.ReactElement} jsx to render the calender.
 */
function DeliveryOnCalender({
  onSelect,
  deliveryDates,
  onSelectRange,
  itemDesiredDeliveryDate,
  itemDesiredDeliveryDatesList,
  pinCode,
  isCourierProduct,
  isPersonalizedProduct,
  selectedVariant,
  isCourierDelivery,
  checkIsInternationalProduct,
  productDetail,
  getPrice,
  currencyCode,
  showMultiOrderModal,
  setShowMultiOrderModal,
  isCourierDateRangeEnable,
  isCheckout,
}) {
  const dispatch = useDispatch();

  const [displaySurge, setDisplaySurge] = useState(false);
  const [surgeDate, setSurgeDate] = useState(null);
  const [noOfDayBlockedInRange, setNoOfDayBlocked] = useState(0);
  const [selectedDay, setSelectedDay] = useState();
  const [selectedDays, setSelectedDays] = useState([]);
  const [surgeDates, setSurgeDates] = useState([]);
  const [showSurgeMsg, setShowSurgeMsg] = useState(false);
  const [multiOrderModalOffset, setMultiOrderModalOffset] = useState({ left: "", right: "" });
  const [msgContent, setMsgContent] = useState("");
  const [cutoffTimestamp, setCutoffTimestamp] = useState("");
  const [dateRangeData, setDateRangeData] = useState({});

  const appConfigs = useSelector((state) => state.appConfigs);
  const { isMobile, catalogId } = appConfigs;
  const { product, priceSurgeDetails, shippingDatesAndMethods, blockedDates } = productDetail;
  const { displayCatalogContent, content } = blockedDates || {};
  const shippingOptionsInfoMessage = shippingDatesAndMethods?.shippingOptionsInfo?.message;

  const { isMultiOrderProduct, multiOrderProductDetails } = product;
  const productId = product?.variantProducts ? selectedVariant?.productId : product.productId;
  const { isCdpEnabled } = useSelector((state) =>
    isMobile ? state.appConfigs.configData.mobile : state.appConfigs.configData.desktop,
  );
  const legendMessage = priceSurgeDetails?.legendMessage || "";
  const calendarRef = useRef(null);
  const deliveryDatesList = deliveryDates || [];

  // config options for Daypicker mobile swipeable
  const configOptions = {
    onSwipedLeft: () => {
      calendarRef.current.showNextMonth();
    },
    onSwipedRight: () => {
      calendarRef.current.showPreviousMonth();
    },
    delta: 10, // min distance(px) before a swipe starts
    preventDefaultTouchmoveEvent: false, // preventDefault on touchmove, *See Details*
    trackTouch: true, // track touch input
    trackMouse: true, // track mouse input
    rotationAngle: 0, // set a rotation angle
  };

  useEffect(() => {
    if (priceSurgeDetails && priceSurgeDetails.eventDates) {
      const eventDates = priceSurgeDetails.eventDates || [];
      const withDateObjects = eventDates.map((date) => dateToDMY(new Date(date)));
      setSurgeDates(withDateObjects);
    }
  }, [priceSurgeDetails]);

  useEffect(() => {
    if (selectedDay) setDateRangeData(getDatesAround(dateToDMY(selectedDay), deliveryDatesList));
  }, [selectedDay, deliveryDatesList]);

  useEffect(() => {
    if (itemDesiredDeliveryDate && !getValueFromKey("selectedDate")) {
      setSelectedDay(sanitizeDateStringToDate(itemDesiredDeliveryDate));
    } else if (isMobile && getValueFromKey("selectedDate")) setSelectedDay(new Date(getValueFromKey("selectedDate")));
  }, [itemDesiredDeliveryDate, isMobile]);

  useEffect(() => {
    if (itemDesiredDeliveryDatesList) {
      setShowMultiOrderModal(true);
      setSelectedDays(itemDesiredDeliveryDatesList.split(",").map((date) => sanitizeDateStringToDate(date)));
    } else {
      const dates = getValueFromKey("selectedDates");
      setSelectedDays(isMobile && dates ? dates.split(",").map((date) => sanitizeDateStringToDate(date)) : []);
    }
  }, [isMobile, itemDesiredDeliveryDatesList, setShowMultiOrderModal]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      const selectedDates = document.getElementsByClassName("DayPicker-Day--selected");
      if (selectedDates.length > 1) {
        setMultiOrderModalOffset({
          left: isMobile ? selectedDates[0].offsetLeft + 9 : selectedDates[0].offsetLeft + 18,
          top: isMobile ? selectedDates[0].offsetTop - 71 : selectedDates[0].offsetTop + 15,
        });
      }
    }, 10);
    return () => {
      clearTimeout(timeOut);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDays]);

  /**
   * This method is used to get same day delivery data
   *
   */
  const getSameDayDeliveryData = async () => {
    try {
      const response = await fetchContentFromCMSOnClient("global/static/web/calenderfootermsg");
      setMsgContent(response);
      const availabilityStatus = isSameDayDeliveryAvailable(response?.cutoffTimestamp);
      setCutoffTimestamp(availabilityStatus);
    } catch (error) {
      setMsgContent("");
    }
  };

  useEffect(() => {
    if (!isCheckout && deliveryDatesList.indexOf(dateToDMY(new Date())) === -1 && !checkIsInternationalProduct) {
      getSameDayDeliveryData();
    }
    // cannot add any dependencies because we don't want to add dependencies for the content itself.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * This method is used to compare date. It has been passed or not
   *
   * @param {object} firstDate date to compare.
   * @param {object} secondDate compare with date.
   * @returns {boolean} date is passed or not.
   */
  const isPastDate = (firstDate, secondDate) => firstDate.setHours(0, 0, 0, 0) - secondDate.setHours(0, 0, 0, 0) < 0;

  /**
   * This method is used to hide surge modal
   *
   */
  const hideSurgeModal = () => {
    setDisplaySurge(false);
  };

  /**
   * This method is used to handle continue with price surge
   */
  const continueWithSurge = () => {
    setDisplaySurge(false);
    setSelectedDay(surgeDate);
    onSelect(surgeDate);
  };

  /**
   * This method is used to find if a day is blocked or not
   *
   * @param {object} day day object to get and manipulate on day operations.
   * @returns {boolean} returns true if day is blocked.
   */
  const isDayDisabled = (day) => {
    if (isMultiOrderProduct) {
      let dayDiff;
      let lastDayDiff;
      const { type, noOfDays } = multiOrderProductDetails;
      const lastDeliveryDate = deliveryDatesList?.[deliveryDatesList?.length - 1]?.split("-")?.reverse()?.join("-");
      if (type === "DAILY" && selectedDays?.length) {
        if (selectedDay) {
          dayDiff = dayDifference(day, selectedDay);
          lastDayDiff = dayDifference(new Date(lastDeliveryDate), selectedDay);
        }

        if (selectedDay && day && dayDiff > 0 && dayDiff <= noOfDays && lastDayDiff >= noOfDays) {
          return false;
        }
      }
    }
    return !deliveryDatesList.includes(dateToDMY(day));
  };

  /**
   * This method is used to render day on calender
   *
   * @param {object} day day object to get and manipulate on day operations.
   * @returns {React.ReactElement} jsx to render the day.
   */
  function renderDay(day) {
    const dayStr = dateToDMY(day);
    const { endRangeDate, selectedDate, startRangeDate } = dateRangeData;
    day.setHours(0, 0, 0, 0);
    const date = day.getDate();
    const dateInMS = day.getTime();
    const dateRangeDataInDetail = {};
    if (selectedDate) {
      dateRangeDataInDetail.desiredDateData = getDateRangeTimeWithoutHours(selectedDate);
      if (endRangeDate) {
        dateRangeDataInDetail.nextToDesiredDateData = getDateRangeTimeWithoutHours(endRangeDate);
      }
      if (startRangeDate) {
        dateRangeDataInDetail.beforeDesiredDateData = getDateRangeTimeWithoutHours(startRangeDate);
      }
    }

    const isSurge = surgeDates.indexOf(dateToDMY(day)) !== -1;
    const isDayBlocked = isDayDisabled(day);
    const showDateRange = isCourierDateRangeEnable && isCourierDelivery;
    const { day: desiredDay, timeInMS: desiredDateInMS } = dateRangeDataInDetail?.desiredDateData || {};
    const { day: rightToDesiredDay, timeInMS: rightDateInMS } = dateRangeDataInDetail?.nextToDesiredDateData || {};
    const { day: leftToDesiredDay, timeInMS: leftDateInMS } = dateRangeDataInDetail?.beforeDesiredDateData || {};

    /**
     * This function will return the classnames for date range.
     *
     * @returns {string} contains class name.
     */
    const getDateRangeClasses = () => {
      const gapInDaysDesiredToNext = getDayCountInRange(desiredDateInMS, rightDateInMS);
      const rightBarrierClass =
        showDateRange &&
        (dayStr === endRangeDate || (dateInMS < rightDateInMS && dateInMS > desiredDateInMS)) &&
        gapInDaysDesiredToNext < 8 &&
        rightToDesiredDay > desiredDay
          ? "right-side-to-selected-date"
          : "";

      const gapInDaysLeftToDesired = getDayCountInRange(desiredDateInMS, leftDateInMS);
      const leftBarrierClass =
        showDateRange &&
        (dayStr === startRangeDate || (dateInMS > leftDateInMS && dateInMS < desiredDateInMS)) &&
        gapInDaysLeftToDesired < 8 &&
        leftToDesiredDay < desiredDay
          ? "left-side-to-selected-date"
          : "";

      const dateRangeCircle =
        showDateRange && (dayStr === startRangeDate || dayStr === endRangeDate) ? "side-to-selected-date-circle" : "";
      return `${rightBarrierClass} ${leftBarrierClass} ${dateRangeCircle}`;
    };

    return (
      <div className={`cell-block ${isMobile ? "mobile-calender" : "desktop-calender"}`}>
        {isSurge && !isDayBlocked && !isCourierProduct && !isPersonalizedProduct && (
          <sup className={isMobile ? "surge-icon surge-icon-mobile" : "surge-icon surge-icon-desktop"} />
        )}
        <div className={`date-block ${getDateRangeClasses()}`}>{date}</div>
      </div>
    );
  }

  /**
   * This method is used to handle day click
   *
   * @param {object} day day object to get and manipulate on day operations.
   * @returns {void} set selected day in to the state.
   */
  const handleDayClick = async (day) => {
    setNoOfDayBlocked(0);
    if (isMobile) setValueIn("selectedDate", day);
    if (isCdpEnabled) {
      callCdpDelivery(day);
    }
    const currentDate = new Date();
    /* Start of GA events */
    const difference = dateDiffInDays(currentDate, day);
    const selectDateLabel = day.getDate();
    const currentDateLabel = currentDate.getDate();

    const selectedMonth = day.toLocaleString("default", { month: "long" }).substring(0, 3);
    const currentMonth = currentDate.toLocaleString("default", { month: "long" }).substring(0, 3);
    const currentSelectedDate = `${selectDateLabel} ${selectedMonth} | ${currentDateLabel} ${currentMonth}`;

    const customDimension = { difference_in_days: `${difference} days` };
    setGaClickEventInteraction(
      "delivery_date_selection",
      "pdp_interaction",
      "delivery_date_selection",
      currentSelectedDate,
      customDimension,
    );
    /* End of GA events */

    if (isPastDate(day, currentDate)) {
      animateToast(noDateToast);
      return false;
    }
    if (deliveryDatesList.length > 0) {
      if (!deliveryDatesList.includes(dateToDMY(day))) {
        animateToast(noDateToast);
        return false;
      }
    }
    /* MULTI ORDER CASE START */
    if (isMultiOrderProduct) {
      let nextDates = [];

      const { type, noOfDays } = multiOrderProductDetails;
      if (type === "DAILY") {
        nextDates = [...Array(noOfDays)].map((_, i) => {
          const date = new Date(day);
          date.setDate(date.getDate() + i);
          return dateToDMY(date);
        });
      }

      if (nextDates.length < noOfDays) {
        animateToast(noDateToast);
        return false;
      }
      setSelectedDay(day);
      const sanitizedDates = nextDates.map((date) => sanitizeDateStringToDate(date));
      setSelectedDays(sanitizedDates);
      if (isMobile) setValueIn("selectedDates", sanitizedDates);
      setShowMultiOrderModal(true);
      return false;
    }
    /* MULTI ORDER CASE END */

    // CHECK AND SHOW PRICE SURGE MODAL IN CASE OF MOBILE ONLY
    if (isMobile) {
      if (!isCourierDelivery) {
        const res = await getProductPriceByDeliveryDate(
          {
            FNP_CURRENT_CATALOG_ID: catalogId,
            deliveryDate: dateToDMY(day),
            geoId: pinCode,
            productId,
          },
          false,
          dispatch,
        );

        const isSurge = surgeDates.indexOf(dateToDMY(day)) !== -1;
        if (isSurge && res?.isPricingRuleApplied === "Y") {
          setSurgeDate(day);
          setDisplaySurge(true);
          return false;
        }
      }
    }

    setSelectedDay(day);
    onSelect(day);
    return "";
  };
  /**
   * This method is used to continue with selected range in multi order case
   *
   * @returns {void} set selected day in to the state.
   */
  const continueWithSelectedRange = async () => {
    setShowMultiOrderModal(false);
    const day = selectedDay;

    // CHECK AND SHOW PRICE SURGE MODAL IN CASE OF MOBILE ONLY
    if (isMobile) {
      const res = await getProductPriceByDeliveryDate(
        {
          FNP_CURRENT_CATALOG_ID: catalogId,
          deliveryDate: dateToDMY(day),
          geoId: pinCode,
          productId,
        },
        false,
        dispatch,
      );
      const isSurge = surgeDates.indexOf(dateToDMY(day)) !== -1;
      if (isSurge && res?.isPricingRuleApplied === "Y") {
        setSurgeDate(day);
        setDisplaySurge(true);
        return false;
      }
    }
    setSelectedDay(day);
    onSelect(day);
    onSelectRange(selectedDays.map((date) => dateToDMY(date)).join());
    return "";
  };

  /**
   * This function renders the msg for the calendar as per the product type
   *
   * @returns {React.ReactElement} returns JSX element for the calendar msg
   */
  const renderDeliveryMsg = () => {
    if (isCourierDelivery) {
      return (
        <>
          <InfoIcon className={`${styles["info-details"]} ${styles.icon}`} />
          <span className={isMobile ? styles["courier-product-msg-mobile"] : styles["courier-product-msg"]}>
            {textConst.productPage.giftMayBeDeliveredOrAfter}
          </span>
        </>
      );
    }
    if (checkIsInternationalProduct) {
      return (
        <Grid className={styles.calender_footer_wrapper}>
          {shippingOptionsInfoMessage && (
            <Grid
              className={`${styles.shippingOptionInfoSvg} ${commonSpace.marginBottom10} ${commonDisplay.displayFlexNoWrap}`}
            >
              <ErrorOutlineIcon />
              <Grid className={styles.shippingOptionInfo}>{shippingOptionsInfoMessage}</Grid>
            </Grid>
          )}
          {/* This code is getting commented because of ticket ID FES-15206, valentine requirement
            and will be uncommented back after valentine season 2024.
          <Grid className={`${commonSpace.marginBottom10} ${commonDisplay.displayFlexNoWrap}`}>
            <ErrorOutlineIcon />
            <Grid>{textConst.productPage.dueToTimezoneDifference}</Grid>
          </Grid> */}
        </Grid>
      );
    }
    return "";
  };

  /**
   * This function will check selected date is past date or future date to today's date.
   *
   * @param {Date} day selected date
   * @returns {Date} returns a date for disabling the past dates.
   */
  const avoidDateOnPastMonth = (day) => {
    return day < new Date() ? day : new Date();
  };

  /**
   * This function will return a future date to block future non-deliverable months.
   *
   * @returns {Date} returns a date for disabling the future dates.
   */
  const nonDeliverableFutureMonth = () => {
    return deliveryDatesList.length
      ? sanitizeDateStringToDate(deliveryDatesList[deliveryDatesList.length - 1])
      : new Date();
  };

  const modifiers = {
    monday: { daysOfWeek: [1] },
    disabled: [
      {
        before: new Date(),
      },
    ],
    currentDate: isMobile ? new Date() : "",
  };

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <>
      <div className={calenderStyles["calender-wrapper"]} data-testid="delivery-on-calender">
        <div
          className={`${"calender"} ${calenderStyles.calender} ${
            isMobile ? calenderStyles["mobile-calender"] : "desk-calender"
          }`}
        >
          <Swipeable {...configOptions}>
            <DayPicker
              selectedDays={selectedDays.length ? selectedDays : selectedDay}
              onDayClick={handleDayClick}
              initialMonth={selectedDay}
              month={selectedDays.length ? selectedDays[0] : selectedDay}
              fixedWeeks
              modifiers={modifiers}
              weekdaysShort={WEEKDAYS_SHORT}
              months={MONTHS}
              navbarElement={<Navbar setShowSurgeMsg={setShowSurgeMsg} />}
              renderDay={renderDay}
              disabledDays={isDayDisabled}
              ref={calendarRef}
              numberOfMonths={1}
              pagedNavigation
              captionElement={({ date }) => <YearMonthForm date={date} />}
              fromMonth={avoidDateOnPastMonth(selectedDays.length ? selectedDays[0] : selectedDay)}
              toMonth={nonDeliverableFutureMonth()}
            />
          </Swipeable>
        </div>

        <Modal
          open={displaySurge}
          onClose={hideSurgeModal}
          aria-labelledby="price-surge-modal"
          aria-describedby="price-surge-modal"
        >
          <SurgePrice
            hideSurgeModal={hideSurgeModal}
            continueWithSurge={continueWithSurge}
            productDetail={productDetail}
            getPrice={getPrice}
            currencyCode={currencyCode}
          />
        </Modal>

        <Modal
          open={showMultiOrderModal}
          onClose={() => {
            setShowMultiOrderModal(false);
          }}
          aria-labelledby="price-surge-modal"
          aria-describedby="price-surge-modal"
          hideBackdrop
        >
          <div className="select-date-popup-container">
            <div className={styles.selectDatePopup} style={{ position: "absolute", top: multiOrderModalOffset.top }}>
              <p>
                {`The date you're selecting would be the start date of your service and your product would be
                delivered for the next ${multiOrderProductDetails?.noOfDays} days.`}
              </p>
              <div className={styles.buttonWrapper}>
                <span
                  role="presentation"
                  onClick={() => {
                    setSelectedDays([]);
                    setShowMultiOrderModal(false);
                  }}
                >
                  {textConst.productPage.editDate}
                </span>
                <span role="presentation" onClick={() => continueWithSelectedRange()}>
                  {textConst.productPage.next}
                </span>
              </div>
              <span
                className={styles.popupArrowDown}
                style={{ position: "absolute", left: multiOrderModalOffset.left }}
              />
            </div>
          </div>
        </Modal>

        <div className={styles["no-delivery-wrapper"]}>
          <div className={styles.noDeliveryToast} id={noDateToast}>
            {textConst.productPage.deliveryIsNotAvailable}
          </div>
        </div>

        <div className={styles["no-delivery-wrapper"]}>
          <div className={styles.noDeliveryToast} id={blockedDateToast}>
            {`${noOfDayBlockedInRange} ${noOfDayBlockedInRange > 1 ? "dates are" : "date is"} blocked for this month`}
          </div>
        </div>

        {showSurgeMsg && surgeDates.length > 0 && !isCourierProduct && !isPersonalizedProduct && (
          <div className={styles.surgePriceMessageBlock}>
            <span className={styles.legendPriceSurgeMessage}>
              <span>{legendMessage}</span>
            </span>
          </div>
        )}
        <div
          className={`calendar-footer adjust-calendar-footer ${
            checkIsInternationalProduct && "international-prod-container"
          }
          ${checkIsInternationalProduct && shippingOptionsInfoMessage && "international-prod-container-shipping-info"}
          ${isCourierProduct && "courier-prod-container"} ${isMobile && "mobile-calender-footer"}`}
        >
          <span className={`${styles.valentineMessage} ${isMobile ? styles.mobilevalentineMessage : ""}`}>
            {renderDeliveryMsg()}
          </span>
        </div>

        {!isCheckout && !checkIsInternationalProduct && (
          <RedirectionToPLPComponent
            blockedDates={blockedDates?.blockedDates}
            displayCatalogContent={displayCatalogContent}
            cutoffTimestamp={cutoffTimestamp}
            isCalender
            deliveryDatesList={deliveryDatesList}
            samedayMsgContent={msgContent}
            limitedCatalogContent={content}
            pincode={pinCode}
          />
        )}
      </div>
    </>
  );
}

DeliveryOnCalender.propTypes = {
  onSelect: PropTypes.func.isRequired,
  deliveryDates: PropTypes.instanceOf(Array),
  onSelectRange: PropTypes.func,
  isCourierProduct: PropTypes.bool.isRequired,
  isPersonalizedProduct: PropTypes.bool.isRequired,
  selectedVariant: PropTypes.objectOf(PropTypes.any).isRequired,
  isCourierDelivery: PropTypes.bool.isRequired,
  checkIsInternationalProduct: PropTypes.bool.isRequired,
  productDetail: PropTypes.objectOf(PropTypes.any).isRequired,
  itemDesiredDeliveryDate: PropTypes.string.isRequired,
  itemDesiredDeliveryDatesList: PropTypes.string.isRequired,
  pinCode: PropTypes.string.isRequired,
  getPrice: PropTypes.func.isRequired,
  currencyCode: PropTypes.string.isRequired,
  showMultiOrderModal: PropTypes.bool.isRequired,
  setShowMultiOrderModal: PropTypes.func.isRequired,
  isCourierDateRangeEnable: PropTypes.bool,
  isCheckout: PropTypes.bool,
};

DeliveryOnCalender.defaultProps = {
  deliveryDates: [],
  onSelectRange: () => {},
  isCourierDateRangeEnable: false,
  isCheckout: false,
};

export default React.memo(DeliveryOnCalender);
