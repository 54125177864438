import React, { useEffect, useMemo, useRef, useState } from "react";
import Autocomplete from "react-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";
import { parseCookies } from "nookies";
import style from "./header-search.module.scss";

import {
  POPULATED_SEARCH_LIST_HEADING_LABEL,
  SEARCH_BOX_PLACE_HOLDER,
  TIMEOUT_PERIOD_500,
} from "../../../../common/constants";
import { requestSearchTermSuggestion, requestManualBoost } from "../../../../../src/actions/common-search-actions";
import { fetchProductListingOnClient } from "../../../../../src/services/api/plp/product-listing-api";
import { getCookie, getPageNameForCleverTap, getRoutePageName } from "../../../../../src/utils/common";
import geoDefault from "../../../../../geo_configurations/default";
import { setValueInLocalstorage } from "../../../../../src/utils/search-utils";
import { ga4SearchEvent } from "../../../../../src/ga/gaEvents";
import { getApiData, stopAnimation } from "../../../../common/placeholderAnimation";
import Regex from "../../../../common/regex";
import callClevertapEvent from "../../../../../src/analytics/clevertapUtility";
import { cleverTapEventsConstants } from "../../../../../src/analytics/clevertapConstants";

/**
 * Header Search Component.
 *
 * @returns {React.ReactElement} Header search Component has the search suggestion , popular search.
 */
function HeaderSearch() {
  const dispatch = useDispatch();
  const router = useRouter();
  const page = getRoutePageName(router.pathname);

  const [combinedSearchList, setCombinedSearchList] = useState([]);
  const { faul } = parseCookies();
  const [storedRecentSearchData, setStoredRecentSearchData] = useState({
    userID: faul,
    recentSearchList: [],
  });
  const popularSearches = useSelector((state) => state.searchData.popularSearches);

  const [selectedValue, setSelected] = useState("");
  const [searching, setSearching] = useState(false);
  const [isSearchInputClickedOnce, setSearchInputClickedOnce] = useState(false);
  const searchFormRef = useRef(null);
  const appConfig = useSelector((state) => state.appConfigs);
  const catalogId = appConfig?.catalogId;
  const { configData } = appConfig;

  useEffect(() => {
    if (popularSearches?.suggestionResponse) {
      const popularSearchList = popularSearches.suggestionResponse;
      const recentSearchesToShow = popularSearches.recentSearchCount || 2;

      const recentSearchItemList = localStorage.getItem("recentSearchItemList");
      let recentSearchListToShow = [];

      if (recentSearchItemList) {
        const parsedSearchItemList = JSON.parse(recentSearchItemList);
        if (parsedSearchItemList?.userID === faul) {
          setStoredRecentSearchData(parsedSearchItemList);
          const filteredRecentSearches = parsedSearchItemList.recentSearchList.filter((item) =>
            item.keyword.toLowerCase().includes(selectedValue.toLowerCase()),
          );

          recentSearchListToShow = filteredRecentSearches.reverse().slice(0, recentSearchesToShow);
        }
      }

      setCombinedSearchList([...recentSearchListToShow, ...popularSearchList]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popularSearches?.suggestionResponse, selectedValue, faul]);

  useEffect(() => {
    const url = new URL(window.location.href);
    if (url.pathname.includes("search")) {
      const searchedTerm = url.searchParams.get("qs");
      if (searchedTerm) setSelected(decodeURI(searchedTerm));
    }
  }, []);

  const requestSearchTermDebounced = useMemo(
    () => debounce((param) => dispatch(requestSearchTermSuggestion(param)), TIMEOUT_PERIOD_500, true),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   * this method will takes the user input if string length > 2 then it calls the api for the suggestions.
   *
   * @param {string} val search string.
   * @returns {void} returns nothing
   */
  const handleAutocompleteState = (val) => {
    const domainId = window?.location?.hostname || "fnp.com";
    const userId = getCookie("faul");
    const uid = getCookie("cdp-fngId"); // gets user id (emailId) if loggedIn.
    const optionParam = {
      domainId,
      geoId: catalogId || "india",
      keyword: val,
      lang: "en",
      uuid: uid,
      userId,
    };
    if (!userId) delete optionParam.userId;
    requestSearchTermDebounced(optionParam);
  };

  /**
   *This method calls the search suggestions.
   *
   * @param {object} event change event
   * @returns {void} returns nothing
   */
  const onChange = (event) => {
    const { value } = event.target;
    event.preventDefault();
    const valTrim = value.trim();
    if (Regex.alphaNumericWithSpace.test(valTrim)) {
      setSelected(value);
    }
    if (valTrim.length) {
      handleAutocompleteState(valTrim);
    } else {
      requestSearchTermDebounced.cancel();
      setSearchInputClickedOnce(false);
      const domainId = window?.location?.hostname || "fnp.com";
      const optionParam = {
        domainId,
        geoId: catalogId || "india",
      };
      dispatch(requestManualBoost(optionParam));
    }
  };

  /**
   * Returns the entire search suggestion elements
   *
   * @param {Array} items suggestions
   * @returns {React.ReactElement} returns the search suggestions dropdown if items are not empty else returns empty drop down.
   */
  const renderMenu = (items) => (
    <div className={style["menu-container"]}>
      {!isEmpty(items) && (
        <>
          <div className={`${style["menu-item"]} ${style["menuitem-heading"]}`} as="div">
            <i className="material-icons">trending_up</i>
            {POPULATED_SEARCH_LIST_HEADING_LABEL}
          </div>
          <ul className="menu" id="search-hint-list">
            {items}
          </ul>
        </>
      )}
    </div>
  );

  /**
   *
   * @param {object} item suggestions detail link label and href detail.
   * @param {string} className prop to render required classes.
   * @returns {React.ReactElement} returns specific item
   */
  const simpleItem = (item, className) => {
    const pos = combinedSearchList.findIndex((search) => search.keyword === item.keyword) + 1;
    const searchTerm = item?.suggestionResponseType === "RS" ? item?.keyword : item?.categoryName || item?.keyword;
    return (
      <div
        className={style[`${className}`]}
        data-page={page}
        data-keywordtype={item?.suggestionResponseType}
        data-searchposition={pos}
        data-search={searchTerm}
      >
        <a
          href={item.targetUrl}
          onClick={() => {
            ga4SearchEvent(item?.categoryName);
          }}
        >
          {searchTerm}
        </a>
      </div>
    );
  };

  /**
   * Conditional rendering of item according to its type
   *
   * @param {object} item suggestions detail link label and href detail.
   * @returns {React.ReactElement} return the item
   */
  const conditionalRenderedItem = (item) => {
    switch (item?.suggestionResponseType) {
      case "RS":
        return simpleItem(item, "restore-icon");
      case "PR":
        return simpleItem(item, "search-icon");
      default:
        return simpleItem(item, "search-icon");
    }
  };

  /**
   * Suggestion details
   *
   * @param {object} item suggestions detail link label and href detail.
   * @returns {React.ReactElement} returns the detail of suggestion.
   */
  const renderItem = (item) => (
    <li className={`${style["menu-item"]}`} key={item.keyword}>
      {conditionalRenderedItem(item)}
    </li>
  );
  /**
   * This method calls when the suggestion selected.
   *
   * @returns {void} returns nothing.
   */
  const handleSelect = async () => {
    const trimmedValue = selectedValue?.trim();
    if (trimmedValue.length > 0) {
      setSearching(true);
      const domainId = "fnp.com";
      const currency = configData?.mobile?.currency || configData?.default?.currency || "INR";
      const options = {
        params: {
          currency,
          geoId: catalogId,
          isFacetEnabled: false,
          page: 0,
          size: geoDefault.size,
          domainId,
          lang: "en",
          pageType: "search",
          qs: trimmedValue,
        },
      };
      ga4SearchEvent(trimmedValue);
      setValueInLocalstorage("searchInfo", JSON.stringify({ keyword: trimmedValue, page }));
      const res = await fetchProductListingOnClient(options);
      if (res?.redirectUrl) {
        setSearching(false);
        window.location.href = res?.redirectUrl;
      } else {
        const searchHref = `/search?FNP_CURRENT_CATALOG_ID=${catalogId}&qs=${encodeURIComponent(trimmedValue)}`;
        setSearching(false);
        window.location.href = `${window.location.origin + searchHref}`;
      }
      if (trimmedValue) {
        // Check if the keyword already exists in the existing values
        const keywordExists = storedRecentSearchData.recentSearchList.some((item) => item.keyword === trimmedValue);

        if (!keywordExists) {
          const newRecentSearch = {
            keyword: trimmedValue,
            suggestionResponseType: "RS",
          };
          const updatedRecentSearchData = {
            ...storedRecentSearchData,
            recentSearchList: [...storedRecentSearchData.recentSearchList, newRecentSearch],
          };

          setStoredRecentSearchData(updatedRecentSearchData);
          localStorage.setItem("recentSearchItemList", JSON.stringify(updatedRecentSearchData));
        }
      }

      setSelected("");
    } else {
      searchFormRef.current.focus();
    }
  };

  useEffect(() => {
    let searchInputId = null;
    if (searchFormRef) {
      searchInputId = searchFormRef.current.refs.input;
      searchInputId.addEventListener("focus", stopAnimation);
      getApiData(searchInputId);
    }
    return () => {
      searchInputId.removeEventListener("focus", stopAnimation);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFormRef]);
  /**
   * This method calls handleSelect when key down pressed
   *
   * @param {object} event event
   * @returns {void} returns nothing.
   */
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSelect();
    }
  };
  /**
   * this function handles the onclose icon click in the search bar
   */
  const handleOnClose = () => {
    setSelected("");
  };

  /**
   * This function call the search suggestion API with empty keyword, when user focus on input box.
   *
   * @param {object} event targeted object
   */
  const handleOnClick = (event) => {
    const { value } = event.target;
    if (!value.length && !isSearchInputClickedOnce) {
      setSearchInputClickedOnce(true);
      const domainId = window?.location?.hostname || "fnp.com";
      const optionParam = {
        domainId,
        geoId: catalogId || "india",
      };
      dispatch(requestManualBoost(optionParam));
    }
    const pageHeaderMenuData = {
      page_name: getPageNameForCleverTap(),
      page_type: getPageNameForCleverTap(),
      menu_name: "Search",
    };
    callClevertapEvent(cleverTapEventsConstants.headerMenuClicked, pageHeaderMenuData);
  };

  return (
    <div
      className={`${style["header-search"]} ${selectedValue.length ? style["close-icon"] : ""} `}
      data-testid="search"
    >
      <Autocomplete
        getItemValue={(item) => {
          return item?.suggestionResponseType === "RS" ? item?.keyword : item?.categoryName || item?.keyword;
        }}
        items={combinedSearchList && Array.isArray(combinedSearchList) && selectedValue ? combinedSearchList : []}
        inputProps={{
          type: "search",
          placeholder: SEARCH_BOX_PLACE_HOLDER,
          onKeyUp: (e) => handleKeyDown(e),
          id: "header-search-input",
          "data-page": page,
          onClick: (event) => handleOnClick(event),
        }}
        wrapperStyle={{ position: "relative", display: "inline-block" }}
        renderItem={renderItem}
        renderMenu={renderMenu}
        value={selectedValue}
        onChange={onChange}
        onSelect={setSelected}
        onMenuVisibilityChange={(val) => val}
        ref={searchFormRef}
        autoHighlight={false}
      />

      <button type="button" onClick={handleOnClose}>
        <i className="material-icons" data-testid="close">
          close
        </i>
      </button>
      <button className={style["search-button"]} type="button" onClick={handleSelect}>
        <i className="material-icons">search</i>
      </button>
      <div className={searching ? style.loader : style["hide-div"]} key={0}>
        <div className={style.spinner} />
      </div>
    </div>
  );
}

export default HeaderSearch;
