import CommonPageActionsConstants from "../action-constants/common-page-actions-constants";
import { execDataLayer } from "../data-layer/dataLayer";
import { endpointConst } from "../data-layer/dataLayerConstants";
import { fetchContentFromCMSOnClient } from "../services/api/common/common-api";

/**
 * Action for requesting page org postal data
 *
 * @returns {object} returns action
 */
export const requestPageOrgPostalData = () => ({
  type: CommonPageActionsConstants.SERVER_REQUEST_MOBILE_PAGE_ORG_POSTAL_DATA,
});

/**
 * Action for setting page org postal data
 *
 * @param {string} payload contains org postal data
 * @returns {object} returns action
 */
export const setPageOrgPostalData = (payload) => ({
  type: CommonPageActionsConstants.SERVER_SET_MOBILE_PAGE_ORG_POSTAL_DATA,
  payload,
});

/**
 * Action for setting org postal data error
 *
 * @param {string} payload contains error data
 * @returns {object} returns action
 */
export const errorPageOrgPostalData = (payload) => ({
  type: CommonPageActionsConstants.SERVER_ERROR_MOBILE_PAGE_ORG_POSTAL_DATA,
  payload,
});

/**
 * Action for requesting page rel alt data
 *
 * @param {string} payload contains rel alt options
 * @returns {object} returns action
 */
export const requestPageRelAltData = (payload) => ({
  type: CommonPageActionsConstants.SERVER_REQUEST_PAGE_REL_ALT_DATA,
  payload,
});

/**
 * Action for setting page rel alt data
 *
 * @param {string} payload contains rel alt data
 * @returns {object} returns action
 */
export const setPageRelAltData = (payload) => ({
  type: CommonPageActionsConstants.SERVER_SET_PAGE_REL_ALT_DATA,
  payload,
});

/**
 * Action for setting page rel alt error
 *
 * @param {string} payload contains error data
 * @returns {object} returns action
 */
export const errorPageRelAltData = (payload) => ({
  type: CommonPageActionsConstants.SERVER_ERROR_PAGE_REL_ALT_DATA,
  payload,
});

/**
 * Action for requesting page meta data
 *
 * @param {string} payload contains meta content id
 * @returns {object} returns action
 */
export const requestPageMetaData = (payload) => ({
  type: CommonPageActionsConstants.SERVER_REQUEST_PAGE_META_DATA,
  payload,
});

/**
 * Action for setting page meta data
 *
 * @param {string} payload contains meta data
 * @returns {object} returns action
 */
export const setPageMetaData = (payload) => ({
  type: CommonPageActionsConstants.SERVER_SET_PAGE_META_DATA,
  payload,
});

/**
 * Action for setting page meta data error
 *
 * @param {string} payload contains error data
 * @returns {object} returns action
 */
export const errorPageMetaData = (payload) => ({
  type: CommonPageActionsConstants.SERVER_ERROR_PAGE_META_DATA,
  payload,
});

/**
 * @function apiFn wrapper function to execute addAddonsToCart from datalayer.
 * @returns {Promise<any>} Response from datalayer
 */
const apiFn = async () => {
  const res = await fetchContentFromCMSOnClient(endpointConst.configurecallandchattime);
  return res;
};
/**
 * This method fetches Timing for CALL and CHAT in my account.
 *
 * @returns {object} response
 */
export async function getCallChatTime() {
  try {
    const response = await execDataLayer(apiFn, endpointConst.configurecallandchattime);
    return response;
  } catch (ex) {
    return ex;
  }
}
