import getConfig from "next/config";
import errorLogger from "../../../../app_configs/logger-service";
import { fetchContentFromCMSOnClient } from "../common/common-api";

const { publicRuntimeConfig } = getConfig();
const SHOW_REQUESTS_LOGS = publicRuntimeConfig.SHOW_REQUESTS_LOGS || "Y";
const error = {
  config: {},
  response: {},
};
/**
 * This method calls the api for fetching all the occasions
 *
 * @param {string} catalogId it's Id of the catalog
 * @returns {Array} response of the  occasions api - array of occasions
 */
async function getOccasions() {
  try {
    return await fetchContentFromCMSOnClient("global/static/web/occasionlist");
  } catch (ex) {
    error.config.url = "global/static/web/occasionlist";
    error.response.status = `An Exception occurred while fetching results => ${ex}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return [];
  }
}
export default getOccasions;

/**
 * This method calls the api for fetching all the relations
 *
 * @returns {Array} response of the  relations api - array of relations
 */
export async function getRelations() {
  try {
    return await fetchContentFromCMSOnClient("global/static/web/relationlist");
  } catch (ex) {
    error.config.url = "global/static/web/relationlist";
    error.response.status = `An Exception occurred while fetching results => ${ex}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return [];
  }
}

/**
 * This method calls the api for fetching all the configured text for the popup
 *
 * @returns {Array} response of the  relations api - array of relations
 */
export async function getConfiguredRemindersData() {
  try {
    return await fetchContentFromCMSOnClient("global/static/web/thankyoupageoccasionpopup");
  } catch (ex) {
    error.config.url = "global/static/web/thankyoupageoccasionpopup";
    error.response.status = `An Exception occurred while fetching results => ${ex}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return [];
  }
}
