import { HYDRATE } from "next-redux-wrapper";
import CommonPageActionsConstants from "../../../src/action-constants/common-page-actions-constants";

const initialState = {
  orgPostalSchema: "",
  relAlternateData: [],
  dynamicMetaData: "",
  errorMessages: {
    orgPostalSchemaError: "",
    dynamicMetaDataError: "",
    relAltError: "",
  },
};

/**
 * Reducer for common app data
 *
 * @param {object} state state object
 * @param {object} action action object
 * @returns {object} state object
 */
const commonPageDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.commonPageData,
      };
    case CommonPageActionsConstants.SERVER_SET_MOBILE_PAGE_ORG_POSTAL_DATA:
      return {
        ...state,
        orgPostalSchema: action.payload,
      };
    case CommonPageActionsConstants.SERVER_SET_PAGE_META_DATA:
      return {
        ...state,
        dynamicMetaData: action.payload,
      };
    case CommonPageActionsConstants.SERVER_SET_PAGE_REL_ALT_DATA:
      return {
        ...state,
        relAlternateData: [...action.payload?.relALtUrls],
        showDefaultRelAlt: action.payload?.showDefaultRelAlt,
      };
    case CommonPageActionsConstants.SERVER_ERROR_MOBILE_PAGE_ORG_POSTAL_DATA:
      return {
        ...state,
        orgPostalSchema: "",
        errorMessages: {
          ...state.errorMessages,
          orgPostalSchemaError: action.payload,
        },
      };
    case CommonPageActionsConstants.SERVER_ERROR_PAGE_META_DATA:
      return {
        ...state,
        dynamicMetaData: "",
        errorMessages: {
          ...state.errorMessages,
          dynamicMetaDataError: action.payload,
        },
      };
    case CommonPageActionsConstants.SERVER_ERROR_PAGE_REL_ALT_DATA:
      return {
        ...state,
        relAlternateData: [],
        errorMessages: {
          ...state.errorMessages,
          relAltError: action.payload,
        },
      };
    case CommonPageActionsConstants.GET_CALL_CHAT_TIME_SUCCESS:
      return { ...state, configureCallAndChatTime: action.payload };
    case CommonPageActionsConstants.GET_CALL_CHAT_TIME_FAIL:
      return {
        ...state,
      };
    case CommonPageActionsConstants.POST_DATA_IN_CLEVER_TAP_SUCCESS:
      return { ...state, publishDataInCleverTap: action.payload };
    case CommonPageActionsConstants.POST_DATA_IN_CLEVER_TAP_FAIL:
      return { ...state };
    default:
      return state;
  }
};

export default commonPageDataReducer;
