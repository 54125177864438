const availablePinCodes = [
  "11",
  "14",
  "17",
  "20",
  "23",
  "26",
  "30",
  "33",
  "37",
  "40",
  "43",
  "46",
  "49",
  "52",
  "57",
  "60",
  "63",
  "68",
  "71",
  "74",
  "77",
  "80",
  "83",
  "12",
  "15",
  "18",
  "21",
  "24",
  "27",
  "31",
  "34",
  "38",
  "41",
  "44",
  "47",
  "50",
  "53",
  "58",
  "61",
  "64",
  "69",
  "72",
  "75",
  "78",
  "81",
  "84",
  "13",
  "16",
  "19",
  "22",
  "25",
  "28",
  "32",
  "36",
  "39",
  "42",
  "45",
  "48",
  "51",
  "56",
  "59",
  "62",
  "67",
  "70",
  "73",
  "76",
  "79",
  "82",
  "85",
  "54",
];
export default availablePinCodes;
