import { HYDRATE } from "next-redux-wrapper";
import actionConstants from "../../src/action-constants/app-actions";

const initialState = {
  uspData: "",
};

/**
 * @param {object} state - state
 * @param {string} action - action
 * @returns {object} new state
 */
function uspReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...payload.usp,
      };
    case actionConstants.GET_USP_DATA_SUCCESS:
      return {
        ...state,
        uspData: payload,
      };
    default:
      return state;
  }
}

export default uspReducer;
