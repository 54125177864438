import React from "react";
import PropTypes from "prop-types";
import style from "./info-toolbar/info-toolbar.module.scss";
import dheaderStyle from "../styles/d-header.module.scss";
import { getPageNameForCleverTap } from "../../../../src/utils/common";
import callClevertapEvent from "../../../../src/analytics/clevertapUtility";
import { cleverTapEventsConstants } from "../../../../src/analytics/clevertapConstants";

/**
 * Component of Enquiries
 *
 * @param {object} root0  props passed to the component .
 * @param {object} root0.links contains link to redirect.
 * @returns {React.ReactElement} returns component.
 */
function SubInfoLinks({ links }) {
  const { label, href } = links;
  const pageHeaderMenuData = {
    page_name: getPageNameForCleverTap(),
    page_type: getPageNameForCleverTap(),
    menu_name: label,
  };

  return (
    <>
      <a
        className={`${style["info-links"]} ${style["info-sub-links"]} ${style.black} ${dheaderStyle["align-items"]}`}
        href={href}
        onClick={() => {
          callClevertapEvent(cleverTapEventsConstants.headerMenuClicked, pageHeaderMenuData);
        }}
      >
        {label}
      </a>
    </>
  );
}

SubInfoLinks.propTypes = {
  links: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SubInfoLinks;
