import { HYDRATE } from "next-redux-wrapper";
import APP_CONSTANTS from "../../../src/action-constants/app-actions";

const InitialState = {
  success: false,
  error: false,
  loginToken: "",
  email: "",
};

/**
 * This method is the reducer for actions that are specific to user
 *
 * @param {object} state - contains initial redux State
 * @param {object} action - contains actionTypes and payload information for specific action
 * @returns {object} state - the next State which needs to be dispatched to redux
 */
function validLoginIdReducer(state = InitialState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.validID,
      };
    case APP_CONSTANTS.VALID_ID:
      return {
        ...state,
        success: false,
        error: false,
      };
    case APP_CONSTANTS.VALID_ID_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        loginToken: action.payload.data.data,
        email: action.payload.email,
      };
    case APP_CONSTANTS.SET_VALID_USER_EXISTS:
      return {
        ...state,
        loginToken: {
          ...state,
          exists: action.payload,
        },
      };
    case APP_CONSTANTS.VALID_ID_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
      };
    default:
      return state;
  }
}
export default validLoginIdReducer;
