import { put, call, takeLatest } from "redux-saga/effects";
import commonSearchConstants from "../../src/action-constants/common-search-constants";
import {
  getPopularSearches,
  getSearchTermSuggestionFromCMS,
  getManualBoost,
} from "../../src/services/api/searchbar/all-search-api";
import { customCreatePopularSearch, updateManualBoostKeyword } from "../../src/utils/search-utils";

/**
 * This worker saga takes the action that was dispatched for popular search
 *
 * @param {object} action action contains the payload for the api call
 */
function* fetchPopularSearches(action) {
  try {
    const res = yield call(getPopularSearches, action.payload);
    if (res) {
      const customElm = yield call(customCreatePopularSearch, res);
      yield put({ type: commonSearchConstants.GET_POPULAR_SEARCH_SUCCESS, payload: customElm });
    }
  } catch (e) {
    yield put({ type: commonSearchConstants.GET_POPULAR_SEARCH_FAIL, payload: e });
  }
}

/**
 * This worker saga takes the action that was dispatched for search term suggestion
 *
 * @param {object} action action contains the payload for the api call
 */
function* fetchSearchTermSuggestionFromCMS(action) {
  try {
    const res = yield call(getSearchTermSuggestionFromCMS, action.payload);

    if (res) {
      const data = updateManualBoostKeyword(res?.suggestionResponse);
      yield put({
        type: commonSearchConstants.GET_POPULAR_SEARCH_SUCCESS,
        payload: res?.productType || {
          recentSearchCount: res?.recentSearchCount,
          suggestionResponse: data,
        },
      });
    }
  } catch (error) {
    yield put({ type: commonSearchConstants.GET_POPULAR_SEARCH_FAIL, payload: error.ex });
  }
}

/**
 *This worker saga takes the action that was dispatched for manual boost
 *
 * @param {object} action - action contains the payload for the api call
 */
function* fetchManualBoost(action) {
  try {
    const res = yield call(getManualBoost, action.payload);
    if (res) {
      const data = updateManualBoostKeyword(res);
      yield put({ type: commonSearchConstants.GET_MANUAL_BOOST_SUCCESS, payload: data });
    }
  } catch (error) {
    yield put({ type: commonSearchConstants.GET_MANUAL_BOOST_FAIL, payload: error.message });
  }
}

/**
 * saga for fetching search related data
 *
 */
function* watchSearchRequest() {
  yield takeLatest(commonSearchConstants.GET_POPULAR_SEARCH_REQUEST, fetchPopularSearches);
  yield takeLatest(commonSearchConstants.GET_SEARCH_TERM_SUGGESTION_REQUEST, fetchSearchTermSuggestionFromCMS);
  yield takeLatest(commonSearchConstants.GET_MANUAL_BOOST_REQUEST, fetchManualBoost);
}

export default watchSearchRequest;
