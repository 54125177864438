import { put } from "redux-saga-test-plan/matchers";
import { call, takeEvery } from "redux-saga/effects";
import MenuPageConstants from "../../src/action-constants/menu-constants";
import {
  requestDesktopMegaMenuDataFail,
  requestMobileLeftMenuDataFail,
  setDesktopMegaMenuData,
  setMobileLeftMenuData,
} from "../../src/actions/menuAction";
import { fetchContentFromCMS } from "../../src/services/api/common/common-api";
import { fetchMobileLeftMenuData } from "../../src/services/api/menu/menu-api";

/**
 * Fetches left menu content based on catalog id
 *
 * @param {object} action contains type and payload
 */
function* getAndSetMobileLeftMenuData(action) {
  try {
    const catalogId = action.payload;
    const response = yield call(fetchMobileLeftMenuData, catalogId);
    yield put(setMobileLeftMenuData(response));
  } catch (error) {
    yield put(requestMobileLeftMenuDataFail(error.message));
  }
}

/**
 * Fetches left menu content based on catalog id
 *
 * @param {object} action contains type and payload
 */
function* getAndSetDesktopMegaMenuData(action) {
  try {
    const response = yield call(fetchContentFromCMS, action.payload);
    if (response) {
      yield put(setDesktopMegaMenuData(response));
    }
  } catch (error) {
    yield put(requestDesktopMegaMenuDataFail(error.message));
  }
}

/**
 * Watcher saga for menu data
 */
function* watchMenuDataSaga() {
  yield takeEvery(MenuPageConstants.SERVER_REQUEST_MOBILE_LEFT_MENU_DATA, getAndSetMobileLeftMenuData);
  yield takeEvery(MenuPageConstants.SERVER_REQUEST_DESKTOP_MEGA_MENU_DATA, getAndSetDesktopMegaMenuData);
}

export default watchMenuDataSaga;
