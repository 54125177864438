import { Badge } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import styles from "./cart-preview.module.scss";

/**
 * This is cart icon component for desktop
 *
 * @param {number} cartSize this number is the current base product count
 * @returns {React.ReactElement} returns a jsx element for the cart icon
 */
function CartIcon({ cartSize }) {
  return (
    <>
      <Badge
        className={styles["cart-icon-badge"]}
        data-ga-title="Cart"
        title="Added cart list"
        badgeContent={cartSize}
        color="error"
      >
        <i className="material-icons">shopping_cart</i>
      </Badge>
      <span>Cart</span>
    </>
  );
}
export default CartIcon;
CartIcon.propTypes = {
  cartSize: PropTypes.number,
};

CartIcon.defaultProps = {
  cartSize: 0,
};
