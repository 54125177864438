import getConfig from "next/config";
import https from "https";
import axios from "./index";

const { publicRuntimeConfig } = getConfig();
const desktop = typeof window !== "undefined" ? window.dSite : false;
const baseApiUrl = desktop ? publicRuntimeConfig.D_BASE_URL : publicRuntimeConfig.BASE_URL;
const agent = new https.Agent({
  rejectUnauthorized: false,
});

export default axios({
  baseURL: baseApiUrl,
  withCredentials: true,
  httpsAgent: agent,
});
