import { HYDRATE } from "next-redux-wrapper";
import ListingPageConstants from "../../src/action-constants/listing-contants";

const initialState = {
  productDesc: {},
  earliestDeliveryDays: {},
  earliestDeliveryDates: {},
};

/**
 * This method is the reducer for actions that are specific to user
 *
 * @param {object} state - contains initial redux State
 * @param {object} action - contains actionTypes and payload information for specific action
 * @returns {object} state - the next State which needs to be dispatched to redux
 */
function productDescReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.productDescription,
      };
    case ListingPageConstants.PRODUCT_DESC_SUCCESS:
      return {
        ...state,
        productDesc: { ...state.productDesc, ...payload?.productSummaryDetails },
      };
    case ListingPageConstants.DELIVERY_DATES_POPULATED:
      return {
        ...state,
        earliestDeliveryDays: {
          ...state.earliestDeliveryDays,
          ...payload.earliestDeliveryDays,
        },
        earliestDeliveryDates: { ...state.earliestDeliveryDates, ...payload.earliestDeliveryDates },
      };
    default:
      return state;
  }
}
export default productDescReducer;
