import APP_CONSTANTS from "../action-constants/app-actions";

/**
 * Action of requesting microSite page
 *
 * @param {object} options represents the set of params required for get content api
 * @returns {object} returns action
 */
export const requestMicroSitePageBody = (options) => ({
  type: APP_CONSTANTS.REQUEST_MICRO_PAGE_BODY,
  payload: options,
});

/**
 * Action to set microSite page data
 *
 * @param {string} apiData contains data from svn content
 * @returns {object} returns action
 */
export const setMicroSitePageData = (apiData) => ({
  type: APP_CONSTANTS.SET_MICRO_PAGE_BODY,
  payload: apiData,
});

/**
 * Action for catching any error in microSite page
 *
 * @param {string} err catches any error in microSite page
 * @returns {object} returns action
 */
export const errorInMicroSitePage = (err) => ({
  type: APP_CONSTANTS.ERROR_IN_MICRO_PAGE,
  payload: err,
});

/**
 * Action of requesting microSite page
 *
 * @param {object} options represents the set of params required for get content api
 * @returns {object} returns action
 */
export const requestTestimonialData = (options) => ({
  type: APP_CONSTANTS.REQUEST_TESTIMONIAL_DATA,
  payload: options,
});

/**
 * Action to set testimonial page data
 *
 * @param {string} apiData contains data from testimonial data
 * @returns {object} returns action
 */
export const setTestimonialData = (apiData) => ({
  type: APP_CONSTANTS.SET_TESTIMONIAL_DATA,
  payload: apiData,
});

/**
 * Action to catch error in  testimonial page data
 *
 * @param {string} apiData contains data from testimonial data
 * @returns {object} returns action
 */
export const errorTestimonial = (apiData) => ({
  type: APP_CONSTANTS.ERROR_TESTIMONIAL,
  payload: apiData,
});

/**
 * Action to set testimonial page data
 *
 * @param {string} apiData contains data from testimonial data
 * @returns {object} returns action
 */
export const setTestimonialReviewSummary = (apiData) => ({
  type: APP_CONSTANTS.SET_TESTIMONIAL_REVIEW_SUMMARY,
  payload: apiData,
});
