import { put, takeLatest, call, all, fork, select } from "redux-saga/effects";
import getConfig from "next/config";
import errorLogger from "../../app_configs/logger-service";
import PAYMENT_CONSTANTS, { PaymentsEndpoint } from "../../src/action-constants/payment-constants";
import CHECKOUT_CONSTANTS, { CheckoutRoutes } from "../../src/action-constants/checkout-constants";
import paymentActions, {
  checkEligibilityApi,
  sendPaymentOtpApi,
  validatePaymentOtpApi,
} from "../../src/actions/payment-actions";
import checkoutActions from "../../src/actions/checkout-actions";
import { getSystemPropertyValue, getSavedCardSystemPropertyValue } from "../../src/actions/common-actions";
import getOccasions, { getRelations } from "../../src/services/api/myAccount/reminder";
import { AccountRoutes } from "../../src/action-constants/my-account-constants";

const { publicRuntimeConfig } = getConfig();
const SHOW_REQUESTS_LOGS = publicRuntimeConfig.SHOW_REQUESTS_LOGS || "Y";

/** This method is making Api call to fetch user saved cards
 *
 * @param {object} action - action type and payload
 */
export function* fetchUserSavedCardsSaga(action) {
  try {
    const response = yield call(paymentActions.fetchUserSavedCards, action.payload);
    if (response?.status.code === "200") {
      yield put({ type: PAYMENT_CONSTANTS.FETCH_ALL_USER_SAVED_CARDS_SUCCESS, payload: response });
    } else {
      throw new Error(response?.status.msg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_ALL_USER_SAVED_CARDS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to fetch all payment associated data
 *
 * @param {object} action - object data
 */
export function* fetchPaymentMethodsSaga(action) {
  try {
    const response = yield call(paymentActions.fetchPaymentMethods, action.payload);
    const isSwipePayment = yield select((state) => state.payments.isPaymentSwipe);
    if (response.paymentDetails) {
      if (isSwipePayment === "Y") {
        yield* fetchUserSavedCardsSaga({
          payload: response.paymentDetails?.paymentOptionsData.partyId,
        });
      }
    }
    if (response.error) {
      yield put({
        type: PAYMENT_CONSTANTS.FETCH_PAYMENT_METHODS_FAILED,
        payload: { error: response.error?.message || "Something Went Wrong!" },
      });
    } else {
      yield put({ type: PAYMENT_CONSTANTS.FETCH_PAYMENT_METHODS_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_PAYMENT_METHODS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to fetch details of card number entered by the user
 *
 * @param {object} action - action type and payload
 */
export function* checkCardTypeSaga(action) {
  try {
    const isSwipePayment = yield select((state) => state.payments.isPaymentSwipe);
    const response = yield call(paymentActions.checkCardType, {
      cardData: action.payload,
      isSwipePayment,
    });
    if (isSwipePayment === "Y" && (response?.status?.code !== "200" || response?.isDomestic === "N")) {
      const retryResponse = yield call(paymentActions.checkCardType, {
        cardData: action.payload,
        isSwipePayment: "N",
      });
      yield put({ type: PAYMENT_CONSTANTS.CHECK_CARD_TYPE_SUCCESS, payload: retryResponse });
    } else {
      yield put({ type: PAYMENT_CONSTANTS.CHECK_CARD_TYPE_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.CHECK_CARD_TYPE_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/**
 * this function get the payment options related data
 *
 * @returns {object} payment page data
 */
function* fetchPaymentOptionsData() {
  const cartItems = yield call(checkoutActions.getCompleteCartItemDetails);
  const { cartSummaryAmountDetail, encodedCustomerDetails, donationEnable, donationAmount } = yield all({
    cartSummaryAmountDetail: call(checkoutActions.getCartSummaryAmountDetail),
    encodedCustomerDetails: call(checkoutActions.getEncodedCustomerDetails),
    donationEnable: call(checkoutActions.getDonationEnable),
    donationAmount: call(checkoutActions.getDonationAmount),
  });

  return {
    cartItems,
    cartSummaryAmountDetail,
    encodedCustomerDetails,
    donationEnable,
    donationAmount,
  };
}

/**
 * This method is initiating all the checkout Payment Api calls
 */
export function* paymentOptionsSaga() {
  try {
    const paymentOptionsResponse = yield call(fetchPaymentOptionsData);

    if (paymentOptionsResponse?.error) {
      throw new Error(paymentOptionsResponse?.error);
    }
    yield put({
      type: CHECKOUT_CONSTANTS.CART_ITEM_EXPIRED,
      payload: null,
    });
    if (paymentOptionsResponse.cartItems?.data?.orderList?.length === 0) {
      yield put({
        type: PAYMENT_CONSTANTS.FETCH_PAYMENT_OPTIONS_METHODS_SUCCESS,
        payload: {
          cartItems: [],
          cartSummaryAmountDetail: [],
          encodedCustomerDetails: [],
          donationAmount: {},
          donationEnable: {},
        },
      });
    } else {
      if (paymentOptionsResponse?.cartItems?.data?.orderList) {
        const selectedContactMechIdObj = {};
        paymentOptionsResponse?.cartItems?.data?.orderList?.map((item) => {
          selectedContactMechIdObj[item?.giftItem?.giftIndex] =
            item?.giftItem?.selectedAddressDetails?.selectedContactMechId;

          return null;
        });
        yield put({
          type: CHECKOUT_CONSTANTS.SET_GIFT_ITEM_DEFAULT_ADDRESS,
          payload: selectedContactMechIdObj,
        });
      }
      yield put({
        type: PAYMENT_CONSTANTS.FETCH_PAYMENT_OPTIONS_METHODS_SUCCESS,
        payload: {
          cartItems: paymentOptionsResponse.cartItems.data,
          cartSummaryAmountDetail: paymentOptionsResponse.cartSummaryAmountDetail.data,
          encodedCustomerDetails: paymentOptionsResponse.encodedCustomerDetails.data,
          donationAmount: paymentOptionsResponse.donationAmount.data,
          donationEnable: paymentOptionsResponse.donationEnable.data,
        },
      });
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_PAYMENT_OPTIONS_METHODS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to fetch details of card number entered by the user
 *
 * @param {object} action - action type and payload
 */
export function* getEmiPaymentValuesSaga(action) {
  try {
    const response = yield call(paymentActions.getEmiPaymentValues, action.payload);
    if (response?.isBankIlligible?.length) {
      yield put({ type: PAYMENT_CONSTANTS.FETCH_EMI_OPTIONS_SUCCESS, payload: response });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_EMI_OPTIONS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to fetch available paypal currencies
 */
export function* getPaypalCurrenciesSaga() {
  try {
    const response = yield call(paymentActions.getPaypalCurrencies);
    if (response?.isSuccess) {
      yield put({ type: PAYMENT_CONSTANTS.FETCH_PAYPAL_CURRENCIES_SUCCESS, payload: response });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_PAYPAL_CURRENCIES_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to fetch available net banking options
 */
export function* getNetBankingListSaga() {
  try {
    const response = yield call(paymentActions.getNetBankingList);
    if (response?.isSuccess) {
      yield put({ type: PAYMENT_CONSTANTS.FETCH_NET_BANKING_LIST_SUCCESS, payload: response });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_NET_BANKING_LIST_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to fetch available UPI COLLECT handles.
 */
export function* getUpiHandlesListSaga() {
  try {
    const response = yield call(paymentActions.getUpiHandlesList);

    if (response?.status?.code === "SUCCESS") {
      yield put({ type: PAYMENT_CONSTANTS.FETCH_UPI_HANDLES_LIST_SUCCESS, payload: response });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_UPI_HANDLES_LIST_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/**
 * This method is making Api call to fetch status for QR payment polling.
 *
 * @param {object} action for getting the response for pollling
 */
export function* getQrPaymentPollingStatusSaga(action) {
  try {
    const response = yield call(paymentActions.getQrPaymentPollingStatus, action.payload);
    const finalResponse = response?.status.isSuccess;

    if (finalResponse === "true" || finalResponse === "false" || finalResponse === "pending") {
      yield put({ type: PAYMENT_CONSTANTS.FETCH_UPI_QR_PAYMENT_POLLING_SUCCESS, payload: response });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_UPI_QR_PAYMENT_POLLING_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to fetch available wallet options
 */
export function* getWalletDetailsSaga() {
  try {
    const response = yield call(paymentActions.getWalletDetails);
    if (response?.isSuccess) {
      yield put({ type: PAYMENT_CONSTANTS.FETCH_WALLET_DETAILS_SUCCESS, payload: response });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_WALLET_DETAILS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to fetch payment related info
 */
export function* getPaymentOptionsDetailsSaga() {
  try {
    const response = yield call(paymentActions.getPaymentOptionsDetails);
    if (response?.isSuccess) {
      yield put({ type: PAYMENT_CONSTANTS.FETCH_PAYMENT_OPTION_DETAILS_SUCCESS, payload: response });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_PAYMENT_OPTION_DETAILS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to fetch min EMI amount
 */
export function* getMinEMIAmountSaga() {
  try {
    const response = yield call(paymentActions.getMinAmountEMI);
    if (response?.isSuccess) {
      yield put({ type: PAYMENT_CONSTANTS.FETCH_MIN_EMI_AMOUNT_SUCCESS, payload: response });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_MIN_EMI_AMOUNT_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to fetch thank you page info
 *
 * @param {object} action - action type and payload
 */
export function* getThankYouPageInfoSaga(action) {
  try {
    const response = yield call(paymentActions.getThankYouPageInfo, action.payload);
    if (response?.isSuccess) {
      yield put({ type: PAYMENT_CONSTANTS.FETCH_THANK_YOU_PAGE_INFO_SUCCESS, payload: response?.result });
    } else if (response?.isSuccess === false) {
      yield put({ type: PAYMENT_CONSTANTS.FETCH_THANK_YOU_PAGE_INFO_SUCCESS, payload: { invalidOrder: true } });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_THANK_YOU_PAGE_INFO_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to fetch all reminders
 *
 * @param {object} action - action type and payload
 */
export function* getAllRemindersSaga(action) {
  try {
    const { occasionId = "", upcoming = false } = action.payload;
    const response = yield call(paymentActions.getAllReminders, occasionId, upcoming);
    if (response?.isSuccess) {
      yield put({ type: PAYMENT_CONSTANTS.FETCH_REMINDERS_SUCCESS, payload: response });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_REMINDERS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to update reminders
 *
 * @param {object} action - action type and payload
 */
export function* saveRemindersSaga(action) {
  try {
    const response = yield call(paymentActions.updateReminders, action.payload);
    if (response?.isSuccess) {
      yield put({
        type: PAYMENT_CONSTANTS.SAVE_REMINDERS_SUCCESS,
        payload: action.payload,
        reminderId: response?.occasionReminderId,
        coupon: response?.couponsDetails?.couponCode,
      });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.SAVE_REMINDERS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to delete reminders
 *
 * @param {object} action - action type and payload
 */
export function* deleteRemindersSaga(action) {
  try {
    const response = yield call(paymentActions.deleteReminders, action.payload);
    if (response?.isSuccess) {
      yield put({ type: PAYMENT_CONSTANTS.DELETE_REMINDERS_SUCCESS, payload: action.payload });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.DELETE_REMINDERS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to delete saved cards
 *
 * @param {object} action - action type and payload
 */
export function* deleteSavedCardSaga(action) {
  try {
    const { tokenId, cardToken } = action.payload;
    const response = yield call(paymentActions.deleteSavedCard, tokenId, cardToken);
    if (response?.responseMessage === "success") {
      yield call(fetchPaymentMethodsSaga, action);
      yield put({ type: PAYMENT_CONSTANTS.DELETE_SAVED_CARDS_SUCCESS, payload: action.payload });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.DELETE_SAVED_CARDS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to fetch thank you page detail
 *
 */
export function* thankYouPageContentDetail() {
  try {
    const response = yield call(paymentActions.getThankYouPageContentDetail);
    if (response) {
      yield put({
        type: PAYMENT_CONSTANTS.FETCH_THANK_YOU_PAGE_DETAIL_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_THANK_YOU_PAGE_DETAIL_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to fetch payment method flags.
 *
 */
export function* paymentMethodFlags() {
  try {
    const response = yield call(paymentActions.getPaymentMethodFlags);
    if (response) {
      yield put({
        type: PAYMENT_CONSTANTS.FETCH_PAYMENT_METHOD_FLAGS_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_PAYMENT_METHOD_FLAGS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to fetch all the net banking options data
 *
 * @param {object} action - object data
 */
export function* fetchNetBankingOptionsSaga(action) {
  try {
    const isSwipePayment = yield select((store) => store.payments.isPaymentSwipe);
    const response = yield call(paymentActions.getNetBankingList, { data: action.payload, isSwipePayment });
    if (response?.isSuccess) {
      yield put({ type: PAYMENT_CONSTANTS.FETCH_NET_BANKING_OPTIONS_SUCCESS, payload: response });
    } else {
      yield put({ type: PAYMENT_CONSTANTS.SWIPE_PAYMENT_ERROR, payload: true });
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_NET_BANKING_OPTIONS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/**
 * This method is making Api call to save user feedback on profile completion
 *
 * @param {object} action - action object for action type and payload
 */
export function* saveUserFeedbackSaga(action) {
  try {
    const { data } = yield call(paymentActions.saveUserFeedback, action.payload);
    if (data?.isSuccess) {
      yield put({ type: PAYMENT_CONSTANTS.SAVE_USER_FEEDBACK_SUCCESS });
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}

/**
 * This method is making Api call to get system property value
 *
 * @param {object} action - action object for action type and payload
 *
 */
export function* getSystemProperty(action) {
  try {
    const response = yield call(getSystemPropertyValue, action.payload);
    if (response) {
      yield put({
        type: PAYMENT_CONSTANTS.GET_SYSTEM_PROPERTY_SUCCESS,
        payload: response.value,
      });
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}
/**
 * This method is making Api call to get system property value for swipe payment
 *
 * @param {object} action - action object for action type and payload
 *
 */
export function* getSystemPropertyForSwipePayment(action) {
  try {
    const response = yield call(getSystemPropertyValue, action.payload);
    if (response) {
      yield put({
        type: PAYMENT_CONSTANTS.GET_SYSTEM_PROPERTY_SUCCESS_FOR_SWIPE_PAYMENT,
        payload: response.value,
      });
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}

/**
 * This method is making Api call to get system property value for saved card
 *
 * @param {object} action - action object for action type and payload
 *
 */
export function* getSavedCardSystemProperty(action) {
  try {
    const response = yield call(getSavedCardSystemPropertyValue, action.payload);
    if (response) {
      yield put({
        type: PAYMENT_CONSTANTS.GET_SAVED_CARD_SYSTEM_PROPERTY_SUCCESS,
        payload: JSON.parse(response.value),
      });
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}

/** This method is making Api call to fetch occasion list
 *
 * @param {object} action - object data
 *
 */
export function* getOccasionContentDetail(action) {
  try {
    const response = yield call(getOccasions, action.payload.catalogId);
    if (response) {
      yield put({
        type: PAYMENT_CONSTANTS.FETCH_OCCASION_LIST_SUCCESS,
        payload: { occasionList: response },
      });
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}

/** This method is making Api call to fetch relation list
 *
 *
 */
export function* getRelationContentDetail() {
  try {
    const response = yield call(getRelations);
    if (response) {
      yield put({
        type: PAYMENT_CONSTANTS.FETCH_RELATION_LIST_SUCCESS,
        payload: { relationList: response },
      });
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}
/** This method is making POST API call for make payment i.e pay-plus
 *
 * @param {object} action - payload
 *
 */
export function* swipePaymentPostCall(action) {
  try {
    yield put({ type: PAYMENT_CONSTANTS.SWIPE_PAYMENT_REQUEST, payload: true });
    const response = yield call(paymentActions.swipePaymentCall, action.payload);
    if (response?.data?.status?.code === "200") {
      yield put({ type: PAYMENT_CONSTANTS.SWIPE_PAYMENT_REDIRECT_URL, payload: response?.data?.payment?.redirectURL });
    } else if (response?.data?.status?.code === "500" && response?.data?.order?.orderId) {
      window.location.href = `${PaymentsEndpoint.pnc}?orderId=${response?.data?.order?.orderId}`;
    } else {
      yield put({ type: PAYMENT_CONSTANTS.SWIPE_PAYMENT_ERROR, payload: true });
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
    // this redirection is handled for the edge case scenario of cancelled API due to timeout
    window.location.href = `/${AccountRoutes.ACCOUNT}/${AccountRoutes.MY_ORDERS}`;
  }
}

/** This method is making POST API call for initiate payment for showing QR screen i.e pay-plus
 *
 * @param {object} action - payload
 *
 */
export function* swipeShowQrPaymentPostCall(action) {
  const renderAmtOnQRScreen = yield select((store) => store.payments.renderAmtOnQRScreen);
  try {
    yield put({ type: PAYMENT_CONSTANTS.SWIPE_QR_PAYMENT_REQUEST });
    const response = yield call(paymentActions.swipePaymentCall, action.payload);
    if (response?.data?.status?.code === "200") {
      yield put({
        type: PAYMENT_CONSTANTS.SET_SWIPE_SHOW_QR_PAYMENT,
        payload: response?.data,
      });
      const generateQRDetails = {
        qrString: response?.data?.payment?.qrDetails?.qrString,
        orderId: response?.data?.order?.orderId,
        timeoutInSec: response?.data?.payment?.qrDetails?.timeoutInSec,
        renderAmtOnQRScreen,
      };
      const encodedgenerateQRDetails = JSON.stringify(generateQRDetails);
      window.location.href = `/${CheckoutRoutes.CHECKOUT}/${CheckoutRoutes.UPI_PAYMENT}?generateQRDetails=${window.btoa(
        encodedgenerateQRDetails,
      )}`;
    } else if (response?.data?.status?.code === "500" && response?.data?.order?.orderId) {
      window.location.href = `${PaymentsEndpoint.pnc}?orderId=${response?.data?.order?.orderId}`;
    } else {
      yield put({ type: PAYMENT_CONSTANTS.SWIPE_PAYMENT_ERROR, payload: true });
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
    // this redirection is handled for the edge case scenario of cancelled API due to timeout
    window.location.href = `/${AccountRoutes.ACCOUNT}/${AccountRoutes.MY_ORDERS}`;
  }
}

/** This method is making POST API call for make UPI intent payment i.e pay-plus
 *
 * @param {object} action - payload
 *
 */
export function* swipeUpiIntentPaymentPostCall(action) {
  try {
    const response = yield call(paymentActions.swipePaymentCall, action.payload);

    if (response?.data?.status.code === "200") {
      yield put({ type: PAYMENT_CONSTANTS.SWIPE_UPI_INTENT_PAYMENT_RESPONSE, payload: response });
    } else if (response?.data?.status?.code === "500" && response?.data?.order?.orderId) {
      window.location.href = `${PaymentsEndpoint.pnc}?orderId=${response?.data?.order?.orderId}`;
    } else {
      yield put({ type: PAYMENT_CONSTANTS.SWIPE_PAYMENT_ERROR, payload: true });
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
    // this redirection is handled for the edge case scenario of cancelled API due to timeout
    window.location.href = `/${AccountRoutes.ACCOUNT}/${AccountRoutes.MY_ORDERS}`;
  }
}

/** This method is making GET API call for getting payment status i.e pay-plus
 *
 * @param {object} action - payload
 *
 */
export function* getPaymentStatus(action) {
  try {
    const response = yield call(paymentActions.getVerifyPaymentCall, action.payload);
    if (response?.data?.status?.code === "200") {
      if (response?.data?.status?.isSuccess) {
        window.location.href = `${PaymentsEndpoint.orderConfirmation}?orderId=${action.payload}`;
      } else {
        window.location.href = `${PaymentsEndpoint.pnc}?orderId=${action.payload}`;
      }
    } else {
      yield put({
        type: PAYMENT_CONSTANTS.SET_UNDERWAY_PAYMENT,
      });
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}

/**
 * This method is making Api call to upi collect payment.
 *
 * @param {object} action - action object for action type and payload
 */
export function* upiCollectPaymentSaga(action) {
  try {
    const response = yield call(paymentActions.upiIdValidate, action.payload?.upiId);

    if (response?.data?.status?.code === "SUCCESS") {
      yield put({ type: PAYMENT_CONSTANTS.SAVE_UPI_ID_VALIDATION_REQUEST, payload: response?.data });
      yield call(swipePaymentPostCall, action);
    } else if (response?.data?.status?.code === "ERROR") {
      yield put({ type: PAYMENT_CONSTANTS.SAVE_UPI_ID_VALIDATION_REQUEST, payload: response?.data });
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}

/**
 * This method is making Api call for user eligibility in case SIMPL.
 *
 * @param {object} action - action object for action type and payload
 */
export function* simplInitiatePaymentCall(action) {
  try {
    const response = yield call(paymentActions.swipePaymentCall, action.payload);
    if (response?.data?.status?.code === "200") {
      if (response?.data?.status?.responseStatus === "COMPLETED") {
        window.location.href = `${PaymentsEndpoint.orderConfirmation}?orderId=${response?.data?.order?.orderId}`;
      } else {
        window.location.href = `${PaymentsEndpoint.pnc}?orderId=${response?.data?.order?.orderId}`;
      }
    } else {
      yield put({
        type: PAYMENT_CONSTANTS.SET_UNDERWAY_PAYMENT,
      });
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}

/**
 * This method is making Api call for user eligibility in case SIMPL.
 *
 * @param {object} action - action object for action type and payload
 */
export function* checkUserEligibility(action) {
  try {
    const data = yield checkEligibilityApi(action.payload);
    if (data?.responseCode === 200) {
      yield put({ type: PAYMENT_CONSTANTS.CHECK_ELIGIBILITY_SUCCESS, payload: data });
    }
  } catch (error) {
    yield put({ type: PAYMENT_CONSTANTS.CHECK_ELIGIBILITY_ERROR, error });
  }
}

/**
 * This method is for send OTP API for simpl.
 *
 * @param {object} action - contains the response payload.
 */
function* sendPaymentOtp(action) {
  try {
    const data = yield sendPaymentOtpApi(action.payload.otpData);
    if (action.payload.checkOtpLogin) {
      yield put({
        type: PAYMENT_CONSTANTS.SEND_PAYMENT_OTP_SUCCESS,
        payload: { data, checkOtpLogin: action.payload.checkOtpLogin },
      });
    } else {
      yield put({ type: PAYMENT_CONSTANTS.SEND_PAYMENT_OTP_SUCCESS, payload: { data } });
    }
  } catch (error) {
    yield put({ type: PAYMENT_CONSTANTS.SEND_PAYMENT_OTP_ERROR, error });
  }
}

/**
 * This method is for verify OTP for simpl.
 *
 * @param {object} action - contains the response payload.
 */
function* verifyPaymentOtp(action) {
  try {
    const data = yield validatePaymentOtpApi(action.payload);

    if (data?.data?.response === "success") {
      yield put({ type: PAYMENT_CONSTANTS.VALIDATE_PAYMENT_OTP_SUCCESS, payload: data });
      yield put({ type: PAYMENT_CONSTANTS.SIMPL_MOBILE_TOKEN, payload: data?.headers?.mobiletoken });
    } else {
      yield put({ type: PAYMENT_CONSTANTS.VALIDATE_PAYMENT_OTP_ERROR, error: data?.data?.msg });
    }
  } catch (error) {
    yield put({ type: PAYMENT_CONSTANTS.VALIDATE_PAYMENT_OTP_ERROR, error });
  }
}

/** This method is used to yield success response of FETCH_PAYMENT_METHODS action */
function* watchFetchPaymentMethodsRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_PAYMENT_METHODS, fetchPaymentMethodsSaga);
}

/** This method is used to yield success response of FETCH_PAYMENT_OPTIONS_METHODS action */
function* watchPaymentOptionsRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_PAYMENT_OPTIONS_METHODS, paymentOptionsSaga);
}

/** This method is used to yield success response of CHECK_CARD_TYPE action */
function* watchCheckCardTypeRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.CHECK_CARD_TYPE, checkCardTypeSaga);
}

/** This method is used to yield success response of FETCH_EMI_OPTIONS action */
function* watchEmiPaymentValuesRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_EMI_OPTIONS, getEmiPaymentValuesSaga);
}

/** This method is used to yield success response of FETCH_PAYPAL_CURRENCIES action */
function* watchGetPaypalCurrenciesRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_PAYPAL_CURRENCIES, getPaypalCurrenciesSaga);
}

/** This method is used to yield success response of FETCH_NET_BANKING_LIST action */
function* watchGetUpiHandlesListRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_UPI_HANDLES_LIST, getUpiHandlesListSaga);
}

/** This method is used to yield response for upi payment polling status */
function* watchGetQrPaymentPollingStatusRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_QR_PAYMENT_STATUS, getQrPaymentPollingStatusSaga);
}

/** This method is used to yield success response of FETCH_WALLET_DETAILS action */
function* watchGetWalletDetailsRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_WALLET_DETAILS, getWalletDetailsSaga);
}
/** This method is used to yield success response of FETCH_PAYMENT_OPTION_DETAILS action */
function* watchGetPaymentOptionsDetailsRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_PAYMENT_OPTION_DETAILS, getPaymentOptionsDetailsSaga);
}
/** This method is used to yield success response of FETCH_MIN_EMI_AMOUNT action */
function* watchGetMinEMIAmountRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_MIN_EMI_AMOUNT, getMinEMIAmountSaga);
}

/** This method is used to yield success response of FETCH_THANK_YOU_PAGE_INFO action */
function* watchGetThankYouPageInfoRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_THANK_YOU_PAGE_INFO, getThankYouPageInfoSaga);
}

/** This method is used to yield success response of FETCH_REMINDERS action */
function* watchGetAllRemindersRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_REMINDERS, getAllRemindersSaga);
}

/** This method is used to yield success response of SAVE_REMINDERS action */
function* watchSaveRemindersRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.SAVE_REMINDERS, saveRemindersSaga);
}

/** This method is used to yield success response of DELETE_REMINDERS action */
function* watchDeleteRemindersRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.DELETE_REMINDERS, deleteRemindersSaga);
}

/** This method is used to yield success response of DELETE_SAVED_CARDS action */
function* watchDeleteSavedCardRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.DELETE_SAVED_CARDS, deleteSavedCardSaga);
}

/** This method is used to yield success response of FETCH_THANK_YOU_PAGE_DETAIL action */
function* watchThankYouPageContentDetailRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_THANK_YOU_PAGE_DETAIL, thankYouPageContentDetail);
}

/** This method is used to yield success response of FETCH_PAYMENT_METHOD_FLAGS_SUCCESS action */
function* watchPaymentMethodFlagsRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_PAYMENT_METHOD_FLAGS, paymentMethodFlags);
}

/** This method is used to yield success response of FETCH_PAYMENT_METHODS action */
function* watchFetchNetBankingOptions() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_NET_BANKING_OPTIONS, fetchNetBankingOptionsSaga);
}

/** This method is used to yield success response of FETCH_PAYMENT_METHODS action */
function* watchSaveUserFeedback() {
  yield takeLatest(PAYMENT_CONSTANTS.SAVE_USER_FEEDBACK, saveUserFeedbackSaga);
}

/** This method is used to yield success response of GET_SYSTEM_PROPERTY_REQUESTED action */
function* watchSystemPropertyRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.GET_SYSTEM_PROPERTY_REQUESTED, getSystemProperty);
}

/** This method is used to yield success response of GET_SAVED_CARD_SYSTEM_PROPERTY_REQUESTED action */
function* watchSavedCardSystemPropertyRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.GET_SAVED_CARD_SYSTEM_PROPERTY_REQUESTED, getSavedCardSystemProperty);
}

/** This method is used to yield success response of FETCH_OCCASION_LIST_REQUESTED action */
function* watchGetOccasionContentDetail() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_OCCASION_LIST_REQUESTED, getOccasionContentDetail);
}
/** This method is used to yield success response of SWIPE_PAYMENT action */
function* watchPostInitPaymentMethodsRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.SWIPE_PAYMENT, swipePaymentPostCall);
}
/** This method is used to yield success response of SWIPE_PAYMENT action */
function* watchPostSwipeShowQrPaymentMethodsRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.SWIPE_SHOW_QR_PAYMENT, swipeShowQrPaymentPostCall);
}

/** This method is used to yield success response of SWIPE_UPI_INTENT_PAYMENT action */
function* wattchPostSwipeUpiIntentPaymentMethodRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.SWIPE_UPI_INTENT_PAYMENT, swipeUpiIntentPaymentPostCall);
}
/** This method is used to yield success response of GET_PAYMENT_STATUS action */
function* watchGetPaymentStatusMethodsRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.GET_PAYMENT_STATUS, getPaymentStatus);
}
/** This method is used to yield success response of GET_SWIPE_PAYMENT_SYSTEM_PROPERTY action */
function* watchGetSystemPropertyForSwipePayment() {
  yield takeLatest(PAYMENT_CONSTANTS.GET_SWIPE_PAYMENT_SYSTEM_PROPERTY, getSystemPropertyForSwipePayment);
}

/** This method is used to yield success response of FETCH_RELATION_LIST_REQUESTED action */
function* watchGetRelationContentDetail() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_RELATION_LIST_REQUESTED, getRelationContentDetail);
}

/** This method is used to yield success response of VALIDATE_UPI_ID action */
function* watchUpiIdValidateRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.UPI_COLLECT_PAYMENT_REQUESTED, upiCollectPaymentSaga);
}
/** This method is used to yield success response of FETCH_USER_SAVED_CARDS_PAYPLUS action */
function* watchFetchAllSavedCardsRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_USER_SAVED_CARDS_PAYPLUS, fetchUserSavedCardsSaga);
}
/** This method is used to yield success response of CHECK_PAYMENT_USER_ELIGIBILITY action */
function* watchFetchCheckUserEligibilityRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_CHECK_PAYMENT_USER_ELIGIBILITY, checkUserEligibility);
}
/**
 * This method is used to yield success response of CHECK_LOGIN_CART_INFO action
 */
function* watchSendPaymentOtpRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.SEND_PAYMENT_OTP, sendPaymentOtp);
}
/**
 * This method is used to yield success response of CHECK_LOGIN_CART_INFO action
 *
 */
function* validatePaymentOtpRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.VALIDATE_PAYMENT_OTP, verifyPaymentOtp);
}
/**
 * This method is used to yield success response of CHECK_LOGIN_CART_INFO action
 *
 */
function* simplInitiatePaymentRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.SIMPL_INITIATE_PAYMENT_CALL_REQUEST, simplInitiatePaymentCall);
}

/** This method is used to listen to respective dispatched action */
export default function* paymentSaga() {
  yield fork(watchFetchPaymentMethodsRequest);
  yield fork(watchCheckCardTypeRequest);
  yield fork(watchEmiPaymentValuesRequest);
  yield fork(watchGetPaypalCurrenciesRequest);
  yield fork(watchGetWalletDetailsRequest);
  yield fork(watchPaymentOptionsRequest);
  yield fork(watchGetPaymentOptionsDetailsRequest);
  yield fork(watchGetMinEMIAmountRequest);
  yield fork(watchGetThankYouPageInfoRequest);
  yield fork(watchGetAllRemindersRequest);
  yield fork(watchSaveRemindersRequest);
  yield fork(watchDeleteRemindersRequest);
  yield fork(watchDeleteSavedCardRequest);
  yield fork(watchThankYouPageContentDetailRequest);
  yield fork(watchFetchNetBankingOptions);
  yield fork(watchSaveUserFeedback);
  yield fork(watchSystemPropertyRequest);
  yield fork(watchSavedCardSystemPropertyRequest);
  yield fork(watchGetOccasionContentDetail);
  yield fork(watchGetRelationContentDetail);
  yield fork(watchUpiIdValidateRequest);
  yield fork(watchGetUpiHandlesListRequest);
  yield fork(watchGetQrPaymentPollingStatusRequest);
  yield fork(watchPaymentMethodFlagsRequest);
  yield fork(watchPostInitPaymentMethodsRequest);
  yield fork(watchGetPaymentStatusMethodsRequest);
  yield fork(watchGetSystemPropertyForSwipePayment);
  yield fork(watchFetchAllSavedCardsRequest);
  yield fork(watchPostSwipeShowQrPaymentMethodsRequest);
  yield fork(wattchPostSwipeUpiIntentPaymentMethodRequest);
  yield fork(watchFetchCheckUserEligibilityRequest);
  yield fork(watchSendPaymentOtpRequest);
  yield fork(validatePaymentOtpRequest);
  yield fork(simplInitiatePaymentRequest);
}
