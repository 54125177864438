import { call, put, takeEvery } from "redux-saga/effects";
import HomeActionsConstants from "../../src/action-constants/home-actions-constants";
import {
  errorHomePageAccordionData,
  errorHomePageBodyData,
  errorHomePageFooterData,
  errorMessageHomePage,
  setHomePageAccordionData,
  setHomePageBodyData,
  setHomePageFooterData,
} from "../../src/actions/home-actions";
import { fetchContentIdDetails } from "../../src/services/api/common/common-api";
import { fetchContentFromSVN } from "../../src/services/api/micro-site/svn-api";
import { fetchFooterAccordion } from "../../src/services/api/menu/menu-api";
import { SOMETHING_WENT_WRONG } from "../../src/constants/common/errorConstants";

/**
 * Fetches home page content data
 *
 * @param {object} action contains type and payload
 */
function* getAndSetPageContentData(action) {
  try {
    const { occasionListContentId, bannerContentId } = action.payload;

    let occasionsListResponse;
    let bannerResponse;

    if (occasionListContentId) {
      occasionsListResponse = yield call(fetchContentIdDetails, occasionListContentId);
    }

    if (bannerContentId) {
      bannerResponse = yield call(fetchContentIdDetails, bannerContentId);
    }

    const contentResponse = yield call(fetchContentFromSVN, action.payload);

    if (contentResponse.status === 404) {
      const ctxRes = action.payload.ctx.res;
      ctxRes.statusCode = 404;
    } else if (contentResponse.status === 503) {
      const ctxRes = action.payload.ctx.res;
      ctxRes.statusCode = 503;
    }

    let pageBody = "";
    if (occasionsListResponse) {
      pageBody += occasionsListResponse;
    }

    if (bannerResponse) {
      pageBody += bannerResponse;
    }

    if (contentResponse.data) {
      pageBody += contentResponse.data;
      yield put(setHomePageBodyData(pageBody));
    } else {
      yield put(errorMessageHomePage(SOMETHING_WENT_WRONG));
    }
  } catch (error) {
    yield put(errorMessageHomePage(SOMETHING_WENT_WRONG));
    yield put(errorHomePageBodyData(error.message));
  }
}

/**
 * Fetches home page accordion data
 *
 * @param {object} action contains type and payload
 */
function* getAndSetAccordionData(action) {
  try {
    const response = yield call(fetchFooterAccordion, action.payload);
    yield put(setHomePageAccordionData(response));
  } catch (error) {
    yield put(errorHomePageAccordionData(error.message));
  }
}

/**
 * Fetches home page footer data
 *
 * @param {object} action contains type and payload
 */
function* getAndSetFooterData(action) {
  try {
    const response = yield call(fetchContentIdDetails, action.payload);
    yield put(setHomePageFooterData(response));
  } catch (error) {
    yield put(errorHomePageFooterData(error.message));
  }
}

/**
 * Watcher saga for home page
 */
function* watchHomePageDataSaga() {
  yield takeEvery(HomeActionsConstants.SERVER_REQUEST_MOBILE_HOME_CONTENT_DATA, getAndSetPageContentData);
  yield takeEvery(HomeActionsConstants.SERVER_REQUEST_MOBILE_HOME_ACCORDION_DATA, getAndSetAccordionData);
  yield takeEvery(HomeActionsConstants.SERVER_REQUEST_DESKTOP_HOME_FOOTER_DATA, getAndSetFooterData);
}

export default watchHomePageDataSaga;
