import { getClevertapInstance } from "../../components/hooks/analytics/clevertap/useClevertapSetup";
import { cleverTapEvents } from "./clevertapConstants";
import {
  fetchGeoLocation,
  getCleverTapID,
  isMobileDevice,
  removeEmptyNullAndUndefinedFromObject,
} from "../utils/common";
import triggerCustomWebSession from "./customWebSession";

/**
 * This is a utility method to call cleverTap events
 *
 * @param {string} eventName - contains event name
 * @param {object} eventData - contains object to be shared in event
 * @param {string} eventType - contains event type
 */
async function callClevertapEvent(eventName, eventData, eventType = cleverTapEvents.common) {
  const city = await fetchGeoLocation();
  const isMobile = isMobileDevice();
  const cleverTapID = getCleverTapID();

  const cleverTapEventData = {
    ...eventData,
    browsing_city: city,
    platform: isMobile ? "mobile" : "desktop",
    ctid: cleverTapID,
  };
  const cleverTapInstance = getClevertapInstance();
  const filteredEventData = removeEmptyNullAndUndefinedFromObject(cleverTapEventData);
  const triggerWebSession = triggerCustomWebSession(cleverTapInstance);
  triggerWebSession();

  if (cleverTapInstance) {
    try {
      switch (eventType) {
        case cleverTapEvents.common:
          cleverTapInstance.event.push(eventName, filteredEventData);
          break;
        case cleverTapEvents.userLogin:
          cleverTapInstance.onUserLogin.push(filteredEventData);
          break;
        default:
          cleverTapInstance.event.push(eventName, filteredEventData);
          break;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("CleverTap event push failed:", error);
    }
  } else {
    // eslint-disable-next-line no-console
    console.warn("CleverTap instance is not initialized.");
  }
}

export default callClevertapEvent;
