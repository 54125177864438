import React from "react";
import { useSelector } from "react-redux";
import HTMLReactParser from "html-react-parser";
import PropTypes from "prop-types";
import styles from "./styles/d-footer.module.scss";
import Copyright from "./footer/copyright";
import InfoFooter from "./footer/info-footer";
import MainFooter from "./footer/main-footer";
import { RoutePageConstants } from "../../../src/utils/common";
import { CheckoutRoutes } from "../../../src/action-constants/checkout-constants";

/** returns Desktop Footer contains three components info-footer , main-footer , copyright-footer
 *
 * @param {object} root0  props passed to the component .
 * @param {string} root0.pageName component
 * @param {string} root0.pageSlug router slug value
 * @returns {React.ReactElement} jsx.
 */
function DFooter({ pageName, pageSlug }) {
  const { desktop } = useSelector((state) => state.appConfigs.configData);
  const { header, footerBodyLinks, socialIcons, paymentGatewayItems, startYear } = desktop;

  const userDetails = useSelector((state) => state.userDetails);
  const userLoggedIn = userDetails?.checkLoginInfo?.loggedin && userDetails.loginChecked;

  const { fnpBrandsData } = useSelector((state) => state.homePageData);
  const callChatTime = useSelector((state) => state.commonPageData?.configureCallAndChatTime || {});

  /** returns svg for iconIds
   *
   * @param {string} iconId svg icon ids.
   * @returns {SVGAnimateElement} .
   */
  const icon = (iconId) => {
    return (
      <svg>
        <use xlinkHref={`/sprite.svg#${iconId}`} />
      </svg>
    );
  };

  /**
   * rendering footer component
   *
   * @returns {React.Element} renders footer
   */
  const renderFooter = () => {
    return (
      <footer
        id="footer"
        className={`${styles.footer} ${pageName === RoutePageConstants.ACCOUNT_PAGE ? styles.accountFooter : ""}`}
      >
        <InfoFooter infoFooterItems={header} icon={icon} isShowCallUs={callChatTime?.callTime} />
        <MainFooter ctaLinks={footerBodyLinks} />
        {pageName === RoutePageConstants.HOME && fnpBrandsData && <section>{HTMLReactParser(fnpBrandsData)}</section>}
        <Copyright
          socialIcons={socialIcons}
          paymentGatewayItems={paymentGatewayItems}
          icon={icon}
          startYear={startYear}
        />
      </footer>
    );
  };

  if (
    (userLoggedIn && pageName !== RoutePageConstants.CHECKOUT_PAGE) ||
    (pageName !== RoutePageConstants.ACCOUNT_PAGE && pageName !== RoutePageConstants.CHECKOUT_PAGE) ||
    (userLoggedIn &&
      pageName === RoutePageConstants.CHECKOUT_PAGE &&
      (pageSlug === CheckoutRoutes.PAYMENT_FAILED || pageSlug === CheckoutRoutes.ORDER_CONFIRMATION))
  ) {
    return renderFooter();
  }

  return "";
}

DFooter.propTypes = {
  pageName: PropTypes.string.isRequired,
  pageSlug: PropTypes.string,
};

DFooter.defaultProps = {
  pageSlug: "",
};

export default DFooter;
