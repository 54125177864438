const MenuPageConstants = {
  SERVER_REQUEST_MOBILE_LEFT_MENU_DATA: "SERVER_REQUEST_MOBILE_LEFT_MENU_DATA",
  SERVER_REQUEST_MOBILE_LEFT_MENU_DATA_FAIL: "SERVER_REQUEST_MOBILE_LEFT_MENU_DATA_FAIL",
  SERVER_SET_MOBILE_LEFT_MENU_DATA: "SERVER_SET_MOBILE_LEFT_MENU_DATA",

  SERVER_REQUEST_DESKTOP_MEGA_MENU_DATA: "SERVER_REQUEST_DESKTOP_MEGA_MENU_DATA",
  SERVER_REQUEST_DESKTOP_MEGA_MENU_DATA_FAIL: "SERVER_REQUEST_DESKTOP_MEGA_MENU_DATA_FAIL",
  SERVER_SET_DESKTOP_MEGA_MENU_DATA: "SERVER_SET_DESKTOP_MEGA_MENU_DATA",
};
export default MenuPageConstants;
