import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import styles from "../style/price-with-name-desktop.module.scss";

const LightTooltip = withStyles((theme) => ({
  tooltip: ({ isNotShowIBtn }) => ({
    marginTop: 10,
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "0 2px 16px 0 rgb(0 0 0 / 13%)",
    zIndex: -1,
    borderRadius: 5,
    width: isNotShowIBtn ? "290px" : "250px",
    minheight: isNotShowIBtn ? "140px" : "",
    height: isNotShowIBtn ? "max-content" : "",
    padding: "15px 15px 10px 15px",
  }),
  popper: {
    marginTop: "7px",
    marginLeft: "-6px",
  },
  arrow: {
    fontSize: 20,
    "&::before": {
      color: theme.palette.common.white,
      marginTop: 0,
      boxShadow: "0 2px 16px 0 rgb(0 0 0 / 13%)",
    },
  },
}))(Tooltip);

/**
 * @param {object} root0 props passed to component
 * @param {string} root0.title title as prop
 * @param {boolean} root0.isForDateRange .
 * @param {boolean} root0.isShowTooltip boolean to show tooltip.
 * @param {boolean} root0.isNotShowIBtn boolean to hide i button.
 * @returns {React.Component} JSX for Tooltip component
 */
function TooltipPdp({ title, isForDateRange, isShowTooltip, isNotShowIBtn }) {
  const [open, setOpen] = React.useState(false);
  /**
   * To close tooltip
   */
  const handleTooltipClose = () => {
    setOpen(false);
  };

  /**
   * To open tooltip
   */
  const handleTooltipOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (isShowTooltip) {
      handleTooltipOpen();
    } else {
      handleTooltipClose();
    }
  }, [isShowTooltip]);

  /**
   * This method is to render UI of generic tooltip for D-site.
   *
   * @returns {React.ReactFragment} jsx.
   */
  const renderTooltipUI = () => {
    return (
      <LightTooltip
        placement="bottom-start"
        open={open}
        disableHoverListener
        interactive={isNotShowIBtn}
        arrow
        title={title}
        onMouseEnter={isNotShowIBtn && handleTooltipOpen}
        onMouseLeave={isNotShowIBtn && handleTooltipClose}
        isNotShowIBtn={isNotShowIBtn}
      >
        <span className={styles["tooltip-anchor"]}>
          <InfoOutlinedIcon
            onMouseEnter={!isNotShowIBtn && handleTooltipOpen}
            onMouseLeave={!isNotShowIBtn && handleTooltipClose}
            className={`${styles["info-details"]} ${styles.icon} ${isForDateRange ? styles["align-bottom"] : ""} ${
              isNotShowIBtn ? styles["hide-info-btn"] : ""
            }`}
          />
        </span>
      </LightTooltip>
    );
  };

  return <>{renderTooltipUI()}</>;
}
TooltipPdp.propTypes = {
  title: PropTypes.node.isRequired,
  isForDateRange: PropTypes.bool,
  isShowTooltip: PropTypes.bool,
  isNotShowIBtn: PropTypes.bool,
};
TooltipPdp.defaultProps = {
  isForDateRange: false,
  isShowTooltip: false,
  isNotShowIBtn: false,
};
export default TooltipPdp;
