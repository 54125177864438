import { setCookie, parseCookies } from "nookies";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { LocationLockStaticConstants } from "../../components/common/constants";
import LocationLockConstants from "../../src/action-constants/location-lock-constants";
import {
  getCountryList,
  getPinCodeList,
  getPinCode,
  getDeliveryAreasAsKey,
  getPinCodeByLocation,
} from "../../src/services/api/location-lock/location-lock-api";
import { setFinalSelectedPinCode } from "../../src/actions/location-lock-actions";
import { requestPlpListingOnClient } from "../../src/actions/plp-actions";
/**
 * dispatches the action with payload data for reducer
 */
export function* getCountryListInfo() {
  try {
    const list = yield call(getCountryList);
    yield put({
      type: LocationLockConstants.GET_COUNTRY_LIST_SUCCESS,
      payload: list,
    });
  } catch (error) {
    yield put({ type: LocationLockConstants.SET_ERROR, payload: error });
  }
}
/**
 * dispatches the action with payload data for reducer
 *
 * @param {object} action key value pair of pin code
 */
export function* getPinCodeListInfo(action) {
  try {
    const list = yield call(getPinCodeList, action.payload);
    yield put({
      type: LocationLockConstants.GET_PINCODE_LIST_SUCCESS,
      payload: list,
    });
  } catch (error) {
    yield put({ type: LocationLockConstants.SET_ERROR, payload: error });
  }
}
/**
 * This function handles the mapping of pin codes which google requires it and dispatches it
 */
export function* handlePinCodeMapping() {
  try {
    const pin = yield call(getPinCode);
    yield put({
      type: LocationLockConstants.SET_PIN_CODE_MAPPING,
      payload: pin,
    });
  } catch (error) {
    yield put({ type: LocationLockConstants.SET_ERROR, payload: error });
  }
}

/**
 * dispatches the action with payload data for reducer
 *
 * @param {object} action object of pin code and place
 */
export function* setDeliveryKey(action) {
  const { pinCode, place } = action.payload;
  try {
    const data = yield call(getDeliveryAreasAsKey, pinCode);
    if (data.isSuccess) {
      const ctx = {};
      const options = {
        maxAge: 60 * 24 * 60 * 30,
        path: "/",
        encode: decodeURIComponent,
      };
      const optionParams = yield select((store) => store.productsLists.searchOptions);
      const checkLoginInfo = yield select((store) => store?.userDetails?.checkLoginInfo);
      yield setCookie(ctx, LocationLockStaticConstants.PF, data.cacheKey, options);
      yield setCookie(ctx, LocationLockStaticConstants.LAST_SELECTED_PINCODE, pinCode, options);
      yield setCookie(ctx, LocationLockStaticConstants.LAST_SELECTED_ADDRESS, place, options);
      yield put(setFinalSelectedPinCode(pinCode));
      if (checkLoginInfo?.loggedin) {
        const { faul } = parseCookies();
        yield put({
          type: LocationLockStaticConstants.SET_LOGGED_IN_PINCODE,
          payload: {
            pincode: pinCode,
            email: faul,
            customerAddress: place,
          },
        });
      }
      if (window.checkPincodeSelectedOnLoad) {
        window.checkPincodeSelectedOnLoad();
      }
      if (Object.keys(optionParams).length !== 0) {
        optionParams.params.viewIndex = 0;
        yield put(requestPlpListingOnClient({ options: optionParams, appendProducts: false }));
      }

      // Todo enable on Auto load feature //
      // yield setCookie(ctx, "llp", "true", options);
    }
  } catch (error) {
    yield put({ type: LocationLockConstants.SET_ERROR, payload: error });
  }
}
/**
 * This function maps the pin code entered in the google suggestion with the available pin code
 *
 * @param {string} pin selected pin code
 * @param {string} locality selected locality
 * @returns {object} pin code object
 */
function* getMappedPinCode(pin, locality) {
  try {
    let mappedPinCode = yield select((store) => store.locationLockLists.mappedPinCode);
    if (mappedPinCode && Object.keys(mappedPinCode).length === 0) {
      mappedPinCode = yield call(getPinCode);
    }
    const mappedPin = mappedPinCode && mappedPinCode[pin];

    const pinCodeObject = {
      isPinCodeMapped: false,
      userTypedPinCode: pin,
      pinCodeSelected: pin,
    };

    const pinCodeExtractedFromLocality =
      locality && locality?.match(/\d{6,6}/).length > 0 && locality?.match(/\d{6,6}/)[0];
    if (pinCodeExtractedFromLocality && Number(pinCodeExtractedFromLocality) !== Number(pin)) {
      pinCodeObject.isPinCodeMapped = true;
      pinCodeObject.userTypedPinCode = pinCodeExtractedFromLocality;
    }

    if (!mappedPin) {
      return pinCodeObject;
    }

    return {
      isPinCodeMapped: true,
      userTypedPinCode: pin,
      pinCodeSelected: mappedPin,
    };
  } catch (error) {
    yield put({ type: LocationLockConstants.SET_ERROR, payload: error });
    throw error;
  }
}
/**
 * This function returns a pin code as per the lat and long
 *
 * @param {object} coordinates object of lat and long
 */
function* handleGetPinCodeByLocation(coordinates) {
  try {
    const data = yield call(getPinCodeByLocation, coordinates);
    const { matchedPincode, pincode } = data;

    const resolvedPincodeObject = yield call(getMappedPinCode, matchedPincode || pincode, "");
    yield put({ type: LocationLockConstants.SET_SELECTED_MAPPED_PIN_CODE, payload: resolvedPincodeObject });
  } catch (error) {
    yield put({ type: LocationLockConstants.SET_ERROR, payload: error });
  }
}

/**
 * dispatches the action with payload data for reducer
 *
 * @param {object} action object of place details
 */
export function* getGooglePlaceDetails(action) {
  const { address_components: addressComponents, geometry, formatted_address: locality } = action.payload;
  try {
    const postalCode = yield addressComponents.find((item) => item.types.includes("postal_code"));
    const pincode = postalCode && postalCode.long_name;
    if (pincode) {
      const resolvedPincodeObject = yield call(getMappedPinCode, pincode, locality);
      yield put({
        type: LocationLockConstants.SET_SELECTED_MAPPED_PIN_CODE,
        payload: resolvedPincodeObject,
      });
    } else {
      const {
        location: { lat, lng },
      } = geometry;
      const latitude = lat();
      const longitude = lng();
      yield call(handleGetPinCodeByLocation, { lat: latitude, lng: longitude });
    }
    yield put({ type: LocationLockConstants.GET_PLACE_DETAILS_SUCCESS, payload: action.payload });
  } catch (error) {
    yield put({ type: LocationLockConstants.SET_ERROR, payload: error });
  }
}
/**
 * @function handleShowLocationPopup dispatches data for the location lock popup
 *
 * @param {object} action payload data
 */
function* handleShowLocationPopup(action) {
  try {
    const catalogId = yield select((store) => store.appConfigs.catalogId);
    if (catalogId === "india") {
      yield put({ type: LocationLockConstants.SHOW_LOCATION_LOCK_POPUP_SUCCESS, payload: action.payload });
    }
  } catch (error) {
    yield put({ type: LocationLockConstants.SET_ERROR, payload: error });
  }
}

/**
 * dispatches data for mapped pin code tooltip
 *
 * @param {object} action action dispatched
 */
function* handleShowMappedPinTooltip(action) {
  try {
    const resolvedPincodeObject = yield call(getMappedPinCode, action.payload.pincode, action.payload.locality);
    yield put({ type: LocationLockConstants.SET_SELECTED_MAPPED_PIN_CODE, payload: resolvedPincodeObject });
  } catch (error) {
    yield put({ type: LocationLockConstants.SET_ERROR, payload: error });
  }
}

/**
 * saga for fetching Location lock
 *
 */
function* watchLocationLock() {
  yield takeLatest(LocationLockConstants.GET_COUNTRY_LIST_REQUEST, getCountryListInfo);
  yield takeLatest(LocationLockConstants.GET_PINCODE_LIST_REQUEST, getPinCodeListInfo);
  yield takeLatest(LocationLockConstants.SET_DELIVERY_AS_KEY, setDeliveryKey);
  yield takeLatest(LocationLockConstants.GET_PLACE_DETAILS_REQUEST, getGooglePlaceDetails);
  yield takeLatest(LocationLockConstants.SET_PIN_CODE_MAPPING_REQUEST, handlePinCodeMapping);
  yield takeLatest(LocationLockConstants.SHOW_LOCATION_LOCK_POPUP_REQUEST, handleShowLocationPopup);
  yield takeLatest(LocationLockConstants.SHOW_MAPPED_PIN_TOOLTIP, handleShowMappedPinTooltip);
}
export default watchLocationLock;
