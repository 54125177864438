import debounce from "lodash/debounce";
import React, { useState } from "react";
import Autocomplete from "react-autocomplete";
import PropTypes from "prop-types";
import style from "./drop-down.module.scss";
/**
 * common Component of drop down used for the currency,more info,accont drop down.
 *
 * @param {object} props props will differ for who use this component.
 * @returns {React.ReactElement} retuns the drop down layout structure.
 */
function DropdownHOC(props) {
  const {
    inputProps,
    renderItem,
    renderMenu,
    items,
    children,
    blurBg,
    showBackDrop,
    cartState,
    handleCartWithPopup,
    isCart,
    isCartOpen,
    handleCartState,
    handleBackdrop,
    getItemValue,
    dropDownHoverCB,
    arrow,
  } = props;
  inputProps.className = style.dropdown;
  const [collapsed, setCollapsed] = useState(true);
  const [isOpen, setOpen] = useState(true);
  /**
   * This method handles the dropdown select
   *
   * @param {string} value props
   * @returns {void} retuns nothing.
   */
  const handleSelect = (value) => {
    if (value === null) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const onMouseEnter = debounce(() => {
    dropDownHoverCB(true);
    setCollapsed(false);
    setOpen(true);
    if (isCart) {
      handleCartState(true);
      handleCartWithPopup(false);
    }
    if (blurBg && !showBackDrop) handleBackdrop(true);
  }, 300);
  /**
   * This method handles the mouse leave
   *
   * @returns {void} retuns nothing.
   */
  const onMouseLeave = () => {
    onMouseEnter.cancel();
    if (isCart) {
      handleCartState(true);
      if (cartState) setCollapsed(false);
      else setCollapsed(true);
    } else setCollapsed(true);
    if (showBackDrop) handleBackdrop(false);
  };

  return (
    <div
      className={`${style["dropdown-container"]} ${!collapsed && isOpen ? style[arrow] : ""}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
      <Autocomplete
        open={isCart ? !collapsed && isOpen && isCartOpen : !collapsed && isOpen}
        items={items}
        inputProps={inputProps}
        onSelect={(value) => handleSelect(value)}
        renderItem={renderItem}
        renderMenu={renderMenu}
        getItemValue={getItemValue}
      />
    </div>
  );
}

DropdownHOC.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  inputProps: PropTypes.objectOf(PropTypes.any),
  renderMenu: PropTypes.func,
  renderItem: PropTypes.func,
  blurBg: PropTypes.bool,
  children: PropTypes.objectOf(PropTypes.any),
  showBackDrop: PropTypes.bool,
  cartState: PropTypes.bool,
  handleCartWithPopup: PropTypes.func,
  isCart: PropTypes.bool,
  isCartOpen: PropTypes.bool,
  handleCartState: PropTypes.func,
  handleBackdrop: PropTypes.func,
  getItemValue: PropTypes.func,
  dropDownHoverCB: PropTypes.func,
  arrow: PropTypes.string,
};

DropdownHOC.defaultProps = {
  items: [],
  inputProps: { className: "dropdown" },
  renderMenu: null,
  renderItem: null,
  blurBg: false,
  children: null,
  handleCartWithPopup: null,
  showBackDrop: false,
  cartState: false,
  isCart: false,
  isCartOpen: false,
  getItemValue: null,
  handleCartState: null,
  handleBackdrop: null,
  dropDownHoverCB: () => {},
  arrow: "",
};

export default DropdownHOC;
