const HomeActionsConstants = {
  SERVER_REQUEST_MOBILE_HOME_ORG_POSTAL_DATA: "SERVER_REQUEST_MOBILE_HOME_ORG_POSTAL_DATA",
  SERVER_SET_MOBILE_HOME_ORG_POSTAL_DATA: "SERVER_SET_MOBILE_HOME_ORG_POSTAL_DATA",
  SERVER_ERROR_MOBILE_HOME_ORG_POSTAL_DATA: "SERVER_ERROR_MOBILE_HOME_ORG_POSTAL_DATA",

  SERVER_REQUEST_MOBILE_HOME_REL_ALT_DATA: "SERVER_REQUEST_MOBILE_HOME_REL_ALT_DATA",
  SERVER_SET_MOBILE_HOME_REL_ALT_DATA: "SERVER_SET_MOBILE_HOME_REL_ALT_DATA",
  SERVER_ERROR_MOBILE_HOME_REL_ALT_DATA: "SERVER_ERROR_MOBILE_HOME_REL_ALT_DATA",

  SERVER_REQUEST_MOBILE_HOME_META_DATA: "SERVER_REQUEST_MOBILE_HOME_META_DATA",
  SERVER_SET_MOBILE_HOME_META_DATA: "SERVER_SET_MOBILE_HOME_META_DATA",
  SERVER_ERROR_MOBILE_HOME_META_DATA: "SERVER_ERROR_MOBILE_HOME_META_DATA",

  SERVER_REQUEST_MOBILE_HOME_CONTENT_DATA: "SERVER_REQUEST_MOBILE_HOME_CONTENT_DATA",
  SERVER_SET_MOBILE_HOME_CONTENT_DATA: "SERVER_SET_MOBILE_HOME_CONTENT_DATA",
  SERVER_ERROR_MOBILE_HOME_CONTENT_DATA: "SERVER_ERROR_MOBILE_HOME_CONTENT_DATA",

  SERVER_REQUEST_MOBILE_HOME_ACCORDION_DATA: "SERVER_REQUEST_MOBILE_HOME_ACCORDION_DATA",
  SERVER_SET_MOBILE_HOME_ACCORDION_DATA: "SERVER_SET_MOBILE_HOME_ACCORDION_DATA",
  SERVER_ERROR_MOBILE_HOME_ACCORDION_DATA: "SERVER_ERROR_MOBILE_HOME_ACCORDION_DATA",

  SERVER_REQUEST_DESKTOP_HOME_FOOTER_DATA: "SERVER_REQUEST_DESKTOP_HOME_FOOTER_DATA",
  SERVER_SET_DESKTOP_HOME_FOOTER_DATA: "SERVER_SET_DESKTOP_HOME_FOOTER_DATA",
  SERVER_ERROR_DESKTOP_HOME_FOOTER_DATA: "SERVER_ERROR_DESKTOP_HOME_FOOTER_DATA",

  SERVER_SET_ERROR_MESSAGE: "SERVER_SET_ERROR_MESSAGE",
};
export default HomeActionsConstants;
