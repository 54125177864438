import { HYDRATE } from "next-redux-wrapper";
import PaymentsConstants from "../../../src/action-constants/payment-constants";

const InitialState = {
  success: false,
  error: false,
  validOtp: "",
  errorMessage: "",
  isValidating: false,
  mobileToken: "",
};

/**
 * This method is the reducer for actions that are specific to user
 *
 * @param {object} state - contains initial redux State
 * @param {object} action - contains actionTypes and payload information for specific action
 * @returns {object} state - the next State which needs to be dispatched to redux
 */
function verifyPaymentOtpReducer(state = InitialState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.validOtp,
      };
    case PaymentsConstants.VALIDATE_PAYMENT_OTP:
      return {
        ...state,
        success: false,
        error: false,
        isValidating: true,
      };
    case PaymentsConstants.VALIDATE_PAYMENT_OTP_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        validOtp: action.payload.data,
        isValidating: false,
      };
    case PaymentsConstants.VALIDATE_PAYMENT_OTP_ERROR:
      return {
        ...state,
        success: false,
        error: true,
        errorMessage: action.error,
        isValidating: false,
      };
    case PaymentsConstants.VALIDATE_PAYMENT_OTP_CLEAR:
      return {
        ...state,
        success: false,
        isValidating: false,
      };
    case PaymentsConstants.SIMPL_MOBILE_TOKEN: {
      return {
        ...state,
        mobileToken: action.payload,
      };
    }
    case PaymentsConstants.CLEAR_PAYMENT_ERR_MSG:
      return {
        ...state,
        success: false,
        error: false,
        validOtp: {},
      };
    case PaymentsConstants.CLEAR_PAYMENT_OTP_ERROR:
      return {
        ...state,
        error: false,
        errorMessage: "",
      };
    default:
      return state;
  }
}
export default verifyPaymentOtpReducer;
