import { HYDRATE } from "next-redux-wrapper";
import LocationLockConstants from "../../src/action-constants/location-lock-constants";

const initialState = {
  countryList: null,
  err: "",
  pinCodeList: [],
  selectedPinCode: null,
  selectedCountry: null,
  finalSelectedPinCode: null,
  placeDetails: {},
  mappedPinCode: null,
  showLocationLockModal: false,
  showLocationToolTip: false,
};

/**
 * This method is the reducer for actions that are specific to user
 *
 * @param {object} state - contains initial redux State
 * @param {object} action - contains actionTypes and payload information for specific action
 * @returns {object} state - the next State which needs to be dispatched to redux
 */
function locationLockReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.locationLockLists,
      };
    case LocationLockConstants.GET_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        countryList: payload,
      };
    case LocationLockConstants.GET_PINCODE_LIST_SUCCESS:
      return {
        ...state,
        pinCodeList: payload,
      };
    case LocationLockConstants.RESET_SELECTED_PINCODE:
    case LocationLockConstants.SET_SELECTED_PINCODE:
      return {
        ...state,
        selectedPinCode: payload,
      };
    case LocationLockConstants.SET_SELECTED_MAPPED_PIN_CODE:
      return {
        ...state,
        selectedPinCode: payload.pinCodeSelected,
        isPinCodeMapped: payload.isPinCodeMapped,
        userTypedPinCode: payload.userTypedPinCode,
      };
    case LocationLockConstants.SET_SELECTED_COUNTRY:
      return {
        ...state,
        selectedCountry: payload,
      };
    case LocationLockConstants.FINAL_SELECTED_PINCODE:
      return {
        ...state,
        finalSelectedPinCode: payload,
      };
    case LocationLockConstants.GET_PLACE_DETAILS_SUCCESS:
      return {
        ...state,
        placeDetails: payload,
      };
    case LocationLockConstants.SET_PIN_CODE_MAPPING:
      return {
        ...state,
        mappedPinCode: payload,
      };
    case LocationLockConstants.SET_ERROR:
      return {
        ...state,
        err: payload,
      };
    case LocationLockConstants.SHOW_LOCATION_LOCK_POPUP_SUCCESS:
      return {
        ...state,
        showLocationLockModal: payload,
        isPinCodeMapped: payload ? false : state.isPinCodeMapped,
      };
    case LocationLockConstants.CLEAR_PIN_CODE_LIST:
      return {
        ...state,
        pinCodeList: [],
      };
    case LocationLockConstants.UNSET_IS_PIN_CODE_MAPPED:
      return {
        ...state,
        isPinCodeMapped: false,
      };
    case LocationLockConstants.SHOW_LOCATION_LOCK_MODAL:
      return {
        ...state,
        showLocationLockModal: payload,
      };
    case LocationLockConstants.SHOW_LOCATION_TOOLTIP:
      return {
        ...state,
        showLocationToolTip: payload,
      };
    default:
      return state;
  }
}

export default locationLockReducer;
