import axios from "../../app_configs/axios/base";
import { CONTROL, VIEW_PAGE } from "../../components/common/constants";
import { usableBalanceEndpoint } from "../action-constants/payment-constants";
import { execDataLayer } from "../data-layer/dataLayer";
import { endpointConst } from "../data-layer/dataLayerConstants";
import { fetchContentFromCMSOnClient } from "../services/api/common/common-api";

/**
 * This method is for fetching wallet value/ balance
 *
 * @param {string} orderId - The order ID to include in the query parameters (optional)
 * @returns {object} - returns json
 */
export async function getWalletBalance(orderId) {
  try {
    /**
     * @function apiFn wrapper function to execute get Wallet balance call from datalayer.
     * @returns response from datalayer
     */
    const apiFn = async () => {
      const url = orderId ? `${endpointConst.gwb}?orderId=${orderId}` : endpointConst.gwb;
      return axios.get(url);
    };
    const res = await execDataLayer(apiFn, endpointConst.gwb);
    return res;
  } catch (ex) {
    return ex;
  }
}

/**
 * This method is for fetching wallet FNP cash label
 *
 * @returns {object} - returns json
 */
export async function setWalletCashLabelValue() {
  try {
    const resp = await axios.get(`${CONTROL}/getSystemPropertyValue?resource=fnp&name=WALLET_INFO`);
    return resp;
  } catch (ex) {
    return ex;
  }
}

/**
 * This method fetches account History.
 *
 * @param {number} param page count
 * @returns {object} response account statements
 */
export async function getAccountStatement(param) {
  try {
    const res = await axios.get(`${CONTROL}/gws?page=${param}&viewSize=${VIEW_PAGE}`);
    return { data: res?.data || {}, status: res?.status };
  } catch (ex) {
    return ex;
  }
}

/**
 * fetches wallet usable balance tnc.
 *
 * @returns {object} - returns the object for
 */
export async function getUsableBalanceTnC() {
  try {
    const res = await fetchContentFromCMSOnClient(`${usableBalanceEndpoint.usableBalanceTnC}`);
    return res?.data;
  } catch (err) {
    return err;
  }
}
