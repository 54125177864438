import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import getConfig from "next/config";
import { useRouter } from "next/router";
import { textConst } from "../../../const/text-english";
import APP_CONSTANTS from "../../../src/action-constants/app-actions";
import walletStyles from "../../mobile/pdp/style/wallet-CheckBox.module.scss";
import Loader from "../../mobile/plp/loader";
import CartListing from "./cartListing";
import EmptyCart from "./emptyCart";
import StickyCartPriceDetails from "./stickyCartPriceDetails";
import {
  updateViewCartJSON,
  setFnpPageType,
  FnpPageType,
  iswalletCalculate,
  getProductDeliveryType,
  processDeliveryDate,
} from "../../../src/utils/common";
import CartListingSkeleton from "./cartListingSkeleton";
import { cleverTapEventsConstants, cleverTapCommonWords } from "../../../src/analytics/clevertapConstants";
import callClevertapEvent from "../../../src/analytics/clevertapUtility";
import useCartPreviewOmnitureSetup from "../../hooks/analytics/omniture/useCartPreviewOmnitureSetup";
import useFNPWalletBalanceData from "../../hooks/useFNPWalletBalanceData";
import useClevertapSetup from "../../hooks/analytics/clevertap/useClevertapSetup";

const { publicRuntimeConfig } = getConfig();

const WalletCheckBox = dynamic(() => import("../../layouts/common/walletCheckBox"), { ssr: false });

const useStyles = makeStyles((theme) => ({
  loaderOverlay: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    zIndex: 1005,
    left: 0,
    top: 0,
    right: 0,
    overflowY: "hidden",
    backgroundColor: "rgba(10, 10, 10, 0.85)",
    backgroundPosition: "center",
  },
  cartListingContainer: { padding: "15px 0 1px" },
  desktopCartListingContainer: { padding: "16px", maxHeight: "330px", overflowY: "auto", overflowX: "hidden" },
  desktopLoaderOverlay: {
    left: 0,
    right: 0,
    bottom: 0,
    top: "53px",
    display: "flex",
    zIndex: 1005,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(10, 10, 10, 0.85)",
    backgroundPosition: "center",
    position: "absolute",
  },
  addMessage: {
    background: theme.color?.olive,
    padding: "5px",
    color: theme.color?.white,
    fontSize: "14px",
    textAlign: "left",
    borderRadius: "4px",
    marginBottom: "10px",
    lineHeight: "normal",
  },
  addMessageText: {
    verticalAlign: "super",
  },
  hide: {
    display: "none",
  },
}));

/**
 * This function in where all the cart component  has to be displayed on the cart page
 *
 * @param {object} param0 Component props
 * @param {Function} param0.handleProceedToAddon Function that will navigate to addon screen
 * @returns {React.ReactElement} jsx for the cart page.
 */
function CartPreview({ handleProceedToAddon, ...props }) {
  const router = useRouter();
  const host = publicRuntimeConfig.D_HOST;
  const classes = useStyles(props);
  const currencies = useSelector((store) => store.currenciesList.currencies || [], shallowEqual);
  const isMobile = useSelector((store) => store.appConfigs.isMobile);
  const { isOmnitureEnabled, isCdpEnabled } = useSelector((state) =>
    isMobile ? state.appConfigs.configData.mobile : state.appConfigs.configData.desktop,
  );
  const {
    cartSummary: cartData,
    isLoadingCartDetails: showCartSkeleton,
    isLoadingPncCard: showPncCartSkeleton,
    isLoadingAddonUpdate,
    isLoadingDeleteItem,
    firstTimeLoad,
    showAddItemToCart,
  } = useSelector((state) => state.cartDetails);
  const { checkLoginInfo: userDetails, cartInfo, fusCompleted } = useSelector((state) => state.userDetails);
  const fvId = useSelector((state) => state.userDetails.cartId);
  const dispatch = useDispatch();
  const localCurrency = useSelector((store) => store.appData.selectedCurrency);
  const [checked, setChecked] = useState(true);
  const [checkedFromSession, setCheckedFromSession] = useState(true);
  const usableBalance = useSelector((state) => state.walletDetails?.walletUsableBalance?.usableBalance) || 0;

  const [currentBalanceWallet, setCurrentBalanceWallet] = useState(0);
  const { amountUsed, availableBalance } = iswalletCalculate(cartData?.total, currentBalanceWallet);
  const [availableAmount, setAvailableAmount] = useState({ amountUse: amountUsed, availableBal: availableBalance });
  const [cleverTapLoginCartState, setCleverTapLoginCartState] = useState({
    nonLoginTriggered: false,
    emptyCartTriggered: false,
  });
  const [hasEventTriggered, setHasEventTriggered] = useState(false);
  const amountInStickyBar = Number(availableAmount?.amountUse);
  const localCurrencyINR = localCurrency === textConst.checkout.inr;
  const fnpWalletSelected = useSelector((state) => state.walletDetails?.fnpWalletSelected);
  const cartTotalAmount = updateViewCartJSON(cartData).paymentInfo.total;
  const finalCartAmount = checked ? cartTotalAmount - amountInStickyBar : cartTotalAmount;
  useClevertapSetup();

  useFNPWalletBalanceData();

  const getCartDataFromLocalStorage =
    typeof window !== "undefined" && JSON.parse(window?.localStorage?.getItem("Cart"));
  const cartDataFromLocal = getCartDataFromLocalStorage?.data || {};
  const additionalPromoAdjustment = cartData.additionalPromoAdjustment?.[0] || {};
  const items = cartData.items || [];

  const pageNameAndPageType = {
    page_name: cleverTapCommonWords.CART,
    page_type: cleverTapCommonWords.CART,
  };

  const cleverTapCartCommonData = {
    cart_value: finalCartAmount ? Number(finalCartAmount) : 0,
    total_delivery_fee: Number(cartDataFromLocal.totalShipping) || 0,
    convenience_fee: additionalPromoAdjustment.additionalAmount ?? 0,
    login_status: userDetails?.loggedin,
    is_cart_empty: items.length === 0,
    donation_amount: cartDataFromLocal.donationAmount ?? 0,
    coupon_discount: Number(cartDataFromLocal.discount) ?? 0,
    fnp_cash_discount: checked ? amountInStickyBar : 0,
    total_discount: Math.abs(Number(cartDataFromLocal.discount)) + (checked ? amountInStickyBar : 0),
    ...pageNameAndPageType,
  };

  useEffect(() => {
    setCurrentBalanceWallet(usableBalance);
  }, [usableBalance]);

  useEffect(() => {
    setAvailableAmount({ amountUse: amountUsed, availableBal: availableBalance });
  }, [amountUsed, availableBalance]);

  useEffect(() => {
    if (isMobile || !firstTimeLoad) {
      dispatch({ type: APP_CONSTANTS.GET_CART_SUMMARY_INFO_REQUEST });
      setFnpPageType(FnpPageType.CART_PREVIEW);
    }
    // disabled because we require component did mount not for did update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCdpEnabled && window.cdp && fvId && cartData?.items?.length) {
      window.cdp.cartDetail(fvId);
    }
  }, [fvId, isCdpEnabled, isOmnitureEnabled, cartData]);

  useCartPreviewOmnitureSetup();

  /**
   * This function dispatches an action to update addon quantity
   *
   * @param {object} param0 item you want to update
   * @param {string} param0.productId Id of item.
   * @param {number} param0.itemIndex Index of item.
   * @param {number} param0.quantity New quantity of item
   * @param {object} param0.giftItem .
   * @param {string} param0.addOnName .
   * @param {number} param0.addOnPrice .
   * @param {boolean} param0.isAddonAdded .
   */
  const handleQuantityUpdate = ({ quantity, itemIndex, productId, giftItem, addOnName, addOnPrice, isAddonAdded }) => {
    const deliverType = getProductDeliveryType(giftItem.primaryProductCategoryId);
    const deliveryDateFormat = giftItem?.shippingDetails?.deliveryDate?.fullDeliveryDate;
    if (isAddonAdded) {
      const cleverTapData = {
        product_name: giftItem.productName,
        pid: productId,
        product_category: giftItem.primaryProductCategoryId,
        product_price: giftItem.price,
        delivery_type: deliverType,
        pincode: giftItem.pincode,
        area: giftItem.deliveryLocation,
        system_pincode: giftItem.pincode,
        date_of_delivery: processDeliveryDate(deliveryDateFormat),
        delivery_shipping: giftItem.shippingMethodName,
        delivery_shipping_fee: giftItem.shippingDetails.shippingPrice,
        delivery_slot: giftItem.shippingDetails.deliveryTimeSlot,
        addon_name: addOnName,
        addon_price: addOnPrice,
        addon_quantity: quantity,
        is_international: giftItem.isIntlCatalog,
        ...pageNameAndPageType,
      };

      callClevertapEvent(cleverTapEventsConstants.addonAdded, cleverTapData);
    } else {
      const cleverTapData = {
        pid: productId,
        pincode: giftItem.pincode,
        product_name: giftItem.productName,
        product_category: giftItem.primaryProductCategoryId,
        product_price: giftItem.price,
        delivery_type: deliverType,
        date_of_delivery: processDeliveryDate(deliveryDateFormat),
        delivery_shipping: giftItem.shippingMethodName,
        delivery_shipping_fee: giftItem.shippingDetails.shippingPrice,
        delivery_slot: giftItem.shippingDetails.deliveryTimeSlot,
        addon_name: addOnName,
        addon_price: addOnPrice,
        addon_quantity: quantity,
        area: giftItem.deliveryLocation,
        system_pincode: giftItem.pincode,
        is_addon_added: giftItem.addons.length > 0,
        is_international: giftItem.isIntlCatalog,
        ...pageNameAndPageType,
      };
      callClevertapEvent(cleverTapEventsConstants.addonRemoved, cleverTapData);
    }

    const params = {
      isCart: true,
      itemIndex,
      productId_quantity: `${productId}_${quantity}`,
      qty: quantity,
    };
    dispatch({ type: APP_CONSTANTS.UPDATE_ADDON_QUANTITY_REQUEST, payload: params });
  };

  /**
   * handle checked and unchecked wallet balance change
   *
   * @param {any} newChecked parameter to be checked and unchecked
   */
  const handleChildStateChange = (newChecked) => {
    setChecked(newChecked);
    sessionStorage.setItem("walletCheckboxState", newChecked);
  };

  /**
   * Function to get the value from session storage and initialize the `checked` state
   *
   */
  const fetchCheckedFromSessionStorage = () => {
    const storedValue = sessionStorage.getItem("walletCheckboxState");
    setCheckedFromSession(
      localCurrencyINR && (storedValue === "true" || (storedValue === null && fnpWalletSelected === "Y")),
    );
  };

  useEffect(() => {
    fetchCheckedFromSessionStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localCurrencyINR, fnpWalletSelected]);

  useEffect(() => {
    setChecked(checkedFromSession);
  }, [checkedFromSession, localCurrencyINR]);

  useEffect(() => {
    if (hasEventTriggered) return;
    const additionalCartData = {
      ...cleverTapCartCommonData,
      complete_url: `${host}${router.asPath}`,
      clean_url: `${host}${router.asPath}`.split("?")[0],
    };

    const timeout = setTimeout(() => {
      callClevertapEvent(cleverTapEventsConstants.cartViewed, additionalCartData);
      setHasEventTriggered(true);
    }, 2000);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalCartAmount, hasEventTriggered]);

  /**
   *
   * @param {boolean} isLoggedIn .
   */
  const triggerCleverTapEmptyCartEvent = (isLoggedIn) => {
    callClevertapEvent(cleverTapEventsConstants.cartEmpty, {
      cart_value: finalCartAmount ? Number(finalCartAmount) : 0,
      login_status: isLoggedIn,
      ...pageNameAndPageType,
    });
  };

  /**
   * Triggers CleverTap events based on cart and user state.
   *
   * Checks if the cart is empty and triggers events for logged-in
   * or non-logged-in users, ensuring events are not repeated.
   *
   * Updates CleverTap state flags to prevent duplicate triggers.
   */
  const handleCleverTapEvents = () => {
    if (
      ((fusCompleted && isMobile && !cartData?.items?.length) || (!isMobile && !cartInfo?.cartTotalQuantity)) &&
      !cleverTapLoginCartState.emptyCartTriggered
    ) {
      triggerCleverTapEmptyCartEvent(userDetails?.loggedin);
      setCleverTapLoginCartState((prev) => ({
        ...prev,
        emptyCartTriggered: true,
      }));
    }
    if (!fusCompleted && isMobile && !cartData?.items?.length && !cleverTapLoginCartState.nonLoginTriggered) {
      triggerCleverTapEmptyCartEvent(false);
      setCleverTapLoginCartState((prev) => ({
        ...prev,
        nonLoginTriggered: true,
      }));
    }
  };

  useEffect(() => {
    if (isMobile) {
      const timeoutId = setTimeout(() => {
        handleCleverTapEvents();
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
    handleCleverTapEvents();
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cartData.items,
    cartInfo.cartTotalQuantity,
    cleverTapLoginCartState.emptyCartTriggered,
    cleverTapLoginCartState.nonLoginTriggered,
    fusCompleted,
    handleCleverTapEvents,
    triggerCleverTapEmptyCartEvent,
    userDetails.loggedin,
  ]);

  const CurrencyCheckBoxStyle = {
    opacity: localCurrencyINR ? 1 : 0.5,
  };

  /**
   * This function dispatches an action to delete cart item
   *
   * @param {object} params .
   * @param {object} params.giftItem .
   * @param {object} params.addOns .
   * @param {boolean} params.isAddon .
   */
  const handleDeleteItem = ({ giftItem, addOns, isAddon }) => {
    const {
      primaryProductCategoryId,
      mainProduct,
      cartItemIndex,
      productName,
      price,
      shippingDetails,
      shippingMethodName,
      pincode,
      isIntlCatalog,
      addons,
    } = giftItem || {};

    const { itemIndex, productId: addOnProductId, productName: addOnProductName, unitPrice, quantity } = addOns || {};

    const index = isAddon ? itemIndex : cartItemIndex;
    const productId = isAddon ? addOnProductId : mainProduct?.productId;

    const deliverType = getProductDeliveryType(productId);
    const deliveryDateFormatCart = shippingDetails?.deliveryDate?.fullDeliveryDate;
    const cleverTapData = {
      pid: productId,
      pincode,
      product_name: productName,
      product_category: primaryProductCategoryId,
      product_price: price,
      delivery_type: deliverType,
      date_of_delivery: processDeliveryDate(deliveryDateFormatCart),
      delivery_shipping: shippingMethodName,
      delivery_shipping_fee: shippingDetails?.shippingPrice,
      delivery_slot: shippingDetails?.deliveryTimeSlot,
      addon_name: addOnProductName,
      addon_price: unitPrice,
      addon_quantity: quantity,
      area: giftItem?.deliveryLocation,
      system_pincode: pincode,
      is_addon_added: addons?.length > 0,
      is_international: isIntlCatalog,
      ...pageNameAndPageType,
    };

    if (isAddon) {
      callClevertapEvent(cleverTapEventsConstants.addonRemoved, cleverTapData);
    } else {
      callClevertapEvent(cleverTapEventsConstants.productRemoved, cleverTapData);
    }

    const params = {
      [`DELETE_${index}`]: index,
      productId,
      format: "json",
    };
    dispatch({ type: APP_CONSTANTS.DELETE_CART_ITEM_REQUEST, payload: params });
  };

  if (
    (showCartSkeleton && !isLoadingAddonUpdate && !isLoadingDeleteItem) ||
    (showPncCartSkeleton && cartInfo?.cartTotalQuantity > 0)
  )
    return <CartListingSkeleton isMobile={isMobile} />;
  return (
    <div className={!showCartSkeleton ? "cart-container" : ""}>
      {(isMobile && !cartData?.items?.length) || (!isMobile && !cartInfo?.cartTotalQuantity) ? (
        <EmptyCart loggedIn={userDetails?.loggedin} isMobile={isMobile} />
      ) : (
        <>
          {isLoadingAddonUpdate || isLoadingDeleteItem ? (
            <div className={isMobile ? classes.loaderOverlay : classes.desktopLoaderOverlay}>
              <Loader />
            </div>
          ) : null}

          <Grid className={isMobile ? classes.cartListingContainer : classes.desktopCartListingContainer}>
            <div className={!showAddItemToCart && classes.hide}>
              <div className={classes.addMessage}>
                <CheckCircleIcon />
                <span id="add-message" className={classes.addMessageText}>
                  Item Added to Cart
                </span>
              </div>
            </div>
            <CartListing
              isMobile={isMobile}
              cartData={updateViewCartJSON(cartData)}
              localCurrency={localCurrency}
              isMultiImageUploadOnVal
              updateQuantityAction={handleQuantityUpdate}
              deleteOrderItemAction={handleDeleteItem}
              currencies={currencies}
              handleProceedToAddon={handleProceedToAddon}
              cleverTapCartCommonData={cleverTapCartCommonData}
            />
            <div className={isMobile ? walletStyles.cartWalletStyle : walletStyles.desktopCartWalletStyles}>
              <WalletCheckBox
                setAvailableAmount={setAvailableAmount}
                onStateChange={handleChildStateChange}
                availableAmount={availableAmount}
                checked={!!checked}
                disabled={!localCurrencyINR}
                CurrencyCheckBoxStyle={CurrencyCheckBoxStyle}
                isCartClick="Y"
                cleverTapCartCommonData={cleverTapCartCommonData}
                cartDataFromLocal={cartDataFromLocal}
              />
            </div>
          </Grid>
          <StickyCartPriceDetails
            isMobile={isMobile}
            priceInfo={updateViewCartJSON(cartData).paymentInfo}
            loggedIn={userDetails?.loggedin}
            localCurrency={localCurrency}
            currencies={currencies}
            availableAmountUsed={amountInStickyBar}
            checkBoxChecked={!checked}
            localCurrencyINR={localCurrencyINR}
            cleverTapCartCommonData={cleverTapCartCommonData}
          />
        </>
      )}
    </div>
  );
}
const propTypes = {
  handleProceedToAddon: PropTypes.func.isRequired,
};

CartPreview.propTypes = propTypes;

export default CartPreview;
