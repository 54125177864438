import getConfig from "next/config";
import { getOrgPostalSchema, getStoreSchema } from "../actions/common-actions";

const { publicRuntimeConfig } = getConfig();
const country = publicRuntimeConfig.COUNTRY;

/**
 * This method returns a string to be appended to the endpoint.
 * for the api call to fetch rel = alternate attribute based on
 * different catalogs and categories. The website id depends on the
 * device, It varies for desktop and mobile.
 *
 * @param {string} catalogId This specifies the different catalogs india, usa, uae etc.
 * @param {string} resolvedCategoryId this can vary depending on the category like cakes, flowers etc.
 * @param {string} websiteId FNP for Desktop and FNPMobile for mobile.
 * @returns {string} final string for alternate parameters.
 */
export function getRelAltTags(catalogId, resolvedCategoryId, websiteId) {
  return `websiteId=${websiteId}&catalogIdFromReqty=${catalogId}&defaultcatalog=india&urlToMatch=/${resolvedCategoryId}`;
}

/**
 * This is a wrapper for seo script tags to be added on different pages.
 * It takes the json content and wraps it up in the script tag.
 *
 * @param {Array | string} scripts is a json string or an array of JSON strings
 * @returns {string} a wrapped script tag string including the script content
 */
export function seoScriptWrapper(scripts) {
  if (scripts) {
    return `<script type=application/ld+json>
  ${scripts.toString()}
 </script>`;
  }
  return "";
}
/**
 * This method returns the script tag containing organization schema data
 * and postal address schema data
 *
 * @returns {string} script tag string.
 */
export async function getOrgPostalScript() {
  const orgScript = await getOrgPostalSchema(country);
  const organization = orgScript.organization ? JSON.stringify(orgScript.organization) : "";
  const postalAddress = orgScript.postalAddress ? JSON.stringify(orgScript.postalAddress) : "";
  return orgScript && organization && postalAddress ? seoScriptWrapper([organization, postalAddress]) : "";
}
/**
 * This method returns the script tag containing store schema data
 *
 * @returns {string} script tag string.
 */
export async function getStoreSchemaScript() {
  const storeScript = await getStoreSchema(country);
  return storeScript ? seoScriptWrapper(JSON.stringify(storeScript)) : "";
}

/**
 * This method takes the local schema json string and calls the wrapper
 * to wrap it up into script tag and then returns the wrapped string
 *
 * @param {string} localScript local schema json data.
 * @returns {string} script tag string.
 */
export function getLocalBusinessScript(localScript) {
  return seoScriptWrapper(localScript);
}
