import { put, takeLatest } from "redux-saga/effects";
import constants from "../../src/action-constants/listing-contants";

/**
 * action to trigger seach instead list
 *
 * @param {object} action - action object
 */
export function* getSearchInsteadData(action) {
  yield put({
    type: constants.SET_SEARCH_INSTEAD_DATA,
    payload: action.payload,
  });
}

/**
 * sagas for seach instead list
 *
 */
function* watchSearchInsteadPageRequest() {
  yield takeLatest(constants.GET_SEARCH_INSTEAD_DATA, getSearchInsteadData);
}

export default watchSearchInsteadPageRequest;
