/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import dynamic from "next/dynamic";
import Dropdown from "../../hoc/dropdown/index";
import style from "./account-dropdown.module.scss";
import dropDownStyle from "../../hoc/dropdown/drop-down.module.scss";
import dHeaderStyle from "../../../styles/d-header.module.scss";
import { EMPTY_VIEW_LABEL, HI_GUEST_LABEL } from "../../../../../common/constants";
import { ACTION_TYPE } from "../../../../../../src/action-constants/my-account-constants";
import { getPageNameForCleverTap } from "../../../../../../src/utils/common";
import callClevertapEvent from "../../../../../../src/analytics/clevertapUtility";
import { cleverTapEventsConstants } from "../../../../../../src/analytics/clevertapConstants";

const FNPCashText = dynamic(() => import("../../../../common/fnpCashText"), {
  ssr: false,
});
const FNPCashData = dynamic(() => import("../../../../common/fnpCashData"), {
  ssr: false,
});
const NewTextBadge = dynamic(() => import("./newBadge"), {
  ssr: false,
});

/**
 * Component of Account DropDown
 *
 * @param {object} props - contains links
 * @param {Array} props.itemsArray array of links used to show when user not logged in.
 * @param {Array} props.loggedinItems array of links used to show when user  logged in.
 * @param {object} props.userDetails user details when user  logged in.
 *
 * @returns {React.ReactElement} returns the account dropdown component.
 */
function HeaderAccountDropdown({ itemsArray, loggedinItems, userDetails }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const isLoggedIn = userDetails?.checkLoginInfo?.loggedin;
  const userName = isLoggedIn ? userDetails?.name : HI_GUEST_LABEL;
  const [accountDropDownSate, setAccountDropDownState] = useState(itemsArray);
  const callChatTime = useSelector((state) => state.commonPageData?.configureCallAndChatTime || {});

  const FNP_CASH = (
    <>
      <span className={style.fnpCashSpan}>
        <FNPCashText />
        {isLoggedIn ? <FNPCashData /> : ""}
      </span>
    </>
  );

  /**
   * This method has the flag set for hover state changes
   *
   * @param {boolean} flag functionName to be invoke.
   */
  const updateDropDownOnHover = (flag) => {
    if (flag) {
      const updatedDropDownData = [...itemsArray];
      const newDropDownItem = {
        label: FNP_CASH,
        icon: "account_balance_wallet",
        link: "/account/wallet-details",
      };
      const insertIndex = isLoggedIn ? 1 : 2;
      updatedDropDownData.splice(insertIndex, 0, newDropDownItem);
      setAccountDropDownState(updatedDropDownData);
    }
  };

  /**
   * Returns the entire account elements
   *
   * @param {Array} items links
   * @returns {React.ReactElement} returns the account dropdown if items are not empty else returns empty
   */
  const renderMenu = (items) => {
    const EmptyView = <div className={style["account-dropdown-header"]}>{EMPTY_VIEW_LABEL}</div>;

    const ListView = <ul className="Menu">{items}</ul>;

    const showEmptyView = items.length === 0;

    return (
      <div
        className={`${dropDownStyle["menu-container"]} ${dropDownStyle["account-arrow"]} ${style["account-dropdown-container"]}`}
      >
        {showEmptyView ? EmptyView : ListView}
      </div>
    );
  };

  /**
   * This method has the switch statement based on the function name respective function will executed
   *
   * @param {string} fnToExec functionName to be invoke.
   * @param {object} item .
   */
  const handleOnClick = (fnToExec, item) => {
    if (fnToExec !== "Logout") {
      if (fnToExec.toLowerCase().includes("login")) {
        window.location.href = `/account/my-account?from=${router.asPath}`;
      } else if (item.label === "Contact Us" && callChatTime?.callTime?.switch === "N") {
        if (window.fcWidget) {
          window.fcWidget?.open();
          window.fcWidget?.show();
        }
      } else {
        window.location.href = item.link;
      }
    } else {
      dispatch({ type: ACTION_TYPE.LOGOUT_REQUESTED });
      document.cookie = "lgpopsh=false; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      document.cookie = "uscnlg=false; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      sessionStorage.clear();
    }
    const pageHeaderMenuData = {
      page_name: getPageNameForCleverTap(),
      page_type: getPageNameForCleverTap(),
      menu_name: item?.icon === "account_balance_wallet" ? "FNP Cash" : item.label,
    };
    callClevertapEvent(cleverTapEventsConstants.headerMenuClicked, pageHeaderMenuData);
  };

  /**
   * Account drop down detail
   *
   * @param {Array} item links
   * @returns {React.ReactElement} retuns the detail of links like login,my profile.
   */
  const renderItem = (item) => {
    const accountBalanceWallet = item.icon === "account_balance_wallet";
    return (
      <li
        className={`${dropDownStyle["menu-list"]} ${style["menu-width"]} ${style["account-dropdown-list"]}`}
        key={item.label}
      >
        <a
          onClick={() => {
            handleOnClick(accountBalanceWallet ? "FNP Cash" : item.label, item);
          }}
          className={style["account-items"]}
        >
          <i className="material-icons">{item.icon}</i>
          <span>{item.label}</span>
          <span style={{ margin: "0px 0px 2px 6px" }}>{accountBalanceWallet && <NewTextBadge />}</span>
        </a>
      </li>
    );
  };
  return (
    <Dropdown
      items={isLoggedIn ? [...accountDropDownSate, ...loggedinItems] : accountDropDownSate}
      dropDownHoverCB={(flag) => updateDropDownOnHover(flag)}
      renderItem={renderItem}
      renderMenu={renderMenu}
      arrow="account-arrow"
      getItemValue={() => {}}
    >
      <div className={dHeaderStyle["maintoolbar-placeholder"]} title={isLoggedIn ? userName : ""}>
        <i className="material-icons">account_circle</i>
        {isLoggedIn ? <span className={style["user-name"]}>{userName}</span> : <span>{userName}</span>}
      </div>
    </Dropdown>
  );
}
HeaderAccountDropdown.propTypes = {
  itemsArray: PropTypes.arrayOf(PropTypes.object),
  loggedinItems: PropTypes.arrayOf(PropTypes.object),
  userDetails: PropTypes.objectOf(PropTypes.any).isRequired,
};

HeaderAccountDropdown.defaultProps = {
  loggedinItems: [],
  itemsArray: [],
};

export default HeaderAccountDropdown;
