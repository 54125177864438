import getConfig from "next/config";
import axios from "../../../../app_configs/axios/base";

const { publicRuntimeConfig } = getConfig();
const CONTROL_API_URL = publicRuntimeConfig.CONTROL_API_URL || "/";

/**
 * This function returns facets for gift finder
 *
 * @param {string} queryParam query parameters received from the calling component.
 * @returns {object} object containing gift finder facet data
 */
export const getFacetsForGiftFinder = async (queryParam) => {
  // Currently calling directly on m-uat site since API is not developed in current codebase
  try {
    const res = await axios.get(`control/getFacetsForGiftFinder?${queryParam}`, { baseURL: CONTROL_API_URL });
    const { gatefieldMap } = res.data;
    return gatefieldMap;
  } catch (e) {
    throw new Error(e);
  }
};

/**
 * This function gets pin code for a provided location
 *
 * @param {object} payload input payload.
 * @returns {void} returns nothing
 */
export async function getPincodeBasedOnLocation(payload) {
  try {
    const { latitude, longitude } = payload;
    const pinCodeResponse = await axios.get(
      `control/getPinCodeByPostalAddress?latitude=${latitude}&longitude=${longitude}`,
    );
    const pinCode = pinCodeResponse.data.pincode || pinCodeResponse.data.matchedPincode;

    return pinCode;
  } catch (e) {
    throw new Error(e);
  }
}

/**
 * This function gets pin code for a provided location
 *
 * @param {object} payload input payload.
 * @returns {void} returns nothing
 */
export async function getCityNameBasedOnLocation(payload) {
  try {
    const { locality, geoLocality, pincode } = payload;
    const cityResponse = await axios.get(
      `control/getCityForPostalAddress?pincode=${pincode}&locality=${locality}&geolevel2=${geoLocality}`,
    );
    return cityResponse;
  } catch (e) {
    throw new Error(e);
  }
}
