import { HYDRATE } from "next-redux-wrapper";
import APP_CONSTANTS from "../../../src/action-constants/app-actions";

const InitialState = {
  success: false,
  statementErrorMsg: "",
  walletValue: "",
  responselocalStorageTimeStamp: "",
  walletText: "",
  fnpCashWithGiftCoupon: true,
  walletStatement: [],
  walletLoader: null,
  valueTimer: "",
  staticContent: "",
  page: 1,
  totalCount: 0,
  accountAPIStatus: 0,
  fnpWalletSelected: "",
  walletUsableBalance: null,
  walletUsableBalanceTnC: "",
};

/**
 * This method is the reducer for actions that are specific to wallet transactions.
 *
 * @param {object} state - contains initial redux State
 * @param {object} action - contains actionTypes and payload information for specific action
 * @returns {object} state - the next State which needs to be dispatched to redux
 */
function walletReducer(state = InitialState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.walletDetails,
      };
    case APP_CONSTANTS.SET_WALLET_CASH_LABEL_SUCCESS:
      return {
        ...state,
        fnpCashWithGiftCoupon: action.payload?.fnpcashwithgiftcoupon,
        valueTimer: action.payload?.wallet?.timer,
        walletText: action.payload?.wallet?.label?.fnpcash,
        fnpWalletSelected: action.payload?.wallet?.fnpWalletSelected,
        noTxnMsg: action.payload?.wallet?.noTxnMsg,
      };
    case APP_CONSTANTS.SET_WALLET_CASH_LABEL_ERROR:
      return {
        ...state,
      };
    case APP_CONSTANTS.SET_WALLET_SUCCESS:
      return {
        ...state,
        walletValue: action.payload,
      };
    case APP_CONSTANTS.SET_WALLET_USABLE_BALANCE_SUCCESS:
      return {
        ...state,
        walletUsableBalance: action.payload,
      };
    case APP_CONSTANTS.SET_WALLET_ERROR:
      return {
        ...state,
      };
    case APP_CONSTANTS.SET_WALLET_ACCOUNT_SUCCESS:
      return {
        ...state,
        success: true,
        walletLoader: false,
        walletStatement: action.payload.data?.statements,
        page: action.payload.page + 1,
        totalCount: action.payload.data?.totalCount,
        accountAPIStatus: action.payload?.status,
      };
    case APP_CONSTANTS.SET_WALLET_ACCOUNT_ERROR:
      return {
        ...state,
        walletLoader: false,
        statementErrorMsg: action.payload.error,
        success: false,
        accountAPIStatus: action.payload?.status,
      };
    case APP_CONSTANTS.SET_WALLET_LOADER:
      return {
        ...state,
        walletLoader: true,
      };
    case APP_CONSTANTS.SET_WALLET_STATIC_CONTENT:
      return {
        ...state,
        staticContent: action.payload,
      };
    case APP_CONSTANTS.FETCH_WALLET_USABLE_BALANCE_TNC_SUCCESS:
      return {
        ...state,
        walletUsableBalanceTnC: action.payload,
      };
    default:
      return state;
  }
}
export default walletReducer;
