/* eslint-disable react/jsx-props-no-spreading */
import { ThemeProvider } from "@material-ui/styles";
import HTMLReactParser from "html-react-parser";
import App from "next/app";
import getConfig from "next/config";
import Head from "next/head";
import Router from "next/router";
import { setCookie } from "nookies";
import React from "react";
import { connect } from "react-redux";
import { END } from "redux-saga";
import DesktopLayout from "../components/layouts/desktop-layout";
import MobileLayout from "../components/layouts/mobile-layout";
import getAppGeoConfig from "../geo_configurations/index";
import wrapper from "../redux/store/store";
import APP_CONSTANTS from "../src/action-constants/app-actions";
import { requestDesktopMegaMenuData, requestMobileLeftMenuData } from "../src/actions/menuAction";
import {
  getCatalogueAndCategory,
  getDomain,
  getPageName,
  getTitle,
  isMobileDevice,
  RoutePageConstants,
} from "../src/utils/common";
import theme from "../styles/materialUi/theme";
import "../styles/styles.default.scss";

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

/**
 * The App component is used to initialize pages
 * Here the App component is extended to control the page initialization
 * Persisting layout between page changes
 * Keeping state when navigating pages
 * Custom error handling using componentDidCatch
 * Inject additional data into pages
 * Add global CSS
 * See [next/app]{@link https://nextjs.org/docs/advanced-features/custom-app}
 *
 * @class BasePage
 * @augments App
 */
class BasePage extends App {
  /**
   * It enables server-side rendering in a page and allows to do initial data population
   * Adding a custom getInitialProps in the App will disable Automatic Static Optimization in pages without Static Generation.
   *
   * @param {object} param0 is the parameter consisting of component and Context object ctx.
   * @param {object} param0.Component object is the page component that will be loaded (e.g. pages/account/index.js)
   * @param {object} param0.ctx is App Context, it is an object with the initial props that
   * were preloaded for the  page by one of the data fetching methods either getInitialProps or getServerSideProps, otherwise it's an empty object.
   * @returns {object} Initial props.
   */
  static getInitialProps = wrapper.getInitialAppProps((store) => async ({ Component, ctx }) => {
    const isMobile = isMobileDevice(ctx);
    const { query } = ctx;
    /*
      These values are only available in node.js environment
      To make this values available in the browser
      Prepend NEXT_PUBLIC_ to the keys
      Eg: NEXT_PUBLIC_COUNTRY="IN"
      https://nextjs.org/docs/basic-features/environment-variables#exposing-environment-variables-to-the-browser
    */
    const country = publicRuntimeConfig.COUNTRY || "IN";
    const baseApiUrl = publicRuntimeConfig.BASE_URL;
    const apiUrl = serverRuntimeConfig.API_URL;
    const showLogs = publicRuntimeConfig.SHOW_LOGS;
    const isInfo = publicRuntimeConfig.IS_INFO;
    const isVerbose = publicRuntimeConfig.IS_VERBOSE;
    const host = isMobile ? publicRuntimeConfig.HOST : publicRuntimeConfig.D_HOST;
    const assetsUrl = isMobile ? publicRuntimeConfig.ASSETS_URL : publicRuntimeConfig.D_ASSETS_URL;

    const configData = getAppGeoConfig(isMobile, country);

    // Based on Request Path Getting CatalogId and Category for Products
    const { catalogue, category } = getCatalogueAndCategory(ctx, country);

    await store.dispatch({
      type: APP_CONSTANTS.SET_DYNAMIC_APP_CONFIGS,
      payload: {
        isMobile,
        configData,
        category,
        catalogId: catalogue,
      },
    });
    if (ctx.req) {
      await store.dispatch({
        type: APP_CONSTANTS.SET_STATIC_APP_CONFIGS,
        payload: {
          baseApiUrl,
          apiUrl,
          host,
          showLogs,
          isInfo,
          isVerbose,
          assetsUrl,
          rootGeoId: country,
          rootCatalogId: configData.default.rootCatalogId,
        },
      });

      if (isMobile) {
        const page = getPageName(ctx.pathname);
        switch (page) {
          case RoutePageConstants.HOME:
          case RoutePageConstants.PLP_PAGE:
          case RoutePageConstants.MICROSITE:
          case RoutePageConstants.TESTIMONIAL:
          case RoutePageConstants.EXPERIENCES_PAGE:
            await store.dispatch(requestMobileLeftMenuData(catalogue));
            break;
          case RoutePageConstants.PDP_PAGE:
            break;
          default:
            break;
        }
      } else {
        /* The `megaMenuOptions` constant is being used to define options for fetching the mega menu
        content. It includes the `contentURL` property set to `"menu"` which specifies the type of
        content to fetch for the mega menu. Additionally, removed the changes for M-site as it is not required as if now, it includes the `headers` property which is set to `ctx?.req?.headers`, allowing access to the headers from the request object in the context (`ctx`). These headers can be used for authentication, authorization, or any other necessary information when fetching the mega menu data. We are mapping for gifts-lp in redis. */
        const megaMenuOptions = {
          contentURL: catalogue === "india" ? "menu" : `menus/${catalogue}/gifts-lp-hm`,
          headers: ctx?.req?.headers,
        };
        store.dispatch(requestDesktopMegaMenuData(megaMenuOptions));
      }

      /**
       * The purpose of adding this code block is to do state reconciliation during hydration on pages without getInitialProps
       *
       * Instead of writing getInitialProps in every page just to terminate running sagas on server, we can handle the same in _app.js getInitialProps
       *
       * */
      if (!Component.getInitialProps) {
        store.dispatch(END);
        await store.sagaTask.toPromise();
      }
    } else {
      // Client Side Navigations
      const { selectedCurrency } = store.getState().appData;
      setCookie(ctx, "localCurrency", selectedCurrency, {
        path: "/",
        domain: getDomain(configData),
      });
    }

    // Wait for all page actions to dispatch
    const pageProps = {
      ...(Component.getInitialProps ? await Component.getInitialProps(ctx) : {}),
    };

    const statusCode = ctx?.pathname === "/_error" ? 500 : ctx?.res?.statusCode;

    return {
      isMobile,
      configData,
      pageProps,
      statusCode,
      query,
    };
  });

  renderHead = (configData, isMobile) => {
    const title = getTitle(configData);
    return (
      <Head>
        {isMobile ? (
          <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no, maximum-scale=1.0" />
        ) : (
          <meta name="viewport" content="minimum-scale=1, initial-scale=1.0, width=device-width" />
        )}
        <title>{title}</title>
        {HTMLReactParser(`<script src="https://accounts.google.com/gsi/client" async defer></script>`)}
      </Head>
    );
  };

  /**
   *
   */
  componentDidMount() {
    const { isMobile, query } = this.props.params ?? this.props;
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    if (isMobile) this.props.fetchCurrencies(query?.curr);
  }

  /**
   * Main render function
   *
   * @returns {ThemeProvider} [ThemeProvider from Material UI]{@link https://material-ui.com/styles/api/#themeprovider}
   */
  render() {
    const { isMobile, configData, Component, pageProps, statusCode } = this.props.params ?? this.props;

    let errorPageName;
    let renderMobileDevice;
    let renderLayout;

    if (statusCode >= 400 && statusCode !== 404) {
      errorPageName = "ServerError";
    }

    if (typeof isMobile === "undefined" && typeof window !== "undefined") {
      if (Router.pathname === "/_error") {
        renderMobileDevice = !window.dSite;
        errorPageName = "ServerError";
      }
    }

    if (isMobile || renderMobileDevice) {
      renderLayout = (
        <MobileLayout errorPageName={errorPageName} statusCode={statusCode}>
          <Component {...pageProps} />
        </MobileLayout>
      );
    } else {
      renderLayout = (
        <DesktopLayout errorPageName={errorPageName} statusCode={statusCode}>
          <Component {...pageProps} />
        </DesktopLayout>
      );
    }

    return (
      <ThemeProvider theme={theme}>
        {this.renderHead(configData, isMobile)}
        {renderLayout}
      </ThemeProvider>
    );
  }
}

/**
 * Mapping redux actions
 *
 * @param {Function} dispatch dispatch function
 *
 * @returns {object} dispatch actions mapped to props
 */
const mapDispatchToProps = (dispatch) => ({
  fetchCurrencies: (currency) => dispatch({ type: APP_CONSTANTS.FETCH_CURRENCIES_REQUESTED, payload: currency || "" }),
});

export default wrapper.withRedux(connect(null, mapDispatchToProps)(BasePage));
