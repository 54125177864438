import React from "react";
import { AppBar, Grid, IconButton, InputBase, makeStyles, Toolbar } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MenuIcon from "@material-ui/icons/Menu";
import PropTypes from "prop-types";

import FNPLogo from "../fnp-logo";
// import GiftFinderIconBtn from "../gift-finder-icon-btn";
import CartIconBtn from "../cart-icon-btn";
import KebabMenuIconBtn from "../kebab-menu-icon-btn";

const useStyles = makeStyles((theme) => ({
  appBarRoot: {
    "&.MuiPaper-root": {
      color: theme.color?.white,
      backgroundColor: theme.color?.olive,
    },
  },
  toolbarOneRoot: {
    minHeight: "44px",
    justifyContent: "space-between",
  },
  toolbarTwoRoot: {
    minHeight: "44px",
    marginLeft: "12px",
    marginRight: "12px",
    marginBottom: "12px",
    background: "#FFFFFF",
    borderRadius: "4px",
  },
  menuButton: {
    borderRadius: "4px",
    background: "#F2F2F2",
    "& svg": {
      color: "#222222",
    },
  },
  searchIconBtn: {
    "& svg": {
      color: "#999999",
    },
  },
  inputRoot: {
    fontSize: "14px",
    width: "100%",
    "&.Mui-disabled": {
      color: "#6C6C6C",
    },
  },
}));

/**
 * This component is used to show home page primary header in the m-site
 *
 * @param {object} root0 props from parent
 * @param {Function} root0.openLeftMenu on click handler to open left menu
 * @param {Function} root0.openSearch on click handler to open search bar
 * @param {Function} root0.openRightMenu on click handler to open right menu
 * @param {string} root0.catalogId current catalog id
 *
 * @returns {React.ReactElement} - renders fnp-logo component
 */
const HomePrimaryHeader = ({ openLeftMenu, openSearch, openRightMenu, catalogId }) => {
  const classes = useStyles();

  return (
    <AppBar elevation={0} position="static" className={classes.appBarRoot}>
      <Toolbar disableGutters className={classes.toolbarOneRoot}>
        <Grid>
          <FNPLogo isHomePage catalogId={catalogId} showCountryFlag />
        </Grid>
        <Grid>
          {/* <GiftFinderIconBtn currentCatalogId={catalogId} /> */}
          <CartIconBtn showCart />
          <KebabMenuIconBtn showKebabMenu openRightMenu={openRightMenu} />
        </Grid>
      </Toolbar>
      <Toolbar disableGutters className={classes.toolbarTwoRoot}>
        <IconButton disableRipple onClick={openLeftMenu} className={classes.menuButton} aria-label="open left menu">
          <MenuIcon />
        </IconButton>
        <IconButton disableRipple onClick={openSearch} className={classes.searchIconBtn} aria-label="open search">
          <SearchIcon />
        </IconButton>
        <InputBase
          onClick={openSearch}
          placeholder="Search flowers, cakes, gifts, etc"
          classes={{
            root: classes.inputRoot,
          }}
        />
      </Toolbar>
    </AppBar>
  );
};

HomePrimaryHeader.propTypes = {
  openLeftMenu: PropTypes.func,
  openSearch: PropTypes.func,
  openRightMenu: PropTypes.func,
  catalogId: PropTypes.string,
};

HomePrimaryHeader.defaultProps = {
  openLeftMenu: () => {},
  openSearch: () => {},
  openRightMenu: () => {},
  catalogId: "india",
};

export default HomePrimaryHeader;
