import { HYDRATE } from "next-redux-wrapper";
import ReminderConstants from "../../../src/action-constants/reminder-constants";

const initialState = {
  remindersList: [],
  isDataChanged: false,
  isLoaded: false,
};

/**
 * reducer for reminders
 *
 * @param {object} state - initial redux state
 * @param {object} action - action object for action type and payload
 * @returns {object} - updated state
 */
function reminderReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.reminder,
      };
    case ReminderConstants.GET_REMINDERS_LIST_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        remindersList: payload.remindersList.map((e) => ({
          occasionReminderId: e.occasionReminderId,
          name: e.name,
          note: e.note,
          occasion: e.occasion,
          relation: e.relation,
          occasionDate: e.occasionDate,
        })),
      };
    case ReminderConstants.GET_REMINDERS_LIST:
      return {
        ...state,
        isSuccess: true,
      };
    case ReminderConstants.GET_REMINDERS_LIST_FAILURE:
      return {
        ...state,
        isSuccess: false,
      };
    default:
      return state;
  }
}

export default reminderReducer;
