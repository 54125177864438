import getConfig from "next/config";
import axios from "axios";

const { publicRuntimeConfig } = getConfig();
const API_TIMEOUT = Number(publicRuntimeConfig.API_TIMEOUT) || 1000;

/**
 * This Axios is a customized instance of the native axios
 * Interceptors are methods which are triggered before the main method.
 * There are two types of interceptors: request interceptor and response interceptor
 *
 * @param { object } AxiosConfig default configuration settings including header auth customization etc.
 * @param { boolean } requestInterceptor this flag decides whether request will be intercepted not.
 * @param { boolean } responseInterceptor this flag decides whether the response will be intercepted or not.
 * @param {Function} requestInterceptorOverride this is called before the actual call to the endpoint is made.
 * @param {Function} responseInterceptorOverride  this is called before the response is received from the call.
 *
 * @returns {object} customized Axios instance.
 */
const Axios = (
  AxiosConfig = {},
  requestInterceptor = true,
  responseInterceptor = true,
  requestInterceptorOverride = (config) => {
    // for getting trace id serverside logs in kibana
    const globalTraceId = global?.pageContext?.req?.headers["x-traceid"] || 0;
    const globalForwardFor = global?.pageContext?.req?.headers["x-forwarded-for"] || 0;
    if (globalTraceId !== 0 && globalForwardFor !== 0) {
      const tempConfig = { ...config };
      tempConfig.headers["x-traceid"] = global?.pageContext?.req?.headers["x-traceid"];
      tempConfig.headers["x-forwarded-for"] = global?.pageContext?.req?.headers["x-forwarded-for"];
      return tempConfig;
    }
    return config;
  },
  responseInterceptorOverride = (config) => config,
) => {
  const isServer = typeof window === "undefined";
  const instance = axios.create({
    ...AxiosConfig,
    paramsSerializer: (params) => {
      let result = "";
      Object.keys(params).forEach((key) => {
        result += `${key}=${encodeURIComponent(params[key])}&`;
      });
      return result.substr(0, result.length - 1);
    },
  });
  instance.defaults.timeout = API_TIMEOUT;
  if (requestInterceptor) {
    instance.interceptors.request.use(
      function Config(config) {
        const newConfig = requestInterceptorOverride(config);
        return newConfig;
      },
      function ConfigError(error) {
        if (isServer) {
          const url = `${error?.config?.baseURL}${error?.config?.url}`;
          const methodType = `${error?.config?.method ? error?.config?.method?.toUpperCase() : ""}`;
          const requestStatus = `${error?.request ? error?.request?.status : "No request status"}`;
          const params = `${error?.config?.params}`;
          // eslint-disable-next-line no-console
          console.error(
            `${new Date().toUTCString()} ::::Request log::::, Url: ${url}, Method type: ${methodType}, Status: ${requestStatus}, Params: ${params}`,
          );
        }

        return Promise.reject(error);
      },
    );
  }
  if (responseInterceptor) {
    instance.interceptors.response.use(
      function Response(response) {
        const newResponse = responseInterceptorOverride(response);
        return newResponse;
      },
      function ResponseError(error) {
        if (isServer) {
          const traceId = error?.config?.headers["x-traceid"] || null;
          const forwardedForIp = error?.config?.headers["x-forwarded-for"] || null;
          const url = `${error?.config?.baseURL}${error?.config?.url}`;
          const responseStatus = `${error?.response ? error?.response?.status : "No response status"}`;
          const responseBody =
            // eslint-disable-next-line no-nested-ternary
            error?.response && error?.response?.data
              ? // eslint-disable-next-line no-nested-ternary
                error?.response?.headers["content-type"].includes("text/html")
                ? "HTML content"
                : JSON.stringify(error?.response?.data)
              : null;
          const responseMessage = error?.response?.data?.message ? error?.response?.data?.message : null;

          let errObjStr = "Not Required";
          if (!responseBody) {
            try {
              errObjStr = JSON.stringify(error);
            } catch (stringifyError) {
              // if error object not able to stringify then we try to get message and stack
              // stringify fails due to "Uncaught TypeError: Converting circular structure to JSON"
              const errMsg = JSON.stringify(error?.message);
              if (errMsg) errObjStr = errMsg;
            }
          }
          // included for checking which header value is failing ie for ["ERR_HTTP_INVALID_HEADER_VALUE"]
          if (error?.message) errObjStr += error.message;
          const errorLogStatement = `${new Date().toUTCString()} ::::Response log:::: Trace Id: ${traceId}, Forwarded for: ${forwardedForIp}, Url: ${url}, Status: ${responseStatus}, Response message: ${responseMessage}, Response body: ${responseBody}, Error Object: ${errObjStr}`;
          // eslint-disable-next-line no-console
          console.error(errorLogStatement);
        }

        return Promise.reject(error);
      },
    );
  }
  return instance;
};

export default Axios;
