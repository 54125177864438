import LocationLockConstants from "../action-constants/location-lock-constants";

/**
 * Action for setting delivery key
 *
 * @param {string} payload contains delivery key.
 * @returns {object} returns action
 */
export const setDeliveryKey = (payload) => ({
  type: LocationLockConstants.SET_DELIVERY_AS_KEY,
  payload,
});

/**
 * Action for setting pincode mapping request
 *
 * @returns {object} returns action
 */
export const setPinCodeMappingReq = () => ({
  type: LocationLockConstants.SET_PIN_CODE_MAPPING_REQUEST,
});

/**
 * Action for re selecting pin code
 *
 * @param {string} payload contains empty string or null
 * @returns {object} returns action
 */
export const reselectPinCode = (payload) => ({
  type: LocationLockConstants.RESET_SELECTED_PINCODE,
  payload,
});

/**
 * Action for setting selected pincode
 *
 * @param {string} payload contains selected pincode
 * @returns {object} returns action
 */
export const setSelectedPinCode = (payload) => ({
  type: LocationLockConstants.SET_SELECTED_PINCODE,
  payload,
});

/**
 * Action for setting final selected pin code
 *
 * @param {string} payload contains pin code
 * @returns {object} returns action
 */
export const setFinalSelectedPinCode = (payload) => ({
  type: LocationLockConstants.FINAL_SELECTED_PINCODE,
  payload,
});

/**
 * Action for showing location pop up
 *
 * @param {string} payload contains boolean value
 * @returns {object} returns action
 */
export const setShowLocationPopUp = (payload) => ({
  type: LocationLockConstants.SHOW_LOCATION_LOCK_POPUP_REQUEST,
  payload,
});

/**
 * Action for getting pin code list
 *
 * @param {string} payload contains pin code list
 * @returns {object} returns action
 */
export const getPinCodeList = (payload) => ({
  type: LocationLockConstants.GET_PINCODE_LIST_REQUEST,
  payload,
});

/**
 * Action for clearing pin code list
 *
 * @returns {object} returns action
 */
export const clearPinCodeList = () => ({
  type: LocationLockConstants.CLEAR_PIN_CODE_LIST,
});
/**
 * Action for requesting Place details
 *
 * @param {string} payload contains place details
 * @returns {object} returns action
 */
export const getPlaceDetails = (payload) => ({
  type: LocationLockConstants.GET_PLACE_DETAILS_REQUEST,
  payload,
});

/**
 * Action for setting Selected country
 *
 * @param {string} payload contains selected country
 * @returns {object} returns action
 */
export const setSelectedCountryAction = (payload) => ({
  type: LocationLockConstants.SET_SELECTED_COUNTRY,
  payload,
});

/**
 * Action for getting country list
 *
 * @returns {object} returns action
 */
export const getCountryListRequest = () => ({
  type: LocationLockConstants.GET_COUNTRY_LIST_REQUEST,
});
/**
 * Action for un setting mapped pin code.
 *
 * @returns {object} returns action
 */
export const unsetPinCodeMapped = () => ({
  type: LocationLockConstants.UNSET_IS_PIN_CODE_MAPPED,
});

/**
 * Action for showing modal or not
 *
 * @param {boolean} payload whether to show modal or not
 * @returns {object} returns action
 */
export const setShowModal = (payload) => ({
  type: LocationLockConstants.SHOW_LOCATION_LOCK_MODAL,
  payload,
});

/**
 * Action for showing tooltip
 *
 * @param {boolean} payload whether to tooltip or not
 * @returns {object} returns action
 */
export const setShowLocationTooltip = (payload) => ({
  type: LocationLockConstants.SHOW_LOCATION_TOOLTIP,
  payload,
});
