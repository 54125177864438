import getConfig from "next/config";
import axios from "../../../../app_configs/axios/base";
import errorLogger from "../../../../app_configs/logger-service";
import { getCacheHeaders } from "../../../utils/common";

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();
const showRequestLogs = publicRuntimeConfig.SHOW_REQUESTS_LOGS;

const error = {
  config: {},
  response: {},
};

const desktop = typeof window !== "undefined" ? window.dSite : false;
const CONTROL_API_URL = desktop ? publicRuntimeConfig.D_CONTROL_API_URL : publicRuntimeConfig.CONTROL_API_URL;
const productApiUrl = publicRuntimeConfig.PRODUCT_API_URL;
const apiUrl = serverRuntimeConfig.API_URL;

/**
 * Method to fetch content from SVN
 *
 * @param {object} options contains folderName, fileName and isMobile
 * @returns {string} returns html file data
 */
export async function fetchContentFromSVN(options) {
  const endPoint = "/productapi/api/rest/v1.3/getContentFromSVN";
  try {
    const { folderName, fileName, isMobile, setResHeaders, ctx, isServer = true } = options;
    const response = await axios.get(endPoint, {
      params: {
        folderName,
        fileName,
        requestSource: isMobile ? "MRPLP" : "RPLP",
      },
      baseURL: isServer ? apiUrl : productApiUrl,
    });
    if (response?.headers && setResHeaders) {
      const newDateTime = new Date().getTime();
      response.headers = {
        ...response.headers,
        "cache-control": "public, max-age=3600, s-maxage=61188",
        "edge-control": `max-age=61188`,
        Expires: `${new Date(newDateTime + 61188)}`,
      };
      const headersToSet = getCacheHeaders(response.headers);
      ctx?.res?.set(headersToSet);
    }
    return response.data;
  } catch (err) {
    error.config.url = endPoint;
    error.response.status = `An Exception occurred while calling svn API => ${err}`;
    errorLogger(error, showRequestLogs);
    throw new Error(err.message);
  }
}

/**
 * Method to fetch content from SVN
 *
 * @param {object} options .
 * @param {string} options.pageType .
 * @param {string} options.categoryId .
 * @param {string} options.productId .
 * @param {string} options.selectedPeriod .
 * @returns {string} returns html file data
 */
export async function fetchTestimonialsContent(options) {
  const endPoint = "control/getTestimonials";

  const { pageType = "", categoryId = "", productId = "", selectedPeriod = "" } = options;
  try {
    const response = await axios.get(`${CONTROL_API_URL}${endPoint}`, {
      params: {
        pageType,
        categoryId,
        productId,
        selectedPeriod,
      },
    });
    return response.data;
  } catch (err) {
    error.config.url = endPoint;
    error.response.status = `An Exception occurred while fetching results => ${err}`;
    errorLogger(error, showRequestLogs);
    return {
      error: err.message,
    };
  }
}
