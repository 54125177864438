import serverControl from "../../app_configs/axios/servercontrol";
import { SOMETHING_WENT_WRONG } from "../../components/common/constants";
import ActionConstants from "../action-constants/app-actions";
/**
 *This function is also used to get product lists for category, however on client side
 * either on Infinite Scroll or Sort
 *
 * @returns {string} error code when timeout occurs.
 */
export default async function getUspDataOnClient() {
  try {
    const response = await serverControl.get(`control/getContentIdDetails?contentId=usp`);
    return response.data;
  } catch (ex) {
    if (ex.code && ex.code === ActionConstants.ECONNABORTED) return SOMETHING_WENT_WRONG;
    return "";
  }
}
