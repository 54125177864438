import { HYDRATE } from "next-redux-wrapper";
import ActionConstants from "../../../src/action-constants/app-actions";

/**
 *  This function is a reducer for desktop footer subscribe email.
 *
 * @param {object}state it represents the current state object
 * @param {object}action it represents the new action object which consists of the new payload.
 * @returns {object} Updated state object with the action object.
 */
function accordionDataReducer(state = "", action) {
  const { type } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.accordianData,
      };
    case ActionConstants.SET_ACCORDIAN_DATA:
      return {
        ...state,
        data: action.payload.accordianData,
      };

    default:
      return state;
  }
}

export default accordionDataReducer;
