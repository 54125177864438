import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useSelector } from "react-redux";
import styles from "../style/time-slot.module.scss";
import calenderStyles from "../style/delivery-calender.module.scss";
import { calculateNextCycleSlot, getSuffix } from "../../../../src/utils/common";
import { setGaTimeSlotSelected } from "../../../../src/ga/gaEvents";

/**
 * This functional component represents the desktop pdp shipping method selection.
 *
 * @param {object} root0 it includes object from parent component
 * @param {Function} root0.gotoPrev on date select handler.
 * @param {Function} root0.gotoCalender on date select handler.
 * @param {Function} root0.onSelect on date select handler.
 * @param {string} root0.shippingMethod on date select handler.
 * @param {string} root0.shippingMethodId on date select handler.
 * @param {object} root0.selectedDeliveryDate shipping details object
 * @param {Function} root0.getPrice calculate price.
 * @param {string} root0.currencyCode show currency code .
 * @param {boolean} root0.checkIsInternationalProduct check it's international product or not .
 * @param {boolean} root0.isMultiOrderProduct is product multi order
 * @param {number} root0.selectedTimeSlotId user selected time slot id
 * @param {string} root0.selectedCurrency selected currency
 * @param {string} root0.selectedShippingMethodName selected shipping method name
 * @param {number} root0.numberOfIntTimeSlot number of time slots
 * @returns {React.Fragment} main jsx for the desktop pdp shipping method selection.
 */
function TimeSlot({
  gotoPrev,
  gotoCalender,
  onSelect,
  shippingMethod,
  shippingMethodId,
  selectedDeliveryDate,
  getPrice,
  currencyCode,
  checkIsInternationalProduct,
  isMultiOrderProduct,
  selectedTimeSlotId,
  selectedCurrency,
  selectedShippingMethodName,
  numberOfIntTimeSlot,
}) {
  const [value, setValue] = useState(null);
  const [internationalTimeSlots, setInternationalTimeSlots] = useState([]);
  const isInternationalEnable = useSelector((state) => state.productDetail?.isInternationalEnable);
  useEffect(() => {
    setValue(selectedTimeSlotId);
  }, [selectedTimeSlotId]);

  useEffect(() => {
    if (isInternationalEnable === "N") {
      const intlTimeSlots = Object?.values(shippingMethod).filter((item) => {
        return item?.shippingMethodName === selectedShippingMethodName;
      });
      setInternationalTimeSlots(intlTimeSlots[0]?.timeSlots);
    }
  }, [isInternationalEnable, selectedShippingMethodName, shippingMethod]);

  /**
   * This method is used to handle panel change
   *
   * @param {object} slot slot info.
   * @returns {void} update state.
   */
  const handleSlotChange = (slot) => {
    setValue(slot?.timeSlotId);
    onSelect(slot);
    // Start Ga event
    setGaTimeSlotSelected(slot.startTime, slot.endTime);
  };

  let timeSlots = [];
  let shippingPrice;
  let shippingMethodName;
  // some time shipping methods are undefine.
  if (shippingMethod && Object.keys(shippingMethod).length > 0) {
    shippingMethodName = shippingMethod?.shippingMethodName;
    timeSlots = shippingMethod?.timeSlots || numberOfIntTimeSlot;
    shippingPrice = shippingMethod?.priceDetails?.price;
    if (checkIsInternationalProduct) {
      if (isInternationalEnable === "N") {
        shippingPrice = shippingMethod?.priceDetails?.price || selectedCurrency;
      }
    }
  }

  return (
    <div className={calenderStyles["calender-wrapper"]}>
      <div className={calenderStyles["delivery-calender"]}>
        <div className={calenderStyles["date-picker-heading"]}>
          <span className={calenderStyles["back-btn"]}>
            <ArrowBackIcon onClick={() => gotoPrev()} data-testid="go-to-prev" />
          </span>
          <span className={calenderStyles["calender-title"]} data-testid="calendar-title">
            Select Time Slot
          </span>
        </div>
        <div className={styles.timeslotContainer}>
          <div className={styles.scrollPane}>
            <ul>
              <li>
                {shippingMethodId === "MIDNIGHT_DELIVERY" && !checkIsInternationalProduct && (
                  <p className={styles.midNightText}>
                    <InfoOutlinedIcon />
                    <span>
                      {`${isMultiOrderProduct ? "These gifts" : "This gift"} will be delivered on Midnight of `}
                      {selectedDeliveryDate.getDate()}
                      <sup>{`${getSuffix(selectedDeliveryDate.getDate())} `}</sup>
                      {selectedDeliveryDate.toLocaleString("default", { month: "short" })}
                    </span>
                  </p>
                )}

                {shippingMethodId === "ETC_DELIVERY" && shippingMethod.priceDetails.price === 0 && (
                  <p className={styles.midNightText}>
                    <InfoOutlinedIcon />
                    <span>{` Your gift will be delivered anytime in between the selected time-slot.`}</span>
                  </p>
                )}

                <span className={styles.timeSlotTitle}>
                  {`${shippingMethodName || selectedShippingMethodName} - `}
                  {shippingPrice === 0 ? (
                    <span className="shipping-price-free">Free</span>
                  ) : (
                    <span>
                      <span className="price-symbol">{currencyCode}</span>
                      {` ${getPrice(shippingPrice)}`}
                    </span>
                  )}
                </span>
                <ul className={styles.slot}>
                  {((isInternationalEnable === "N" && internationalTimeSlots) || timeSlots)?.map((slot, i) => {
                    let startTime = slot.startTime.substring(0, 5);
                    let endTime = slot.endTime.substring(0, 5);
                    if (slot?.isDynamic === "Y" && slot.leadTime) {
                      [startTime, endTime] = calculateNextCycleSlot(30 * 60 * 1000, slot.leadTime);
                    }
                    const slotLabel = `${startTime} - ${endTime} hrs`;
                    return (
                      <li className={styles.timeSlotTable} key={slot?.timeSlotId}>
                        <FormLabel className={styles.timeRadioLabel}>
                          <span className={styles.leftBox}>
                            <Radio
                              checked={value === slot?.timeSlotId}
                              value={slot?.timeSlotId}
                              onClick={() => handleSlotChange({ ...slot, startTime, endTime })}
                              inputProps={{
                                id: "time-slots",
                                "data-testid": `time-slot-${i}`,
                              }}
                            />
                            <span className={styles.timeSlotRange}>{slotLabel}</span>
                          </span>
                        </FormLabel>
                      </li>
                    );
                  })}
                </ul>
              </li>
            </ul>
          </div>
        </div>
        {!checkIsInternationalProduct && (
          <div className="desktop-delivery-footer desktop-shipping-footer">
            <span className="b2c" onClick={() => gotoCalender()} role="presentation" data-testid="go-to-calendar">
              Back to Calendar
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

TimeSlot.propTypes = {
  gotoPrev: PropTypes.func.isRequired,
  gotoCalender: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  shippingMethod: PropTypes.instanceOf(Object).isRequired,
  shippingMethodId: PropTypes.string,
  selectedDeliveryDate: PropTypes.instanceOf(Object),
  getPrice: PropTypes.func.isRequired,
  currencyCode: PropTypes.string.isRequired,
  checkIsInternationalProduct: PropTypes.bool.isRequired,
  isMultiOrderProduct: PropTypes.bool,
  selectedTimeSlotId: PropTypes.number,
  selectedCurrency: PropTypes.string.isRequired,
  selectedShippingMethodName: PropTypes.string.isRequired,
  numberOfIntTimeSlot: PropTypes.number.isRequired,
};

TimeSlot.defaultProps = {
  shippingMethodId: "",
  selectedDeliveryDate: null,
  isMultiOrderProduct: false,
  selectedTimeSlotId: null,
};

export default TimeSlot;
