import { takeLatest, call, put, all } from "redux-saga/effects";
import PDP_CONSTANTS from "../../src/action-constants/pdp-constants";
import {
  setPDPError,
  setProductData,
  populateOffersData,
  populateProductBreadCrumb,
  setDeliveryTimeLeft,
  setProductExpiryInfo,
  setProductReviews,
  updateProductVariant,
  filterVariantInfo,
  setIsEnabledToSetPincodeAndDeliveryLocation,
  setIncludeShippingDetailSwitch,
} from "../../src/actions/pdp-actions";
import {
  fetchproductDetails,
  fetchProductBreadCrumbs,
  getDeliveryTimeLeft,
  getProductExpiryInfo,
  getProductReviews,
} from "../../src/services/api/pdp/pdp-api";
import { fetchContentFromCMSOnClient, getSystemProperty } from "../../src/services/api/common/common-api";
import { setSelectedVariant } from "../../src/actions/pdp-context-actions";

/**
 * This function handles the api call for Populating Bread Crumbs data for Category details and set the value in store
 *
 * @param {object} payload payload
 * @function isDesktop check is isDesktop
 */
function* getProductBreadCrumbData(payload) {
  try {
    const res = yield call(fetchProductBreadCrumbs, payload);
    if (res) {
      yield put(populateProductBreadCrumb(res.data));
    }
  } catch (error) {
    yield put(setPDPError(error.message));
  }
}

/**
 * handle product review by the product id
 *
 * @param {object} payload product reviews
 * @param {object} payload.payload payload
 */
function* handleProductReviews({ payload }) {
  const { productId, domainId } = payload;
  const VIEW_ALL = "view_all";
  const redirectionUrl = `/info/testimonials?domainId=${domainId}&pageType=product&productId=${productId}`;

  try {
    const response = yield call(getProductReviews, productId, domainId);
    if (response?.data) {
      const categoryReviews = [...response.data];
      if (response.data.length >= 12) {
        categoryReviews.push({
          categoryId: VIEW_ALL,
          redirectionUrl,
        });
      }
      yield put(setProductReviews(categoryReviews));
    }
  } catch (error) {
    yield put(setPDPError(error.message));
  }
}

/**
 * handle product Expiry Info
 *
 * @param {object} root0 action
 * @param {object} root0.payload  payload
 */
function* handleProductExpiryInfo({ payload }) {
  const { productId, isDesktop } = payload;
  try {
    const res = yield call(getProductExpiryInfo, productId, isDesktop);
    yield put(setProductExpiryInfo(res));
  } catch (error) {
    yield put(setPDPError(error.message));
  }
}

/**
 * handle delivery time left data api
 *
 * @param {object} param0 action
 * @param {object} param0.payload payload
 */
function* handleDeliveryTimeLeft({ payload }) {
  try {
    const { productId } = payload;
    const params = {
      productId,
    };
    const response = yield call(getDeliveryTimeLeft, params);
    if (response) {
      yield put(setDeliveryTimeLeft(response));
    }
  } catch (error) {
    yield put(setPDPError(error.message));
  }
}

/**
 * handle product details from API
 *
 * @param {string} action - action object
 */
function* handleProductDetails({ payload }) {
  const params = {
    catalogId: payload.catalogId,
    productUrl: payload.noHashPathParam,
    productDataExists: "",
  };
  try {
    const { setResHeaders, ctx, isDesktop, domainId } = payload;
    const response = yield call(fetchproductDetails, setResHeaders, ctx, params, isDesktop);
    if (response.status === 200) {
      if (response?.data?.data) {
        const { productId, productName, variantProducts } = response.data.data;
        let customProductId = productId;
        yield put(
          setProductData({ ...response.data.data, variantProducts: variantProducts?.length ? variantProducts : null }),
        );
        if (variantProducts?.length) {
          const haveVariant = variantProducts[0] instanceof Object;
          if (haveVariant) {
            const selectedVariant =
              variantProducts.find((variant) => {
                const key = Object.keys(variant);
                return variant[key].urlIdentifier === payload.selectedVariantURL;
              }) || variantProducts[0];
            const [title, variantInfo] = Object.entries(selectedVariant)[0];
            if (variantInfo?.productId) customProductId = variantInfo?.productId;
            yield put(setSelectedVariant({ ...variantInfo, title }));
            yield put(updateProductVariant(filterVariantInfo(variantInfo)));
          }
        }

        const promiseList = [call(handleProductExpiryInfo, { payload: { productId: customProductId, isDesktop } })];
        const totalReview = response?.data?.data?.productReviewsAndRatings?.totalReview;

        if (totalReview > 0) {
          promiseList.push(call(handleProductReviews, { payload: { productId, isDesktop, domainId } }));
        }

        promiseList.push(
          call(getProductBreadCrumbData, { productId, productName, isDesktop, catalogId: payload.catalogId }),
        );

        yield all(promiseList);
      } else {
        const ctxRes = ctx.res;
        ctxRes.statusCode = 404;
      }
    } else {
      const ctxRes = ctx.res;
      ctxRes.statusCode = response?.status || response?.errorStatus || 500;
    }
  } catch (error) {
    yield put(setPDPError(error.message));
  }
}

/**
 * @param {object} root0  props passed to the component
 * @param {object} root0.payload payload
 */
function* getPDPOffers({ payload }) {
  try {
    const response = yield call(() => fetchContentFromCMSOnClient(`global/static/web/${payload.cmsContentId}`));
    if (response) {
      yield put(populateOffersData(response));
    }
  } catch (error) {
    yield put(setPDPError(error.message));
  }
}

/**
 * Generator function to handle the retrieval of information related to pincode
 * and delivery location settings.
 * @yields {object} call getSystemPropertyMsitePinCodeAndDeliveryArea API
 * @param {object} action isMobile check
 * @throws {Error} Throws an error if there's an issue while fetching or updating
 * pincode and delivery location settings.
 */
function* handlePincodeAndDeliveryLocation() {
  try {
    const contentUrl = "global/static/web/ispincodearealocalitymergeenabled";
    const response = yield call(() => fetchContentFromCMSOnClient(contentUrl));
    if (response) {
      yield put(setIsEnabledToSetPincodeAndDeliveryLocation(response));
    }
  } catch (error) {
    yield put(setPDPError(error.message));
  }
}
/**
 * Generator function to handle the retrieval of information related to out of stock redirection
 *
 * @yields {object} call out_of_stock_redirection API
 * @throws {Error} Throws an error if there's an issue while fetching or updating
 * the reponse.
 */
function* handleOutOfStockRedirectionUrl() {
  try {
    const contentUrl = "global/static/web/out_of_stock_redirection";
    const response = yield call(() => fetchContentFromCMSOnClient(contentUrl));
    if (response) {
      yield put({ type: PDP_CONSTANTS.SET_OUT_OF_STOCK_REDIRECTION_URL, payload: response });
    }
  } catch (error) {
    yield put(setPDPError(error.message));
  }
}

/**
 * Generator function responsible for handling the inclusion of shipping details switch.
 * It retrieves the Include Shipping Detail value, and then dispatches an action to update the state accordingly.
 *
 * @generator
 * @function handleIncludeShippingDetailSwitch
 */
function* handleIncludeShippingDetailSwitch() {
  const params = { resource: "fnp", name: "includeShippingDetails" };
  try {
    const res = yield call(() => getSystemProperty(params));
    if (res) {
      yield put(setIncludeShippingDetailSwitch(res.value));
    }
  } catch (error) {
    yield put(setPDPError(error.message));
  }
}

/**
 * Watcher saga for PDP
 */
function* watchPDPSaga() {
  yield takeLatest(PDP_CONSTANTS.SERVER_REQUEST_PRODUCT_DETAILS, handleProductDetails);
  yield takeLatest(PDP_CONSTANTS.GET_PDP_OFFERS, getPDPOffers);
  yield takeLatest(PDP_CONSTANTS.UPDATE_SET_REVIEWS, handleProductReviews);
  yield takeLatest(PDP_CONSTANTS.FETCH_PRODUCT_EXPIRY_INFO, handleProductExpiryInfo);
  yield takeLatest(PDP_CONSTANTS.GET_DELIVERY_TIME_LEFT, handleDeliveryTimeLeft);
  yield takeLatest(PDP_CONSTANTS.GET_PINCODE_AND_DELIVERY_LOCATION_ENABLE, handlePincodeAndDeliveryLocation);
  yield takeLatest(PDP_CONSTANTS.GET_INCLUDE_SHIPPING_DETAILS, handleIncludeShippingDetailSwitch);
  yield takeLatest(PDP_CONSTANTS.GET_OUT_OF_STOCK_REDIRECTION_URL, handleOutOfStockRedirectionUrl);
}

export default watchPDPSaga;
