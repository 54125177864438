import { HYDRATE } from "next-redux-wrapper";

import MenuPageConstants from "../../src/action-constants/menu-constants";

const initialState = {
  mobile: {
    menu: "",
    bannerLink: "",
    bannerImage: "",
    mediaEmail: "",
  },
  desktopMegaMenuData: "",
  errorMessages: {
    mobileLeftMenuError: "",
    desktopMegaMenuError: "",
  },
};

/**
 * This method is the reducer for actions for menu data
 *
 * @param {object} state - contains initial redux state
 * @param {object} action - contains actionTypes and menu data information for specific action
 * @returns {object} state - the next state which needs to be dispatched to redux
 */
const menuDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.menuData,
      };
    case MenuPageConstants.SERVER_SET_MOBILE_LEFT_MENU_DATA:
      return {
        ...state,
        mobile: {
          ...state.mobile,
          menu: action.payload.menu,
          bannerImage: action.payload.bannerImage,
          bannerLink: action.payload.bannerLink,
          mediaEmail: action.payload.mediaEmail,
        },
      };
    case MenuPageConstants.SERVER_REQUEST_MOBILE_LEFT_MENU_DATA_FAIL:
      return {
        ...state,
        errorMessages: {
          ...state.errorMessages,
          mobileLeftMenuError: action.payload,
        },
      };
    case MenuPageConstants.SERVER_SET_DESKTOP_MEGA_MENU_DATA:
      return {
        ...state,
        desktopMegaMenuData: action.payload.menu,
      };
    case MenuPageConstants.SERVER_REQUEST_DESKTOP_MEGA_MENU_DATA_FAIL:
      return {
        ...state,
        errorMessages: {
          ...state.errorMessages,
          desktopMegaMenuError: action.payload,
        },
      };
    default:
      return state;
  }
};

export default menuDataReducer;
