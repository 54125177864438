import React from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { dateToDMY, getDates } from "../../src/utils/common";
import RedirectToCatalog from "./redirectToCatalog/RedirectToCatalog";
import LimitedCatalog from "./limitedCatalog/limitedtCatalog";

/**
 * This functional component represents the catalog redirection mesg and link.
 *
 * @param {object} props - The object containing message info.
 * @param {boolean} props.blockedDates - list of block dates
 * @param {boolean} props.displayCatalogContent - true if we show limited catalog.
 * @param {boolean} props.cutoffTimestamp - true if cutoftime is not pass.
 * @param {boolean} props.isCalender - is calender.
 * @param {object} props.deliveryDatesList - deliveryDatesList array.
 * @param {object} props.samedayMsgContent - samedayMsgContent object.
 * @param {object} props.limitedCatalogContent - limitedCatalogContent object.
 * @param {string} props.pincode - user selected pincode.
 * @returns {React.Fragment} The main JSX for the desktop delivery input.
 */
const RedirectionToPLPComponent = ({
  blockedDates,
  displayCatalogContent,
  cutoffTimestamp,
  isCalender,
  deliveryDatesList,
  samedayMsgContent,
  limitedCatalogContent,
  pincode,
}) => {
  const { isMobile } = useSelector((state) => state.appConfigs);

  const isLimitedCatalog = isEmpty(limitedCatalogContent);
  const isRedirectToCatalog = !getDates(blockedDates) || !displayCatalogContent || isLimitedCatalog;

  return (
    <>
      {isRedirectToCatalog ? (
        deliveryDatesList.length > 0 &&
        deliveryDatesList.indexOf(dateToDMY(new Date())) === -1 &&
        cutoffTimestamp && (
          <RedirectToCatalog
            message={samedayMsgContent?.calenderFooterMsg}
            href={`${samedayMsgContent?.calenderFooterUrl}&pincode=${pincode}`}
            linkText={samedayMsgContent?.calenderFooterAnchortext}
            isCalender={isCalender}
            isMobile={isMobile}
          />
        )
      ) : (
        <LimitedCatalog
          message={limitedCatalogContent?.webmessage}
          href={`${limitedCatalogContent?.link}?promo=${limitedCatalogContent?.promo}`}
          isCalender={isCalender}
          isMobile={isMobile}
          image={limitedCatalogContent?.image}
          backgroundColor={limitedCatalogContent?.gradientColor}
          textColor={limitedCatalogContent?.textColor}
        />
      )}
    </>
  );
};

RedirectionToPLPComponent.propTypes = {
  blockedDates: PropTypes.arrayOf(PropTypes.string).isRequired,
  displayCatalogContent: PropTypes.bool.isRequired,
  cutoffTimestamp: PropTypes.bool.isRequired,
  deliveryDatesList: PropTypes.arrayOf(PropTypes.string).isRequired,
  isCalender: PropTypes.bool.isRequired,
  samedayMsgContent: PropTypes.objectOf(PropTypes.any),
  limitedCatalogContent: PropTypes.objectOf(PropTypes.any),
  pincode: PropTypes.string,
};
RedirectionToPLPComponent.defaultProps = {
  pincode: "",
  samedayMsgContent: {},
  limitedCatalogContent: {},
};
export default RedirectionToPLPComponent;
