const PdpPageConstants = {
  FETCH_PRODUCT_TRANSITION_SHOW: "FETCH_PRODUCT_TRANSITION_SHOW",
  FETCH_PRODUCT_TRANSITION_HIDE: "FETCH_PRODUCT_TRANSITION_HIDE",
  SET_PRODUCT: "SET_PRODUCT",
  FETCH_PRODUCT_ERROR: "FETCH_PRODUCT_ERROR",
  SET_PRODUCT_BREADCRUMBS: "SET_PRODUCT_BREADCRUMBS",
  SET_OFFERS: "SET_OFFERS",
  SET_DELIVERY_TIME_LEFT: "SET_DELIVERY_TIME_LEFT",
  GET_DELIVERY_TIME_LEFT: "GET_DELIVERY_TIME_LEFT",
  SET_PRODUCT_EXPIRY_INFO: "SET_PRODUCT_EXPIRY_INFO",
  FETCH_PRODUCT_EXPIRY_INFO: "FETCH_PRODUCT_EXPIRY_INFO",
  SET_PINCODES: "SET_PINCODES",
  SET_DATES_AND_SHIPPING_METHODS: "SET_DATES_AND_SHIPPING_METHODS",
  SET_SHIPPING_DETAILS: "SET_SHIPPING_DETAILS",
  SHIPPING_FETCH_START: "SHIPPING_FETCH_START",
  SET_BLOCKED_DATES: "SET_BLOCKED_DATES",
  ERROR_SET_BLOCKED_DATES: "ERROR_SET_BLOCKED_DATES",
  SET_PRICE_SURGE: "SET_PRICE_SURGE",
  SET_YOU_MAY_ALSO_LIKE_PRODUCTS: "SET_YOU_MAY_ALSO_LIKE_PRODUCTS",
  SET_WOAV_DATA: "SET_WOAV_DATA",
  SET_RECENTLY_VIEWED_PRODUCTS: "SET_RECENTLY_VIEWED_PRODUCTS",
  SET_REVIEWS: "SET_REVIEWS",
  UPDATE_SET_REVIEWS: "UPDATE_SET_REVIEWS",
  CLEAR_PINCODES: "CLEAR_PINCODES",
  UPDATE_PRODUCT_VARIANT: "UPDATE_PRODUCT_VARIANT",
  SET_ADDONS: "SET_ADDONS",
  SET_PERSONALIZED_IMAGE: "SET_PERSONALIZED_IMAGE",
  SET_SAME_SKU_CONTENT: "SET_SAME_SKU_CONTENT",
  SET_EARLIEST_DELIVERY_DATES: "SET_EARLIEST_DELIVERY_DATES",
  FETCH_PRICE_DETAILS_BY_DATE_START: "FETCH_PRICE_DETAILS_BY_DATE_START",
  SET_PRICE_DETAILS_BY_DATE: "SET_PRICE_DETAILS_BY_DATE",
  SET_PRICE_DETAILS_BY_PINCODE: "SET_PRICE_DETAILS_BY_PINCODE",
  FETCH_PRICE_DETAILS_BY_DATE_ERROR: "FETCH_PRICE_DETAILS_BY_DATE_ERROR",
  SET_PDP_CURRENCY: "SET_PDP_CURRENCY",
  SET_PRODUCT_NOT_FOUND: "SET_PRODUCT_NOT_FOUND",
  SET_INTERNATIONAL_CITIES: "SET_INTERNATIONAL_CITIES",
  SET_INTERNATIONAL_SHIPPING_DETAILS: "SET_INTERNATIONAL_SHIPPING_DETAILS",
  SET_INTERNATIONAL_DELIVERY_DATES: "SET_INTERNATIONAL_DELIVERY_DATES",
  SET_PRODUCT_PRICE: "SET_PRODUCT_PRICE",
  SET_PDP_USER_SELECTIONS: "SET_PDP_USER_SELECTIONS",
  SET_SELECTED_TYPE: "SET_SELECTED_TYPE",
  SET_SELECTED_FLAVOUR: "SET_SELECTED_FLAVOUR",
  SET_FLAVOURS: "SET_FLAVOURS",
  SET_SELECTED_VARIANT: "SET_SELECTED_VARIANT",
  SET_USER_PERSONALIZATIONS: "SET_USER_PERSONALIZATIONS",
  SET_VARIANT_LIST: "SET_VARIANT_LIST",
  SET_QUANTITY_OR_TYPE_LIST: "SET_QUANTITY_OR_TYPE_LIST",
  SET_DELIVERY_TYPE: "SET_DELIVERY_TYPE",
  SET_IMAGE_CONFIGS: "SET_IMAGE_CONFIGS",
  SET_CATALOG_LOCALITIES: "SET_CATALOG_LOCALITIES",
  SET_CATALOG_DELIVERY_DATES: "SET_CATALOG_DELIVERY_DATES",
  SHIPPING_DETAILS_FETCH_START: "SHIPPING_DETAILS_FETCH_START",
  SHIPPING_DETAILS_FETCH_ERROR: "SHIPPING_DETAILS_FETCH_ERROR",
  SET_UPLOAD_PROGRESS_BAR: "SET_UPLOAD_PROGRESS_BAR",
  SERVER_REQUEST_PRODUCT_DETAILS: "SERVER_REQUEST_PRODUCT_DETAILS",
  FETCH_CONTENT_ID: "FETCH_CONTENT_ID",
  PDP_ERROR_MSG: "PDP_ERROR_MSG",
  EGGLESS: "EGGLESS",
  SET_MULTI_IMAGE_UPLOAD_COMPLETED: "SET_MULTI_IMAGE_UPLOAD_COMPLETED",
  SET_IS_PRODUCT_NOT_DELIVERABLE: "SET_IS_PRODUCT_NOT_DELIVERABLE",
  INIT_TIME_SLOT: "INIT_TIME_SLOT",
  INIT_TIME_SLOT_COMPLETION: "INIT_TIME_SLOT_COMPLETION",
  INIT_ADD_TO_CART: "INIT_ADD_TO_CART",
  INIT_ADD_TO_CART_COMPLETION: "INIT_ADD_TO_CART_COMPLETION",
  SET_IS_INTERNATIONAL_ENABLE: "SET_IS_INTERNATIONAL_ENABLE",
  SET_ERROR_IN_IS_INTERNATIONAL_ENABLE: "SET_ERROR_IN_IS_INTERNATIONAL_ENABLE",
  SET_USER_SELECTED_DELIVERY: "SET_USER_SELECTED_DELIVERY",
  GET_PINCODE_AND_DELIVERY_LOCATION_ENABLE: "GET_PINCODE_AND_DELIVERY_LOCATION_ENABLE",
  SET_PINCODE_AND_DELIVERY_LOCATION_ENABLE: "SET_PINCODE_AND_DELIVERY_LOCATION_ENABLE",
  GET_INCLUDE_SHIPPING_DETAILS: "GET_INCLUDE_SHIPPING_DETAILS",
  SET_INCLUDE_SHIPPING_DETAILS: "SET_INCLUDE_SHIPPING_DETAILS",
  GET_PDP_OFFERS: "GET_PDP_OFFERS",
  DELIVERY_DATES_FETCH_START: "DELIVERY_DATES_FETCH_START",
  DELIVERY_DATES_FETCH_FAILED: "DELIVERY_DATES_FETCH_FAILED",
  GET_OUT_OF_STOCK_REDIRECTION_URL: "GET_OUT_OF_STOCK_REDIRECTION_URL",
  SET_OUT_OF_STOCK_REDIRECTION_URL: "SET_OUT_OF_STOCK_REDIRECTION_URL",
};

export const WEEKDAYS_SHORT = ["S", "M", "T", "W", "T", "F", "S"];
export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const CAKETYPES = ["WITH EGG", "EGGLESS"];
export default PdpPageConstants;
