import { call, put, takeEvery } from "redux-saga/effects";
import APP_CONSTANTS from "../../src/action-constants/app-actions";
import {
  errorInMicroSitePage,
  setMicroSitePageData,
  setTestimonialData,
  errorTestimonial,
} from "../../src/actions/microSite-actions";
import { fetchContentFromSVN, fetchTestimonialsContent } from "../../src/services/api/micro-site/svn-api";

/**
 * This is a worker saga function that calls the get content for svn and sets the data in reducer
 *
 * @param {object} action - contains the response payload information of svn content
 */
function* getSVNData(action) {
  try {
    const res = yield call(fetchContentFromSVN, action.payload);
    if (res.status === 404) {
      const ctxRes = action.payload.ctx.res;
      ctxRes.statusCode = 404;
    } else if (res.status === 503) {
      const ctxRes = action.payload.ctx.res;
      ctxRes.statusCode = 503;
    } else if (res.error) {
      yield put(errorInMicroSitePage(res.error));
    } else {
      yield put(setMicroSitePageData(res));
    }
  } catch (error) {
    yield put(errorInMicroSitePage(error.message));
  }
}

/**
 * This is a worker saga function that calls the get content for svn and sets the data in reducer
 *
 * @param {object} action - contains the response payload information of svn content
 */
function* getTestimonialData(action) {
  try {
    const res = yield call(fetchTestimonialsContent, action.payload);
    if (res.error) {
      yield put(errorTestimonial(res.error));
    } else {
      yield put(setTestimonialData(res.testimonials));
    }
  } catch (error) {
    yield put(errorTestimonial(error.message));
  }
}

/**
 * Watcher saga for SVN pages
 */
function* watchSVNPageDataSaga() {
  yield takeEvery(APP_CONSTANTS.REQUEST_MICRO_PAGE_BODY, getSVNData);
  yield takeEvery(APP_CONSTANTS.REQUEST_TESTIMONIAL_DATA, getTestimonialData);
}

export default watchSVNPageDataSaga;
