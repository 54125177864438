import React from "react";
import { AppBar, Grid, makeStyles, Toolbar } from "@material-ui/core";
import PropTypes from "prop-types";

import BackArrowIconBtn from "../back-arrow-icon-btn";
import LeftMenuIconBtn from "../left-menu-icon-btn";
import FNPLogo from "../fnp-logo";
import SearchIconBtn from "../search-icon-btn";
// import GiftFinderIconBtn from "../gift-finder-icon-btn";
import CartIconBtn from "../cart-icon-btn";
import KebabMenuIconBtn from "../kebab-menu-icon-btn";

import { RoutePageConstants } from "../../../../../src/utils/common";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "48px",
  },
  appBarRoot: {
    "&.MuiPaper-root": {
      color: theme.color?.white,
      backgroundColor: theme.color?.olive,
      zIndex: "3",
    },
  },
  toolbarRoot: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: "44px",
    "& svg": {
      height: "24px",
      width: "24px",
    },
  },
}));

/**
 * This component is used to show header without title in the m-site
 *
 * @param {object} root0 props from parent
 * @param {string} root0.pageName current page name
 * @param {Function} root0.handleBack whether to use a custom back button on click handler
 * @param {Function} root0.onBackPress on click handler to handle back press
 * @param {Function} root0.openLeftMenu on click handler to open left menu
 * @param {Function} root0.openSearch on click handler to open search bar
 * @param {Function} root0.openRightMenu on click handler to open right menu
 * @param {string} root0.catalogId current catalog id
 * @param {boolean} root0.isInternationalHomepage whether the page is a international home page
 *
 * @returns {React.ReactElement} - renders header-without-title component
 */
const HeaderWithoutTitle = ({
  pageName,
  handleBack,
  onBackPress,
  openLeftMenu,
  openSearch,
  openRightMenu,
  catalogId,
  isInternationalHomepage,
}) => {
  const classes = useStyles();

  let showBackArrow = false;
  let showLeftMenu = true;
  let showSearch = true;
  // let showGiftFinder = true;
  let showCountryFlag = false;
  let isNotFoundPage = false;

  if (
    [RoutePageConstants.MICROSITE, RoutePageConstants.TESTIMONIAL, RoutePageConstants.EXPERIENCES_PAGE].includes(
      pageName,
    )
  ) {
    showSearch = false;
  } else if (pageName === RoutePageConstants.PLP_PAGE) {
    if (catalogId !== "india" && isInternationalHomepage) {
      showCountryFlag = true;
    }
  } else if (pageName === RoutePageConstants.PDP_PAGE) {
    showBackArrow = true;
    showLeftMenu = false;
  } else if (pageName === RoutePageConstants.NOT_FOUND || pageName === RoutePageConstants.SERVER_ERROR) {
    showLeftMenu = false;
    showSearch = false;
    // showGiftFinder = false;
    isNotFoundPage = true;
  }

  return (
    <div className={classes.root}>
      <AppBar elevation={0} position="fixed" className={classes.appBarRoot}>
        <Toolbar disableGutters className={classes.toolbarRoot}>
          <Grid>
            {showBackArrow && <BackArrowIconBtn handleBack={handleBack} onBackPress={onBackPress} />}
            {showLeftMenu && <LeftMenuIconBtn openLeftMenu={openLeftMenu} />}
            <FNPLogo isNotFoundPage={isNotFoundPage} catalogId={catalogId} showCountryFlag={showCountryFlag} />
          </Grid>
          <Grid>
            {showSearch && <SearchIconBtn openSearch={openSearch} />}
            {/* For PDP */}
            {/* {showGiftFinder && <GiftFinderIconBtn currentCatalogId={catalogId} />} */}
            <CartIconBtn />
            <KebabMenuIconBtn openRightMenu={openRightMenu} />
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

HeaderWithoutTitle.propTypes = {
  pageName: PropTypes.string,
  handleBack: PropTypes.bool,
  onBackPress: PropTypes.func,
  openLeftMenu: PropTypes.func,
  openSearch: PropTypes.func,
  openRightMenu: PropTypes.func,
  catalogId: PropTypes.string,
  isInternationalHomepage: PropTypes.bool,
};

HeaderWithoutTitle.defaultProps = {
  pageName: "",
  handleBack: false,
  onBackPress: () => {},
  openLeftMenu: () => {},
  openSearch: () => {},
  openRightMenu: () => {},
  catalogId: "india",
  isInternationalHomepage: false,
};

export default HeaderWithoutTitle;
