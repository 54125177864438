import { setCookie } from "nookies";
import { put, takeLatest, call } from "redux-saga/effects";
import { LocationLockStaticConstants } from "../../components/common/constants";
import AppConstants from "../../src/action-constants/app-actions";
import { checkLoginCartInfo as checkUserAuthState } from "../../src/actions/common-actions";
import { setFinalSelectedPinCode } from "../../src/actions/location-lock-actions";

/**
 * This method is yielding fus api response in saga that is retrieving cartinfo and loginInfo
 *
 * @param {object} action - contains the response payload information of cartInfo and loginInfo
 * after calling fus api
 */
export function* checkLoginCartInfo(action) {
  yield put({
    type: AppConstants.CHECK_LOGIN_CART_INFO,
    payload: { cartInfo: action.payload.cartInfo, checkLoginInfo: action.payload.checkLoginInfo },
  });
}

/**
 * Function to initiate fus request
 */
export function* initiateFus() {
  try {
    const response = yield call(checkUserAuthState);
    yield put({
      type: AppConstants.CHECK_LOGIN_CART_INFO,
      payload: {
        cartInfo: response?.data?.cart || { cartTotalQuantity: 0, isSuccess: false },
        checkLoginInfo: response?.data?.checklogin || { loggedin: false, isOAuthUser: false },
        cartId: response?.data?.fvId || "",
        pincodeGivenByUser: response?.data?.pincodeGivenByUser,
      },
    });
    yield put({
      type: AppConstants.FUS_SUCCESS,
      payload: true,
    });

    if (response?.data?.pincodeGivenByUser) {
      const ctx = {};
      const options = {
        maxAge: 60 * 24 * 60 * 30,
        path: "/",
        encode: decodeURIComponent,
      };
      const userPincode = response?.data?.pincodeGivenByUser?.pincode;
      const userAddress = response?.data?.pincodeGivenByUser?.customerAddress;
      yield setCookie(ctx, LocationLockStaticConstants.LAST_SELECTED_PINCODE, userPincode, options);
      yield setCookie(ctx, LocationLockStaticConstants.LAST_SELECTED_ADDRESS, userAddress, options);
      yield put(setFinalSelectedPinCode(userPincode));
      if (window.checkPincodeSelectedOnLoad) {
        window.checkPincodeSelectedOnLoad();
      }
    }

    window.userDetails = response.data || {};
  } catch (error) {
    yield put({
      type: AppConstants.FUS_FAIL,
    });
  }
}

/**
 * This method is used to yield success response of CHECK_LOGIN_CART_INFO action
 *
 */
function* watchFusRequest() {
  yield takeLatest(AppConstants.CHECK_LOGIN_CART_INFO_SUCCESS, checkLoginCartInfo);
  yield takeLatest(AppConstants.INITIATE_FUS, initiateFus);
}

export default watchFusRequest;
