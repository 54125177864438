import { call, put, takeEvery, select } from "redux-saga/effects";
import fetchCurrencies from "../../src/actions/common-actions";
import APP_CONSTANTS from "../../src/action-constants/app-actions";
import getProductListForAddOns from "../../src/actions/addOnActions";

/**
 * dispatches the action with payload data for reducer
 *
 * @param {object} action is the action object received from parent component
 */
export function* getAddOnProducts(action) {
  try {
    const addOnData = yield call(() => getProductListForAddOns(action.payload));
    const currencies = yield select((store) => store.currenciesList.currencies);
    if (!currencies.length) {
      const resp = yield call(fetchCurrencies);
      yield put({ type: APP_CONSTANTS.GET_CURRENCIES_LIST, payload: resp.data.currencies });
    }
    yield put({ type: APP_CONSTANTS.GET_ADD_ON_PRODUCTS_SUCCESS, payload: addOnData });
    yield put({ type: APP_CONSTANTS.ALL_ORGANIZED_ADDONS_LIST, payload: addOnData });
  } catch (error) {
    yield put({ type: APP_CONSTANTS.GET_ADD_ON_PRODUCTS_ERROR, payload: error });
  }
}

/**
 * saga for fetching add on products
 *
 */
function* watchAddOnProducts() {
  yield takeEvery(APP_CONSTANTS.GET_ADD_ON_PRODUCTS_REQUEST, getAddOnProducts);
}
export default watchAddOnProducts;
