import get from "lodash/get";
import getConfig from "next/config";
import axios from "../../../../app_configs/axios/base";
import errorLogger from "../../../../app_configs/logger-service";
import availablePinCodes from "../../../helpers/available-pincodes";
import { fetchContentFromCMSOnClient } from "../common/common-api";

const { publicRuntimeConfig } = getConfig();
const SHOW_REQUESTS_LOGS = publicRuntimeConfig.SHOW_REQUESTS_LOGS || "Y";
const error = {
  config: {},
  response: {},
};
/**
 * This function gets the country list
 *
 * @returns {object} object of country list
 */
export async function getCountryList() {
  try {
    return await fetchContentFromCMSOnClient("global/static/country/supportedcityandcountry");
  } catch (ex) {
    error.config.url = "global/static/country/supportedcityandcountry";
    error.response.status = `An exception occurred while fetching country list => ${ex.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return "";
  }
}

/**
 * THis function returns the pin code list as per the input value
 *
 * @param {string} code string of input value entered
 * @returns {Array} array of pin code list
 */
export async function getPinCodeList(code) {
  if (availablePinCodes.indexOf(code) > -1) {
    try {
      const res = await axios.get(`assets/js/pincodes/${code}.json`);
      return get(res, "data", "");
    } catch (ex) {
      error.config.url = `assets/js/pincodes/${code}.json`;
      error.response.status = `An exception occurred while fetching pinCode list list => ${ex.code}`;
      errorLogger(error, SHOW_REQUESTS_LOGS);
      throw new Error(ex);
    }
  } else {
    return [];
  }
}

/**
 * This function maps the pin code as per the value
 *
 * @returns {*} .
 */
export async function getPinCode() {
  try {
    const res = await axios.get("control/jc?id=PINCODE_MAPPING");
    return get(res, "data", "");
  } catch (ex) {
    error.config.url = "control/jc?id=PINCODE_MAPPING";
    error.response.status = `An exception occurred while fetching pincode mapping list => ${ex.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    throw new Error(ex);
  }
}

/**
 * This function gets the delivery options as pet the pin code
 *
 * @param {string} pincode pin code entered
 * @returns {object}  object of delivery data
 */
export async function getDeliveryAreasAsKey(pincode) {
  try {
    const res = await axios.get("control/getDeliveryAreasAsKey", {
      params: {
        pincode,
      },
    });
    return get(res, "data", "");
  } catch (ex) {
    error.config.url = "control/getDeliveryAreasAsKey";
    error.response.status = `An exception occurred while fetching Delivery area as key => ${ex.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    throw new Error(ex);
  }
}

/**
 * This function returns the pin code and place details as per the latitude and longitude
 *
 * @param {object} root .
 * @param {number} root.lat latitude
 * @param {number} root.lng longitude
 * @returns {object} object of data as per the latitude and longitude
 */
export async function getPinCodeByLocation({ lat, lng }) {
  try {
    const res = await axios.get(`control/getPinCodeByPostalAddress?latitude=${lat}&longitude=${lng}`);
    return get(res, "data", "");
  } catch (ex) {
    error.config.url = `control/getPinCodeByPostalAddress?latitude=${lat}&longitude=${lng}`;
    error.response.status = `An exception occurred while fetching pin code by place => ${ex.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    throw new Error(ex);
  }
}
