import ListingPageConstants from "../action-constants/listing-contants";
import APP_CONSTANTS from "../action-constants/app-actions";

/**
 * Action for requesting PLP reviews
 *
 * @param {string} payload contains category ID
 * @returns {object} returns action
 */
export const requestPLPReviews = (payload) => ({
  type: ListingPageConstants.SERVER_REQUEST_PLP_REVIEWS,
  payload,
});

/**
 * Action for setting PLP reviews
 *
 * @param {string} payload contains review data
 * @returns {object} returns action
 */
export const setPLPReviews = (payload) => ({
  type: ListingPageConstants.SET_CATEGORY_REVIEWS,
  payload,
});

/**
 * Action for requesting product list  data
 *
 * @param {object} payload contains content options
 * @returns {object} returns action
 */
export const requestPlpListing = (payload) => ({
  type: ListingPageConstants.GET_PRODUCT_LISTING_SERVER_REQUEST,
  payload,
});

/**
 * actions for setting the svn data in reducer
 *
 * @param {object} payload contains svn data options
 * @returns {object} returns action
 */
export const setPlpSvnData = (payload) => ({
  type: ListingPageConstants.SET_SVN_MICROSITE_DATA,
  payload,
});

/**
 * Action for plp error msg
 *
 * @param {object} error contains error msg
 * @returns {object} returns action
 */
export const plpErrorMSG = (error) => ({
  type: ListingPageConstants.PRODUCT_LISTING_ERROR_MSG,
  payload: error,
});

/**
 * Action for plp related omniture details
 *
 * @param {object} payload contains contentID
 * @returns {object} returns action
 */
export const requestOmnitureDetails = (payload) => ({
  type: ListingPageConstants.GET_OMNITURE_DETAILS,
  payload,
});
/**
 * Action for requesting banner category data
 *
 * @param {object} payload contains contentId
 * @returns {object} returns action
 */
export const requestBannerCategory = (payload) => ({
  type: ListingPageConstants.GET_BANNER_CATEGORY,
  payload,
});

/**
 * Action for requesting product list  data
 *
 * @param {object} payload contains content options
 * @returns {object} returns action
 */
export const requestPlpListingOnClient = (payload) => ({
  type: ListingPageConstants.REQUEST_GET_PRODUCT_LISTING,
  payload,
});

/**
 * Action for requesting to set product list loading
 *
 * @param {boolean} isLoading true/false of loading
 * @returns {object} returns action
 */
export const setPlpListingLoading = (isLoading) => ({
  type: ListingPageConstants.SET_PRODUCT_LISTING_LOADING,
  payload: isLoading,
});

/**
 * Action for setting plp related omniture details
 *
 * @param {object} omnitureTagDetails contains contentID
 * @returns {object} returns action
 */
export const setOmnitureDetails = (omnitureTagDetails) => ({
  type: ListingPageConstants.SET_OMNITURE_DETAILS,
  payload: omnitureTagDetails,
});

/**
 * Action for setting banner category data
 *
 * @param {object} payload contains banner category data
 * @returns {object} returns action
 */
export const setBannerCategory = (payload) => ({
  type: ListingPageConstants.SET_BANNER_DETAILS,
  payload,
});

/**
 * Action for setting product list data
 *
 * @param {object} payload contains product list data
 * @returns {object} returns action
 */
export const setProductList = (payload) => ({
  type: ListingPageConstants.SET_PRODUCTS_LISTING,
  payload,
});

/**
 * Populating Bread Crumbs data for Category to the store for showing on Desktop PLP Page
 *
 * @param {object} payload contains content options
 * @returns {object} returns action
 */
export const setBreadCrumbsData = (payload) => ({
  type: ListingPageConstants.SET_BREAD_CRUMBS_DATA,
  payload,
});

/**
 * Action for setting SVN Bread Crumbs data
 *
 * @param {object} payload contains content options
 * @returns {object} returns action
 */
export const setPlpSvnBreadCrumbs = (payload) => ({
  type: ListingPageConstants.SET_PLP_SVN_BREAD_CRUMBS,
  payload,
});

/**
 * Action for setting page name.
 *
 * @param {string} payload contains page name to update.
 * @returns {object} returns data to set page name.
 */
export const setPageName = (payload) => ({
  type: APP_CONSTANTS.SET_PAGE_NAME,
  payload,
});

/**
 * Action for starting sorting process
 *
 * @returns {object} returns action
 */
export const startProductListSorting = () => ({
  type: ListingPageConstants.SORTING_PRODUCTS_LIST,
});

/** Action of returning the dispatcher object for fetching the delivery dates.
 *
 * @param {payload} payload which contains pincode
 * @returns {object} contains dispatcher data for delivery dates.
 */
export const requestEarliestDeliveries = (payload) => ({
  type: ListingPageConstants.FETCH_EARLIEST_DELIVERIES,
  payload,
});

/**
 * Action of returning the dispatcher object for updating the product list with
 * earliest dates.
 *
 * @param {object} daysList list of products with earliest delivery days.
 * @param {object} dateList list of products with earliest delivery dates.
 * @returns {object} contains dispatcher data for update products.
 */
export const updateEarliestDeliveries = (daysList, dateList) => ({
  type: ListingPageConstants.DELIVERY_DATES_POPULATED,
  payload: {
    earliestDeliveryDays: daysList,
    earliestDeliveryDates: dateList,
  },
});

/**
 * Action for setting page name.
 *
 * @param {string} payload set content id details.
 * @returns {object} returns get content id details.
 */
export const setContentIdDetails = (payload) => ({
  type: ListingPageConstants.SET_SEARCH_INSTEAD_DATA,
  payload,
});

/**
 * Action for requesting and setting filter data
 *
 * @param {string} payload content id for the black strip filter
 * @returns {object} filter request action object
 */
export const requestFilterData = (payload) => ({
  type: APP_CONSTANTS.GET_FILTER_DATA_REQUEST,
  payload,
});

/**
 * Action for setting filer data success
 *
 * @param {string} payload response.
 * @returns {object} filter data success object
 */
export const setFilterSuccess = (payload) => ({
  type: APP_CONSTANTS.GET_FILTER_DATA_SUCCESS,
  payload,
});

/**
 * Action for requesting and setting usp data
 *
 * @param {string} payload response.
 * @returns {object} usp data request action object
 */
export const requestUspData = (payload) => ({
  type: APP_CONSTANTS.GET_USP_DATA_REQUEST,
  payload,
});

/**
 * Action for setting usp data success
 *
 * @param {string} payload response.
 * @returns {object} usp data success object
 */
export const setUspSuccess = (payload) => ({
  type: APP_CONSTANTS.GET_USP_DATA_SUCCESS,
  payload,
});

/**
 * Action for requesting product summary
 *
 * @param {Array} allProductIds  array of product id
 * @returns {object} returns object of action to be dispatched.
 */
export const requestProductSummaryDetails = (allProductIds) => ({
  type: ListingPageConstants.PRODUCT_DESC_REQUEST,
  payload: allProductIds,
});

/**
 * Action for setting product summary in reducer
 *
 * @param {object} data  data for product summary
 * @returns {object} returns object of action to be dispatched.
 */
export const setProductSummaryDetails = (data) => ({
  type: ListingPageConstants.PRODUCT_DESC_SUCCESS,
  payload: data,
});

/**
 * Action for clearing product listing reducer
 *
 * @returns {object} returns object of action to be dispatched.
 */
export const resetProductListing = () => ({
  type: ListingPageConstants.CLEAR_PRODUCTS_LISTING,
});

/**
 * Action to get isEnableToShowAddToCartPlp flag
 *
 * @param {boolean} isMobile is mobile flag
 * @returns {object} returns object of action to be dispatched.
 */
export const requestIsEnableToShowAddToCartPlp = (isMobile) => ({
  type: ListingPageConstants.FETCH_ENABLE_ADD_TO_CART,
  payload: isMobile,
});

/**
 * Action to set isEnableToShowAddToCartPlp flag
 *
 * @param {object} payload payload
 * @returns {object} returns object of action to be dispatched.
 */
export const setIsEnableToShowAddToCartPlp = (payload) => ({
  type: ListingPageConstants.SET_ENABLE_ADD_TO_CART,
  payload,
});
