/**
 * @function convertDateFormat this function will convert dateString to a specified format
 *
 * @param {string }dateString this is date string that is to be formatted
 * @returns {string} it will return formatted string of date
 */
export function convertDateFormat(dateString) {
  // Parse the input date string
  const formattedDate = new Date(dateString);

  // Get the day, month, and year
  const day = String(formattedDate.getDate()).padStart(2, "0");
  const month = String(formattedDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = formattedDate.getFullYear();

  // Return the formatted date string
  return `${day}-${month}-${year}`;
}

/**
 * @function formatTime this function will convert time to a specified format
 *
 * @param {string }time this is time string that is to be formatted
 * @returns {string} it will return formatted string of time
 */
export function formatTime(time) {
  const [hours, minutes] = time?.split(":");
  return `${hours}:${minutes}`;
}
