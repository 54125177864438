import isEmpty from "lodash/isEmpty";
import { Router } from "../../routes/routes";
import { CheckoutRoutes } from "../action-constants/checkout-constants";
import { productTypes } from "./pdp-constants";

export const months = [
  "01 (January)",
  "02 (February)",
  "03 (March)",
  "04 (April)",
  "05 (May)",
  "06 (June)",
  "07 (July)",
  "08 (August)",
  "09 (September)",
  "10 (October)",
  "11 (November)",
  "12 (December)",
];

const stepNumbers = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
};

export const countryCodes = ["+91 IND"];

/**
 * This method calculates the years to be shown in payment card form
 *
 * @returns {Array} list of years to be shown in payment card form
 */
export const getYearsList = () => {
  const currentYear = new Date().getFullYear();
  const validYearLimit = currentYear + 19;
  let year = currentYear;
  const result = [];
  while (year <= validYearLimit) {
    result.push(year);
    year += 1;
  }
  return result;
};

/**
 * This method formats the credit card input field value
 *
 * @param {string} value credit card input field value
 * @param {Array} gaps - gap's index on card number
 * @param {number} maxCardLength - max length of the card
 *
 * @returns {string} formatted credit card number
 */
export const formatCardNumber = (value, gaps, maxCardLength) => {
  if (value) {
    const finalString = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    const initialOffsets = maxCardLength > 16 ? [0].concat(gaps).concat([16]) : [0].concat(gaps);
    const finalCombination = initialOffsets.concat([finalString.length]);
    const formattedValue = finalCombination
      .map((end, index) => {
        if (index === 0) return "";
        const start = finalCombination[index - 1];
        return finalString.substr(start, end - start);
      })
      .filter((part) => part !== "")
      .join(" ");

    return formattedValue;
  }
  return "";
};

/**
 * This method restricts inputs to accept only numbers
 *
 * @param {object} event input field value
 * @returns {undefined}
 */
export const checkIfNumber = (event) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

/**
 * This method restricts inputs to accept only alphabets
 *
 * @param {object} event input field value
 * @returns {undefined}
 */
export const checkIfAlphabet = (event) => {
  if (/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

/**
 * This method validates emails
 *
 * @param {string} emailAddress input field value
 * @returns {boolean} - if the email is valid
 */
export const validateEmail = (emailAddress) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(emailAddress).toLowerCase());
};

/**
 * This method returns minutes and seconds for a given number of seconds
 *
 * @param {number} counter counter value
 * @returns {object} - minutes and seconds
 */
export const getMinutesAndSecondsFromCounter = (counter) => {
  const minutes = Math.floor((counter % 3600) / 60)
    .toString()
    .padStart(2, "0");

  const seconds = Math.floor(counter % 60)
    .toString()
    .padStart(2, "0");

  return { minutes, seconds };
};

/**
 * Function used to set cookie with name and its value for a specific time frame.
 *
 * @param {string} cname - name
 * @param {string} cvalue - value
 * @param {number} minutes - no of minutes
 */
export const setCookieForMinutes = (cname, cvalue, minutes) => {
  const date = new Date();
  date.setTime(date.getTime() + minutes * 60 * 1000);
  const expires = cname === "fnpmobilesearch" ? "expires=" : `expires=${date.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

/**
 * create form and submit
 *
 * @param {object} props - props
 */
export const createFormAndSubmit = (props) => {
  const { formTypeDetails, formInputElements } = props;
  const formToCreate = document.createElement("FORM");
  formToCreate.id = formTypeDetails.id;
  formToCreate.method = formTypeDetails.type;
  formToCreate.action = formTypeDetails.action;

  const inputElement = document.createElement("INPUT");
  inputElement.type = "HIDDEN";
  inputElement.name = formInputElements.name;
  inputElement.value = formInputElements.value;
  formToCreate.appendChild(inputElement);
  document.body.appendChild(formToCreate);

  if (formTypeDetails.submit) {
    document.getElementById(formTypeDetails.id).submit();
  }
};

/**
 * get payload Items
 *
 * @param {object} senderInfo - sender details
 * @param {object} cartItems - cart details
 * @param {boolean} isMobile - isMobile
 * @param {object} selectedContactMechIdList - list of selected address ContactMechID
 * @param {object} customerDetails - customer details
 * @param {string} messageOnCake - cake message
 * @returns {object} payload which needs to send
 */
export const getPayloadItems = (
  senderInfo,
  cartItems,
  isMobile,
  selectedContactMechIdList,
  customerDetails,
  messageOnCake = {},
) => {
  const payload = {
    bill_party_id: senderInfo?.senderId,
    checkoutpage: "deliveryaddress",
    sTel: senderInfo?.primaryContactNumber,
    senderEmail: senderInfo?.emailAddress,
    sname: senderInfo?.senderName,
    sCity: senderInfo?.sCity,
    makeProfileCity: senderInfo?.makeProfileCity,
    salutation: senderInfo?.salutationId,
    sCountryCode: senderInfo?.primaryCountryCode,
  };
  if (isMobile) {
    payload.isRecipientCity = senderInfo?.isRecipientCity;
  }

  cartItems?.orderList?.map((value) => {
    const {
      mainproduct: { personalizedImage = "", personalizedText = "" } = {},
      cartItemIndex,
      giftIndex,
      selectedAddressDetails: { selectedContactMechId },
      shippingInstructions,
    } = value.giftItem;

    payload[`cakeMsg_${cartItemIndex}`] = messageOnCake[cartItemIndex];
    payload[`personalizedImage_${cartItemIndex}`] = personalizedImage;
    payload[`personalizedText_${cartItemIndex}`] = personalizedText;
    payload[`selectedContactMechId_${cartItemIndex}`] = !isMobile
      ? selectedContactMechIdList[giftIndex]
      : selectedContactMechId;
    if ((customerDetails?.isAgentLogin || customerDetails?.isSupplierLogin) && !isMobile && shippingInstructions)
      payload[`specialinstr_${cartItemIndex}`] = shippingInstructions;
    return null;
  });

  return payload;
};

/**
 * finalize route for redirection
 *
 * @param {boolean} isMobile - mobile check
 * @returns {string} returning route
 */
export const finalRoute = (isMobile) => {
  const { slug1, orderId } = Router.router.query;
  let route = "";
  if ([CheckoutRoutes.ORDER_CONFIRMATION, CheckoutRoutes.PAYMENT_FAILED].includes(slug1)) {
    route = `${slug1}?orderId=${orderId}`;
  } else if (isMobile) {
    route = CheckoutRoutes.DELIVERY_ADDRESS;
  } else {
    route = CheckoutRoutes.DELIVERY_ADDRESS;
  }
  return route;
};

/**
 * fetches step number
 *
 * @param {string} pageName - name of the page
 * @returns {number} step number
 */
export const getStepNumber = (pageName) => {
  if (pageName === CheckoutRoutes.LOGIN) {
    return stepNumbers.ONE;
  }
  if (pageName === CheckoutRoutes.DELIVERY_ADDRESS) {
    return stepNumbers.TWO;
  }
  if (
    pageName === CheckoutRoutes.PAYMENT_OPTIONS &&
    (Router.query?.slug1 === CheckoutRoutes.DELIVERY_ADDRESS || Router.query?.slug1 === CheckoutRoutes.PAYMENT_OPTIONS)
  ) {
    return stepNumbers.THREE;
  }
  return stepNumbers.ONE;
};

/**
 * Checkout login redirection
 *
 * @param {boolean} isMobile - mobile check
 */
export const checkoutLoginRedirection = (isMobile) => {
  Router.replaceRoute(`/${CheckoutRoutes.CHECKOUT}/${finalRoute(isMobile)}`);
};

/**
 * This method is used to check that product is personalized type or not
 *
 * @param {object} mainProduct main product.
 * @returns {boolean} is express product or not .
 */
export const isPersonalizedProduct = (mainProduct) =>
  mainProduct?.primaryProductCategoryId === productTypes.PERSONALIZED;

/**
 * This method is used to check that product is courier type or not
 *
 * @param {object} mainProduct main product.
 * @returns  {boolean} is courier product or not .
 */
export const isCourierProduct = (mainProduct) => mainProduct?.primaryProductCategoryId === productTypes.COURIER;

/**
 * This method is used to check that product is digital product or not
 *
 * @param {object} mainProduct main product.
 * @returns  {boolean} is digital product or not .
 */
export const isDigitalProduct = (mainProduct) => mainProduct?.productTypeId === productTypes.DIGITAL_GOOD;

/**
 * This method is used to check that delivery type is courier or not
 *
 * @param {object} mainProduct main product.
 * @returns  {boolean} delivery type is courier or not .
 */
export const isCourierDelivery = (mainProduct) => isCourierProduct(mainProduct) || isPersonalizedProduct(mainProduct);

/**
 * This method is used to check the product is international or not
 *
 * @param {object} mainProduct main product.
 * @returns {boolean}  will return true or false
 */
export const checkIsInternationalProduct = (mainProduct) => {
  return mainProduct?.primaryProductCategoryId === productTypes.INTERNATIONAL;
};

/**
 * This method is used to check the product is international or not
 *
 * @param {object} selectedContactMechIds user selected address ids
 * @param {object} orderList order details.
 * @returns {boolean}  will return true or false
 */
export const verifySelectedContactMechIdsWithSavedAddress = (selectedContactMechIds, orderList) => {
  const selectedContactMechIdList = selectedContactMechIds;
  if (selectedContactMechIdList && orderList?.length > 1) {
    orderList?.forEach((item) => {
      const giftObj = item.giftItem;
      const userSelectedContactMechId = selectedContactMechIdList[giftObj?.giftIndex];
      let foundContactMechId = null;
      if (!isEmpty(userSelectedContactMechId)) {
        foundContactMechId = giftObj?.savedAddress?.addressList?.find((itemAddress) => {
          return itemAddress.contactMechId === userSelectedContactMechId;
        })?.contactMechId;
      }
      if (isEmpty(userSelectedContactMechId) || isEmpty(foundContactMechId)) {
        const mechId = giftObj?.savedAddress?.addressList?.find((itemAddress) => {
          return itemAddress.contactMechId === giftObj?.selectedAddressDetails?.selectedContactMechId;
        })?.contactMechId;
        selectedContactMechIdList[giftObj?.giftIndex] = isEmpty(mechId)
          ? giftObj?.savedAddress?.addressList[0]?.contactMechId
          : giftObj?.selectedAddressDetails?.selectedContactMechId;
      }
      return null;
    });
  }
  return selectedContactMechIdList;
};
