const APP_CONSTANTS = {
  SET_LOGGED_IN_PINCODE: "SET_LOGGED_IN_PINCODE",
  SET_STATIC_APP_CONFIGS: "SET_APP_CONFIGS",
  SET_DYNAMIC_APP_CONFIGS: "SET_DYNAMIC_APP_CONFIGS",
  SET_USER: "SET_USER",
  MOBILE_HEADER_FETCHED: "MOBILE_HEADER_FETCHED",
  SET_STICKY_INDEX: "SET_STICKY_INDEX",
  SET_ACCORDIAN_DATA: "SET_ACCORDIAN_DATA",
  FOOTER_ACCORDIAN_FETCHED: "FOOTER_ACCORDIAN_FETCHED",
  SET_FOOTER_SUBSCRIPTION: "SET_FOOTER_SUBSCRIPTION",
  DESKTOP_FOOTER_SUBSCRIPTION: "DESKTOP_FOOTER_SUBSCRIPTION",
  FOOTER_SUBSCRIPTION_ERROR: "FOOTER_SUBSCRIPTION_ERROR",
  GET_CURRENCIES_LIST: "GET_CURRENCIES_LIST",
  FETCH_CURRENCIES_REQUESTED: "FETCH_CURRENCIES_REQUESTED",
  WEB_ANALYTICS: "WEB_ANALYTICS",
  ECONNABORTED: "ECONNABORTED",
  SET_ORG_POSTAL_DATA: "SET_ORG_POSTAL_DATA",
  CHECK_LOGIN_CART_INFO: "CHECK_LOGIN_CART_INFO",
  CHECK_LOGIN_CART_INFO_SUCCESS: "CHECK_LOGIN_CART_INFO_SUCCESS",
  GET_PRODUCT_COUNT: "GET_PRODUCT_COUNT",
  ERROR_PRODUCT_COUNT: "ERROR_PRODUCT_COUNT",
  GET_PRODUCT_COUNT_REQUESTED: "GET_PRODUCT_COUNT_REQUESTED",
  SET_HOME_PAGE_BODY: "SET_HOME_PAGE_BODY",
  REQUEST_HOME_PAGE_BODY: "REQUEST_HOME_PAGE_BODY",
  ERROR_HOME_PAGE: "ERROR_HOME_PAGE",
  REQUEST_MICRO_PAGE_BODY: "REQUEST_MICRO_PAGE_BODY",
  SET_MICRO_PAGE_BODY: "SET_INFO_MICRO_BODY",
  ERROR_IN_MICRO_PAGE: "ERROR_IN_MICRO_PAGE",
  LEFT_MENU_ENABLE: "LEFT_MENU_ENABLE",
  GET_CART_SUMMARY_INFO_REQUEST: "GET_CART_SUMMARY_INFO_REQUEST",
  GET_CART_SUMMARY_INFO_SUCCESS: "GET_CART_SUMMARY_INFO_SUCCESS",
  SET_CART_COUNT: "SET_CART_COUNT",
  GET_PRICE_DETAILS_SUCCESS: "GET_PRICE_DETAILS_SUCCESS",
  SET_CART_ERROR_MESSAGE: "SET_CART_ERROR_MESSAGE",
  RESET_CART_ERROR_MESSAGE: "RESET_CART_ERROR_MESSAGE",
  GET_RETRY_PAYMENT_INFO_REQUEST: "GET_RETRY_PAYMENT_INFO_REQUEST",
  GET_RETRY_PAYMENT_INFO_SUCCESS: "GET_RETRY_PAYMENT_INFO_SUCCESS",
  UPDATE_ADDON_QUANTITY_REQUEST: "UPDATE_ADDON_QUANTITY_REQUEST",
  DELETE_CART_ITEM_REQUEST: "DELETE_CART_ITEM_REQUEST",
  UPDATE_SELECTED_CURRENCY: "UPDATE_SELECTED_CURRENCY",
  PAGE_TRANSITION_LOADER_SHOW: "PAGE_TRANSITION_LOADER_SHOW",
  PAGE_TRANSITION_LOADER_HIDE: "PAGE_TRANSITION_LOADER_HIDE",
  SET_PAGE_NAME: "SET_PAGE_NAME",
  SET_CATALOG_ID: "SET_CATALOG_ID",
  GET_USP_DATA_REQUEST: "GET_USP_DATA_REQUEST",
  GET_USP_DATA_SUCCESS: "GET_USP_DATA_SUCCESS",
  SET_YEARS: "SET_YEARS",
  LOGIN_CHECKED: "LOGIN_CHECKED",
  GET_ADD_ON_PRODUCTS_REQUEST: "GET_ADD_ON_PRODUCTS_REQUEST",
  GET_ADD_ON_PRODUCTS_SUCCESS: "GET_ADD_ON_PRODUCTS_SUCCESS",
  GET_ADD_ON_PRODUCTS_ERROR: "GET_ADD_ON_PRODUCTS_ERROR",
  SHOW_BACKDROP_REQUEST: "SHOW_BACKDROP_REQUEST",
  SHOW_ADDON_REQUEST: "SHOW_ADDON_REQUEST",
  SHOW_PDP_ADDON_REQUEST: "SHOW_PDP_ADDON_REQUEST",
  SHOW_LOADER: "SHOW_LOADER",
  SHOW_ITEM_NOTIFICATION: "SHOW_ITEM_NOTIFICATION",
  SHOW_CART_AND_NOTIFICATION: "SHOW_CART_AND_NOTIFICATION",
  GET_CART_DETAILS_FIRST_TIME: "GET_CART_DETAILS_FIRST_TIME",
  GET_CART_SIZE: "GET_CART_SIZE",
  GET_FILTER_DATA_REQUEST: "GET_FILTER_DATA_REQUEST",
  GET_FILTER_DATA_SUCCESS: "GET_FILTER_DATA_SUCCESS",
  VALID_ID: "VALID_ID",
  VALID_ID_SUCCESS: "VALID_ID_SUCCESS",
  VALID_ID_ERROR: "VALID_ID_ERROR",
  CHECK_LOGIN: "CHECK_LOGIN",
  CHECK_LOGIN_SUCCESS: "CHECK_LOGIN_SUCCESS",
  CHECK_LOGIN_ERROR: "CHECK_LOGIN_ERROR",
  CREATE_USER: "CREATE_USER",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_ERROR: "CREATE_USER_ERROR",
  GET_CSRFTOKENS: "GET_CSRFTOKENS",
  GET_CSRFTOKENS_SUCCESS: "GET_CSRFTOKENS_SUCCESS",
  GET_CSRFTOKENS_ERROR: "GET_CSRFTOKENS_ERROR",
  SEND_OTP: "SEND_OTP",
  SEND_OTP_SUCCESS: "SEND_OTP_SUCCESS",
  SEND_OTP_ERROR: "SEND_OTP_ERROR",
  LOGIN_USER: "LOGIN_USER",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_ERROR: "LOGIN_USER_ERROR",
  VALIDATE_OTP: "VALIDATE_OTP",
  VALIDATE_OTP_SUCCESS: "VALIDATE_OTP_SUCCESS",
  VALIDATE_OTP_ERROR: "VALIDATE_OTP_ERROR",
  CLEAR_ERR_MSG: "CLEAR_ERR_MSG",
  SET_PASSWORD: "SET_PASSWORD",
  SET_PASSWORD_SUCCESS: "SET_PASSWORD_SUCCESS",
  SET_PASSWORD_ERROR: "SET_PASSWORD_ERROR",
  SET_VALID_USER_EXISTS: "SET_VALID_USER_EXISTS",
  SET_VALID_USER: "SET_VALID_USER",
  CLEAR_OTP_ERROR: "CLEAR_OTP_ERROR",
  CLEAR_LOGIN_ERROR_MESSAGE: "CLEAR_LOGIN_ERROR_MESSAGE",
  CLEAR_PASSWORD_ERROR: "CLEAR_PASSWORD_ERROR",
  SET_CREATE_USER_DETAIL: "SET_CREATE_USER_DETAIL",
  SET_HOME_REL_ALTS_DATA: "SET_HOME_REL_ALTS_DATA",
  SET_FNP_BRANDS: "SET_FNP_BRANDS",
  SET_META_DATA: "SET_META_DATA",
  MICROSITE_REL_ALTS_DATA_REQUEST: "SET_MICROSITE_REL_ALTS_DATA_REQUEST",
  MICROSITE_REL_ALTS_DATA_SUCCESS: "MICROSITE_REL_ALTS_DATA_SUCCESS",
  SET_TESTIMONIAL_DATA: "SET_TESTIMONIAL_DATA",
  ERROR_TESTIMONIAL: "ERROR_TESTIMONIAL",
  REQUEST_TESTIMONIAL_DATA: "REQUEST_TESTIMONIAL_DATA",
  INITIATE_FUS: "INITIATE_FUS",
  FUS_FAIL: "FUS_FAIL",
  FUS_SUCCESS: "FUS_SUCCESS",
  GET_DATE_RANGE_ENABLE: "GET_DATE_RANGE_ENABLE",
  GET_DATE_RANGE_DATA: "GET_DATE_RANGE_DATA",
  SET_DATE_RANGE_DATA: "SET_DATE_RANGE_DATA",
  SET_DATE_RANGE_ENABLE: "SET_DATE_RANGE_ENABLE",
  SET_ERROR_IN_DATE_RANGE_ENABLE: "SET_ERROR_IN_DATE_RANGE_ENABLE",
  CHECK_MOBILE_VALIDATION: "CHECK_MOBILE_VALIDATION",
  SET_MOBILE_VALIDATION_ENABLE: "SET_MOBILE_VALIDATION_ENABLE",
  SET_MOBILE_VALIDATION_ENABLE_ERROR: "SET_MOBILE_VALIDATION_ENABLE_ERROR",
  CLEAR_ADDON_PRODUCT_LIST: "CLEAR_ADDON_PRODUCT_LIST",
  SHOW_MEDIA_INFO: "SHOW_MEDIA_INFO",
  GET_IS_INTERNATIONAL_ENABLE: "GET_IS_INTERNATIONAL_ENABLE",
  SET_WALLET: "SET_WALLET",
  SET_WALLET_SUCCESS: "SET_WALLET_SUCCESS",
  SET_WALLET_USABLE_BALANCE_SUCCESS: "SET_WALLET_USABLE_BALANCE_SUCCESS",
  SET_WALLET_ERROR: "SET_WALLET_ERROR",
  SET_WALLET_ACCOUNT: "SET_WALLET_ACCOUNT",
  SET_WALLET_ACCOUNT_SUCCESS: "SET_WALLET_ACCOUNT_SUCCESS",
  SET_WALLET_ACCOUNT_ERROR: "SET_WALLET_ACCOUNT_ERROR",
  SET_WALLET_CASH_LABEL: "SET_WALLET_CASH_LABEL",
  SET_WALLET_CASH_LABEL_SUCCESS: "SET_WALLET_CASH_LABEL_SUCCESS",
  SET_WALLET_CASH_LABEL_ERROR: "SET_WALLET_CASH_LABEL_ERROR",
  SET_WALLET_VALUE_RX: "SET_WALLET_VALUE_RX",
  SET_WALLET_LOADER: "SET_WALLET_LOADER",
  SET_WALLET_STATIC_CONTENT: "SET_WALLET_STATIC_CONTENT",
  ALL_ORGANIZED_ADDONS_LIST: "ALL_ORGANIZED_ADDONS_LIST",
  INITIATE_SOCIAL_LOGIN: "INITIATE_SOCIAL_LOGIN",
  FETCH_WALLET_USABLE_BALANCE_TNC_SUCCESS: "FETCH_WALLET_USABLE_BALANCE_TNC_SUCCESS",
  FETCH_WALLET_USABLE_BALANCE_TNC_FAILED: "FETCH_WALLET_USABLE_BALANCE_TNC_FAILED",
  SET_WALLET_USABLE_BALANCE_TNC: "SET_WALLET_USABLE_BALANCE_TNC",
};

export const COUNTRIES = {
  INDIA: "IN",
};

export default APP_CONSTANTS;
