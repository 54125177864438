import getConfig from "next/config";
import serverControl from "../../../../app_configs/axios/servercontrol";
import axiosControl from "../../../../app_configs/axios/control";
import errorLogger from "../../../../app_configs/logger-service";

const { publicRuntimeConfig } = getConfig();
const showRequestLogs = publicRuntimeConfig.SHOW_REQUESTS_LOGS;

const error = {
  config: {},
  response: {},
};

/**
 * Fetches mobile left menu data
 *
 * @param {string} catalogId current catalog id.
 * @returns {Array | string} array with left menu data or error code
 */
export const fetchMobileLeftMenuData = async (catalogId) => {
  try {
    const response = await axiosControl.get(`/productapi/api/rest/v1.3/getMobileLeftNav?catalogId=${catalogId}`);
    return response.data;
  } catch (e) {
    error.config.url = "/productapi/api/rest/v1.3/getMobileLeftNav?catalogId=";
    error.response.status = `An exception occurred while calling mobile left nav api => ${e}`;
    errorLogger(error, showRequestLogs);
    throw new Error(e);
  }
};

/**
 * Fetches footer accordion data
 *
 * @param {string} endPoint footer accordion endpoint.
 * @returns {string} footer accordion data or error
 */
export const fetchFooterAccordion = async (endPoint) => {
  try {
    const res = await serverControl.get(endPoint);
    return res.data;
  } catch (e) {
    error.config.url = endPoint;
    error.response.status = `An exception occurred while calling mobile footer accordion API => ${e}`;
    errorLogger(error, showRequestLogs);
    throw new Error(e);
  }
};
