import { put, takeLatest, call } from "redux-saga/effects";
import USER_CONSTANTS from "../../src/action-constants/user-constants";
import { getCustomerDetails } from "../../src/actions/user-actions";

/** This method is making Api call to fetch logged in user details */
export function* getCustomerDetailsSaga() {
  try {
    const response = yield call(getCustomerDetails);
    yield put({ type: USER_CONSTANTS.FETCH_CUSTOMER_DETAILS_SUCCESS, payload: response });
  } catch (error) {
    yield put({
      type: USER_CONSTANTS.FETCH_CUSTOMER_DETAILS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is used to yield success response of FETCH_CUSTOMER_DETAILS action */
function* watchFetchCustomerDetailsRequest() {
  yield takeLatest(USER_CONSTANTS.FETCH_CUSTOMER_DETAILS, getCustomerDetailsSaga);
}

export default watchFetchCustomerDetailsRequest;
