import React from "react";
import { useSelector } from "react-redux";
import { styled } from "@material-ui/core/styles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { parseCookies } from "nookies";
import PropTypes from "prop-types";
import style from "./limitedCatalog.module.scss";
import callClevertapEvent from "../../../src/analytics/clevertapUtility";
import { cleverTapEventsConstants, cleverTapCommonWords } from "../../../src/analytics/clevertapConstants";
import { CAKE_TYPE, productTypes } from "../../../src/utils/pdp-constants";
import { calDiscountPercentage, getProductDeliveryType } from "../../../src/utils/common";

export const ArrowForward = styled(ArrowForwardIosIcon)({
  fontSize: "15px",
});

/**
 * This functional component represents the catalog redirection mesg and link.
 *
 * @param {object} props - The object containing message info.
 * @param {string} props.message - message to show.
 * @param {string} props.href - anchor link.
 * @param {boolean} props.isCalender - check is calender component.
 * @param {boolean} props.isMobile - check is mobile device
 * @param {string} props.image - Image url
 * @param {object} props.backgroundColor - this object contains start and end color value
 * @param {string} props.textColor - this text color
 * @returns {React.Fragment} The main JSX for the desktop delivery input.
 */
const LimitedCatalog = ({ message, href, isCalender, isMobile, image, backgroundColor, textColor }) => {
  const { lastSelectedPincode, lastSelectedAddress } = parseCookies();
  const { selectedType } = useSelector((state) => state.pdpContext);
  const { catalogId } = useSelector((state) => state.appConfigs);
  const { product, shippingDatesAndMethods } = useSelector((state) => state.productDetail);

  const {
    productId,
    primaryCategoryId,
    productName,
    productPrice,
    productReviewsAndRatings,
    urlIdentifier,
    isVariant,
  } = product;

  /**
   * click on limit catalog
   */
  const handleOnClick = () => {
    const discountPercentage = calDiscountPercentage(productPrice.listPrice, productPrice.price);
    const productDeliveryType = getProductDeliveryType(primaryCategoryId);

    const cleverTapData = {
      page_type: cleverTapCommonWords.PDP,
      page_name: urlIdentifier,
      pincode: lastSelectedPincode,
      area: lastSelectedAddress,
      system_pincode: lastSelectedPincode,
      country: catalogId,
      city: lastSelectedAddress,
      product_price: productPrice.price,
      strike_price: productPrice.listPrice,
      discount_percentage: discountPercentage,
      product_rating: productReviewsAndRatings?.ratingValue,
      product_review_count: productReviewsAndRatings?.totalReview,
      earliest_delivery_date: shippingDatesAndMethods?.deliveryDates?.[0] ?? "",
      product_name: productName,
      pid: productId,
      product_category: primaryCategoryId,
      is_variant_available: isVariant === "Y",
      is_egg: selectedType?.toUpperCase() === CAKE_TYPE.WITH_EGG ?? false,
      delivery_type: productDeliveryType,
      is_limited_catalogue: true,
      is_on_calendar: isCalender,
      is_international: primaryCategoryId === productTypes.INTERNATIONAL,
    };

    callClevertapEvent(cleverTapEventsConstants.wantSameDayClicked, cleverTapData);
  };

  return (
    <div
      className={`${style.limitedCatalog} ${isMobile && style.limitedCatalogMobile} ${
        isCalender && style.limitedCatalogCalender
      }`}
      style={{
        background: backgroundColor.startColor,
        // eslint-disable-next-line no-dupe-keys
        background: `linear-gradient(90deg, ${backgroundColor.startColor} 0%, ${backgroundColor.endColor} 100%)`,
      }}
    >
      <a href={href} style={{ color: textColor }} onClick={handleOnClick}>
        <div className={style.imageSection}>
          <img src={image} alt={message} />
        </div>
        <div className={style.contentSection}>{message}</div>
        <ArrowForward />
      </a>
    </div>
  );
};

LimitedCatalog.propTypes = {
  message: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  isCalender: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  image: PropTypes.string.isRequired,
  backgroundColor: PropTypes.objectOf(PropTypes.any).isRequired,
  textColor: PropTypes.string,
};
LimitedCatalog.defaultProps = {
  textColor: "#222",
};

export default LimitedCatalog;
