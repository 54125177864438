import { call, put, takeEvery } from "redux-saga/effects";
import getConfig from "next/config";
import CommonPageActionsConstants from "../../src/action-constants/common-page-actions-constants";
import {
  errorPageMetaData,
  errorPageOrgPostalData,
  errorPageRelAltData,
  setPageMetaData,
  setPageOrgPostalData,
  setPageRelAltData,
  getCallChatTime,
} from "../../src/actions/common-page-actions";
import { fetchContentIdDetails, fetchRelAltData, publishCleverTapData } from "../../src/services/api/common/common-api";
import { getRelAltTags, seoScriptWrapper } from "../../src/utils/seo-utils";
// import { publishCleverTapData } from "../../src/services/api/common/test";

const { publicRuntimeConfig } = getConfig();

/**
 * Handles page org postal data
 *
 */
function* getAndSetOrgPostalData() {
  try {
    const country = publicRuntimeConfig.COUNTRY;
    const contentId = `SCHEMA_DETAILS_${country}`;
    const response = yield call(fetchContentIdDetails, contentId);

    const organization = response.organization ? JSON.stringify(response.organization) : "";
    const postalAddress = response.postalAddress ? JSON.stringify(response.postalAddress) : "";
    const orgSchema = response && organization && postalAddress ? seoScriptWrapper([organization, postalAddress]) : "";

    yield put(setPageOrgPostalData(orgSchema));
  } catch (error) {
    yield put(errorPageOrgPostalData(error.message));
  }
}

/**
 * Handles page meta data
 *
 * @param {object} action contains type and payload
 */
function* getAndSetMetaData(action) {
  const { contentId, isMobile } = action.payload;
  try {
    const response = yield call(fetchContentIdDetails, contentId, isMobile);

    if (response) {
      yield put(setPageMetaData(response));
    } else {
      yield put(setPageMetaData(""));
    }
  } catch (error) {
    yield put(errorPageMetaData(error.message));
  }
}

/**
 * Handles page rel alt data
 *
 * @param {object} action contains type and payload
 */
function* getAndSetRelAltData(action) {
  try {
    const { catalogId, categoryId, webSiteId, isMobile } = action.payload;
    const relAltQueryString = getRelAltTags(catalogId, categoryId, webSiteId);
    const response = yield call(fetchRelAltData, relAltQueryString, isMobile);

    if (response?.relALtUrls) {
      yield put(setPageRelAltData(response));
    }
  } catch (error) {
    yield put(errorPageRelAltData(error.message));
  }
}
/**
 * this function fetches data from CMS call center related configration
 *
 * @param {object} action contains the response payload information of CMS content
 */
function* fetchCallChatTime(action) {
  try {
    const res = yield call(getCallChatTime, action.payload);
    yield put({ type: CommonPageActionsConstants.GET_CALL_CHAT_TIME_SUCCESS, payload: { ...res, ...action.payload } });
  } catch (error) {
    yield put({ type: CommonPageActionsConstants.GET_CALL_CHAT_TIME_FAIL });
  }
}

/**
 * this function fetches data from CMS call center related configration
 *
 * @param {object} action contains the response payload information of CMS content
 */
function* postDataInCleverTapDat(action) {
  try {
    const res = yield call(publishCleverTapData, action.payload);
    yield put({ type: CommonPageActionsConstants.GET_CALL_CHAT_TIME_SUCCESS, payload: { ...res, ...action.payload } });
  } catch (err) {
    yield put({ type: CommonPageActionsConstants.POST_DATA_IN_CLEVER_TAP_FAIL });
  }
}
/**
 * Watcher saga for common page actions
 */
function* watchCommonPageDataSaga() {
  yield takeEvery(CommonPageActionsConstants.SERVER_REQUEST_MOBILE_PAGE_ORG_POSTAL_DATA, getAndSetOrgPostalData);
  yield takeEvery(CommonPageActionsConstants.SERVER_REQUEST_PAGE_META_DATA, getAndSetMetaData);
  yield takeEvery(CommonPageActionsConstants.SERVER_REQUEST_PAGE_REL_ALT_DATA, getAndSetRelAltData);
  yield takeEvery(CommonPageActionsConstants.GET_CALL_CHAT_TIME, fetchCallChatTime);
  yield takeEvery(CommonPageActionsConstants.POST_DATA_IN_CLEVER_TAP, postDataInCleverTapDat);
}

export default watchCommonPageDataSaga;
