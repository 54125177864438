import queryString from "query-string";
import getConfig from "next/config";
import axios from "../../app_configs/axios/base";
import errorLogger from "../../app_configs/logger-service";
import { paymentGateways } from "../utils/payment";
import Regex from "../../components/common/regex";
import { isMobileDevice } from "../utils/common";
import { fetchContentFromCMSOnClient } from "../services/api/common/common-api";
import { execDataLayer } from "../data-layer/dataLayer";
import { endpointConst } from "../data-layer/dataLayerConstants";
import {
  upiCollectHandlesEndpoint,
  upiIdValidationEndpoint,
  PaymentsEndpoint,
} from "../action-constants/payment-constants";

const { publicRuntimeConfig } = getConfig();
const desktop = typeof window !== "undefined" ? window.dSite : false;
const CONTROL_API_URL = desktop ? publicRuntimeConfig.D_BASE_URL : publicRuntimeConfig.BASE_URL;
const SHOW_REQUESTS_LOGS = publicRuntimeConfig.SHOW_REQUESTS_LOGS || "Y";
const error = {
  config: {},
  response: {},
};
const isServer = typeof window === "undefined";
const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
};

/**
 *
 * @param {boolean} isDesktop - isDesktop flag
 * @returns {string} domain
 */
const getDeviceSpecificDomain = (isDesktop) =>
  isDesktop ? publicRuntimeConfig.D_SERVER_CONTROL_FTS_HOST : publicRuntimeConfig.SERVER_CONTROL_FTS_HOST;

/**
 * fetches all the available currency options for paypal payment
 *
 * @returns {Array} - returns a list of all the currencies
 */
async function getPaypalCurrencies() {
  try {
    const response = await axios.get("/control/getCurrencyNamesPayPal-rj");
    return response.data;
  } catch (err) {
    error.config.url = "/control/getCurrencyNamesPayPal";
    error.response.status = `An exception occurred while fetching paypal currecies => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * fetches all the available banks for net banking
 *
 * @param {string} payload - value of gateway
 * @returns {Array} - returns a list of all the banks
 */
async function getNetBankingList({ data, isSwipePayment }) {
  let apiUrl;
  try {
    if (isSwipePayment === "Y") {
      apiUrl = PaymentsEndpoint?.paymentBankList;
    } else if (data === paymentGateways.PAYU) {
      apiUrl = PaymentsEndpoint?.payUBankList;
    } else {
      apiUrl = PaymentsEndpoint?.ruPayBankList;
    }
    const response = await axios.get(apiUrl);
    return response?.data;
  } catch (err) {
    error.config.url = apiUrl;
    error.response.status = `An exception occurred while fetching net banking banks list => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * fetches all the available upi handles.
 *
 * @returns {Array} - returns a list of all the upi handles.
 */
async function getUpiHandlesList() {
  try {
    const response = await axios.get(`${upiCollectHandlesEndpoint.upiHandles}`);
    return response?.data;
  } catch (err) {
    error.config.url = `${upiCollectHandlesEndpoint.upiHandles}`;
    error.response.status = `An exception occurred while fetching upi handles list => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * fetches polling status for UPI QR.
 *
 * @param {object} payload passing required payload.
 * @returns {Array} - returns a list of all the upi handles.
 */
async function getQrPaymentPollingStatus(payload) {
  try {
    const response = await axios.post(`${PaymentsEndpoint.qrPaymentPollingStatus}`, { orderId: payload });
    return response?.data;
  } catch (err) {
    error.config.url = `${PaymentsEndpoint.qrPaymentPollingStatus}`;
    error.response.status = `An exception occurred while fetching verify payment polling status  => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * fetches all the available wallet options
 *
 * @returns {Array} - returns a list of all the wallets available
 */
async function getWalletDetails() {
  try {
    return await fetchContentFromCMSOnClient(`global/static/web/fnp_wallet_gateways`);
  } catch (err) {
    error.config.url = `global/static/web/fnp_wallet_gateways`;
    error.response.status = `An exception occurred while fetching wallet details => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * fetches payment options
 *
 * @param {object} payload - object with required params
 * @returns {Array} - returns payment options
 */
async function getPaymentOptionsDetails(payload) {
  try {
    /**
     * @function apiFn wrapper function to execute getPaymentOptionsDetails call from datalayer.
     * @returns response from datalayer
     */
    const apiFn = async () => {
      const orderParams =
        !!payload?.orderId?.trim() && Regex.onlyNumbers.test(payload?.orderId?.trim())
          ? `?orderId=${payload.orderId}`
          : "";
      const pncParams = payload?.pnc === false ? `&pnc=${payload.pnc}` : "";
      return axios.get(`${endpointConst.getPaymentOptionsDetails}${orderParams}${pncParams}`);
    };
    const res = await execDataLayer(apiFn, `${endpointConst.getPaymentOptionsDetails}`);
    return res.data;
  } catch (err) {
    error.config.url = "/control/getPaymentOptionsDetails";
    error.response.status = `An exception occurred while fetching payment options => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * fetches saved cards for logged-in user
 *
 * @param {number} partyId - returns a list of all the saved cards for logged-in user
 * @returns {Array} - returns a list of all the saved cards for logged-in user
 */
async function fetchUserSavedCards(partyId) {
  try {
    const response = await axios.get(`${PaymentsEndpoint.fetchUserSavedCards}?partyId=${partyId}`);
    return response.data;
  } catch (err) {
    error.config.url = PaymentsEndpoint.fetchUserSavedCards;
    error.response.status = `An exception occurred while fetching logged in user saved cards => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * fetches minimum EMI amount
 *
 * @returns {Array} - returns the minimum EMI amount
 */
async function getMinAmountEMI() {
  try {
    const response = await axios.get(`/control/getMinAmountEMI`);
    return response.data;
  } catch (err) {
    error.config.url = "/control/getMinAmountEMI";
    error.response.status = `An exception occurred while fetching minimum EMI amount  => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * checks entered card number's info
 *
 * @param {number} cardData - card data
 * @returns {Array} - returns the entered card number's info
 */
async function checkCardType({ cardData, isSwipePayment }) {
  try {
    const dataString = queryString.stringify({
      cardNumber: cardData?.cardNumber?.replace(/\s/g, ""),
      orderId: cardData?.orderId,
    });
    const cardTypeEndpoint =
      isSwipePayment === "Y" ? PaymentsEndpoint.checkPaymentCardType : PaymentsEndpoint.checkCardType;
    const response = await axios.post(cardTypeEndpoint, dataString);
    return response.data;
  } catch (err) {
    error.config.url = "/control/checkCardType";
    error.response.status = `An exception occurred while fetching card details => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * fetches EMI payment values
 *
 * @param {number} amount - total cart amount
 * @returns {Array} - returns the EMI payment values
 */
async function getEmiPaymentValues(amount) {
  try {
    const response = await axios.post(`/control/getEmiPaymentValues?amount=${amount}`);
    return response.data;
  } catch (err) {
    error.config.url = "/control/getEmiPaymentValues";
    error.response.status = `An exception occurred while fetching EMI payment values  => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * fetches thank you page info
 *
 * @param {object} payload - contains request object
 * @returns {Array} - returns the thank you page info
 */
async function getThankYouPageInfo(payload) {
  try {
    const { ctx, orderId } = payload;
    const host = isServer ? getDeviceSpecificDomain(!isMobileDevice(ctx)) : CONTROL_API_URL;
    const axiosURL = `/control/getThankYouPageInfo?orderId=${orderId}&isDRTP=Y&isRRTP=Y`;
    /**
     * @function apiFn wrapper function to execute thankyou page call from datalayer.
     * @returns {object }response from datalayer
     */
    const apiFn = async () => {
      return axios.get(axiosURL, {
        baseURL: host,
        headers: ctx
          ? {
              Cookie: ctx.req.headers.cookie,
            }
          : {},
      });
    };
    const res = await execDataLayer(apiFn, endpointConst.thankYouPage);
    return res.data;
  } catch (err) {
    error.config.url = "/control/getThankYouPageInfo";
    error.response.status = `An exception occurred while fetching thank you page information  => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}
/**
 * fetches all reminders
 *
 * @param {number} occasionReminderId - reminder id
 * @param {boolean} upcoming - fetch all upcoming reminders flag
 * @returns {Array} - returns the all reminders
 */
async function getAllReminders(occasionReminderId, upcoming) {
  try {
    let axiosURL = `/control/getAllReminders-rj`;
    if (occasionReminderId) {
      axiosURL = `/control/getAllReminders-rj?occasionReminderId=${occasionReminderId}`;
    }
    if (upcoming) {
      axiosURL = `/control/getAllReminders-rj?viewIndex=0&viewSize=2&isApi=Y&occasionReminder=Y`;
    }
    const response = await axios.get(axiosURL);
    return response.data;
  } catch (err) {
    error.config.url = "/control/getAllReminders";
    error.response.status = `An exception occurred while fetching all reminders  => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * update reminders
 *
 * @param {object} payload - reminder data
 * @returns {Array} - returns the updated reminder data
 */
async function updateReminders(payload) {
  try {
    const { occasionReminderId = "" } = payload;
    const dataString = queryString.stringify({
      name: payload.name || "",
      occasion: payload.occasion || "",
      day: payload.day || "",
      month: payload.month || "",
      year: payload.year || "",
      occasionReminderId: occasionReminderId || "",
      relation: payload.relation || "",
    });

    const axiosURL = `/control/saveReminder-rj`;
    const response = await axios.post(axiosURL, dataString);
    return response.data;
  } catch (err) {
    error.config.url = "/control/saveReminder";
    error.response.status = `An exception occurred while updating reminders  => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * delete reminders
 *
 * @param {number} occasionReminderId - reminder id
 * @returns {Array} - returns the delete reminder
 */
async function deleteReminders(occasionReminderId) {
  try {
    const dataString = queryString.stringify({
      occasionReminderId: Number(occasionReminderId),
    });
    const response = await axios.post(`/control/deleteReminder-rj`, dataString);
    return response.data;
  } catch (err) {
    error.config.url = "/control/deleteReminder";
    error.response.status = `An exception occurred while deleting reminder  => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * delete user saved card
 *
 * @param {number} tokenId - token id
 * @param {string} cardToken - cardToken
 * @returns {Array} - returns the delete card
 */
async function deleteSavedCard(tokenId, cardToken) {
  try {
    const response = await axios.post(`/control/deleteSavedUserCard-rj?cardToken=${cardToken}&tokenId=${tokenId}`);
    return response.data;
  } catch (err) {
    error.config.url = "/control/deleteSavedUserCard";
    error.response.status = `An exception occurred while deleting user saved card  => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * makes all the api calls to collect payments data
 *
 * @param {object} payload - params for api call
 * @returns {Array} - returns all data required to process payments
 */
async function fetchPaymentMethods(payload) {
  try {
    const response = await Promise.all([getWalletDetails(), getPaymentOptionsDetails(payload)]);
    return {
      walletDetails: response[0],
      paymentDetails: response[1],
    };
  } catch (err) {
    error.config.url = "/initiatePaymentAPIs";
    error.response.status = `An exception occurred while fetching initiatePaymentAPIs=> ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * fetches thank you page detail
 *
 * @returns {Array} - returns the thank you page detail
 */
async function getThankYouPageContentDetail() {
  try {
    return await fetchContentFromCMSOnClient("/global/static/web/mobile/customizethankyoupage");
  } catch (err) {
    error.config.url = "/global/static/web/mobile/customizethankyoupage";
    error.response.status = `An exception occurred while fetching thank you page information  => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * fetches payment method flags.
 *
 * @returns {object} - returns the object for
 */
async function getPaymentMethodFlags() {
  try {
    const res = await fetchContentFromCMSOnClient(`${PaymentsEndpoint.paymentMethodFlag}`);
    return res;
  } catch (err) {
    error.config.url = `${PaymentsEndpoint.paymentMethodFlag}`;
    error.response.status = `An exception occurred while fetching payment method flags  => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * save user feedback
 *
 * @param {object} payload - params for api call
 * @returns {object} - response from api
 */
async function saveUserFeedback(payload) {
  try {
    return axios.post("/control/storeUserFeedback", queryString.stringify(payload));
  } catch (err) {
    error.config.url = "/control/storeUserFeedback";
    error.response.status = `An exception occurred while saving user feedback  => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}
/**
 * Make Payment API Call
 *
 * @param {object} payload - params for api call
 * @returns {object} - response from api
 */
async function swipePaymentCall(payload) {
  try {
    const isRetrypayment = !!payload?.orderId;
    const initOrRetryEndpoint = isRetrypayment
      ? PaymentsEndpoint.retryInitiatePayment
      : PaymentsEndpoint.initiatePayment;
    return axios.post(initOrRetryEndpoint, queryString.stringify(payload), {
      headers: {
        javaEnabled: window.navigator.javaEnabled(),
        javascriptEnabled: true,
        timezoneOffset: new Date().getTimezoneOffset(),
        colorDepth: window.screen.colorDepth,
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
      },
    });
  } catch (err) {
    error.config.url = PaymentsEndpoint.initiatePayment;
    error.response.status = `An exception occurred while saving user feedback  => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}
/**
 * Get Payment Verify API Call
 *
 * @param {object} payload - params for api call
 * @returns {object} - response from api
 */
async function getVerifyPaymentCall(payload) {
  try {
    return axios.get(`${PaymentsEndpoint.verifyPayment}?orderId=${payload}`);
  } catch (err) {
    error.config.url = PaymentsEndpoint.verifyPayment;
    error.response.status = `An exception occurred while saving user feedback  => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * UPI ID validation.
 *
 * @param {object} payload - params for api call
 * @returns {object} - response from api
 */
async function upiIdValidate(payload) {
  try {
    return axios.post(`${upiIdValidationEndpoint.upiValidation}`, { upiId: payload });
  } catch (err) {
    error.config.url = `${upiIdValidationEndpoint.upiValidation}`;
    error.response.status = `An exception occurred while sending upi id validation  => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * This method is to make check eligibility API call.
 *
 *
 * @param {string} data - return event
 * @returns {void} - returns nothing
 */
export async function checkEligibilityApi(data) {
  const { mobileToken, ...payload } = data;
  try {
    const resp = await axios.post(endpointConst.checkEligibility, queryString.stringify(payload), {
      headers: { mobileToken },
    });
    return resp?.data;
  } catch (ex) {
    return ex;
  }
}

/**
 * This method is to send otp for mobile number verification.
 *
 *
 * @param {string} data - return event
 * @returns {void} - returns nothing
 */
export async function sendPaymentOtpApi(data) {
  try {
    const resp = await axios.post(endpointConst.sendPaymentOtp, data, { headers });
    return resp;
  } catch (ex) {
    return ex;
  }
}

/**
 * This method is for validate otp.
 *
 *
 * @param {string} data - return event
 * @returns {void} - returns nothing
 */
export async function validatePaymentOtpApi(data) {
  try {
    /**
     * @function verifyPaymentOtpFn wrapper function to execute verify otp simpl call from datalayer.
     * @returns response from datalayer
     */
    const verifyPaymentOtpFn = async () => {
      return axios.post(endpointConst.verifyPaymentOtp, data, { headers });
    };
    const res = await execDataLayer(verifyPaymentOtpFn, endpointConst.verifyPaymentOtp);
    return res;
  } catch (ex) {
    return ex;
  }
}

const paymentActions = {
  checkCardType,
  fetchPaymentMethods,
  getEmiPaymentValues,
  getPaypalCurrencies,
  getNetBankingList,
  getWalletDetails,
  getPaymentOptionsDetails,
  getMinAmountEMI,
  getThankYouPageInfo,
  getAllReminders,
  updateReminders,
  deleteReminders,
  deleteSavedCard,
  getThankYouPageContentDetail,
  getPaymentMethodFlags,
  saveUserFeedback,
  upiIdValidate,
  getUpiHandlesList,
  getQrPaymentPollingStatus,
  swipePaymentCall,
  getVerifyPaymentCall,
  fetchUserSavedCards,
};

export default paymentActions;
