import { HYDRATE } from "next-redux-wrapper";
import APP_CONSTANTS from "../../src/action-constants/app-actions";

const initialState = {
  data: "",
  errorMessages: {
    pageBodyError: "",
  },
  testimonialData: "",
  testimonialError: "",
};

/**
 * Reducer for Info Page
 *
 * @param {object} state state object
 * @param {object} action action object
 * @returns {object} state object
 */
const microPageReducer = (state = initialState, action = {}) => {
  const { type } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.microPage,
      };
    case APP_CONSTANTS.SET_MICRO_PAGE_BODY:
      return {
        ...state,
        data: action.payload,
        errorMessages: {
          ...state.errorMessages,
          criticalError: "",
          pageBodyError: "",
        },
      };
    case APP_CONSTANTS.ERROR_IN_MICRO_PAGE:
      return {
        ...state,
        data: "",
        errorMessages: {
          ...state.errorMessages,
          pageBodyError: action.payload,
        },
      };
    case APP_CONSTANTS.SET_TESTIMONIAL_DATA:
      return {
        ...state,
        testimonialData: action.payload,
        testimonialError: "",
      };
    case APP_CONSTANTS.ERROR_TESTIMONIAL:
      return {
        ...state,
        testimonialError: action.payload,
        testimonialData: "",
      };

    default:
      return state;
  }
};

export default microPageReducer;
