const CommonPageActionsConstants = {
  SERVER_REQUEST_MOBILE_PAGE_ORG_POSTAL_DATA: "SERVER_REQUEST_MOBILE_PAGE_ORG_POSTAL_DATA",
  SERVER_SET_MOBILE_PAGE_ORG_POSTAL_DATA: "SERVER_SET_MOBILE_PAGE_ORG_POSTAL_DATA",
  SERVER_ERROR_MOBILE_PAGE_ORG_POSTAL_DATA: "SERVER_ERROR_MOBILE_PAGE_ORG_POSTAL_DATA",

  SERVER_REQUEST_PAGE_META_DATA: "SERVER_REQUEST_PAGE_META_DATA",
  SERVER_SET_PAGE_META_DATA: "SERVER_SET_PAGE_META_DATA",
  SERVER_ERROR_PAGE_META_DATA: "SERVER_ERROR_PAGE_META_DATA",

  SERVER_REQUEST_PAGE_REL_ALT_DATA: "SERVER_REQUEST_PAGE_REL_ALT_DATA",
  SERVER_SET_PAGE_REL_ALT_DATA: "SERVER_SET_PAGE_REL_ALT_DATA",
  SERVER_ERROR_PAGE_REL_ALT_DATA: "SERVER_ERROR_PAGE_REL_ALT_DATA",

  GET_CALL_CHAT_TIME: "GET_CALL_CHAT_TIME",
  GET_CALL_CHAT_TIME_SUCCESS: "GET_CALL_CHAT_TIME_SUCCESS",
  GET_CALL_CHAT_TIME_FAIL: "GET_CALL_CHAT_TIME_FAIL",

  POST_DATA_IN_CLEVER_TAP: "POST_DATA_IN_CLEVER_TAP",
  POST_DATA_IN_CLEVER_TAP_SUCCESS: "POST_DATA_IN_CLEVER_TAP_SUCCESS",
  POST_DATA_IN_CLEVER_TAP_FAIL: "POST_DATA_IN_CLEVER_TAP_FAIL",
};

export default CommonPageActionsConstants;
