import { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import useCommonOmnitureData from "./useCommonOmnitureData";
import useOmnitureSetup from "./useOmnitureSetup";

/**
 * Cart Preview page omniture setup
 */
export default function useCartPreviewOmnitureSetup() {
  const { rootGeoId, catalogId, checkLoginInfo } = useCommonOmnitureData();
  const cartItems = useSelector((state) => state.cartDetails.cartSummary?.items, shallowEqual);
  const omniData = useMemo(
    () => ({
      userDetails: {
        checkLoginInfo,
      },
      appConfigs: {
        rootGeoId,
        catalogId,
      },
      cartDetails: {
        cartItems,
      },
    }),
    [cartItems, checkLoginInfo, catalogId, rootGeoId],
  );

  useOmnitureSetup(omniData);
}
