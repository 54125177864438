const ReminderConstants = {
  GET_REMINDERS_LIST: "GET_REMINDERS_LIST",
  GET_REMINDERS_LIST_SUCCESS: "GET_REMINDERS_LIST_SUCCESS",
  SAVE_REMINDER: "SAVE_REMINDER",
  DELETE_REMINDER: "DELETE_REMINDER",
  GET_REMINDERS_LIST_FAILURE: "GET_REMINDERS_LIST_FAILURE",
  MAX_OCCASION_LENGTH: 15,
};

export default ReminderConstants;
