import set from "lodash/set";
import { GA4_EVENTS } from "../../components/common/constants";

const dataLayerVarName = "dataLayer";

/**
 * This methods returns the dataLayer global object.
 *
 * @returns {object} - returns the dataLayer global object
 */
function getDigitalLayer() {
  window[dataLayerVarName] = window[dataLayerVarName] || [];
  return window[dataLayerVarName];
}

/**
 * This method return true or false is id contains LUX
 *
 * @param {string} id this is the SKU ID
 * @returns {boolean} - returns the boolean value if lux contains
 */
function isSkuId(id) {
  return id?.toLocaleLowerCase().includes("lux");
}

/**
 * Setting the checkout event object for GA
 *
 * @param {object} cartItems cartItems data
 */
const setProducDetailsForCheckout = (cartItems) => {
  const orderItems = cartItems?.orderList;
  const products = [];
  orderItems?.forEach((item) => {
    const product = {};
    product.id = item?.giftItem?.mainproduct?.productId;
    product.sku = item?.giftItem?.mainproduct?.sku;
    product.name = item?.giftItem?.mainproduct?.productName;
    product.category = item?.giftItem?.mainproduct?.category;
    product.price = item?.giftItem?.mainproduct?.price;
    product.currency = cartItems?.otherDeliveryDetails?.currency;
    product.brand = cartItems?.otherDeliveryDetails?.brand;
    product.quantity = 1;
    products.push(product);
    if (item?.giftItem?.addons?.length > 0) {
      item?.giftItem?.addons?.forEach((addonItem) => {
        const addonProduct = {};
        addonProduct.id = addonItem?.productId;
        addonProduct.sku = addonItem?.sku;
        addonProduct.name = addonItem?.productName;
        addonProduct.category = item?.giftItem?.mainproduct?.category;
        addonProduct.price = addonItem?.price;
        addonProduct.currency = cartItems?.otherDeliveryDetails?.currency;
        addonProduct.brand = cartItems?.otherDeliveryDetails?.brand;
        addonProduct.quantity = addonItem?.quantity;
        products.push(addonProduct);
      });
    }
  });
  let gaDeliveryAddressObject;
  getDigitalLayer()?.every((item) => {
    if (
      item?.event &&
      item.event === "checkout" &&
      item?.ecommerce?.checkout?.actionField?.option === "DeliveryAddressPage"
    ) {
      gaDeliveryAddressObject = item;
      return false;
    }
    return true;
  });

  if (gaDeliveryAddressObject) {
    gaDeliveryAddressObject.ecommerce.checkout.products = products;
    set(getDigitalLayer(), "ecommerce.checkout.products", gaDeliveryAddressObject);
  } else {
    gaDeliveryAddressObject = {
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: { step: 1, option: "DeliveryAddressPage" },
          products,
        },
      },
    };
    getDigitalLayer().push(gaDeliveryAddressObject);
  }
};

/**
 * Setting the purchace event object for GA.
 *
 * @param {object} viewProductData Order Data
 * @param {string} countryCode countryCode
 * @param {string} phoneNum phoneNum
 */
const setOrderDetailsForThankYouPage = (viewProductData, countryCode, phoneNum) => {
  if (!viewProductData?.orderId || !viewProductData?.paymentReceived || viewProductData?.paymentReceived === "false") {
    return;
  }
  const products = [];
  let isLuxProductContains = false;
  viewProductData?.thankYouPageInfo?.forEach((orderItems) => {
    orderItems?.productDetails?.forEach((item) => {
      const product = {};
      product.id = item?.productId;
      product.sku = item?.skuCode;
      product.name = item?.productDisplayName;
      product.category = item?.category;
      product.brand = viewProductData?.brand;
      product.price = item?.productPrice;
      product.orderSequenceId = item?.orderSequenceId;
      product.currency = viewProductData?.currency;
      product.quantity = 1;
      products.push(product);
      if (!isLuxProductContains && isSkuId(item?.skuCode)) {
        isLuxProductContains = true;
      }
      if (Array.isArray(item?.productAddonsInfo)) {
        item?.productAddonsInfo?.forEach((addon) => {
          const addonProduct = {};
          addonProduct.id = addon?.productId;
          addonProduct.sku = addon?.skuCode;
          addonProduct.name = addon?.addonName;
          addonProduct.category = addon?.category;
          addonProduct.brand = viewProductData?.brand;
          addonProduct.price = addon?.addonPrice;
          addonProduct.orderSequenceId = addon?.orderSequenceId;
          addonProduct.currency = viewProductData?.currency;
          addonProduct.quantity = addon?.quantity;
          products.push(addonProduct);
        });
      }
    });
  });

  const gaPurchaceEvent = {
    event: "purchase",
    ecommerce: {
      purchase: {
        actionField: {
          id: viewProductData?.orderId,
          revenue: viewProductData?.grandTotal,
          discountAmt: viewProductData?.otherAdjAmount,
          tax: 0,
          shipping: viewProductData?.shippingAmount,
          currency: viewProductData?.currency,
          phone: `+${countryCode}${phoneNum}`,
          isLuxProductContains,
        },
        products,
      },
    },
  };
  getDigitalLayer().push(gaPurchaceEvent);
};

/**
 * @function setPdpPageGaEvent Setting the view product event object for GA.
 *
 * @param {event} event type of event
 * @param {object} viewProduct login state
 * @param {string} pincode selected pincode
 * @param {boolean} isUserLoggedIn login state
 * @param {string} selectedCurrency login state
 * @param {object} productDetail product detail
 */
const setPdpPageGaEvent = (event, viewProduct, pincode, isUserLoggedIn, selectedCurrency, productDetail) => {
  const items = [];
  const product = {};
  product.item_name = viewProduct?.productName || null;
  product.item_id = viewProduct?.productId || null;
  product.parent_id = productDetail?.product?.productId || null;
  product.mrp = viewProduct?.productPrice.listPrice || null;
  product.price = viewProduct?.productPrice.price || null;
  product.item_brand = viewProduct?.brandName || "FNP-India";

  product.item_category = viewProduct?.primaryKeywordId || null;
  product.item_category2 = viewProduct?.productTypeId || null;
  product.item_category3 = viewProduct?.primaryCategoryId || null;
  product.quantity = 1;
  items.push(product);
  if (productDetail?.product?.variantProducts) {
    product.item_variant = `${viewProduct?.title}_${viewProduct?.productId}` || null;
  }
  const gaPdpPageEvent = {
    event,
    pincode: pincode || null,
    login_state: isUserLoggedIn,
    ecommerce: {
      currency: "INR",
      selected_currency: selectedCurrency,
      value: viewProduct?.productPrice.price,
      items,
    },
  };
  getDigitalLayer().push(gaPdpPageEvent);
};

/**
 * @function changeVariantGaEvent Setting the change variant event object for GA.
 *
 * @param {object} selectedVariant selectedVariant
 * @param {object} pincode pincode
 * @param {boolean} product variants
 */
const changeVariantGaEvent = (selectedVariant, pincode, product) => {
  const gaChangeVariantEvent = {
    event: GA4_EVENTS.VARIANT_CHANGED,
    pincode: pincode || null,
    item_id: product?.productId || null,
    item_name: product?.productName || null,
    variants_available: "Y",
    selected_variant_name: selectedVariant?.productName || null,
  };
  getDigitalLayer().push(gaChangeVariantEvent);
};
/**
 * @function pincodeGaEvent Setting the pincode related event object for GA.
 * @param {object} event event
 * @param {object} pincode pincode
 * @param {boolean} pageType pageType
 */
const pincodeGaEvent = (event, pincode, pageType) => {
  const gaPincodeEvent = {
    event,
    pincode,
  };
  if (event === GA4_EVENTS.PINCODE_ENTERED) {
    gaPincodeEvent.page_type = pageType || null;
  }
  getDigitalLayer().push(gaPincodeEvent);
};
/**
 * @function nsaOrOutOfStockGaEvent Setting the NSA and out of stock event object for GA.
 * @param {object} event event
 * @param {object} pincode pincode
 * @param {boolean} product product detail
 */
const nsaOrOutOfStockGaEvent = (event, pincode, product) => {
  const gaNsaOrOutOfStockEvent = {
    event,
    pincode: pincode || null,
    item_id: product?.productId || null,
    item_name: product?.productName || null,
    price: product?.productPrice?.price || null,
  };
  getDigitalLayer().push(gaNsaOrOutOfStockEvent);
};

/**
 * @function removeFromCartGaEvent tracking removing cart event object for GA.
 * @param {object} cartData cart  detail
 * @param {boolean} isUserLoggedIn login state
 * @param {string} selectedCurrency selected currency
 */
const removeFromCartGaEvent = (cartData, isUserLoggedIn, selectedCurrency) => {
  const items = [];
  const product = {};
  product.item_name = cartData?.productName || null;
  product.item_id = cartData?.productId || null;
  product.price = cartData?.price || null;
  product.item_brand = cartData?.brandName || "FNP-India";
  product.pincode = cartData?.pincode || null;
  product.item_variant = cartData?.variantType || null;
  product.item_category = cartData?.category || null;
  product.item_category2 = cartData?.productTypeId || null;
  product.item_category3 = cartData?.primaryProductCategoryId || null;
  product.quantity = 1;
  items.push(product);

  const gaRemoveFromCartEvent = {
    event: GA4_EVENTS.REMOVE_FROM_CART,
    login_state: isUserLoggedIn,
    ecommerce: {
      currency: "INR",
      selected_currency: selectedCurrency,
      value: cartData?.price || null,
      items,
    },
  };
  getDigitalLayer().push(gaRemoveFromCartEvent);
};
/**
 * @function removeFromCartGaEvent tracking removing cart event object for GA.
 *
 * @param {object} cartData cart  detail
 * @param {boolean} isUserLoggedIn login state
 * @param {string} selectedCurrency selected currency
 * @param {string} giftItemPincode pincode
 * @param {object} singleAddon removed addon
 */
const removeAddonsFromCartGaEvent = (cartData, isUserLoggedIn, selectedCurrency, giftItemPincode, singleAddon) => {
  const items = [];
  const product = {};
  product.item_name = cartData?.productName || null;
  product.item_id = cartData?.productId || null;
  product.price = cartData?.unitPrice || cartData?.price || null;
  product.quantity = singleAddon ? 1 : cartData?.quantity || cartData?.selectedNumber;
  product.pincode = giftItemPincode?.pincode || null;
  items.push(product);

  const gaRemoveFromCartEvent = {
    event: GA4_EVENTS.ADDONS_REMOVE,
    login_state: isUserLoggedIn,
    ecommerce: {
      currency: "INR",
      selected_currency: selectedCurrency,
      items,
    },
  };
  getDigitalLayer().push(gaRemoveFromCartEvent);
};

/** Sets the login and sign-up Ga Event ..
 *
 * @param {object} customerDetails - The details of the customer.
 * @param {object} checkLoginInfo - The information related to login validation.
 * @param {boolean} googleLogin - A flag indicating if the login type is Google login.
 * @param {object} senderInfo - The information related to the sender.
 * @returns {void}
 */
const setGaLoginAndSignUp = (customerDetails, checkLoginInfo, googleLogin, senderInfo) => {
  const loginAndSignUpObj = {
    event: "",
    login_Type: googleLogin ? "social_login" : "password",
    name: customerDetails?.userName || checkLoginInfo?.fullname,
    email: customerDetails?.emailID || senderInfo?.emailAddress || senderInfo?.emailAddress,
    phone_number: customerDetails?.mobNo || senderInfo?.primaryContactNumber || senderInfo?.phoneNum,
    customer_party_id: customerDetails?.customerID || senderInfo?.senderId || senderInfo?.customerId,
  };
  if (checkLoginInfo?.userType === "exisiting") {
    getDigitalLayer().push({ ...loginAndSignUpObj, event: "login" });
  } else {
    getDigitalLayer().push({ ...loginAndSignUpObj, event: "sign_up" });
  }
};

/**
 * Sets the login and sign-up information for d-site My Account page.
 *
 * @param {boolean} googleLogin - A flag indicating if the login type is Google login.
 * @param {object} userDetails - The details of the user.
 * @returns {void}
 */
const setGaLoginAndSingUpForDesktopMyAccount = (googleLogin, userDetails) => {
  const loginAndSignUpForDesktopMyAccountObj = {
    event: "",
    name: userDetails?.fullname,
    login_Type: googleLogin ? "google_login" : "manual_login",
  };
  if (userDetails?.userType === "exisiting") {
    getDigitalLayer().push({ ...loginAndSignUpForDesktopMyAccountObj, event: "login" });
  } else {
    getDigitalLayer().push({ ...loginAndSignUpForDesktopMyAccountObj, event: "sign_up" });
  }
};

/**
 * Sets the selected shipping method name.
 *
 * @param {string} shippingMethodName - The name of the selected shipping method.
 * @returns {void}
 */
const setGaShippingMethodName = (shippingMethodName) => {
  const selectedShippingMethodNameObj = {
    event: GA4_EVENTS.SHIPPING_METHOD_SELECTED,
    shipping_method: shippingMethodName,
  };
  getDigitalLayer().push(selectedShippingMethodNameObj);
};

/**
 * Sets the selected time slot.
 *
 * @param {string} startTime - The start time of the selected time slot.
 * @param {string} endTime - The end time of the selected time slot.
 * @returns {void}
 */
const setGaTimeSlotSelected = (startTime, endTime) => {
  const selectedTimeSlotObj = {
    event: GA4_EVENTS.TIME_SLOT_SELECTED,
    time_slot: `${startTime} - ${endTime}`,
  };
  getDigitalLayer().push(selectedTimeSlotObj);
};

/**
 * Sets the "widget_viewed" event in the digital data layer.
 *
 * @param {string} selectedCurrency - The currency selected by the user.
 * @param {object} userDetails - The user details.
 * @param {object} productDetail - The product data.
 * @param {number} position - The position of the widget.
 * @param {string} cid - The identifier of the widget clicked.
 * @param {object} selectedVariant - The selected variant of the product.
 */
const setGaWidgetViewed = (selectedCurrency, userDetails, productDetail, position, cid, selectedVariant) => {
  const {
    productId,
    productPrice,
    productName,
    variantProducts,
    productTypeId,
    primaryKeywordId,
    primaryCategoryId,
  } = productDetail?.product;
  const pincode = userDetails?.pincodeGivenByUser?.pincode;
  const loginState = userDetails?.checkLoginInfo?.loggedin;
  const price = productPrice?.price;
  const listPrice = productPrice?.listPrice;
  const defaultPrice = productPrice?.defaultPrice;
  const variant = variantProducts ? `${selectedVariant?.title}_${selectedVariant?.productId}` : null;
  const primaryKeywordIds = variantProducts ? `${selectedVariant?.primaryKeywordId}` : primaryKeywordId;
  const productTypeIds = variantProducts ? `${selectedVariant?.productTypeId}` : productTypeId;
  const primaryCategoryIds = variantProducts ? `${selectedVariant?.primaryCategoryId}` : primaryCategoryId;

  const widgetViewedObj = {
    event: GA4_EVENTS.WIDGET_VIEWED,
    pincode,
    login_state: loginState,
    ecommerce: {
      currency: "INR",
      selected_currency: selectedCurrency,
      value: price,
      widget_clicked: cid,
      widget_rank: position,
      item: [
        {
          item_name: productName,
          item_id: variantProducts ? selectedVariant?.productId : productId,
          parent_name: productId,
          price: listPrice,
          mrp: defaultPrice,
          item_brand: "FNP-India",
          item_variant: variant,
          item_category: primaryKeywordIds,
          item_category2: productTypeIds,
          item_category3: primaryCategoryIds,
          quantity: 1,
        },
      ],
    },
  };
  getDigitalLayer().push(widgetViewedObj);
};

/**
 * Sets the "add_shipping_info" event in the digital data layer.
 *
 * @param {boolean} loginState - The login state of the user.
 * @param {string} selectedCurrency - The currency selected by the user.
 * @param {string} coupon - The coupon code applied.
 * @param {Array} cartItems - The cart items data.
 * @param {number} orderGrandTotal - total value of products
 */
const setGaAddShippingInfo = (loginState, selectedCurrency, coupon, cartItems, orderGrandTotal) => {
  const products = [];
  cartItems?.orderList?.forEach((item) => {
    const product = {};
    const {
      productName,
      productId,
      price,
      productTypeId,
      primaryProductCategoryId,
      quantity,
      sku,
    } = item?.giftItem?.mainproduct;
    product.item_name = productName;
    product.item_id = productId;
    product.sku = sku || null;
    product.price = price;
    product.mrp = null;
    product.item_brand = "FNP-India";
    product.item_variant = "";
    product.item_category = primaryProductCategoryId;
    product.item_category2 = productTypeId;
    product.item_category3 = null;
    product.quantity = quantity;
    product.shippingTier = item?.giftItem?.shippingDetails?.shippingMethodName || null;
    if (item?.giftItem?.addons) {
      const addonProducts = [];
      item?.giftItem?.addons?.forEach((addon) => {
        const addonProduct = {};
        addonProduct.item_name = addon?.productName;
        addonProduct.item_id = addon?.productId;
        addonProduct.item_price = addon?.price;
        addonProduct.item_brand = addon?.brand;
        addonProduct.item_category = addon?.productCategoryId || "Addon";
        addonProduct.item_category2 = item?.productTypeId || null;
        addonProduct.item_category3 = item?.fnpProductType || null;
        addonProduct.quantity = addon?.quantity;
        addonProducts.push(addonProduct);
      });
      product.item_variant = addonProducts;
    }
    products.push(product);
  });
  const addShippingInfoObj = {
    event: GA4_EVENTS.ADD_SHIPPING_INFO,
    login_state: loginState,
    ecommerce: {
      currency: "INR",
      selected_currency: selectedCurrency,
      value: orderGrandTotal || null,
      coupon: coupon ?? "",
      item: products,
    },
  };
  getDigitalLayer().push(addShippingInfoObj);
};

/**
 * Sets the "add_payment_info" event in the digital data layer.
 *
 * @param {string} selectedCurrency - The currency selected by the user.
 * @param {object} cartItems - The cart items data.
 * @param {boolean} loginState - The login state of the user.
 * @param {object} priceDetails - The price details of the cart.
 * @param {string} paymentType - The payment type used by the user.
 */
const setGaAddPaymentInfo = (selectedCurrency, cartItems, loginState, priceDetails, paymentType) => {
  const products = [];
  cartItems?.orderList?.forEach((item) => {
    const product = {};
    const {
      productName,
      productId,
      price,
      productTypeId,
      primaryProductCategoryId,
      quantity,
    } = item?.giftItem?.mainproduct;
    product.item_name = productName;
    product.item_id = productId;
    product.price = price;
    product.mrp = null;
    product.item_brand = "FNP-India";
    product.item_variant = "";
    product.item_category = primaryProductCategoryId;
    product.item_category2 = productTypeId;
    product.item_category3 = null;
    product.quantity = quantity;
    product.shippingTier = item?.giftItem?.shippingDetails?.shippingMethodName || null;
    if (item?.giftItem?.addons) {
      const addonProducts = [];
      item?.giftItem?.addons?.forEach((addon) => {
        const addonProduct = {};
        addonProduct.item_name = addon?.productName;
        addonProduct.item_id = addon?.productId;
        addonProduct.item_price = addon?.price;
        addonProduct.item_brand = addon?.brand;
        addonProduct.item_category = addon?.productCategoryId || "Addon";
        addonProduct.item_category2 = item?.productTypeId || null;
        addonProduct.item_category3 = item?.fnpProductType || null;
        addonProduct.quantity = addon?.quantity;
        addonProducts.push(addonProduct);
      });
      product.item_variant = addonProducts;
    }
    products.push(product);
  });

  const addPaymentInfoObj = {
    event: GA4_EVENTS.ADD_PAYMENT_INFO,
    login_state: loginState,
    ecommerce: {
      currency: "INR",
      selected_currency: selectedCurrency,
      value: priceDetails?.orderGrandTotal || null,
      coupon: priceDetails?.couponCode || null,
      payment_type: paymentType,
      item: products,
    },
  };
  getDigitalLayer().push(addPaymentInfoObj);
};

/**
 * Setting the click event interactions .
 *
 * @param {string} event - event name
 * @param {string} eventCategory - event category
 * @param {string} eventAction - event action
 * @param {string} eventLabel - event label
 * @param {object} customDimension - custom dimension
 *
 */
const setGaClickEventInteraction = (event, eventCategory, eventAction, eventLabel = "", customDimension = {}) => {
  const gaLoginEvent = {
    event,
    eventCategory,
    eventAction,
  };

  if (eventLabel) Object.assign(gaLoginEvent, { eventLabel });

  if (Object.keys(customDimension)?.length) Object.assign(gaLoginEvent, { ...customDimension });

  getDigitalLayer().push(gaLoginEvent);
};

/**
 * GA4 Purchase Event
 *
 * @param {object} orderData Order Data
 * @param {string} countryCode countryCode
 * @param {string} phoneNum phoneNum
 * @param {string} selectedCurrency user selected currency
 */
const ga4PurchaseEvent = (orderData, countryCode, phoneNum, selectedCurrency) => {
  const gaPurchaceEvent = {};
  if (!orderData?.orderId || !orderData?.paymentReceived) {
    return;
  }

  if (orderData?.paymentReceived === "false") {
    const products = [];
    orderData?.thankYouPageInfo?.forEach((orderItems) => {
      orderItems?.productDetails?.forEach((item) => {
        const product = {};
        product.item_name = item?.productDisplayName || null;
        product.item_id = item?.productId || null;
        product.sku = item?.skuCode || null;
        product.price = item?.productPrice || null;
        product.item_brand = item?.brand || "FNP-India";
        product.item_category = item?.primaryProductCategoryId || null;
        product.item_category2 = item?.productTypeId || null;
        product.item_category3 = item?.productVariantType || null;
        product.pincode = orderItems?.postalAddress?.postalCode || null;
        product.quantity = 1;
        if (item?.productAddonsInfo) {
          const addonProducts = [];
          item?.productAddonsInfo?.forEach((addon) => {
            const addonProduct = {};
            addonProduct.name = addon?.addonName;
            addonProduct.id = addon?.productId;
            addonProduct.price = addon?.addonPrice;
            addonProduct.brand = addon?.brand;
            addonProduct.item_category = item?.primaryProductCategoryId || null;
            addonProduct.item_category2 = item?.productTypeId || null;
            addonProduct.item_category3 = item?.fnpProductType || null;
            addonProduct.quantity = addon?.quantity || 1;
            addonProducts.push(addonProduct);
          });
          product.item_variant = addonProducts;
        }
        products.push(product);
      });
    });
    gaPurchaceEvent.event = GA4_EVENTS.PAYMENT_FAILED;
    gaPurchaceEvent.ecommerce = {};
    gaPurchaceEvent.ecommerce.transaction_id = orderData?.orderId;
    gaPurchaceEvent.ecommerce.value = orderData?.grandTotal;
    gaPurchaceEvent.ecommerce.items = products;
  } else {
    const products = [];
    let isLuxProductContains = false;
    orderData?.thankYouPageInfo?.forEach((orderItems) => {
      orderItems?.productDetails?.forEach((item) => {
        const product = {};
        product.item_name = item?.productDisplayName || null;
        product.item_id = item?.productId || null;
        product.sku = item?.skuCode || null;
        product.price = item?.productPrice || null;
        product.item_brand = item?.brand || "FNP-India";
        product.item_category = item?.primaryProductCategoryId || null;
        product.item_category2 = item?.productTypeId || null;
        product.item_category3 = item?.productVariantType || null;
        product.pincode = orderItems?.postalAddress?.postalCode || null;
        product.quantity = 1;
        if (!isLuxProductContains && isSkuId(item?.skuCode)) {
          isLuxProductContains = true;
        }
        if (item?.productAddonsInfo) {
          const addonProducts = [];
          item?.productAddonsInfo?.forEach((addon) => {
            const addonProduct = {};
            addonProduct.name = addon?.addonName;
            addonProduct.id = addon?.productId;
            addonProduct.price = addon?.addonPrice;
            addonProduct.brand = addon?.brand;
            addonProduct.item_category = item?.primaryProductCategoryId || null;
            addonProduct.item_category2 = item?.productTypeId || null;
            addonProduct.item_category3 = item?.fnpProductType || null;
            addonProduct.quantity = addon?.quantity || 1;
            addonProducts.push(addonProduct);
          });
          product.item_variant = addonProducts;
        }
        products.push(product);
      });
    });
    gaPurchaceEvent.event = GA4_EVENTS.PURCHASE;
    gaPurchaceEvent.login_state = true;
    gaPurchaceEvent.ecommerce = {};
    gaPurchaceEvent.ecommerce.transaction_id = orderData?.orderId || null;
    gaPurchaceEvent.ecommerce.value = orderData?.grandTotal || null;
    gaPurchaceEvent.ecommerce.affiliation = "Website" || null;
    gaPurchaceEvent.ecommerce.tax = orderData?.taxAmount || null;
    gaPurchaceEvent.ecommerce.shipping = orderData?.shippingAmount || null;
    gaPurchaceEvent.ecommerce.currency = "INR";
    gaPurchaceEvent.ecommerce.selected_currency = selectedCurrency || null;
    gaPurchaceEvent.ecommerce.coupon = orderData?.coupon || null;
    gaPurchaceEvent.ecommerce.discontedPrice = orderData?.otherAdjAmount || null;
    gaPurchaceEvent.ecommerce.items = products;
    gaPurchaceEvent.ecommerce.phone = `+${countryCode}${phoneNum}`;
    gaPurchaceEvent.ecommerce.donation_amount = orderData?.donationAmount || null;
    gaPurchaceEvent.ecommerce.convenience = orderData?.convenienceAmount?.[0]?.additionalAmount || null;
    gaPurchaceEvent.ecommerce.isLuxProductContains = isLuxProductContains;
  }
  getDigitalLayer().push(gaPurchaceEvent);
};

/**
 * GA4 Search event
 *
 * @param {string} searchvalue search value
 */
const ga4SearchEvent = (searchvalue) => {
  const gaSearchEvent = {
    event: GA4_EVENTS.SEARCH,
    search: searchvalue || null,
  };
  getDigitalLayer().push(gaSearchEvent);
};
/**
 * GA4 Search event
 *
 * @param {string} country current country
 * @param {string} city selected city
 */
const ga4InternamtionalCitySelection = (country, city) => {
  const gaCitySelection = {
    event: GA4_EVENTS.AFTER_CITY_SELECTION,
    city: city || null,
    country: country || null,
  };
  getDigitalLayer().push(gaCitySelection);
};

/**
 * GA4 addon selected
 *
 * @param {string} pinCode selected pincode
 * @param {boolean} loggedIn is userLoggedin
 * @param {string} pageType what type of page
 * @param {string} interactionType type interactionType is plus icon or minus icon
 */
const ga4addonSelected = (pinCode, loggedIn, pageType, interactionType) => {
  const gaaddonSelected = {
    event: GA4_EVENTS.ADDON_SELECTED,
    pincode: pinCode || null,
    login_state: loggedIn || null,
    page_type: pageType || null,
    interactionType: interactionType || null,
  };
  getDigitalLayer().push(gaaddonSelected);
};

/**
 * GA4 continue with or without addon selected button
 *
 * @param {object} addons selected addons
 * @param {string} pinCode selected pincode
 * @param {boolean} loggedIn is userLoggedin
 * @param {string} pageType what type of page
 * @param {localCurrency} localCurrency user selected currency
 * @param {number} quantity increase quantity on plus on DA page
 */
const ga4addonAdded = (addons, pinCode, loggedIn, pageType, localCurrency, quantity) => {
  if (addons === null || addons === undefined) {
    return;
  }
  const gaaddonContinue = {};
  if (addons.length > 0) {
    const products = [];
    addons?.forEach((item, index) => {
      const product = {};
      product.index = index;
      product.item_name = item?.productName || null;
      product.item_id = item?.productId || null;
      product.price = item?.unitPrice ? item?.unitPrice : item?.price || null;
      product.mrp = item?.priceDetails?.defaultPrice || null;
      product.quantity = quantity || item?.quantity;
      products.push(product);
    });
    gaaddonContinue.event = GA4_EVENTS.ADDON_ADDED;
    gaaddonContinue.pincode = pinCode || null;
    gaaddonContinue.login_state = loggedIn || null;
    gaaddonContinue.page_type = pageType || null;
    gaaddonContinue.ecommerce = {};
    gaaddonContinue.ecommerce.currency = "INR";
    gaaddonContinue.ecommerce.selected_currency = localCurrency || null;
    gaaddonContinue.ecommerce.items = products;
  } else {
    gaaddonContinue.event = GA4_EVENTS.CONTINUE_WITHOUT_ADDON;
  }
  getDigitalLayer().push(gaaddonContinue);
};

/**
 * GA4 continue with or without addon selected button
 *
 * @param {object} products selected addons
 * @param {boolean} loggedIn is userLoggedin
 * @param {object} cartSummaryAmountDetail total cart summery on DA page
 * @param {string} localCurrency user selected currency
 * @param {object} customerDetails all customer details
 */
const ga4beginCheckout = (products, loggedIn, cartSummaryAmountDetail, localCurrency, customerDetails) => {
  if (products && products.length > 0) {
    const gaBeginCheckout = {};
    const allProducts = [];
    products?.forEach((item, index) => {
      const product = {};
      product.index = index;
      product.item_name = item?.giftItem?.mainproduct?.productName || null;
      product.item_id = item?.giftItem?.mainproduct?.productId || null;
      product.sku = item?.giftItem?.mainproduct?.sku || null;
      product.price = item?.giftItem?.mainproduct?.price || null;
      product.mrp = item?.giftItem?.mainproduct?.mrp || null;
      product.item_brand = item?.giftItem?.mainproduct?.brand || "FNP-India";
      product.item_category = item?.giftItem?.mainproduct?.primaryProductCategoryId || null;
      product.item_category2 = item?.giftItem?.mainproduct?.productTypeId || null;
      product.item_category3 = item?.giftItem?.mainproduct?.fnpProductType || null;
      product.quantity = item?.giftItem?.mainproduct?.quantity || null;
      if (item?.giftItem?.addons) {
        const addonProducts = [];
        item?.giftItem?.addons?.forEach((addon) => {
          const addonProduct = {};
          addonProduct.name = addon?.productName;
          addonProduct.id = addon?.productId;
          addonProduct.price = addon?.price;
          addonProduct.quantity = addon?.quantity;
          addonProducts.push(addonProduct);
        });
        product.item_variant = addonProducts || null;
      }

      allProducts.push(product);
    });
    gaBeginCheckout.event = GA4_EVENTS.BEGIN_CHECKOUT;
    gaBeginCheckout.login_state = loggedIn || null;
    gaBeginCheckout.ecommerce = {};
    gaBeginCheckout.ecommerce.currency = "INR";
    gaBeginCheckout.ecommerce.value = cartSummaryAmountDetail?.orderGrandTotal || null;
    gaBeginCheckout.ecommerce.coupon = cartSummaryAmountDetail?.couponCode || null;
    gaBeginCheckout.ecommerce.selected_currency = localCurrency || null;
    gaBeginCheckout.ecommerce.items = allProducts;
    gaBeginCheckout.ecommerce.email = customerDetails?.emailID || customerDetails?.emailAddress || null;
    gaBeginCheckout.ecommerce.phone_number = customerDetails?.mobNo || customerDetails?.primaryContactNumber || null;
    gaBeginCheckout.ecommerce.customer_party_id = customerDetails?.customerID || customerDetails?.senderId || null;
    gaBeginCheckout.ecommerce.userName = customerDetails?.userName || customerDetails?.senderName || null;
    getDigitalLayer().push(gaBeginCheckout);
  }
};
export {
  setProducDetailsForCheckout,
  setOrderDetailsForThankYouPage,
  setGaClickEventInteraction,
  setPdpPageGaEvent,
  removeFromCartGaEvent,
  changeVariantGaEvent,
  nsaOrOutOfStockGaEvent,
  pincodeGaEvent,
  removeAddonsFromCartGaEvent,
  ga4PurchaseEvent,
  ga4SearchEvent,
  ga4InternamtionalCitySelection,
  ga4addonSelected,
  ga4addonAdded,
  ga4beginCheckout,
  setGaLoginAndSignUp,
  setGaShippingMethodName,
  setGaTimeSlotSelected,
  setGaLoginAndSingUpForDesktopMyAccount,
  setGaWidgetViewed,
  setGaAddShippingInfo,
  setGaAddPaymentInfo,
};
